import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { setAccessibilityMessage } from "../../actions/settings-actions";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IS_CNAV } from "../../config";

const StyledFooter = styled.footer`
    padding-left: 8.515625vw;
    padding-right: 8.515625vw;
    padding-top: 5.75vh;
    padding-bottom: 2.5vh;
    width: 82.96875vw;
    background: #F0F2FE;

    * {
        font-family: 'Ubuntu', sans-serif;
    }

    #footer-content {
        
        &>.footer-segment {
            width: 100%;
        }

        &>.footer-segment>h3.footer-title {
            margin: 0;
            font-size: 1.5625em;
            letter-spacing: 0em;
            color: #1B2026;
            font-weight: 500;
        }

        &>.footer-segment>.footer-links {
            margin-top: 2em;
        }

        .footer-links {
            &>.link {
                font-size: 1em;
                margin-bottom: 0.9375em;
                font-weight: 400;
            }
            &>.link>a {
                color: #485769;
                font-weight: 500;
            }

            &>.link>a:hover {
                color: var(--blue);
            }

            &>.link>svg {
                font-size: 1.75em;
                margin-right: 0.25em;
                width: 1em;
                color: #485769;
            }
        }

    }

    #footer-bottom {
        margin-top: 9.5vh;
    }

    #footer-bottom>.footer-bottom-item {
        color: #485769;
        margin-right: 2.4375em;
        font-size: 0.875em;
    }


    @media screen and (max-width:900px) {
            .desktop {
                display: none !important;
            }
            a {
                text-decoration: none;
            }
            #footer-content {
                flex-direction: column;
                
                &>.footer-segment {
                    margin-bottom: 2.5em;
                }
                
                &>.footer-segment>h3.footer-title {
                    font-size: 1.25em;
                }
            }
            
            
            .footer-links{
                &>.link>i {
                    margin-right: 0.625em;
                }
                &>.link>a {
                    display: flex;
                    align-items: center;
                    font-size: 0.875em;
                }
            }
            
            
            #footer-bottom {
                margin-top: 4.5vh;   
                &>.footer-bottom-item {
                    margin-right: calc(0.65em + 0.8vw);
                }
                
                &>span.footer-bottom-item {
                    font-weight: 600;
                }
                
                &>a {
                    text-decoration: underline;
                }
            }
            
        }
`

const Footer = () => {

    const dispatch = useDispatch();

    const setMessage = (message) => {
        dispatch(setAccessibilityMessage(message));
    }


    if (IS_CNAV) return <div></div>
    return (
        <StyledFooter className="flex flex-direction-column">
            <div id="footer-content" className="flex">

                <div className="footer-segment flex flex-direction-column">
                    <h3 className="footer-title"> Contactez-nous </h3>
                    <div className="footer-links">
                        <div className="link flex align-items-center">
                            <FontAwesomeIcon className="flex align-items-center" color="#485769" fontSize="1.75em" icon={faEnvelope} />
                            <a href="mailto:contact@u31.io" className="">contact@u31.io</a>
                        </div>
                        <div className="link flex align-items-center">
                            <FontAwesomeIcon className="flex align-items-center" color="#485769" fontSize="1.75em" icon={faLinkedin} />
                            <a href="https://www.linkedin.com/company/u-31" target="_blank" className="">Linkedin</a>
                        </div>
                    </div>
                </div>

                <div className="footer-segment flex flex-direction-column">
                    <h3 className="footer-title"> Besoin d'aide ? </h3>
                    <div className="footer-links">
                        {!IS_CNAV &&
                            <div className="link flex align-items-center">
                                <a onClick={() => setMessage("Défilement vers la foire aux questions")} href="/#faq" className="">FAQ</a>
                            </div>
                        }
                        <div className="link flex align-items-center">
                            <a href="/contact" className="">Contact</a>
                        </div>
                    </div>
                </div>

                <div className="footer-segment flex flex-direction-column">
                    <h3 className="footer-title"> Plan du site </h3>
                    <div className="footer-links">
                        <div className="link flex align-items-center">
                            <a href="/" className="">Accueil</a>
                        </div>
                        <div className="link flex align-items-center">
                            <a href="/pourquoi-adapter-vos-contenus" className="">Pourquoi adapter vos contenus ?</a>
                        </div>
                        <div className="link flex align-items-center">
                            <a href="/notre-technologie" className="">Notre technologie</a>
                        </div>
                        <div className="link flex align-items-center">
                            <a href="/mon-compte" className="">Accédez à l'outil U31</a>
                        </div>
                        <div className="link flex align-items-center">
                            <a href="/cgus" className="">Conditions Générales d’Utilisation</a>
                        </div>
                        <div className="link flex align-items-center">
                            <a href="/politique-de-confidentialite" className="">Politique de confidentialité</a>
                        </div>
                        <div className="link flex align-items-center">
                            <a href="/accessibilite" className="">Accessibilité :<br /> totalement conforme</a>
                        </div>
                        <div className="link flex align-items-center">
                            <a href="/mentions-legales" className="">Mentions légales</a>
                        </div>
                    </div>
                </div>

            </div>

            <div id="footer-bottom" className="flex">
                <span className="footer-bottom-item"> U31 ©2022 </span>
            </div>
        </StyledFooter>
    )
}

export default Footer;