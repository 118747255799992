import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { U31Button, U31Switch, U31TextInput } from "@u31-dev/u31-ui";
import { setActiveCategories, setRightPanelActivePage } from "../../../../actions/settings-actions";
import { getRemainingWordCount, payRecommendations, sendQuotation, setSmartBilling } from "../../../../helpers";
import { getMetrics } from "../../../../reducers/nlp-reducer";
import { getCategories, getRevisionState } from "../../../../reducers/settings-reducer";
import { getUser } from "../../../../reducers/user-reducer";
import { RevisionState, UserType } from "../../../../types";
import useInput from "../../../helpers/useInput";
import { CustomSelect, CustomSelectOption } from "@u31-dev/u31-ui";
import { RightPanelPages } from "../RightPanel";
import { PartTitle } from "../shared.styled";
import { DatePriceOption, StyledRightPanelQuotations } from "./quotations.styled";

const days = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."];
const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
const getLabelForDate = (date: Date) => `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getUTCFullYear()}`;

interface DatePrice {
    date: Date,
    price: number,
}

enum QuotationState {
    SOLUTIONS,
    REQUEST_QUOTATION,
    REQUEST_SENT,
}



const RightPanelQuotations = (props: { without_reco: boolean }) => {
    const theme = useTheme();

    const [state, setState] = useState<QuotationState>(QuotationState.SOLUTIONS)
    const dispatch = useDispatch();
    const categories = useSelector(getCategories);

    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const calendarPrices = lastRevisionState?.prices?.service_prices;
    const user = useSelector(getUser) as UserType;
    const datePrices: Array<DatePrice> = (calendarPrices ? [...Array(30)].map((_, i) => {
        const difference_in_days = i - 1;

        const key = Object.keys(calendarPrices)
            .map(diff => parseInt(diff))
            .filter(diff => diff <= difference_in_days)
            .slice(-1)
            .pop()

        const date = new Date(Date.now())
        date.setDate(date.getDate() + i);
        if (calendarPrices[key]) {
            return { date: date, price: Math.round(calendarPrices[key] * 100) / 100 }
        } else {
            return { date: date, price: -1 } // <0 => disabled
        }
    }) : [])
        .filter((datePrice) => datePrice.price >= 0);

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const selectedDatePrice = datePrices[selectedIndex] || { date: new Date(), price: 0 };

    useEffect(() => {
        console.log("RERENDERRR")
    }, [])

    useEffect(() => {
        if (datePrices?.length > 0) {
            console.log("setSelectedDatePrice", datePrices)
            setSelectedIndex(0)
        }
    }, [lastRevisionState])

    const onSelectIndexChange = (index: number) => {
        console.log("onSelectedIndexChange", index)
        setSelectedIndex(index)
    };

    const Solutions = useCallback(() => {
        return (
            <div className="solutions" style={{ flexFlow: user?.offer_type?.key == "annual_license" ? "column-reverse" : "column" }}>
                <div className="solution">
                    <p className="title">Service d’adaptation en Langage Clair</p>
                    <p className="checked">Adaptation par un <b>professionnel</b></p>
                    <p className="checked"><b>Aucune action</b> requise</p>
                    <p className="checked">Livrable <b>prêt à l’emploi</b></p>
                    <p className="guaranteed_date">Date de livraison garantie</p>
                    <CustomSelect
                        onSelectedIndexChange={onSelectIndexChange}
                        selectedIndex={selectedIndex}
                        options={
                            datePrices
                                .map((datePrice, i) => {
                                    return {
                                        key: i,
                                        value: <DatePriceOption key={`${i}-option`}>
                                            <p className="date">
                                                {getLabelForDate(datePrice.date)}
                                            </p>
                                            <p className="price">
                                                {datePrice.price}€
                                            </p>
                                        </DatePriceOption>
                                    }
                                })
                        } />

                    {/* <p className="price">
                        {selectedDatePrice.price}€ <span className="ttc">TTC</span>
                    </p> */}

                    <U31Button onClick={() => { setState(QuotationState.REQUEST_QUOTATION) }}>Commander</U31Button>
                </div>


                {props.without_reco == false && <div className="solution">
                    <p className="title">Accès à l’éditeur U31</p>
                    <p className="checked"><b>Recommandations</b> de U31</p>
                    <p className="checked">Toutes les <b>fonctionnalités d’édition</b></p>
                    <p className="checked">Guide complet du <b>Langage Clair</b></p>


                    {/* do not display buy new reco and smart billing option to license users */}
                    {!["annual_license"].includes(user?.offer_type?.key) && <>
                        <div id="smart_billing" className="flex center-v">
                            <label htmlFor="smart_billing" style={{ fontSize: "14px", fontFamily: "Ubuntu", color: "#515E6E", whiteSpace: "nowrap", fontWeight: 500, marginRight: "1em" }}>Facturation intelligente </label>
                            <U31Switch checked={user?.smart_billing || false} onChange={(checked: boolean) => { setSmartBilling(checked, dispatch) }} />
                        </div>
                        {/* do not display price if already payed */}
                        {lastRevisionState.payed != true && <>
                            <p className="price">
                                {lastRevisionState?.prices?.recommendations_price} <span className="credits">crédits</span>
                            </p>
                        </>}
                        <p className="availableCredits">
                            Mots restants : <span className="credits">{getRemainingWordCount(user)}</span>
                        </p>
                    </>}

                    <U31Button onClick={() => {
                        payRecommendations(lastRevisionState, dispatch);
                        dispatch(setRightPanelActivePage(RightPanelPages.RECOMMANDATIONS))
                        // open first category
                        dispatch(setActiveCategories([Object.keys(categories)[0]]))
                    }}>Afficher les recommandations</U31Button>
                </div>}

            </div>

        )
    }, [calendarPrices, selectedDatePrice])

    const RequestQuotation = () => {
        const user = useSelector(getUser);

        useEffect(() => {
            if (user) {
                firstname.setValue(user?.firstname);
                lastname.setValue(user?.lastname);
                email.setValue(user?.email);
                phone.setValue(user?.phone);
            }
        }, [user])

        const firstname = useInput();
        const lastname = useInput();
        const organism = useInput();
        const email = useInput();
        const phone = useInput();
        const comment = useInput();

        const onSubmitForm = async (e) => {
            e.preventDefault();
            await sendQuotation({
                firstname: firstname.value,
                lastname: lastname.value,
                email: email.value,
                phone: phone.value,
                comment: comment.value,
                date: selectedDatePrice.date,
                document_id: lastRevisionState.document_id,
                version_id: lastRevisionState.document_backup_id,
            })
            setState(QuotationState.REQUEST_SENT);

        }
        return (
            <div className="requestQuotation">
                <button id="cancel"
                    onClick={() => { setState(QuotationState.SOLUTIONS) }}
                >
                    <img src="/images/rightPanel/arrow_right.svg" alt="" />
                    <p>Annuler</p>
                </button>
                <div className="summary">
                    <p className="title">Votre commande</p>
                    <p className="row"><b>1x</b> Document en Langage Clair</p>
                    <p className="delivery">Livraison garantie le : <b>{getLabelForDate(selectedDatePrice.date)}</b></p>
                    <div className="divider"></div>
                    <p className="ttc">Total TTC</p>
                    <p className="price">{selectedDatePrice.price}€</p>
                </div>

                <form onSubmit={onSubmitForm}>
                    <p className="title">Vos informations</p>
                    <label htmlFor="lastname" className="input">Nom</label>
                    <input {...lastname} required={true} type="text" name="lastname" id="lastname" />
                    <label htmlFor="firstname" className="input">Prénom</label>
                    <input {...firstname} required={true} type="text" name="firstname" id="firstname" />
                    <label htmlFor="organisme" className="input">Organisme</label>
                    <input {...organism} type="text" name="organisme" id="organisme" />
                    <label htmlFor="mail" className="input">E-mail</label>
                    <input {...email} type="text" name="mail" id="mail" />
                    <label htmlFor="phone" className="input">Tel.</label>
                    <input {...phone} type="text" name="phone" id="phone" />
                    <label htmlFor="comment" className="input">Commentaire</label>
                    <textarea {...comment} name="comment" id="comment"></textarea>
                    <U31Button
                        variant="light"
                        type="submit"
                    >Transférer votre commande</U31Button>
                </form>
            </div>
        )
    }

    const RequestSent = () => {
        return (
            <div className="quotationSent">
                <p className="title">👍 <br /> C’est envoyé !</p>
                <p className="text">
                    Nous vous recontacterons très prochainement afin de finaliser votre commande.
                </p>
                <p className="blue">
                    L’équipe de U31 vous remercie de votre confiance.
                </p>
                <U31Button
                    variant="light"
                    onClick={() => {
                        setState(QuotationState.REQUEST_QUOTATION);
                    }}
                >Retour</U31Button>
            </div>
        )
    }

    return (
        <StyledRightPanelQuotations>
            <PartTitle text={state == QuotationState.SOLUTIONS && "Nos solutions pour vous accompagner" || (state == QuotationState.REQUEST_QUOTATION || state == QuotationState.REQUEST_SENT) && "Service d’adaptation en Langage Clair" || ""} color={theme.color.primary} />
            {state == QuotationState.SOLUTIONS && <Solutions />}
            {state == QuotationState.REQUEST_QUOTATION && <RequestQuotation />}
            {state == QuotationState.REQUEST_SENT && <RequestSent />}

        </StyledRightPanelQuotations>
    )
}

export default RightPanelQuotations;