import { EditorContent } from "@tiptap/react";
import styled from "styled-components";

export const StyledEditorContent = styled(EditorContent)`
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    >div {
        height: 100%;
    }

    /* TODO: fix Erreurs soulignées : ne pas souligner les espaces à la fin de la portion soulignée.*/
    /* white-space: initial; */
    flex: 1;
    padding-bottom: 2em;
    overflow: auto;
    transition: 0.4s;
    width: 100%;
    /* TODO: don't deal with font here, but with plugins */
    font: normal normal normal 15px/31px 'Inter' !important;
    color: #1B2026;

    h1 {
        font-weight: 800;
        font-size: 20px;
    }

    h2 {
        font-weight: 600;
        font-size: 18px;
    }

    ul {
        margin-top: 0.5rem;
    
        li > p {
            margin: 0;
        }
    }

    .ProseMirror {
        outline: none;
    }

    .ProseMirror p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    .ProseMirror p:first-child.is-empty:before {
        content: attr(data-placeholder);
        color: #6A788A;
        position: absolute;
    }
    #importDocExt {
        display: none;
    }
    .ProseMirror p:first-child.is-empty ~ .react-renderer > #importDocExt {
        display: block;
    }

    /* TABLES */
    .ProseMirror {
        table {
            border-collapse: collapse;
            table-layout: fixed;
            width: 100%;
            margin: 0;
            overflow: hidden;

            td,
            th {
                min-width: 1em;
                border: 2px solid #ced4da;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                position: relative;

                > * {
                    margin-bottom: 0;
                }
            }

            th {
                font-weight: bold;
                text-align: left;
                background-color: #f1f3f5;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(200, 200, 255, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: -2px;
                width: 4px;
                background-color: #adf;
                pointer-events: none;
            }
        }
    }
    }

    .tableWrapper {
        overflow-x: auto;
    }

    .resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
    }
`;



interface StyledEditorProps {
    isLanding?: boolean,
    isDemo?: boolean,
}

export const StyledEditor = styled.div<StyledEditorProps>`
height: 100vh;
display: flex;
flex-direction: row;
background: #FFF;
overflow: hidden;
font-family: 'Inter';
font-weight: 400;
font-style: normal;



/* LANDING */
${props => props.isLanding == true && `
    height: 80% !important;
    box-shadow: rgb(66 97 178 / 13%) 10px 10px 40px;
    border-radius: 10px;
    
    #diagnostic {
        width: 310pt !important;
    }

    #editorToolbarWrapper {
        padding: 0 1rem;
    }
`}
/* DEMO */
${props => props.isDemo == true && `
    #hide_assistant {
        display: none;
    }
    .serviceButton {
        display: none !important;
    }
`}

#editorToolbarWrapper {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    transition: 0.4s;
    z-index: 1;
    flex: 1;

    #editorWrapper {
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
        padding: 0 1rem;
        flex: 1;
        margin-left: auto;
        margin-right: auto;
        overflow: auto;
        position: relative;
        z-index: 0;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-flow: column;
        max-width: 543pt;

        #editorContainer {
            flex: 1 1;
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            width: 100%;
            display: flex;
            height: 100%;
            align-items: center;
        }
    }
}

.u31-underlined {
    position: relative;
    border-bottom: 3px solid var(--data-color);
    cursor: pointer;
}

.u31-underlined-super-span {
    position: relative;
    border-bottom: 1px solid var(--data-color);
    cursor: pointer;
    /* border-bottom-style: dashed; */
}

.u31-underlined-super-span-children {
    position: relative;
    border-bottom: 3px solid var(--data-color);
    cursor: pointer;
    /* text-decoration-style: wavy;
    text-decoration-color: var(--data-color);
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    text-decoration-line: underline; */
}

.u31-underlined:hover, 
.u31-underlined.active, 
.u31-underlined.hovered {
    background: var(--data-color-opacity);
}

@media screen and (max-width: 1280px) {
    #editorToolbarWrapper #editorWrapper {
        max-width: 440pt !important;
    }
}
`
