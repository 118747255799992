import { AddRecommandationButton } from "./components/editor/rightPanel/recommandations/Recommandations";

export const INACTIVITY_DELAY_BEFORE_PROCESS: number = 5_000; // 5 secondes
export const INACTIVITY_DELAY_BEFORE_PROCESS_SHORT: number = 500; // 0.5 secondes
export const DEFAULT_TEXT_COLOR = "#000"
export const MAX_CHAR_COUNT = 2000;
export const MIN_CHAR_COUNT = 400;
export const IS_CNAV: Boolean = eval(process.env?.REACT_APP_CNAV || "false");

export const BEST_PRACTICES = {
    grammar: [],
    sent_complexity: [
        {
            id: "ef8d2b7e-183c-5076-97a2-5a2271e850fa",
            content: <p>Dans la plupart de vos phrases, y a-t-il une seule information par phrase ?</p>,
            type: "best_practice",
            category: "sent_complexity",
            name: "Syntaxe",
        },
        {
            id: "9388125c-5cb0-5fdf-81f4-9f835f0513ea",
            content: IS_CNAV ? <p>Est-ce que vous vous adressez directement à l’usager ?</p> : <p>Est-ce que vous vous adressez, dès que vous le pouvez, directement à votre lecteur ?</p>,
            type: "best_practice",
            category: "sent_complexity",
            name: "Syntaxe",
        },
        {
            id: "10a9d894-baaa-5d6b-8577-3acd4921ad98",
            content: <p>Les formules de politesse sont souvent complexes, utilisez des termes simples"</p>,
            type: "best_practice",
            category: "sent_complexity",
            name: "Formule de politesse",
        }
    ],
    word_complexity: [
        {
            id: "82b9eb3f-2425-59ee-a95b-b64c4545cd45",
            content: <p>Utilisez un même mot pour parler d’un même concept</p>,
            type: "best_practice",
            category: "word_complexity",
            name: "Synonymes",
        }
    ],
    structure: [
        {
            id: "5fafe308-900b-5214-8de5-8e381983be4a",
            content: <p>Les informations les plus importantes sont-elles placées au début de votre texte ?</p>,
            type: "best_practice",
            category: "structure",
            name: "Structure",
        },
        {
            id: "7213931c-0a81-57e0-bf6d-16a06c938ab2",
            content: <p>Si vous attendez une action de la part de votre lecteur, est-ce facile pour lui de l’identifier ?</p>,
            type: "best_practice",
            category: "structure",
            name: "Structure",
        },
        {
            id: "6deeede0-19b8-50d5-be2b-59ffd8528ff7",
            content: <p>Y a t-il bien une information principale par paragraphe ?</p>,
            type: "best_practice",
            category: "structure",
            name: "Structure",
        },
        {
            id: "48a32d39-b004-534b-b543-02734c816f71",
            content: <p>Y a-t-il les mêmes informations répétées à différents endroits du courrier ? Si oui, regroupez les.</p>,
            type: "best_practice",
            category: "structure",
            name: "Structure",
        },
        // cognitive_comfort
        {
            id: "c753e924-0d66-5b9e-b3be-058ca7fbe16f",
            content: <p>Avez-vous évité d’utiliser l’italique et le soulignement (sauf quand il s’agit de liens Internet) ?</p>,
            type: "best_practice",
            category: "structure",
            name: "Mise en page",
        },
        // {
        //     id: Math.random().toString(),
        //     type: "best_practice",
        //     content: <div>
        //         <p><AddRecommandationButton><span style={{cursor: "pointer"}}>Avez-vous bien relu le texte ?</span></AddRecommandationButton></p>
        //     </div>,
        //     category: "structure",
        //     name: "Mise en page",
        // },
    ],
    inclusion: [
        {
            id: "509c25d2-3d9e-51ad-8872-f2391ffee99e",
            content: <p>Utiliser un langage qui respecte et reflète la diversité des sexes, des orientations sexuelles, des races, des ethnies, des âges, des capacités et des origines culturelles.</p>,
            type: "best_practice",
            category: "inclusion",
            name: "Inclusion",
        },
        {
            id: "6bfe2df0-ed77-5098-b6fd-34b50e05913e",
            content: <p>Eviter  les stéréotypes raciaux, ethniques, de genre, d'âge, de classe sociale, de handicap, ou d'orientation sexuelle.</p>,
            type: "best_practice",
            category: "inclusion",
            name: "Inclusion",
        },
        {
            id: "0334c22e-9165-5581-bebf-f96006cfa9af",
            content: <p>Ajouter des alternatives textuelles aux images et pictogrammes</p>,
            type: "best_practice",
            category: "inclusion",
            name: "Inclusion",
        },
    ],
    eco: [
        {
            id: "dbb2d92b-b133-5a44-9d93-2a4e352b000e",
            content: <p>Si le texte est destiné à l'impression, envisagez d'utiliser du papier recyclé ou des encres écologiques.</p>,
            type: "best_practice",
            category: "eco",
            name: "L'écoconception",
        },
        {
            id: "fc8da990-c67b-5ac5-8fd3-fa09e73ab176",
            content: <p>Si vous utilisez des images, compressez-les ou utilisez des sites de dépôts temporaires ou des dossiers partagés et partagez seulement le lien.</p>,
            type: "best_practice",
            category: "eco",
            name: "Poids des médias",
        },
    ],
    accessibility: [
        {
            id: "07e89d09-b3fd-5ee9-be5e-1245512a720d",
            content: <p>Si vous vous écrivez pour un public dyslexique, exportez le document au format .doc ou .docx, puis appliquez <a href="https://www.dafont.com/open-dyslexic.font" target="_blank">la police open-dys</a> ou <a href="https://github.com/Orange-OpenSource/font-accessible-dfa" target="_blank">Accessible-DfA</a> </p>,
            type: "best_practice",
            category: "accessibility",
            name: "L'accessibilité",
        },
        {
            id: "54419032-d5e6-5001-a4f2-c9e5ebac7fc0",
            content: <p>Privilégiez le format .doc ou .docx au format pdf lors de l’exportation.</p>,
            type: "best_practice",
            category: "accessibility",
            name: "L'accessibilité",
        },
        {
            id: "b9349118-8528-5751-8ac6-71b718da78fa",
            content: <p>Évitez au maximum les mots et phrases en italique, en majuscule ou soulignés.</p>,
            type: "best_practice",
            category: "accessibility",
            name: "L'accessibilité",
        },
        {
            id: "faad4342-51e9-53c4-a1d6-db4c41d0cc90",
            content: <p>Après le téléchargement de votre document, pensez à ajouter une Description explicite des liens et des alternatives textuelles aux images affichées. <br />Pour les documents word (.doc et .docx) utilisez : <a href="https://support.microsoft.com/fr-fr/office/vid%C3%A9o-v%C3%A9rifier-l-accessibilit%C3%A9-de-votre-document-9d660cba-1fcd-45ad-a9d1-c4f4b5eb5b7d">https://support.microsoft.com/fr-fr/office/vid%C3%A9o-v%C3%A9rifier-l-accessibilit%C3%A9-de-votre-document-9d660cba-1fcd-45ad-a9d1-c4f4b5eb5b7d</a> .<br />Pour les PDF utilisez <a href="https://helpx.adobe.com/fr/acrobat/using/create-verify-pdf-accessibility.html">https://helpx.adobe.com/fr/acrobat/using/create-verify-pdf-accessibility.html</a></p>,
            type: "best_practice",
            category: "accessibility",
            name: "L'accessibilité",
        },
        {
            id: "8ebcc962-9d1c-5c25-8b06-842077f50aa6",
            content: <p>Essayer de faire relire votre texte par un public concerné pour avoir leurs retours</p>,
            type: "best_practice",
            category: "accessibility",
            name: "L'accessibilité",
        },
    ]
}

