import { Editor, generateHTML, JSONContent } from "@tiptap/react";
import axios from "axios";
import { findInlineNodes, findTextNodes } from "prosemirror-utils";
import { Dispatch, RefObject, useEffect, useState } from "react";
import { batch } from "react-redux";
import { setRecommandations, setScores } from "./actions/nlp-actions";
import { setRevisionState, setNlpLoading, setRightPanelActivePage, setHasDocChangedAfterProcess, setHiddenCardIds } from "./actions/settings-actions";
import { setDocuments, setUser, setUserLoggedIn } from "./actions/user-actions";
import { getExtensions } from "./components/editor/editorConfig";
import { IDocumentResponse, U31Recommandation, UserType } from '@u31-dev/u31-ui/dist/types';
import { RightPanelPages } from "./components/editor/rightPanel/RightPanel";
import { Revision, RevisionState } from "./types";

export const wording = {
    languageLevel: {
        falc: "FALC",
        lc: "Langage Clair"
    }
}

// axios.defaults.withCredentials = true;

interface AnalyzeProps {
    editor?: Editor | null,
    documentId: string,
    dispatch: Dispatch<any>,
    demo?: Boolean,
    callback?: (success: boolean, statusCode: number) => void
}

const removeKeys = (obj: Object, keys: Array<string>) => obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
        ? obj.map((item) => removeKeys(item, keys))
        : Object.keys(obj)
            .filter((k) => !keys.includes(k))
            .reduce(
                (acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }),
                {}
            )


export const analyze = (props: AnalyzeProps) => {
    const { editor, documentId, dispatch, demo, callback } = props;
    const editor_state = editor?.getJSON();
    if (!editor_state) return;

    // const light_editor_state = removeKeys(editor_state, ["marks"])
    const light_editor_state = editor_state

    let payload = {
        editor_state: light_editor_state,
    };

    if (demo != true) {
        payload = {
            ...payload,
            document_id: documentId,
            document_title: (document.querySelector("#documentTitle") as HTMLInputElement | null)?.value
        }
    }


    const config: Object = {
        method: 'post',
        url: `${process.env.REACT_APP_NLP_URL}/getRecommandations`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': demo == true ? "" : localStorage.getItem('u31token')
        },
        data: payload
    };

    dispatch(setNlpLoading(true));
    axios(config)
        .then(function (response) {
            const revision: Revision = response.data;
            batch(() => {
                dispatch(setHiddenCardIds([]))
                dispatch(setRecommandations(revision));
                dispatch(setNlpLoading(false));
                dispatch(setRevisionState({ ...revision, payed: true }));
                dispatch(setHasDocChangedAfterProcess(false));
            });
            !demo && fetchAndDispatchUser(dispatch);
            console.log("ANALYZE callback success", callback)
            if (callback)
                callback(true, response?.status);
        })
        .catch(function (error) {
            dispatch(setNlpLoading(false));
            console.log("ANALYZE callback failed", callback)
            if (callback)
                callback(false, error?.response?.status);
            if (demo) return;
            switch (error?.response?.status) {
                // unauthorized
                case 401:
                    window.location.pathname = "/connexion";
                    break;
                // need payment
                case 402:
                    const revisionState: RevisionState = error?.response?.data;
                    // user need to pay to get the recommendations
                    if (revisionState) {
                        dispatch(setRevisionState({ ...revisionState, payed: false }));
                        dispatch(setScores(revisionState.scores))
                    }
                    break;

                default:
                    break;
            }
        });
}

export const updateUser = (data: Object, dispatch: Function) => {
    axios.put(`${process.env.REACT_APP_ACCOUNT_API_URL}/users/me`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
    })
        .then((response) => {
            const data = response.data;
            console.log("NEW USER DATA", data.is_betatester)
            dispatch(setUser(data));

        }, (error) => {
            console.log("AXIOS ERROR:", error);
        });

}

export const updateTitle = (document_id: string, title: string, dispatch: Function) => {
    if (!document_id || !title) return;
    axios.post(`${process.env.REACT_APP_STORAGE_URL}/document/${document_id}/update_title`, { title }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
    })
        .then((response) => {
            const data = response.data;
            console.log("NEW TITLE OK", data)
            // dispatch(setUser(data));
        })
        .catch(error => {
            console.log("AXIOS ERROR:", error);
        })

}

export const generateAiSimpleSentenceFromRawText = async (text: string, type: string) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_AI_URL}/generate_simple_sentence_from_raw_text`, {
            "headers": {
                "content-type": "application/json",
            },
            "body": JSON.stringify({
                "text": text, 
                "type": type,
            }),
            "method": "POST"
        });
        const data = await response.json();
        console.log("generateAiSynonyms", response, data)
        return data;
    } catch (error) {
        console.log("Error fetching synonyms:", error);
    }
}


export const generateAiSynonyms = async (document_id: string, revision_id: string, recommandation_id: string) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_AI_URL}/generate_synonymes`, {
            "headers": {
                "content-type": "application/json",
            },
            "body": JSON.stringify({
                "document_id": document_id,
                "revision_id": revision_id,
                "recommandations_id": recommandation_id
            }),
            "method": "POST"
        });
        const data = await response.json();
        console.log("generateAiSynonyms", response, data, document_id, revision_id, recommandation_id)
        return data;
    } catch (error) {
        console.log("Error fetching synonyms:", error);
    }
}

export const payRecommendations = (revisionState: RevisionState, dispatch: Function) => {
    dispatch(setNlpLoading(true));
    console.log("PAY RECOMMENDATIONS", revisionState);
    const { document_id, document_backup_id } = revisionState;

    const config: Object = {
        method: 'post',
        url: `${process.env.REACT_APP_NLP_URL}/payRecommandations`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
            document_id,
            document_backup_id
        }
    };

    return new Promise((resolve, reject) => {

        axios(config)
            .then(function (response) {
                dispatch(setRecommandations(response.data));
                dispatch(setNlpLoading(false));
                dispatch(setRevisionState({ ...revisionState, payed: true, not_enough_credits: false }));
                fetchAndDispatchUser(dispatch);
                return resolve(true)
            })
            .catch(function (error) {
                dispatch(setNlpLoading(false));
                switch (error?.response?.status) {
                    // unauthorized
                    case 401:
                        window.location.pathname = "/connexion";
                        break;
                    // if we try to pay and receive a 402, it means we do not have enough credits
                    case 402:
                        dispatch(setRevisionState({ ...revisionState, not_enough_credits: true }));
                        break;
                    default:
                        break;
                }
            });
    });

}

export const sendUserSuggestion = (data: {
    category: string,
    sentence: string,
    context: [number, number],
    synonymes?: string[],
    definition?: string,
    simpleSentence?: string,
}) => {
    return new Promise((resolve, reject) => {

        const config: Object = {
            method: 'post',
            url: `${process.env.REACT_APP_STORAGE_URL}/userSuggestion`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('u31token')
            },
            data
        };
        axios(config)
            .then(function (response) {
                return resolve(response);
            })
            .catch(function (error) {
                return reject(error);
            });
    });
}



export const createDocument = (): Promise<string> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_STORAGE_URL}/document/create`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
        }
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data._id);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

export const importDocument = (document_title, editor_state): Promise<any> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_STORAGE_URL}/document/import`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
            document_title,
            editor_state
        }
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

interface SendQuotationProps {
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    comment: string,
    date: string,
    document_id: string,
    version_id: string,
}
export const sendQuotation = (data: SendQuotationProps): Promise<string> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_TOOLS_URL}/mailer/quotation`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}


interface AskCreditsProps {
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    comment: string,
    credit_amount: number,
}
export const askCredits = (data: AskCreditsProps): Promise<string> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_TOOLS_URL}/mailer/credits`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

export const setIgnoredCards = (document_backup_id: string, ignored_cards_ids: any): Promise<string> => {

    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_STORAGE_URL}/processedDocumentVersion/${document_backup_id}/setIgnoredCards`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
            cards_ids: ignored_cards_ids
        }
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

export const addAppliedCardId = (document_backup_id: string, applied_card_id: any): Promise<string> => {

    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_STORAGE_URL}/processedDocumentVersion/${document_backup_id}/appliedCard`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
            card_id: applied_card_id
        }
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

export const validateBestPractices = (document_id: string, applied_card_id: string): Promise<string> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_STORAGE_URL}/document/${document_id}/best_practices/validate`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
            id: applied_card_id
        }
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}

export const discardBestPractices = (document_id: string, discarded_card_id: string): Promise<string> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_STORAGE_URL}/document/${document_id}/best_practices/discard`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
            id: discarded_card_id
        }
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })
}

interface AskLicenceProps {
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    comment: string,
}
export const askLicence = (data: AskLicenceProps): Promise<string> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_TOOLS_URL}/mailer/licence`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

export const fetchAndDispatchUser = async (dispatch: Function) => {
    try {
        const auth = (await axios.get(`${process.env.REACT_APP_ACCOUNT_API_URL}/users/me`, { credentials: 'include', headers: { Authorization: localStorage.getItem("u31token") } },));
        const user = auth.data;
        dispatch(setUser(user));
        dispatch(setUserLoggedIn(true));
        return user;
    } catch (error) {
        console.log("USERRRRRR", error)
        dispatch(setUserLoggedIn(false));
        return false;
    }
}

export const setSmartBilling = (enabled: boolean, dispatch: Function): Promise<string> => {
    const config: any = {
        method: 'post',
        url: `${process.env.REACT_APP_ACCOUNT_API_URL}/users/me/set_smart_billing`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        },
        data: {
            enabled
        }
    };

    return new Promise<string>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(dispatch(setUser(response.data)));
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

export const getDocument = (id: string): Promise<IDocumentResponse> => {
    const config: any = {
        method: 'get',
        url: `${process.env.REACT_APP_STORAGE_URL}/document/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('u31token')
        }
    };

    return new Promise<IDocumentResponse>((resolve, reject) => {
        axios(config)
            .then(function (response) {
                return resolve(response.data as IDocumentResponse);
            })
            .catch(function (error) {
                console.log(error);
            });
    })

}

export const fetchMyDocuments = (dispatch: Function): Promise<string> => {
    return new Promise<string>((resolve, reject) => {

        axios.get(`${process.env.REACT_APP_STORAGE_URL}/document/getAllWithScore`, {
            headers: {
                'Authorization': localStorage.getItem('u31token')
            },
        })
            .then((resp) => resp.data)
            .then((data) => {
                return resolve(dispatch(setDocuments(data)));
            })
    })
}


export const useInput = (initialValue: string) => {

    const [value, setValue] = useState(initialValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return {
        value,
        onChange: handleChange
    };
};

export function useHover<T extends HTMLElement = HTMLElement>(
    elementRef: RefObject<T>,
): boolean {
    const [value, setValue] = useState<boolean>(false)

    const handleMouseEnter = () => setValue(true)
    const handleMouseLeave = () => setValue(false)

    useEffect(() => {
        const node = elementRef?.current

        if (node) {
            node.addEventListener('mouseenter', handleMouseEnter)
            node.addEventListener('mouseleave', handleMouseLeave)

            return () => {
                node.removeEventListener('mouseenter', handleMouseEnter)
                node.removeEventListener('mouseleave', handleMouseLeave)
            }
        }
    }, [elementRef])

    return value
}

// export const getCookie = (name: string) => {
//     var value = "; " + document.cookie;
//     var parts: Array<String> = value.split("; " + name + "=");
//     if (parts.length == 2) return parts?.pop()?.split(";").shift();
// };


interface WindowSize {
    width: number
    height: number
}

export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: 0,
        height: 0,
    })

    useEffect(() => {
        const handler = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        handler()

        window.addEventListener('resize', handler)

        return () => {
            window.removeEventListener('resize', handler)
        }
    }, [])

    return windowSize
}


export const isDateThisDay = (date: Date) => {
    const today = new Date();
    return date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();
}

export const formatDate = (date: Date) => {
    const days = new Array('Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi');
    const months = new Array('Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre')

    return `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;

}


/**
 * group / merge  overlapping recommandations
 * going from
 * [0, 5] => reco1 | [2, 10] => reco2
 * to
 * [0, 2] => reco1 | [2, 5] => reco1, reco2 | [5, 10] => reco2
 */
export const createRangesForRecos = (recommandations: Array<U31Recommandation>, categories) => {
    const ranges = recommandations.reduce((acc, reco) => {
        console.log("CATEGORIES" , categories, reco.category, reco)
        reco.color = categories[reco.category].color
        var newRanges = []

        if (reco?.data?.super_span)
            newRanges.push({ recos: { ...reco, isSuperSpan: true }, begin: reco.data.super_span[0], end: reco.data.super_span[1] });

        reco.spans.forEach((span) => newRanges.push({ recos: reco, begin: span[0], end: span[1] }));
        return [...acc, newRanges]
    }, []).flat()

    const flatRanges = flattenRanges(ranges);
    const inflatedRanges = inflateRanges(flatRanges);
    return inflatedRanges.filter(range => range.recos);
}

// export const createRangesForSuperSpanRecos = (recommandations: Array<U31Recommandation>, categories) => {
//     const ranges = recommandations.reduce((acc, reco) => {
//         console.log("RECOOOOOOO", reco)
//         reco.color = categories[reco.category].color
//         // return [...acc, reco.spans.map((span) => ({ recos: reco, begin: span[0], end: span[1] }))]
//         return [...acc, [{ recos: reco, begin: reco.data.super_span[0], end: reco.data.super_span[1] }]]
//     }, []).flat()

//     const flatRanges = flattenRanges(ranges);
//     const inflatedRanges = inflateRanges(flatRanges);
//     return inflatedRanges.filter(range => range.recos);
// }

function flattenRanges(ranges) {
    var points = [];
    var flattened = [];
    for (var i in ranges) {
        if (ranges[i].end < ranges[i].begin) { //RE-ORDER THIS ITEM (BEGIN/END)
            var tmp = ranges[i].end; //RE-ORDER BY SWAPPING
            ranges[i].end = ranges[i].begin;
            ranges[i].begin = tmp;
        }
        points.push(ranges[i].begin);
        points.push(ranges[i].end);
    }
    //MAKE SURE OUR LIST OF POINTS IS IN ORDER
    points.sort(function (a, b) { return a - b });
    //FIND THE INTERSECTING SPANS FOR EACH PAIR OF POINTS (IF ANY)
    //ALSO MERGE THE ATTRIBUTES OF EACH INTERSECTING SPAN, AND INCREASE THE COUNT FOR EACH INTERSECTION
    for (var i in points) {
        if (i == 0 || points[i] == points[i - 1]) continue;
        var includedRanges = ranges.filter(function (x) {
            return (Math.max(x.begin, points[i - 1]) < Math.min(x.end, points[i]));
        });
        if (includedRanges.length > 0) {
            var flattenedRange = {
                begin: points[i - 1],
                end: points[i],
                count: 0
            }
            for (var j in includedRanges) {
                var includedRange = includedRanges[j];
                for (var prop in includedRange) {
                    if (prop != 'begin' && prop != 'end') {
                        if (!flattenedRange[prop]) flattenedRange[prop] = [];
                        flattenedRange[prop].push(includedRange[prop]);
                    }
                }
                flattenedRange.count++;
            }
            flattened.push(flattenedRange);
        }
    }
    return flattened;
}


function inflateRanges(ranges, length = 0) {
    var inflated = [];
    var lastIndex;
    for (var i in ranges) {
        if (i == 0) {
            //IF THERE IS EMPTY TEXT IN THE BEGINNING, CREATE AN EMPTY RANGE
            if (ranges[i].begin > 0) {
                inflated.push({
                    begin: 0,
                    end: ranges[i].begin - 1,
                    count: 0
                });
            }
            inflated.push(ranges[i]);
        } else {
            // if (ranges[i].begin == ranges[i - 1].end) {
            //     ranges[i - 1].end--;
            // }
            if (ranges[i].begin - ranges[i - 1].end > 1) {
                inflated.push({
                    begin: ranges[i - 1].end + 1,
                    end: ranges[i].begin - 1,
                    count: 0
                });
            }
            inflated.push(ranges[i]);
        }
        lastIndex = ranges[i].end;
    }
    //FOR SIMPLICITY, ADD ANY REMAINING TEXT AS AN EMPTY RANGE
    if (lastIndex + 1 < length - 1) {
        inflated.push({
            begin: lastIndex + 1,
            end: length - 1,
            count: 0
        })
    }
    return inflated;
}


function fillRanges(ranges, text) {
    for (var i in ranges) {
        ranges[i].text = text.slice(ranges[i].begin, ranges[i].end + 1);
    }
    return ranges;
}




// // find absolute text offset in proseMirror document, from text offset in specific line
// export const findTextPos = (editor: Editor | null, line: number, idx: number) => {
//     let text_pos = 0;
//     let ret = 0;
//     let found = false;
//     const line_offset = editor?.state.doc.content?.content.slice(0, line).reduce((total, block) => block.nodeSize + total, 0)

//     findInlineNodes(editor?.state.doc.content?.content[line]).map(({ node, pos: node_pos }) => {
//         let text_size = 0;
//         if (node.text) {
//             text_size = node.text.length
//         }
//         if (node.type.name == "hardBreak") {
//             text_size = 1;
//         }

//         if (!found && (text_pos + text_size) >= idx) {
//             ret = node_pos + idx - text_pos + 1
//             found = true;
//         }
//         // console.log(text_pos, text_size)
//         text_pos += text_size;
//     })
//     return ret + line_offset;
// }


export const readFileInputEventAsArrayBuffer = (file: any): Promise<ArrayBuffer> => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = function (loadEvent) {
            var arrayBuffer = loadEvent?.target?.result;
            return resolve(arrayBuffer as ArrayBuffer);
        };

        reader.readAsArrayBuffer(file);
    })
}


const exportedFileAndDownload = (html: string, filename: string, type: string) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        html,
        filename,
        type,
    });

    var requestOptions: Object = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };



    fetch(`${process.env.REACT_APP_TOOLS_URL}/convert`, requestOptions)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `${filename || "document"}.${type}`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again         
        })
        .catch(error => console.log('error', error));
}


export const exportDocument = async (props: { editor?: Editor, fileName: string, type: string }) => {
    const { editor, fileName, type } = props;
    if (editor) {

        const savedDocument = editor.getJSON() as JSONContent;

        // remove u31 marks
        editor?.commands.focus();
        editor?.commands.selectAll();
        editor?.commands.unsetU31HighlightReco();


        const contentHtml = generateHTML(
            editor.getJSON() as JSONContent,
            getExtensions({ isHeadLess: true })
        );

        const html = `
        <html>
        <style>
        body: {
            font-size: 14pt;
            font-family: Ubuntu;
        }
        h1 {
            font-size: 30pt;
            font-family: Ubuntu;
        }
        h2 {
            font-size: 22pt;
            font-family: Ubuntu;
        }
        img {
            width: 50pt;
        }
        p {
            font-size: 14pt;
            font-family: Ubuntu;
            margin-top: 10pt;
        }
        </style>
        <body style="font-family: Ubuntu">
        ${contentHtml}
        </body>
        </html>
        `
        editor.commands.setContent(savedDocument)
        exportedFileAndDownload(html, fileName, type)
    }
}


export const srSpeak = (text: string, priority?: string) => {
    var el = document.createElement("div");
    var id = "speak-" + Date.now();
    el.setAttribute("id", id);
    el.setAttribute("aria-live", priority || "polite");
    el.classList.add("visually-hidden");
    document.body.appendChild(el);

    window.setTimeout(function () {
        document.getElementById(id).innerHTML = text;
    }, 100);

    window.setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
    }, 1000);
}


export const getRemainingWordCount = (user?: UserType) => {
    if (!user || !user?.credits) return 0;
    const averageWordLength = 4.8;
    const remainingChars = user.credits * 1000;
    return Math.round(remainingChars / (averageWordLength + 1)) // +1 for space.
}