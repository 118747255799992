import { FC, Fragment, RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguageRecommandationsAsArray } from "../../../../reducers/nlp-reducer";
import { getaddRecommandationState, getHiddenCardIds, getActiveCategories, getLanguage, getOpenCardId, getIgnoredCardIds, getCategories, getActiveDocumentId, getRevisionState, getDiscardedBestPracticesIds, getValidatedBestPracticesIds, getOpenCardIdForCategory } from "../../../../reducers/settings-reducer";
import { setAccessibilityMessage, setActiveCategories, setaddRecommandationState, setHiddenCardIds, setOpenCardId, setOpenCardIdForCategory, setRightPanelActivePage } from "../../../../actions/settings-actions";
import { Editor } from "@tiptap/react";
import { IgnoredCardsWrapper, StyledGoToNextCategory, StyledNextStep, StyledRecommandations } from "./recommandations.styled";
import { AddRecommandationCard } from "./cards/AddRecommandationCard";
import { RightPanelPages } from "../RightPanel";
import { PartTitle } from "../shared.styled";
import NewRecommandations from "./NewRecommandations";
import { findChildrenByAttr, findChildrenByMark } from "prosemirror-utils";

import { BaseCard, SimpleCard, CardWithPropositions, CardEnum, CardBestPractice, CardLara, CardToUnlock, CardAIGeneration, U31Button, U31ActionButton, CardCombined } from '@u31-dev/u31-ui';
import { CardContext, RevisionState, U31Recommandation } from '@u31-dev/u31-ui/dist/types';
import { ActionDiscardBestPractice, ActionIgnoreReco, ActionInsertEnum, ActionUndoIgnoreReco, ActionValidateBestPractice, insertText, } from "./cards/CardActions";
import { addAppliedCardId, analyze, generateAiSynonyms, srSpeak, updateTitle } from "../../../../helpers";
import { IS_CNAV } from "../../../../config";
import { Key } from "@styled-icons/foundation";
import WaitLoading, { SpinnerLoading } from "../../../WaitLoading";
import { getUser } from "../../../../reducers/user-reducer";
import { UserType } from "../../../../types";
import React from "react";
import { setDocumentTitle } from "../../../../actions/nlp-actions";
import styled from "styled-components";
import { collectFrontRecommandations, IFrontRecommandations } from "../../../frontRecommendations";


type RecommandationsProps = {
    editorRef: RefObject<Editor> | null,
    isDemo?: boolean,
    frontRecos: IFrontRecommandations
};


const cnavLogo = <svg width="30" viewBox="0 0 99 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.8465 47.8549C44.8465 47.8549 25.6285 45.6449 17.8825 26.3209C17.8825 26.3209 16.6385 23.0059 17.6085 22.7329C17.6085 22.7329 20.6495 20.523 26.4525 22.591C27.017 23.0921 27.384 23.7783 27.4875 24.526C28.7776 28.587 30.9518 32.3117 33.8535 35.432C33.8535 35.432 38.9635 42.815 51.5485 44.132C51.5485 44.132 71.6585 34.187 86.8765 4.36696L88.1195 1.74592C88.1195 1.74592 89.2195 -0.323081 93.0965 0.0899188C93.0965 0.0899188 98.4905 0.501918 98.0735 2.57692C98.0735 2.57692 98.6285 2.57697 93.9275 10.856C93.9275 10.856 80.2405 35.8479 56.4585 48.5559C45.663 54.8502 33.7234 58.9341 21.3345 60.57C21.3345 60.57 7.37352 62.776 2.94752 61.531C2.94752 61.531 -0.924483 60.5699 1.56552 58.3569C3.31451 57.6327 5.20148 57.3022 7.09253 57.3889C7.09253 57.3889 30.1925 56.7009 44.8455 47.8649" fill="#FFA900" />
    <path d="M63.7782 13.6319C67.5842 17.4409 66.3692 24.8039 61.0702 30.0799C55.7712 35.3559 48.3903 36.5409 44.5883 32.7299C40.7863 28.9189 41.9992 21.5529 47.2992 16.2769C52.5992 11.0009 59.9772 9.8189 63.7782 13.6319Z" fill="#005FAE" />
</svg>

export const scrollDocumentMarkToTop = (cardId: string) => {
    const scrollableY = document.querySelector(".scrollableY")
    const editor = document.querySelector("#editorWrapper")
    if (!scrollableY || !editor) return;
    const target = Array.from(document.querySelectorAll(".u31-underlined")).find(elem => JSON.parse(elem.getAttribute('u31-ids')).includes(cardId))
    if (!target) return;
    editor.scrollBy({ top: target.getBoundingClientRect().y - editor.getBoundingClientRect().y - scrollableY.getBoundingClientRect().y - 150, behavior: "smooth" })
}

export const getOffset = (editor: Editor, reco: U31Recommandation) => {
    const u31HighlightRecoNodes = findChildrenByMark(editor.state.doc, editor.schema.marks.u31HighlightReco)

    for (let i = 0; i < u31HighlightRecoNodes.length; i++) {
        const nodeWithPos = u31HighlightRecoNodes[i]
        const { node, pos } = nodeWithPos;
        for (let j = 0; j < node.marks.length; j++) {
            const mark = node.marks[j];
            if (mark.type.name == "u31HighlightReco") {
                for (let k = 0; k < mark.attrs.u31recommandations.length; k++) {
                    const markReco = mark.attrs.u31recommandations[k]
                    if (markReco.id == reco.id) {
                        return pos - reco.spans[0][0];
                    }
                }
            }
        }

    }
    return 0;
};


interface IRenderCardReco {
    reco: U31Recommandation,
    ignored: boolean,
    editor: Editor,
    setErrorSelection: Function,
    nextCardId: string,
    isDemo?: boolean,
    // isOpen: boolean,
}

const RenderCardReco = (props: IRenderCardReco) => {
    // const RenderCardReco = (props: IRenderCardReco) => {
    const dispatch = useDispatch();
    const categories = useSelector(getCategories);
    const color = categories[props.reco.category].color
    const openCardId = useSelector(getOpenCardIdForCategory(props.reco.category));
    const isOpen = props.reco.id == openCardId;
    const cardId = props.reco.id;
    const ignored = props.ignored;
    const hiddenCardIds = useSelector(getHiddenCardIds);
    const documentId = useSelector(getActiveDocumentId);
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const insert_range = props?.reco?.insert_range;

    useEffect(() => {
        if (props.reco.id == "42da37e0-f789-4c47-b833-23b10d63e14b") {
            console.log("42da37e0-f789-4c47-b833-23b10d63e14b RENDER")
        }
    }, [])


    const [generatedContent, setGeneratedContent] = useState<string | undefined>();
    const [generatedContentLoading, setGeneratedContentLoading] = useState<boolean>(false);


    const generateDocumentTitle = () => {
        setGeneratedContentLoading(true);
        const text = props.editor?.getText().slice(0, 500);
        console.log("TEXT", text);

        fetch(`${process.env.REACT_APP_AI_URL}/generate_title`, {
            body: JSON.stringify({
                "text": text
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "POST"
        })
            .then(response => response.json())
            .then((data: any) => {
                if (data?.generated && documentId) {
                    updateTitle(documentId, data.generated, dispatch)
                    setGeneratedContent(data.generated)
                    setGeneratedContentLoading(false);
                }
            })

    }


    const generateSummary = (text: string) => {
        setGeneratedContentLoading(true);
        fetch(`${process.env.REACT_APP_AI_URL}/summarize`, {
            body: JSON.stringify({
                "text": text,
                "max_length": 100,
                "num_beams": 4,
                "skip_special_tokens": true,
                "clean_up_tokenization_spaces": false
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "POST"
        })
            .then(response => response.json())
            .then((data: any) => {
                setGeneratedContent(data?.generated || "Erreur lors de la génération.")
                setGeneratedContentLoading(false);
            })
    }

    const generateTitle = (text: string) => {
        setGeneratedContentLoading(true);
        fetch(`${process.env.REACT_APP_AI_URL}/generate_title`, {
            body: JSON.stringify({
                "text": text,
                "max_length": 100,
                "num_beams": 4,
                "skip_special_tokens": true,
                "clean_up_tokenization_spaces": false
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "POST"
        })
            .then(response => response.json())
            .then((data: any) => {
                setGeneratedContent(data?.generated || "Erreur lors de la génération.")
                setGeneratedContentLoading(false);
            })
    }

    const onCardClicked = () => {
        scrollDocumentMarkToTop(cardId);
        dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: isOpen ? null : cardId }))
    }

    const user = useSelector<UserType | null>(getUser);

    // re analyze after text insertion
    // keep track of applied card_ids
    const _insertText = (editor: Editor, reco: U31Recommandation, range: [number, number], text: string, reprocess: boolean) => insertText(editor, reco, range, text, () => {
        dispatch(setAccessibilityMessage("text inséré"));
        if (props?.isDemo == true) return;

        addAppliedCardId(lastRevisionState.document_backup_id, reco.id);
        dispatch(setHiddenCardIds([...hiddenCardIds, reco.id]))
        if (reprocess) {
            setTimeout(() => {
                analyze({
                    editor: props.editor,
                    documentId: documentId,
                    dispatch,
                    demo: false,
                });
            }, 400)
        }
    })

    const cardContext: CardContext = {
        // enableGeneration: user.is_betatester,
        enableGeneration: true, // NOTE: now always enable generation
        document_id: lastRevisionState.document_id,
        revision_id: lastRevisionState.document_backup_id,
        user: user,
    }

    const validatedBestPractices = useSelector(getValidatedBestPracticesIds);
    const discardedBestPractices = useSelector(getDiscardedBestPracticesIds);



    // TODO DO SAME AS SIMPLECARD FOR ALL OTHER (IMPORT FROM u31-ui)
    switch (props.reco.type) {
        case "simple":
            return <SimpleCard
                key={props.reco.id}
                onClick={onCardClicked}
                color={color}
                reco={props.reco}
                ignored={props.ignored}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                nextCardId={props.nextCardId}
                // infoTooltip="Règle issue du standard Langage Clair."
                insertText={(text: string, range: [number, number]) => {
                    _insertText(props.editor, props.reco, range, text, true);
                    dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
                    setTimeout(() => {
                        dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                        dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: props.nextCardId }))
                    }, 400)
                }}
                actions={
                    <>
                        {ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />}
                    </>
                }
            />
        case "enum":
            let htmlEnumToList = "";
            if (props.editor) {
                const block_pos = getOffset(props.editor, props.reco)
                try {
                    const enum_spans = Array.from(
                        new Map(props.reco.data.enum_spans.map((span: Array<any>) => [span.join(), span])).values()
                    )

                    htmlEnumToList = "<ul>" + enum_spans.map((spans: any) => `<li>${spans.map((span: [number, number]) => props.editor.state.doc.textBetween(span[0] + block_pos, span[1] + block_pos)/*.trim()*/).join("")}</li>`, '').join("") + "</ul>"
                } catch (error) {
                    console.log("ERROR", error)
                }
            }

            return <CardEnum
                key={props.reco.id}
                onClick={onCardClicked}
                htmlEnumToList={htmlEnumToList}
                color={color}
                reco={props.reco}
                ignored={props.ignored}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                nextCardId={props.nextCardId}
                // infoTooltip="Règle issue du standard Langage Clair."
                actions={
                    <>
                        <ActionInsertEnum editor={props.editor} html={htmlEnumToList} reco={props.reco} nextCardId={props.nextCardId} />
                        {ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />}
                    </>
                }

            />

        case "card_with_propositions":
            return <CardWithPropositions
                key={props.reco.id}
                onClick={onCardClicked}
                color={color}
                ignored={props.ignored}
                reco={props.reco}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                nextCardId={props.nextCardId}
                // infoTooltip="Règle issue du standard Langage Clair."
                insertText={(text: string, range: [number, number]) => {
                    _insertText(props.editor, props.reco, range, text, true);
                    dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
                    setTimeout(() => {
                        dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                        dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: props.nextCardId }))
                    }, 400)
                }}
                actions={
                    <>
                        {
                            ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />
                        }
                    </>
                }
            />
        case "lara":
            return <CardLara
                key={props.reco.id}
                reco={props.reco}
                onClick={onCardClicked}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                insertText={(text: string, range: [number, number]) => {
                    _insertText(props.editor, props.reco, range, text, true);
                    dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
                    setTimeout(() => {
                        dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                        dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: props.nextCardId }))
                    }, 400)
                }}
                // badge={<img src="/images/rightPanel/actions/icone_guide.svg" style={{ position: 'absolute', width: '0.9rem' }} />}
                infoTooltip={IS_CNAV ? "Lexique ou règle personnalisés pour l'administration." : undefined}
                infoTooltipLogo={IS_CNAV ? cnavLogo : undefined}
                nextCardId={props.nextCardId}
                color={color}
                actions={
                    <>
                        {
                            ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />
                        }
                    </>
                }

            />

        case "cnav":
            return <CardLara
                key={props.reco.id}
                reco={props.reco}
                onClick={onCardClicked}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                insertText={(text: string, range: [number, number]) => {
                    _insertText(props.editor, props.reco, range, text, true);
                    dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
                    setTimeout(() => {
                        dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                        dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: props.nextCardId }))
                    }, 400)
                }}
                // badge={<img src="/images/cnav.svg" style={{ position: 'absolute', width: '1.5rem' }} />}
                infoTooltip={IS_CNAV ? "Lexique ou règle personnalisés pour l'administration." : undefined}
                infoTooltipLogo={IS_CNAV ? cnavLogo : undefined}
                nextCardId={props.nextCardId}
                color={color}
                actions={
                    <>
                        {
                            ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />
                        }
                    </>
                }

            />

        case "best_practice":
            return !validatedBestPractices.includes(props.reco.id) && !discardedBestPractices.includes(props.reco.id) ? hiddenCardIds.includes(props.reco.id) ? <></> : <CardBestPractice
                key={props.reco.id}
                reco={props.reco}
                color={color}
                isOpen={true}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                nextCardId={""}
                actions={
                    <>
                        <ActionDiscardBestPractice documentId={documentId} color={color} nextCardId={""} reco={props.reco} />
                        <ActionValidateBestPractice documentId={documentId} color={color} nextCardId={""} reco={props.reco} />
                    </>
                }
            /> : <></>

        case "empty":
            return <CardToUnlock
                key={props.reco.id}
                isOpen={false}
                reco={props.reco}
                color={color}
                nextCardId={""}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
            />

        case "generate_summary":
            return <CardAIGeneration
                key={props.reco.id}
                nextCardId={props.nextCardId}
                color={color}
                reco={props.reco}
                onClick={onCardClicked}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                generatedContent={generatedContent}
                isLoading={generatedContentLoading}
                actions={
                    <>
                        {
                            ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />
                        }
                        {!generatedContent && <U31ActionButton style={{ opacity: generatedContentLoading ? 0.5 : 1 }} onClick={() => !generatedContentLoading && generateSummary(props.reco.context)}>{generatedContentLoading ? "Chargement..." : "Résumer"}</U31ActionButton>}
                        {generatedContent && !!insert_range && <U31ActionButton onClick={() => {
                            _insertText(props.editor, props.reco, insert_range, generatedContent, false);
                            // dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]));
                        }}>Remplacer</U31ActionButton>}
                    </>
                }
            />

        case "generate_title":
            return <CardAIGeneration
                key={props.reco.id}
                nextCardId={props.nextCardId}
                color={color}
                reco={props.reco}
                onClick={onCardClicked}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                generatedContent={generatedContent}
                isLoading={generatedContentLoading}
                actions={
                    <>
                        {
                            ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />
                        }
                        {!generatedContent && <U31ActionButton style={{ opacity: generatedContentLoading ? 0.5 : 1 }} onClick={() => !generatedContentLoading && generateTitle(props.reco.context)}>{generatedContentLoading ? "Chargement..." : "Générer un titre"}</U31ActionButton>}
                        {generatedContent && !!insert_range && <U31ActionButton onClick={() => {
                            _insertText(props.editor, props.reco, insert_range, `<h2>${generatedContent}</h2><br/>`, false)
                            // dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                        }}>Insérer</U31ActionButton>}
                    </>
                }
            />
        case "generate_document_title":
            return <CardAIGeneration
                key={props.reco.id}
                nextCardId={props.nextCardId}
                color={color}
                reco={props.reco}
                onClick={onCardClicked}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                generatedContent={generatedContent}
                isLoading={generatedContentLoading}
                actions={
                    <>
                        {
                            ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />
                        }
                        {!generatedContent && <U31ActionButton style={{ opacity: generatedContentLoading ? 0.5 : 1 }}
                            onClick={() => {
                                if (!generatedContentLoading)
                                    generateDocumentTitle()
                            }}
                        >{generatedContentLoading ? "Chargement..." : "Générer un titre"}</U31ActionButton>}
                        {generatedContent && !!insert_range && <U31ActionButton onClick={() => {
                            // _insertText(props.editor, props.reco, insert_range, `<h2>${generatedContent}</h2><br/>`, false)
                            dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                            dispatch(setDocumentTitle(generatedContent))

                        }}>Insérer</U31ActionButton>}
                    </>
                }
            />
        case "combined_card":
            return <CardCombined
                key={props.reco.id}
                nextCardId={props.nextCardId}
                color={color}
                reco={props.reco}
                onClick={onCardClicked}
                isOpen={isOpen}
                setErrorSelection={props.setErrorSelection}
                cardContext={cardContext}
                insertText={(text: string, range: [number, number]) => {
                    _insertText(props.editor, props.reco, range, text, true);
                    dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
                    setTimeout(() => {
                        dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                        dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: props.nextCardId }))
                    }, 400)
                }}

                actions={
                    <>
                        {ignored ? <ActionUndoIgnoreReco reco={props.reco} /> : <ActionIgnoreReco editor={props.editor} nextCardId={props.nextCardId} reco={props.reco} />}
                    </>
                }
            />

        default:
            return <></>
    }
}

const DownArrow = (props: { color: string, open: boolean }) => {
    return (
        <svg
            style={{ transition: "0.4s", transform: `rotate(${props.open ? -180 : 0}deg` }}
            xmlns="http://www.w3.org/2000/svg"
            width="7.852"
            height="3.961"
            viewBox="0 0 7.852 3.961"
        >
            <path
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M7.147.705L3.905 3.461.705.705"
                data-name="Tracé 297"
            ></path>
        </svg>
    );
}

const BestPracticesContainer = styled.div`
    display: flex;
    flex-flow: column;
    min-height: 0;
    height: 0;
    transition: 0.4s;
    overflow: hidden;
    
    &.open {
        overflow: visible;
        min-height: 100%;
        height: 100%;
    }
`

export const AddRecommandationButton = (props: { children: JSX.Element }) => {
    const dispatch = useDispatch();
    const switchToRecommandationMode = () => {
        const scrollableY = document.querySelector(".scrollableY");
        if (!scrollableY) return;
        scrollableY.scrollTo({ top: 0, behavior: "smooth" })
        dispatch(setaddRecommandationState(1))
    }
    return (
        <span
            onClick={switchToRecommandationMode}
        >
            {props.children}
        </span>
        // <U31Button
        //     variant="light"
        //     style={{ width: "100%", margin: 0, marginTop: "1rem" }}
        //     onClick={switchToRecommandationMode}
        // >
        //     Ajouter une reco
        // </U31Button>
    )
}

const NextStepArrow = (props: { nextCategory: any }) => {
    const dispatch = useDispatch();
    if (props.nextCategory) {
        return (
            <StyledNextStep
                onClick={() => dispatch(setActiveCategories([props.nextCategory.key]))}
                color={props.nextCategory.color}>
                <svg className="arrow" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.37 33.37">
                    <title>suivant</title>
                    <g>
                        <path fill="#d0d0e3" d="M16.68,1a15.69,15.69,0,0,0,0,31.37A15.68,15.68,0,0,0,27.77,5.59,15.58,15.58,0,0,0,16.68,1m0-1A16.69,16.69,0,1,1,0,16.68,16.68,16.68,0,0,1,16.68,0Z" transform="translate(0 0)" />
                        <path fill={props.nextCategory.color} d="M16.51,22.86a1.53,1.53,0,0,1-.87-.28L6.91,16.41A1.5,1.5,0,1,1,8.64,14l7.87,5.56L24.37,14a1.5,1.5,0,0,1,2.09.36,1.51,1.51,0,0,1-.36,2.09l-8.73,6.17A1.52,1.52,0,0,1,16.51,22.86Z" transform="translate(0 0)" />
                    </g>
                </svg>
                <p className="name">{props.nextCategory.name}</p>
            </StyledNextStep>
        )
    }
    // No next category so Export
    else {
        return (
            <StyledNextStep
                onClick={() => {
                    dispatch(setRightPanelActivePage(RightPanelPages.EXPORT));
                    dispatch(setActiveCategories([]));
                }}
                color="#446EE0">
                <svg className="arrow" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.37 33.37">
                    <title>arrow suivant</title>
                    <g>
                        <path fill="#d0d0e3" d="M16.68,1a15.69,15.69,0,0,0,0,31.37A15.68,15.68,0,0,0,27.77,5.59,15.58,15.58,0,0,0,16.68,1m0-1A16.69,16.69,0,1,1,0,16.68,16.68,16.68,0,0,1,16.68,0Z" transform="translate(0 0)" />
                        <path fill="#446EE0" d="M16.51,22.86a1.53,1.53,0,0,1-.87-.28L6.91,16.41A1.5,1.5,0,1,1,8.64,14l7.87,5.56L24.37,14a1.5,1.5,0,0,1,2.09.36,1.51,1.51,0,0,1-.36,2.09l-8.73,6.17A1.52,1.52,0,0,1,16.51,22.86Z" transform="translate(0 0)" />
                    </g>
                </svg>
                <p className="name">Exporter</p>
            </StyledNextStep>
        )
    }
}


const NextStep = (props: { color: string, nextCategory: any }) => {
    return (
        <StyledGoToNextCategory>
            <p className="congrats">Bravo !</p>
            <p className="message">Vous avez traité toutes les recommandations de cette catégorie.</p>
            <div className="divider"></div>
            <p className="nextStep">Aller à la catégorie suivante</p>
            <NextStepArrow nextCategory={props.nextCategory} />
        </StyledGoToNextCategory>
    )
}

const Cards = (props: RecommandationsProps) => {


    const { editorRef } = props;
    const editor = editorRef?.current;
    const [errorSelection, setErrorSelection] = useState("Sélectionnez la zone de texte où se trouve l’erreur.")
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);


    const hiddenCardIds = useSelector(getHiddenCardIds);
    const validatedBestPractices = useSelector(getValidatedBestPracticesIds);
    const discardedBestPractices = useSelector(getDiscardedBestPracticesIds);
    const languageRecommandationList = useSelector(getLanguageRecommandationsAsArray(language)) as Array<U31Recommandation>;
    const activeCategories = useSelector(getActiveCategories);
    const categories = useSelector(getCategories);
    const ignoredCardsIds = useSelector(getIgnoredCardIds);

    const activeCategory = activeCategories[0];
    const color = categories[activeCategory]?.color;

    const blocks = useMemo(() => findChildrenByAttr(editor?.state.doc, attrs => attrs.block_id).reduce((acc, curr) => ({ ...acc, [curr.node.attrs.block_id]: curr.pos }), {}), [languageRecommandationList])
    const recommandationList = useMemo(() => languageRecommandationList
        .filter((reco) => reco.spans.length > 0 && activeCategories.includes(reco.category))
        .filter((reco: U31Recommandation) => reco?.spans && reco?.spans?.length > 0)
        .sort((recoA: U31Recommandation, recoB: U31Recommandation) => {
            const recoAoffset = blocks[recoA.block_id]
            const recoBoffset = blocks[recoB.block_id]
            return (recoA.spans[0][0] + recoAoffset) - (recoB.spans[0][0] + recoBoffset)
        })
        , [languageRecommandationList, activeCategories, hiddenCardIds, ignoredCardsIds])

    const bestPractices: U31Recommandation[] = categories[activeCategory]?.bestPractices || []
    // .filter((reco) => !ignoredCardsIds.includes(reco.id)) || []

    const remainingRecoCount = recommandationList
        .filter(reco => !ignoredCardsIds.includes(reco.id) && !hiddenCardIds.includes(reco.id))
        .length;

    const remainingBestPracticesCount = bestPractices
        .filter(bp => !(validatedBestPractices.includes(bp.id) || discardedBestPractices.includes(bp.id)))
        .length;

    // Open first card when first receive recos
    const [didOpenFirstCard, setDidOpenFirstCard] = useState(false);
    useEffect(() => {
        if (!didOpenFirstCard && recommandationList && recommandationList.length > 0) {
            dispatch(setOpenCardIdForCategory({ category: recommandationList[0].category, id: recommandationList[0].id }))
            setDidOpenFirstCard(true);
        }
    }, [recommandationList])



    const [ignoredCardsActive, setIgnoredCardsActive] = useState(false);
    useEffect(() => {
        if (ignoredCardsActive == true) {
            setTimeout(() => {
                const ignoredRecosButton = document.querySelector("#ignoredRecosButton");
                if (!ignoredRecosButton) return;
                document.querySelector(".scrollableY")?.scrollBy({ top: ignoredRecosButton.getBoundingClientRect().top - 60, behavior: "smooth" })
            }, 400);

        }
    }, [ignoredCardsActive])

    const [bpOpen, setBpOpen] = useState(false);

    // useEffect(() => {
    //     setBpOpen(false);
    // }, [activeCategory])

    const visibleCards = recommandationList
        .filter((reco) => !hiddenCardIds.includes(reco.id))
        .filter((reco) => !ignoredCardsIds.includes(reco.id))
        .filter((reco) => activeCategories.includes(reco.category))

    const ignoredCards = recommandationList
        .filter((reco) => ignoredCardsIds.includes(reco.id))

    const categoriesKeys = Object.keys(categories)
    const currentIndex = categoriesKeys.findIndex(key => key == activeCategory)
    const nextCategory = currentIndex >= 0 && categories[categoriesKeys[(currentIndex + 1)]];

    return (<>
        <PartTitle text="Détections" color={color} right={remainingRecoCount == 0 ? <Check color={color} /> : remainingRecoCount} />
        {
            props.frontRecos != null ? activeCategories.map(cat => cat in props.frontRecos ? props.frontRecos[cat] : []).flat() : <></>
        }
        {/* VISIBLE CARDS */}
        {
            visibleCards
                .map((reco: U31Recommandation, i: number) => <RenderCardReco key={reco.id} isDemo={props?.isDemo} nextCardId={visibleCards[i + 1]?.id} key={reco.id} reco={reco} ignored={false} setErrorSelection={setErrorSelection} editor={editor} />)
        }
        {/* BEST PRACTICES */}
        {
            bestPractices?.length > 0 && <>
                <PartTitle
                    text="Bonnes pratiques"
                    id="bestPractices"
                    onClick={() => {
                        setBpOpen(!bpOpen)
                        setTimeout(() => {
                            document.querySelector(".scrollableY")?.scrollTo({ top: document.querySelector("#bestPractices")?.offsetTop - 40, behavior: "smooth" });
                        }, 400)
                    }}
                    style={{ zIndex: 2, cursor: "pointer", position: "sticky", bottom: "-1rem", top: 0, }}
                    color={color}
                    right={<div style={{
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: '0.75rem',
                        gap: '0.5rem'
                    }}>
                        {remainingBestPracticesCount == 0 ? <Check color={color} /> : remainingBestPracticesCount}
                        <DownArrow open={bpOpen} color={color} />
                    </div>}
                />
                <BestPracticesContainer className={`${bpOpen && "open"}`}>
                    {
                        bestPractices
                            .map((bestPractice: any, i: number) => <RenderCardReco key={bestPractice.id} isDemo={props?.isDemo} nextCardId={visibleCards[i + 1]?.id} key={bestPractice.id} reco={bestPractice} ignored={false} setErrorSelection={setErrorSelection} editor={editor} />)
                    }
                </BestPracticesContainer>

                {
                    remainingRecoCount + remainingBestPracticesCount == 0 && <NextStep color={color} nextCategory={nextCategory} />
                }
            </>
        }


        {/* IGNORED CARDS */}
        {
            ignoredCards?.length > 0 && <IgnoredCardsWrapper count={ignoredCards.length} active={ignoredCardsActive}>
                <button id="ignoredRecosButton" className="header" onClick={(e) => { setIgnoredCardsActive(!ignoredCardsActive) }}>
                    <p>Recommandations ignorées</p>
                </button>
                <div className={`ignoredCardsList ${ignoredCardsActive && 'visible'}`}>
                    {
                        ignoredCards
                            .map((reco: U31Recommandation, i) => <RenderCardReco key={reco.id} isDemo={props?.isDemo} nextCardId={ignoredCards[i + 1]?.id} key={reco.id} reco={reco} ignored={true} setErrorSelection={setErrorSelection} editor={editor} />)
                    }
                </div>
            </IgnoredCardsWrapper>
        }

    </>)

}

const Recommandations: FC<RecommandationsProps> = (props: RecommandationsProps) => {

    const dispatch = useDispatch();
    const activeCategories = useSelector(getActiveCategories);
    const documentId = useSelector(getActiveDocumentId);
    const addRecommandationState = useSelector(getaddRecommandationState);
    const recommandationsWrapperRef = useRef<HTMLDivElement>();
    const openCardId = useSelector(getOpenCardIdForCategory(activeCategories[0]));
    const [errorSelection, setErrorSelection] = useState("");

    useEffect(() => {
        if (!recommandationsWrapperRef?.current) return;
        const openCardDom = recommandationsWrapperRef.current.querySelector(`.card[data-id="${openCardId}"]`) as HTMLDivElement;
        const textSpan = document.querySelector(`[u31reco-${openCardId}]`) as HTMLSpanElement;
        const u31editor = document.querySelector("#u31editor") as HTMLDivElement;

        if (openCardDom && textSpan && u31editor && recommandationsWrapperRef.current) {
            setTimeout(() => {
                document.querySelector(".scrollableY")?.scrollTo({ top: openCardDom.offsetTop - 124, behavior: "smooth" })
                u31editor.scrollTo({ top: textSpan.offsetTop - u31editor.offsetTop, behavior: "smooth" });
            }, 400);
        }
    }, [openCardId])


    return (
        <>
            <StyledRecommandations ref={recommandationsWrapperRef} id="recommandations">
                <div className="scrollableY">
                    {addRecommandationState != 0 ? <AddRecommandationCard saveCallback={() => {
                        if (!documentId) return;
                        analyze({
                            editor: props.editorRef?.current,
                            documentId: documentId,
                            dispatch,
                            demo: false,
                        });
                    }} selection={errorSelection} setErrorSelection={setErrorSelection} /> : null}
                    <Cards {...props} />
                </div>
                <NewRecommandations />
            </StyledRecommandations>
        </>
    );
}

const Check = (props: { color: string }) => <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.51 9.5">
    <g id="Groupe_3388" data-name="Groupe 3388">
        <path fill={props.color} d="M6.06,9.92a1,1,0,0,1-.7-.29L.71,5a1,1,0,0,1,0-1.42,1,1,0,0,1,1.41,0l4,3.93L13.23.69a1,1,0,0,1,1.38,1.45L6.75,9.64A1,1,0,0,1,6.06,9.92Z" transform="translate(-0.42 -0.42)" />
    </g>
</svg>


export default Recommandations;