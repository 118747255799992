import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import FetchUser from './components/account/FetchUser';
import AccessibilityMessage from './components/shared/AccessibilityMessage';
// import EditorHome from './components/editor/EditorHome';
import WaitLoading, { FullPageLoader } from './components/WaitLoading';
import './css/sr-only.css';
import './css/style.css';
import { fetchAndDispatchUser, isDateThisDay } from './helpers';
import nlpReducer from './reducers/nlp-reducer';
// import Annexe from './components/landing/Annexe';
import settingsReducer from './reducers/settings-reducer.ts';
import userReducer from './reducers/user-reducer';
import u31Theme from './u31Theme';
import { Notifications } from './components/notifications/Notifications';
import { AddCreditPopupProvider } from './components/modals/AddCreditPopup';
import { PaymentPopupProvider } from './components/modals/PaymentPopup';
import { GetLicencePopupProvider } from './components/modals/GetLicencePopup';
import { DemoDocChoicePopupProvider } from './components/modals/DemoDocChoicePopup';
import CGU from './components/landing/CGU';
import PrivacyPolicy from './components/landing/PrivacyPolicy';
import Cookies from './components/landing/Cookies';
import { ClariScore } from './components/shared/ClariScore';
import { RightPanelPages } from './components/editor/rightPanel/RightPanel';
import { BEST_PRACTICES, IS_CNAV } from './config';
import { DEFAULT_DOCUMENT_TITLE } from './components/editor/U31Editor';


const allReducers = combineReducers({
  settingsReducer,
  nlpReducer,
  userReducer,
});

const allStoreEnhancers = composeWithDevTools(
  applyMiddleware(thunk),
);


export const categoryMap = {
  grammar: "grammar",
  word_complexity: "word_complexity",
  sent_complexity: "sent_complexity",
  structure: "structure",
  cognitive_comfort: "structure",
  eco: "eco",
  inclusion: "inclusion",
  accessibility: "accessibility",
}

export const u31store = createStore(
  allReducers,
  {
    userReducer: {
      documents: [],
      user: {
        firstname: "",
        lastname: "",
        organism: "",
        loggedIn: undefined,
      }
    },
    settingsReducer: {
      hasDocChangedAfterProcess: false,
      nlpLoading: false,
      accessibilityMessage: '',
      language: 'lc', // lc | falc
      openCardId: {
        grammar: null,
        word_complexity: null,
        sent_complexity: null,
        structure: null,
      },
      discardedBestPracticesIds: [],
      validatedBestPracticesIds: [],
      hoveredCardsIds: [],
      hiddenCardIds: [],
      ignoredCardIds: [],
      activeCategories: ["grammar"],
      // activeCategories: ["word_complexity"],
      rightPanelActivePage: RightPanelPages.EMPTY,
      categories: { // TODO! re map categories with @charlotte
        grammar: {
          name: "L'orthographe",
          key: "grammar",
          score: 0,
          subCategories: {},
          color: "#DE5C4C",
          bestPractices: BEST_PRACTICES.grammar,
        },
        sent_complexity: {
          name: "Les phrases",
          key: "sent_complexity",
          score: 0,
          subCategories: {},
          color: "#9677B7",
          bestPractices: BEST_PRACTICES.sent_complexity,
        },
        word_complexity: {
          name: "Les mots", // + l'orthographe
          key: "word_complexity",
          score: 0,
          subCategories: {},
          color: "#FD905F",
          bestPractices: BEST_PRACTICES.word_complexity,
        },
        structure: {
          name: "Les paragraphes", // + mise en page
          key: "structure",
          score: 0,
          subCategories: {},
          color: "#5AA8F2",
          bestPractices: [...BEST_PRACTICES.structure],
        },
        inclusion: {
          name: "L'inclusion",
          key: "inclusion",
          score: 0,
          subCategories: {},
          color: "#DAA520",
          bestPractices: [...BEST_PRACTICES.inclusion],
        },
        accessibility: {
          name: "L'accessibilité",
          key: "accessibility",
          score: 0,
          subCategories: {},
          color: "#F273B6",
          bestPractices: [...BEST_PRACTICES.accessibility],
        },
        eco: {
          name: "L'écoconception",
          key: "eco",
          score: 0,
          subCategories: {},
          color: "#6EB47F",
          bestPractices: [...BEST_PRACTICES.eco],
        }
      },
      addRecommandationState: 0, // 0 close, 1 open, 2 end
      activeDocumentId: null,
      importExportPopup: { open: false, type: null },
      revisionState: {
        payed: true,
      },
    },
    nlpReducer: {
      title: DEFAULT_DOCUMENT_TITLE,
      metrics: {
        readingTime: 0,
        wordCount: 0,
        charCount: 0,
      },
      prices: {},
      scores: {},
      recommandations: {
        falc: {
          categories: {
          },
          name: "falc",
          score: -1
        },
        lc: {
          categories: {},
          name: "lc",
          score: -1
        }
      },
      categories_error_count: {},
    }


  },
  allStoreEnhancers
);


const LazyAnnexe = React.lazy(() => import('./components/landing/Annexe/Annexe'))
const LazyContact = React.lazy(() => import('./components/landing/Contact'))
const LazyEditorHome = React.lazy(() => import('./components/editor/U31Editor'))

const LazyLoginRegister = React.lazy(() => import('./components/account/LoginRegister'));
const LazyHome = React.lazy(() => import('./components/landing/Home/Home'));
const LazyAccount = React.lazy(() => import('./components/account/Account'));
const LazyAccessibility = React.lazy(() => import('./components/landing/Accessibility'));
const LazyAccessibilis = React.lazy(() => import('./components/landing/Accessibilis'));
const LazyLegalNotices = React.lazy(() => import('./components/landing/LegalNotices'));
const LazyGuide = React.lazy(() => import('./components/guide/Guide'));
const LazyOurTool = React.lazy(() => import('./components/landing/OurTool/OurTool'));
const LazyResetPassword = React.lazy(() => import('./components/account/ResetPassword'));

const PopupsMemo = ({ children }) => {
  const memoChild = useMemo(() => children, [])
  return (
    <GetLicencePopupProvider>
      <AddCreditPopupProvider >
        <PaymentPopupProvider>
          <DemoDocChoicePopupProvider>

            {memoChild}

          </DemoDocChoicePopupProvider>
        </PaymentPopupProvider>
      </AddCreditPopupProvider>
    </GetLicencePopupProvider>
  );
}


const BackendLoginCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);;
    const token = params.get('token');
    if (token) {
      localStorage.setItem("u31token", token);
      fetchAndDispatchUser(dispatch);
      setTimeout(() => {
        navigate("/mon-compte")
      }, 3000)
    }
  }, [])
  return <FullPageLoader />
}


ReactDOM.render(
  <ThemeProvider theme={u31Theme}>
    <Provider store={u31store}>
      {/* check if user is logged in, if so, fill the state with user */}
      <FetchUser />
      <AccessibilityMessage />
      <PopupsMemo>

        <BrowserRouter>
          <Routes>

            {/* login */}
            <Route path="/login-callback" element={<BackendLoginCallback />}></Route>

            <Route path="/mon-compte/*" element={<WaitLoading><LazyAccount /></WaitLoading>}></Route>
            <Route path="/connexion" element={<WaitLoading><LazyLoginRegister _hasAccount={true} /></WaitLoading>}></Route>
            <Route path="/reinitialisation-mot-de-passe" element={<WaitLoading><LazyResetPassword /></WaitLoading>}></Route>
            <Route path="/creer-un-compte" element={<WaitLoading><LazyLoginRegister _hasAccount={false} /></WaitLoading>}></Route>
            <Route path="/document/:id" element={<WaitLoading><LazyEditorHome /></WaitLoading>}></Route>
            {!IS_CNAV && <Route path="/demo" element={<WaitLoading><LazyEditorHome isDemo={true} /></WaitLoading>}></Route>}
            <Route path="/guide/:path?" element={<WaitLoading><LazyGuide /></WaitLoading>}></Route>

            <Route path="/pourquoi-adapter-vos-contenus" element={<WaitLoading><LazyAnnexe /></WaitLoading>}></Route>
            <Route path="/notre-technologie" element={<WaitLoading><LazyOurTool /></WaitLoading>}></Route>
            <Route path="/accessibilite" element={<WaitLoading><LazyAccessibility /></WaitLoading>}></Route>
            <Route path="/accessibilis" element={<WaitLoading><LazyAccessibilis /></WaitLoading>}></Route>
            <Route path="/contact" element={<WaitLoading><LazyContact /></WaitLoading>}></Route>


            <Route path="/mentions-legales" element={<WaitLoading><LazyLegalNotices /></WaitLoading>}></Route>
            <Route path="/cgus" element={<CGU />}></Route>
            <Route path="/cookies" element={<Cookies />}></Route>
            <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />}></Route>

            {!IS_CNAV && <Route path="/" element={<WaitLoading><LazyHome /></WaitLoading>}></Route>}
            {IS_CNAV && <Route path="/*" element={<WaitLoading><LazyLoginRegister _hasAccount={true} /></WaitLoading>}></Route>}

          </Routes>
        </BrowserRouter>

      </PopupsMemo>

    </Provider >
  </ThemeProvider >
  , document.getElementById('root')
);
