import styled from "styled-components";

export const StyledExport = styled.div`
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    display: flex;
    flex-flow: column;
    width: ${props => props.theme.ui.cards.width};
    overflow: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 3rem;
    margin-left: auto;
`
export const StyledRemainingErrors = styled.div`
    margin-top: 0;
    .message {
        margin-bottom: 2rem;
        p {
            b {
                font-weight: 500;
            }
            &.title {
                font: normal normal 500 16px/30px Ubuntu;
                letter-spacing: 0px;
                color: ${props => props.theme.color.primary};
            }
            text-align: center;
            font: normal normal normal 14px/22px Ubuntu;
            letter-spacing: 0px;
            color: #4F5456;
        }
    }
    padding-bottom: 2rem;
    border-bottom: 1px solid #D0D0E3;

`

export const StyledExportSuccess = styled(StyledRemainingErrors)`
    display: flex;
    flex-flow: column;
    flex: 1;
    button {
        margin: 0;
        width: 100%;
        border-radius: 3px;
        margin-top: auto;
    }
`

export const StyledExportOptions = styled.div`
    margin-bottom: 3rem;
    p.title {
        font: normal normal 500 14px/15px Ubuntu;
        letter-spacing: 0px;
        color: #515E6E;
        margin-top: 3rem;
    }

    input#exportTitle {
        border: 1px solid #8A94AF;
        border-radius: 3px;
        width: 100%;
        padding: 0.8rem;
        box-sizing: border-box;

        font: normal normal normal 14px/22px Ubuntu;
        letter-spacing: 0px;
        color: #515E6E;
    }

    button {
        margin: 0;
        width: 100%;
        border-radius: 3px;
    }


    .format {
        display: flex;
        flex-flow: column;
        gap: 1rem;
        margin-bottom: 2rem;
        .divider {
            width: 100%;
            border-bottom: 1px solid #D0D0E3;
        }
        .option {
            cursor: pointer;
            display: flex;
            align-items: center;
            img {
                height: 2.5rem;
                width: 3rem;
                margin-left: 1rem;
            }
            
            .type {
                display: flex;
                flex-flow: column;
                margin-left: 1.5rem;
                p {
                    font: normal normal 500 14px/18px Ubuntu;
                    color: #293B50;
                    margin: 0;
                    &.extension {
                        font: normal normal normal 14px/18px Ubuntu;
                        color: #8A94AF;
                    }
                }

            }
        }
    }
`