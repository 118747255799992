import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from "@mui/material";
import { DOMSerializer } from "@tiptap/pm/model";
import { BubbleMenu, Editor } from "@tiptap/react";
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setNlpLoading } from "../../../actions/settings-actions";
import { analyze, generateAiSimpleSentenceFromRawText } from "../../../helpers";
import { getActiveDocumentId } from "../../../reducers/settings-reducer";

export interface LoaderProps {
  editor: Editor
}

const color2 = "#d3deff"
// const StyledButton = styled.button`
const StyledButton = styled(Button)`
@keyframes spinner-rotate {
	100% {
		transform: rotate(1turn);
	}
}

position: relative;
z-index: 0;
border-radius: 5px;
overflow: hidden;
padding: 1rem;

filter: grayscale(1);
&.active {
  filter: none;
  &:not(.loading) {
    border: 2px solid #4070F4;
  }
}

&.loading {
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #4070F4;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#4070F4, #4070F4), linear-gradient(${color2}, ${color2}), linear-gradient(${color2}, ${color2}), linear-gradient(${color2}, ${color2});
		animation: spinner-rotate 0.75s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 3px;
		top: 3px;
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		background: white;
		border-radius: 4px;
	}
}
`

const SimplifyWithAi = (props: LoaderProps) => {
  const { editor } = props;
  const dispatch = useDispatch()
  const documentId = useSelector(getActiveDocumentId);

  const [loading, setLoading] = useState(false)

  const getHtml = () => {
    const { from, to, empty } = editor.state.selection
    let selectedHTML = '';

    editor.state.doc.nodesBetween(from, to, (node, pos) => {
      const spec = node.type.spec.toDOM ? node.type.spec.toDOM(node) : null;

      if (spec) {
        const domSerializer = DOMSerializer.fromSchema(editor.state.schema);
        const nodeHTMLFragment = domSerializer.serializeFragment(node.content);

        // Convert DocumentFragment to HTML string
        const div = document.createElement('div');
        div.appendChild(nodeHTMLFragment);
        const nodeHTML = div.innerHTML;

        selectedHTML += nodeHTML;
      }
    });
    // strip all tags
    return selectedHTML.replace(/<\s*([a-z][a-z0-9]*)\s.*?>/gi, '<$1>');
  }

  const getText = () => {
    const { from, to, empty } = editor.state.selection
    if (empty || !documentId) return ""
    const selectedText = editor.state.doc.textBetween(from, to, ' ')
    return selectedText;
  }

  const simplify = async (content: string, type: string) => {
    setLoading(true);
    const simplification = await generateAiSimpleSentenceFromRawText(content, type)
    setLoading(false);
    return simplification.generated;

  }
  const active = editor.state.selection.$from != editor.state.selection.$to;


  const onAiClicked = async () => {
    if (!active) {

      handleClickOpen();
      return;
    }
    if (!documentId) return;
    const nodeType = editor.state.selection.$anchor.node(1).type.name
    if (nodeType == "table") {
      const simplification = await simplify(getHtml(), "table")

      const { tr } = editor.state;
      const paragraphNode = editor.schema.nodes.paragraph.create({}, editor.schema.text(simplification));
      // tr.insert(editor.state.selection.$to.after(), paragraphNode);
      tr.insert(editor.state.selection.$anchor.after(), paragraphNode);
      editor.view.dispatch(tr);
    }
    else {
      const simplification = await simplify(getText(), "paragraph")
      editor.commands.insertContent(`${simplification} `)
    }

    analyze({
      editor,
      documentId: documentId,
      dispatch,
      demo: false,
      callback: () => {
      }
    });

  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return <>
    {/* <Button data-tooltip-place="right" className="is-active" data-tooltip-id="tbtt" data-tooltip-content="Pictogrammes" aria-label="Image" */}
    <StyledButton className={`${loading && "loading"} ${active && "active"}`} data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Simplifier" aria-label="Image"
      onClick={() => !loading && onAiClicked()}
      style={{ width: "2.6rem", height: "2.6rem", minWidth: "2.6rem", minHeight: "2.6rem" }}
    >
      <Icon fontSize="medium" style={{ width: "1.6rem", height: "1.6rem", minWidth: "1.6rem", minHeight: "1.6rem" }}>
        <img className="icon" style={{ width: "1.6rem", height: "1.6rem", minWidth: "1.6rem", minHeight: "1.6rem" }} role="presentation" src="/images/rightPanel/actions/ai_write.svg" />
      </Icon>
    </StyledButton>

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Comment uiliser la simplification automatique
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <ol>
            <li>Séléctionner la portion de texte que vous voulez simplifier dans l'éditeur</li>
            <li>Cliquer sur le boutton         <img className="icon" style={{ width: '1.6rem', height: '1.6rem', transform: 'translateY(30%)', padding: '0 0.5rem' }} role="presentation" src="/images/rightPanel/actions/ai_write.svg" /> pour simplifier le text séléctionné</li>
          </ol>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>Fermer</Button>
      </DialogActions>
    </Dialog>

  </>
}

export default SimplifyWithAi;