import { RevisionState } from '@u31-dev/u31-ui';
import axios from 'axios';
import { RightPanelPages } from '../components/editor/rightPanel/RightPanel';

export const SET_LANGUAGE = 'settings:SET_LANGUAGE';
export const SET_HIDDEN_TABS = 'settings:SET_HIDDEN_TABS';
export const SET_OPEN_CARD_ID = 'settings:SET_OPEN_CARD_ID';
export const SET_OPEN_CARD_ID_FOR_CATEGORY = 'settings:SET_OPEN_CARD_ID_FOR_CATEGORY';
export const SET_HOVERED_CARDS_IDS = 'settings:SET_HOVERED_CARD_ID';
export const SET_ADD_RECOMMANDATION_OPEN = 'settings:SET_ADD_RECOMMANDATION_OPEN';
export const SET_ACTIVE_DOCUMENT_ID = 'settings:SET_ACTIVE_DOCUMENT_ID';
export const SET_IMPORT_EXPORT_POPUP_OPEN = 'settings:SET_IMPORT_EXPORT_POPUP_OPEN';
export const SET_IMPORT_EXPORT_POPUP_TYPE = 'settings:SET_IMPORT_EXPORT_POPUP_TYPE';
export const SET_ACCESSIBILITY_MESSAGE = 'settings:SET_ACCESSIBILITY_MESSAGE';
export const SET_NLP_LOADING = 'settings:SET_NLP_LOADING';
export const SET_REVISION_STATE = 'settings:SET_REVISION_STATE';
export const SET_CATEGORIES = 'settings:SET_CATEGORIES';
export const SET_ACTIVE_CATEGORIES = 'settings:SET_ACTIVE_CATEGORIES';
export const SET_HIDDEN_CARD_IDS = 'settings:SET_HIDDEN_CARD_IDS';
export const SET_IGNORED_CARD_IDS = 'settings:SET_IGNORED_CARD_IDS';
export const SET_RIGHT_PANEL_ACTIVE_PAGE = 'settings:SET_RIGHT_PANEL_ACTIVE_PAGE';
export const HAS_DOC_CHANGED_AFTER_PROCESS = 'settings:HAS_DOC_CHANGED_AFTER_PROCESS';
export const SET_DISCARDED_BEST_PRACTICES_IDS = 'settings:SET_DISCARDED_BEST_PRACTICES_IDS';
export const SET_VALIDATED_BEST_PRACTICES_IDS = 'settings:SET_VALIDATED_BEST_PRACTICES_IDS';

export function setLanguage(data: string) {
  // console.log("SET LANGUAGE : ", data)
  return {
    type: SET_LANGUAGE,
    payload: data
  };
}

export function setOpenCardId(data: {
  grammar: string | null,
  word_complexity: string | null,
  sent_complexity: string | null,
  structure: string | null,
}) {
  // console.log("SET OPEN CARD ID : ", data)
  return {
    type: SET_OPEN_CARD_ID,
    payload: data
  };
}

export function setOpenCardIdForCategory(data: { category: String, id: string | null }) {
  // console.log("SET OPEN CARD ID : ", data)
  return {
    type: SET_OPEN_CARD_ID_FOR_CATEGORY,
    payload: data
  };
}

export function setHoveredCardsIds(data: string[] | null) {
  return {
    type: SET_HOVERED_CARDS_IDS,
    payload: data
  };
}

export function setaddRecommandationState(data: 0 | 1 | 2) {
  // console.log("SET ADD RECOMMANDATION OPEN : ", data)
  return {
    type: SET_ADD_RECOMMANDATION_OPEN,
    payload: data
  };
}

export function setActiveDocumentId(data: string | null) {
  return {
    type: SET_ACTIVE_DOCUMENT_ID,
    payload: data
  };
}

export function setImportExportPopupOpen(data: boolean) {
  console.log("setImportExportPopupOpen ", data)
  return {
    type: SET_IMPORT_EXPORT_POPUP_OPEN,
    payload: data
  };
}


export function setImportExportPopupType(data: string) {
  console.log("setImportExportPopupType ", data)
  return {
    type: SET_IMPORT_EXPORT_POPUP_TYPE,
    payload: data
  };
}

export function setAccessibilityMessage(data: string) {
  return {
    type: SET_ACCESSIBILITY_MESSAGE,
    payload: data
  };
}


export function setNlpLoading(data: boolean) {
  return {
    type: SET_NLP_LOADING,
    payload: data
  };
}

export function setHiddenCardIds(data: string[]) {
  return {
    type: SET_HIDDEN_CARD_IDS,
    payload: data
  };
}

export function setIgnoredCardIds(data: string[]) {
  return {
    type: SET_IGNORED_CARD_IDS,
    payload: data
  };
}

export function setDiscardedBestPracticesIds(data: string[]) {
  return {
    type: SET_DISCARDED_BEST_PRACTICES_IDS,
    payload: data
  };
}

export function setValidatedBestPracticesIds(data: string[]) {
  return {
    type: SET_VALIDATED_BEST_PRACTICES_IDS,
    payload: data
  };
}

export function setRevisionState(data: RevisionState) {
  return {
    type: SET_REVISION_STATE,
    payload: data
  };
}

export function setCategories(data: any) {
  return {
    type: SET_CATEGORIES,
    payload: data
  };
}

export function setActiveCategories(data: string[]) {
  return {
    type: SET_ACTIVE_CATEGORIES,
    payload: data
  };
}

export function setRightPanelActivePage(data: RightPanelPages) {
  return {
    type: SET_RIGHT_PANEL_ACTIVE_PAGE,
    payload: data
  };
}

export function setHasDocChangedAfterProcess(data: boolean) {
  return {
    type: HAS_DOC_CHANGED_AFTER_PROCESS,
    payload: data
  };
}


// export function fetchLanguage() {
//   return (dispatch, getState) => {
//     axios.get('https://storage.accessibilis.fr/health-check')
//       .then((resp) => resp.data)
//       .then((data) => {
// console.log("DATA", data)
//         return dispatch(setLanguage(data));
//       })
//   };
// }
