import { Editor } from "@tiptap/react"
import React from "react"
import { setOpenCardIdForCategory } from "../../actions/settings-actions"
import { scrollDocumentMarkToTop } from "../editor/rightPanel/recommandations/Recommandations"
import { showTableOrganisation, TableOrganisation } from "./customCardsAccessibility"
import { showUsefulPictures, UsefulPictures } from "./customCardsEco"
import { InclusivPictures, showInclusivePictures } from "./customCardsInclusion"

export interface IFrontRecommandations {
    [key: string]: JSX.Element[];
}

export const collectFrontRecommandations = (activeCategories: string[], editor: Editor) => {
    let recos: IFrontRecommandations = {
        "inclusion": [],
        "eco": [],
        "accessibility": []
    }

    if (!editor) return null;

    if (showInclusivePictures(editor))
        recos["inclusion"].push(<InclusivPictures editor={editor} />)

    if (showUsefulPictures(editor))
        recos["eco"].push(<UsefulPictures editor={editor} />)

    if (showTableOrganisation(editor))
        recos["accessibility"].push(<TableOrganisation editor={editor} />)

    return recos
}

