import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { Step } from "../RightPanel";
import { PartTitle, StyledStepper } from "../shared.styled";
import { getActiveCategories, getActiveDocumentId, getCategories, getHiddenCardIds, getIgnoredCardIds, getLanguage, getRevisionState, getRightPanelActivePage } from '../../../../reducers/settings-reducer';
import { StyledExport, StyledExportOptions, StyledExportSuccess, StyledRemainingErrors } from "./export.styled";
import { getDocumentTitle, getLanguageRecommandationsAsArray, getScores } from "../../../../reducers/nlp-reducer";
import { getClariScoreInfo } from "../../../shared/ClariScore";
import { U31Button, U31Link, U31Recommandation, U31TextInput } from "@u31-dev/u31-ui";
import { RefObject, useEffect, useState } from "react";
import { exportDocument } from "../../../../helpers";
import { Editor } from "@tiptap/react";
import { DEFAULT_DOCUMENT_TITLE } from "../../U31Editor";
import { clariScoreText } from "../../../account/diagnostic/Diagnostic";

const RemainingErrors = () => {
    const activeCategories = useSelector(getActiveCategories);
    const categories = useSelector(getCategories);
    const language = useSelector(getLanguage);
    const recommandationList = useSelector(getLanguageRecommandationsAsArray(language)) as Array<U31Recommandation>;
    const hiddenCardsIds = useSelector(getHiddenCardIds);
    const ignoredCardsIds = useSelector(getIgnoredCardIds);


    const scores = useSelector(getScores);
    const score = scores[language];
    const clariScoreInfo = getClariScoreInfo(score);

    const allBestPractices = Object.keys(categories).reduce((acc, curr) => [...acc, ...(categories[curr]?.bestPractices || [])], []);

    // const itRemainsError = () => [...recommandationList, ...allBestPractices]
    const itRemainsError = () => [...recommandationList]
        .filter(reco => !ignoredCardsIds.includes(reco.id) && !hiddenCardsIds.includes(reco.id))
        .length > 0

    if (itRemainsError() && score < 80) {
        return (
            <StyledRemainingErrors>
                <div className="message">
                    <p className="title">😕<br />Vous êtes sûr ?</p>
                    <p>Avec un score de <b><span style={{ color: clariScoreInfo?.color }}>{score}</span>/100</b>, votre <br /> document a atteint l’indice de clarté <b style={{ color: clariScoreInfo?.color }}>{clariScoreInfo?.label}</b>.</p>
                    <p>Pour améliorer votre document, <br /> <b>vérifiez les catégories suivantes :</b></p>
                </div>

                <StyledStepper className="export">
                    {
                        Object.keys(categories)
                            .map((categoryKey, i) => {

                                // const errorCount = [...recommandationList, ...categories[categoryKey].bestPractices]
                                const errorCount = [...recommandationList]
                                    .filter(reco => reco.category == categoryKey)
                                    .filter(reco => !ignoredCardsIds.includes(reco.id) && !hiddenCardsIds.includes(reco.id))
                                    .length

                                const processed = errorCount == 0;

                                return !processed && <Step
                                    key={i}
                                    isExportStep={true}
                                    categoryKey={categoryKey}
                                    active={activeCategories.includes(categoryKey)}
                                    errorCount={errorCount}
                                    processed={processed}
                                />
                            })
                    }
                </StyledStepper>
            </StyledRemainingErrors>
        )
    } else {
        return (
            <StyledRemainingErrors>
                <div className="message">
                    <p className="title">🎉 <br /> Félicitations !</p>
                    <p>Avec un score de <b><span style={{ color: clariScoreInfo?.color }}>{score}</span>/100</b>, votre <br /> document a atteint l’indice de clarté <b style={{ color: clariScoreInfo?.color }}>{clariScoreInfo?.label}</b>.</p>
                </div>
            </StyledRemainingErrors>
        )
    }
}

const ExportOptions = (props: { editorRef: RefObject<Editor> | null, setExportStep: Function }) => {
    const documentTitle = useSelector(getDocumentTitle);
    const activeDocumentId = useSelector(getActiveDocumentId);
    const [showTitleInput, setShowTitleInput] = useState(documentTitle == DEFAULT_DOCUMENT_TITLE)
    const [title, setTitle] = useState(documentTitle);
    const [type, setType] = useState("docx")

    useEffect(() => {
        setTitle(documentTitle)
    }, [documentTitle])
    return (
        <StyledExportOptions>
            {showTitleInput && <>
                <p className="title">Nommez le document</p>
                <input id="exportTitle" type="text" placeholder="Origin du texte" value={title} onChange={(e) => setTitle(e.target.value)} />
            </>}
            <p className="title">Choisissez un format</p>

            <div className="format">
                <div
                    onClick={() => { setType("docx") }}
                    className={`option ${type == "docx" ? "selected" : ""}`}>
                    <input type="radio" checked={type == "docx"} />
                    <img src="/images/word.svg" alt="" />
                    <div className="type">
                        <p>Document Word</p>
                        <p className="extension">.docx</p>
                    </div>
                </div>
                <div className="divider"></div>
                <div
                    onClick={() => { setType("pdf") }}
                    className={`option ${type == "pdf" ? "selected" : ""}`}>
                    <input type="radio" checked={type == "pdf"} />
                    <img src="/images/pdf-file.svg" alt="" />
                    <div className="type">
                        <p>Document PDF</p>
                        <p className="extension">.pdf</p>
                    </div>
                </div>
            </div>

            <U31Button
                variant="normal"
                onClick={async () => {
                    await exportDocument({ editor: props.editorRef.current, fileName: title, type })
                    props.setExportStep(ExportStep.SUCCESS)
                }}
            >Exporter le document</U31Button>

            <U31Button as="a" variant="light" style={{
                margin: '0',
                display: 'flex',
                width: 'auto',
                textDecoration: 'none',
                justifyContent: 'center',
                marginTop: '1rem'
            }} href={`/mon-compte/diagnostic?id=${activeDocumentId}`} target="_blank">Afficher le diagnostic</U31Button>


        </StyledExportOptions>
    )
}

const ExportSuccess = (props: { setExportStep: Function }) => {
    const theme = useTheme();
    return (
        <StyledExportSuccess>
            <div className="message">
                <p className="title">🎉 <br /> C’est exporté !</p>
                <p>Il ne vous reste plus qu’à diffuser <br /> votre document.</p>
                <p style={{ color: theme.color.primary, fontWeight: 500 }}>U31 vous remercie de votre confiance.</p>
            </div>
            <U31Button
                variant="normal"
                onClick={() => {
                    props.setExportStep(ExportStep.FORM)
                }}
            >Retour</U31Button>

        </StyledExportSuccess>
    )
}

enum ExportStep {
    FORM,
    SUCCESS
}

const Export = (props: { editorRef: RefObject<Editor> | null }) => {
    const theme = useTheme();
    const [exportStep, setExportStep] = useState<ExportStep>(ExportStep.FORM)
    const lastRevisionState = useSelector(getRevisionState);
    const scores = useSelector(getScores)
    const clariScoreInfo = getClariScoreInfo(scores?.lc || 0);


    return (
        <StyledExport>
            <PartTitle text="Exporter" onClick={() => {
                console.log("lastRevisionState", lastRevisionState);
            }} color={theme.color.primary} />
            {
                exportStep == ExportStep.FORM && <>
                    <p style={{textAlign: "center"}}>{clariScoreText[(clariScoreInfo?.label || "A") as keyof typeof clariScoreText]}</p>
                    <RemainingErrors />
                    <ExportOptions setExportStep={setExportStep} editorRef={props.editorRef} />
                </>
            }
            {
                exportStep == ExportStep.SUCCESS && <ExportSuccess setExportStep={setExportStep} />
            }
        </StyledExport>
    )
}

export default Export;