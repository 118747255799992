import Underline from '@tiptap/extension-underline'
import U31HighlightRecoTipTapPlugin from './plugins/tiptap/u31-highlight-reco';
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Placeholder from '@tiptap/extension-placeholder'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import Link from '@tiptap/extension-link'
import TableHeader from '@tiptap/extension-table-header'
// import Image from '@tiptap/extension-image'
import HardBreak from '@tiptap/extension-hard-break'
import FontSize from './plugins/tiptap/font-size'
import Paragraph from '@tiptap/extension-paragraph';
import StarterKit from '@tiptap/starter-kit';
import { U31LiveProcess } from './plugins/tiptap/u31-live-process';
import { NormalizeTextOnPast } from './plugins/tiptap/normalize-text-on-past';
import { LimitCharCount } from './plugins/tiptap/limit-char-count/limit-char-count';
import { INACTIVITY_DELAY_BEFORE_PROCESS, INACTIVITY_DELAY_BEFORE_PROCESS_SHORT, MAX_CHAR_COUNT } from '../../config';
import ImportDocument from './plugins/tiptap/import-document';
import { Extension, Mark } from '@tiptap/react';
import UniqueID from '@tiptap-pro/extension-unique-id';
import U31TiptapAccessibility from './plugins/tiptap/u31-tiptap-accessibility';
import { ImageSizeExtension } from './plugins/tiptap/image-resize/ImageSizeExtension';
import fileHandler from './plugins/tiptap/file-handler';

import BubbleMenu from '@tiptap/extension-bubble-menu'

interface ExtensionsProps {
    dispatch?: Function,
    isDemo?: boolean,
    isHeadLess?: boolean, // no front, only used to get html from doc, and other utilities
    analyzeCallback?: Function,
}

export const EMPTY_EDITOR_CONTENT = {
    "type": "doc",
    "content": [
        {
            "type": "paragraph",
        },
        {
            "type": "importDocument",
        }

    ]
}

export const getExtensions = (props: ExtensionsProps) => {
    let extensions: Array<any> = [
        StarterKit
            .configure({
                code: false,
                codeBlock: false,
                // hardBreak: false
            }),
        // ShiftEnter,
        // CustomHardBreak,
        // Image,
        fileHandler,
        ImageSizeExtension.configure({
            maxWidth: "min(800px, 100%)",
            activeBorderColor: "#4070f4",
            levels: [150, 300, 600],
        }),
        TextStyle,
        Color,
        FontFamily,
        FontSize,
        Underline,
        U31TiptapAccessibility(props.dispatch),
        Link.extend({ inclusive: false }).configure({
            linkOnPaste: true,
            openOnClick: false,
            HTMLAttributes: {
                target: '#',
            }
        }),
        // table
        Table.configure({
            resizable: true,
        }),

        TableRow,
        TableHeader,
        TableCell,
        NormalizeTextOnPast,
        U31HighlightRecoTipTapPlugin(props.dispatch),
        ImportDocument,
        Placeholder.configure({
            emptyNodeClass: 'is-empty',
            showOnlyWhenEditable: false,
            showOnlyCurrent: true,
            includeChildren: false,
            placeholder: 'Entrez votre texte ici ou importez un document…',
        }),
    ]

    if (!props.isHeadLess) {
        extensions.push(
            BubbleMenu.configure({
                element: document.querySelector('#linkPreview') as HTMLDivElement,
                shouldShow: ({ editor, view, state, oldState, from, to }) => {
                    return editor.isActive('image') || editor.isActive('link')
                },
                // updateDelay: 1000
            })
        )
        extensions.push(
            UniqueID.configure({
                attributeName: 'block_id',
                types: ['heading', 'paragraph'],
            }),
        )
        if (props.isDemo) {
            extensions.push(
                LimitCharCount(props.dispatch).configure({
                    maxChar: MAX_CHAR_COUNT
                })
            )
        }
        if (!props.isDemo) {
            extensions.push(
                U31LiveProcess(props.dispatch, props.analyzeCallback).configure({
                    inactivityDelayBeforeProcess: INACTIVITY_DELAY_BEFORE_PROCESS,
                    inactivityDelayBeforeProcessShort: INACTIVITY_DELAY_BEFORE_PROCESS_SHORT
                })
            )
        }
    }
    return extensions;
}