// import { options } from '../editor/initialValues';
// import { options } from '../config/initialValues';
import { Navigate, useNavigate } from 'react-router-dom';
import ToolbarIcon from './ToolbarIcon';
import U31ColorPicker from './U31ColorPicker';
import { Editor } from '@tiptap/react';
import styled from 'styled-components';
import { SpinnerLoading } from '../../WaitLoading';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, TextField } from '@mui/material';
import IconPicker from './PictoPicker';
import { Tooltip } from 'react-tooltip'
import TableButton from './TableButton';
import SimplifyWithAi from './SimplifyWithAi';
import { useState } from 'react';
import { U31TextInput } from '@u31-dev/u31-ui';


type ToolBarProps = {
  editor: Editor | null,
  isDemo?: boolean,
};

const ToolBarWrapper = styled.div`
    height: 100%;

    padding: 0 0.5rem;

    width: 3rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    justify-self: flex-start;
    overflow: visible;
    margin-right: 1rem;
    

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    #u31-logo {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
      background: none;
      border: none;

      & > img {
          position: sticky;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          width: 100%;
          top: 0;
          z-index: 1;
          cursor: pointer;
      }
    }

    & #icons {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      overflow: auto;
      flex-grow: 6;
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      & .spacer {
        margin-top: 1rem;
      }
      display: flex;
      gap: 0.2rem;

      button {
        cursor: pointer;
        min-width: unset !important;
        &.is-active {
          border: 2px solid #4a81ff;
        }
        width: 100%;
        max-width: 3rem;
        max-height: 3rem;

        aspect-ratio: 1;
        height: 100%;
        span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 1.5rem;
            height: 1.5rem;
            object-fit: contain;
          }
        }
      }
    }

    @media screen and (max-width: 1280px) {
    }

`

const LinkDialog = (props: { open: boolean, setOpen: Function, setLink: Function }) => {
  const [link, setLink] = useState("")
  const [error, setError] = useState(true);

  function isValidURL(url: string): boolean {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    const valid = urlRegex.test(url);
    return valid
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Entrez le lien dans la zone de texte</DialogTitle>
        <DialogContent >
          <DialogContentText style={{ paddingTop: "1rem" }} id="alert-dialog-description">
            <TextField
              error={error}
              helperText={error ? "Entrez une url valide" : ""}
              value={link}
              onChange={e => {
                const newLink = e.target.value;
                setError(!isValidURL(newLink))
                setLink(newLink)
              }}
              style={{ width: "100%" }}
              id="outlined-basic"
              label="Lien"
              variant="outlined" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setOpen(false)}>Annuler</Button>
          {
            !error && <Button onClick={() => { props.setLink(link); props.setOpen(false) }} autoFocus>Valider</Button>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}


const Toolbar = ({ editor, isDemo }: ToolBarProps) => {

  // console.log("RENDERING TOOLBAR")
  if (!editor) {
    return <></>;
  }
  const navigate = useNavigate();

  const [linkDialogOpen, setLinkDialogOpen] = useState(false);


  return (
    <ToolBarWrapper id="toolbar">
      <Tooltip id="tbtt" style={{ zIndex: 9999 }} />

      <a href="/mon-compte/documents" aria-label="Aller à la page mes documents" id="u31-logo"><img alt="Logo U31." src="/images/LOGOU31.svg" /></a>
      <div id="icons">
        <span className="spacer" />

        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Annuler" aria-label={`Annuler`}
          onClick={() => editor && editor.commands.undo()}
          className={editor && editor.isActive('undo') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img style={{ width: "2rem", height: "2rem" }} className="icon" role="presentation" src="/images/toolbar/undo.svg" />
          </Icon>
        </Button>

        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Rétablir" aria-label={`Rétablir`}
          onClick={() => editor && editor.commands.redo()}
          className={editor && editor.isActive('redo') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img style={{ width: "2rem", height: "2rem" }} className="icon" role="presentation" src="/images/toolbar/redo.svg" />
          </Icon>
        </Button>

        {/* <span className="spacer" /> */}
        {/* {editor && editor.state.selection.$from != editor.state.selection.$to && <SimplifyWithAi editor={editor} />} */}
        <SimplifyWithAi editor={editor} />
        {/* <span className="spacer" /> */}

        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Titre 1" aria-label={`${editor && editor.isActive('heading', { level: 1 }) ? "Transformer le titre 1 en paragraphe" : "Titre 1"}`}
          onClick={() => editor && editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor && editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img style={{ width: "2rem" }} className="icon" role="presentation" src="/images/H1.svg" />
          </Icon>
        </Button>

        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Titre 2" aria-label={`${editor && editor.isActive('heading', { level: 2 }) ? "Transformer le titre 2 en paragraphe" : "Titre 2"}`}
          onClick={() => editor && editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor && editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img style={{ width: "1.4rem" }} className="icon" role="presentation" src="/images/H2.svg" />
          </Icon>
        </Button>

        <span className="spacer" />


        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Gras" aria-label={`${editor && editor.isActive('bold') ? "Supprimer le gras" : "Gras"}`}
          onClick={() => editor && editor.chain().focus().toggleBold().run()}
          className={editor && editor.isActive('bold') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img style={{ height: "1.3rem" }} className="icon" role="presentation" src="/images/bubble_menu/gras.svg" />
          </Icon>
        </Button>


        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Italique" aria-label={`${editor && editor.isActive('italic') ? "Supprimer l'italique" : "Italique"}`}
          onClick={() => editor && editor.chain().focus().toggleItalic().run()}
          className={editor && editor.isActive('italic') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img className="icon" role="presentation" src="/images/bubble_menu/italique.svg" />
          </Icon>
        </Button>


        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Souligner" aria-label={`${editor && editor.isActive('underline') ? "Supprimer le souligné" : "Souligné"}`}
          onClick={() => editor && editor.chain().focus().toggleUnderline().run()}
          className={editor && editor.isActive('underline') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img className="icon" role="presentation" src="/images/bubble_menu/souligne.svg" />
          </Icon>
        </Button>


        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Liste à puces" aria-label="Liste à puces"
          onClick={() => editor && editor.chain().focus().toggleBulletList().run()}
          className={editor && editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img className="icon" role="presentation" src="/images/bubble_menu/liste_puces.svg" />
          </Icon>
        </Button>


        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Liste numérotée" aria-label="Liste numerotée"
          onClick={() => editor && editor.chain().focus().toggleOrderedList().run()}
          className={editor && editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img className="icon" role="presentation" src="/images/bubble_menu/liste_numerotee.svg" />
          </Icon>
        </Button>

        {editor && <TableButton editor={editor} />}


        <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Lien" aria-label="Lien"
          onClick={() => {
            if (!editor) return;
            if (editor.isActive('link')) {
              editor.chain().focus().unsetLink().run()
            }
            else {
              setLinkDialogOpen(true);
            }
          }}
          className={editor && editor.isActive('link') ? 'is-active' : ''}
        >
          <Icon fontSize="medium">
            <img className="icon" role="presentation" src="/images/bubble_menu/link.svg" />
          </Icon>
        </Button>


        {editor && <IconPicker editor={editor} />}


        <LinkDialog setLink={(link: string) => editor.chain().focus().setLink({ href: link, target: "_blank" }).run()} open={linkDialogOpen} setOpen={setLinkDialogOpen} />
      </div>
    </ToolBarWrapper>
  )
}
export default Toolbar;