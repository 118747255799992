// @ts-ignore
import { ReactTinyLink } from 'react-tiny-link'
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { Editor } from "@tiptap/react"
import { useEffect, useState } from "react"
import styled from 'styled-components';
import { U31CircularProgress } from '@u31-dev/u31-ui';


// const customFetcher = async (url: string) => {
//     const response = await fetch(`https://rlp-proxy.herokuapp.com/v2?url=${url}`);
//     const json = await response.json();
//     return json.metadata;
//   };

const StyledLinkPreview = styled.div`
    width: 350px;
`

const U31LinkPreview = (props: { editor: Editor | null }) => {
    const editor = props.editor;
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    const getLink = () => {
        if (!editor) return;
        const { state } = editor;
        const { selection } = state;

        // Check if there is a link node at the current selection
        const _link = state.doc.nodeAt(selection.anchor);

        if (_link) {
            const mark = _link.marks.find(m => m.type.name == "link");
            if (mark)
                return mark.attrs.href;
        }
    }


    useEffect(() => {
        const newLink = getLink();
        if (newLink != link) {
            setLink(newLink)
            setLoading(true);
        }
    }, [editor?.state?.selection?.$anchor])


    return <StyledLinkPreview id="linkPreview">
        {link && <ReactTinyLink
            cardSize="small"
            description={loading && " "}
            url={link}
            header={loading && " "}
            showGraphic={true}
            autoPlay={true}
            maxLine={2}
            minLine={1}
            proxyUrl={process.env.REACT_APP_CORS_URL}
            onSuccess={() => {
                setLoading(false);
            }}
        />
        }
    </StyledLinkPreview>
}

export default U31LinkPreview