const  u31Theme = {
    color: {
        primary: "#446EE0",
    },
    heading: {
        1: {
            // color: "#000",
            // fontSize: "10px"
        },
    },
    paragraph: {
        // color: "red"
    },
    ui: {
        rightPanel: {
            width: "14rem",
        },
        cards: {
            width: "340px"
        }
    }
};

export default u31Theme;