import { Editor } from "@tiptap/react";
import { U31StyledStep } from "@u31-dev/u31-ui";
import { RefObject } from "react";
import styled from "styled-components";
import { IFrontRecommandations } from "../../frontRecommendations";


export const StyledCategories = styled.div`
	margin-left: 9%;
	margin-right: 9%;
	margin-top: 5%;
	overflow: auto;

    scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}

	.errorCategory {
		position: relative;
		display: flex;
		flex-flow: column;

		&.active, &:hover {
			background: #FCFCFD;
			box-shadow: 0pt 3pt 6pt #4070F417;
			border-radius: 3px;
		}
		&.active .header {
			background: #FCFCFD;
			box-shadow: 0px 3px 6px #4070F417;
		}
		.header {
		position: sticky;
		top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: auto;
		cursor: pointer;
		padding-left: 9%;
		padding-right: 4.5%;
		border-radius: 3px;
		padding-top: 1.5em;
		padding-bottom: 1.5em;
		max-height: 0.8em;
	}

	.header .chip {
			max-width: 0.6em;
			height: 0.6em;
			border-radius: 4.5pt;
			/* background-color: red; */
			flex: 0 0 0.6em;
		}

		.header .name {
			margin: 0;
			width: 60%;
			padding-left: 2.5%;
			font: normal normal 400 15px/22px Ubuntu;
			letter-spacing: 0px;
			color: #515E6E;
		}

		.header .arrow {
			transition: 0.4s;
			margin-left: 5%;
			transform: rotateZ(0);
			height: 0.6em;
		}

		.errorCategory.active .arrow {
			transform: rotateZ(90deg);
		}

		.header .progress {
			width: 50%;
		}

		.subCategories {
			visibility: hidden;
			height: 0;
			opacity: 0;
			overflow: hidden;
			width: 80%;
			transition: 0.4s;
			padding-left: 10%;
			padding-right: 10%;
			display: flex;
			flex-flow: column;
			background: #f8f8fb;
			color: white;
		}

		.subCategories.active {
			visibility: visible;
			height: auto;
			padding-top: 12pt;
			padding-bottom: 8pt;
			opacity: 1;
			display: flex;
			gap: 0.5rem;
		}


		.subCategory {
			display: flex;
			align-items: baseline;
			padding-bottom: 5pt;
			padding-top: 10pt;
			justify-content: space-between;
			border-bottom: 1px solid #e0e2e8;
			&:last-child {
				border-bottom: none;
			}
			
			p {
				margin: 0;
			}
			
			p.name {
				color: #485769;
				font: normal normal 500 0.9em/1em 'Inter';
				padding-left: calc(6px + 2.5%);
			}            
			
			.nbErrors {
				width: 55%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				flex: 1;
			}
			
			.nbErrors p {
				font: normal normal normal 0.913em/19pt 'Inter';
			}
		}



	}
`

export const StyledHideAssistant = styled.div`
	position: absolute;
	white-space: nowrap;
	width: calc(${props => props.theme.ui.cards.width} + 4px);
	margin-right: 1rem;
	height: 2rem;
	display: flex;
	opacity: 1;
	transition: 0.4s;
	z-index: 0;
	margin-top: 1rem;
	/* margin-right: calc(0.5rem + 2px); */
	z-index: 999;
	background: #F5F8FF;
	border-radius: 3px;
	padding: 0;
	/* mask */
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		top: -1rem;
		left: 0;
		height: 1rem;
		background: white;
	}

	/* box-shadow: 0px 0px 15px #27376217; */
	border-radius: 3px;
	& > button {
		background: unset;
		color: #677688;
		font-size: 0.9em;
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		width: 100%;
		justify-content: space-between;
		& > img {
			height: 1rem; 
			margin-right: 0.5em;
			align-self: center; 
		}
	}


`


const StyledPartTitle = styled.div`
	z-index: 2;
	width: ${props => props.theme.ui.cards.width};
	margin-left: auto;
	color: ${props => props.color};
	border-bottom: 1px solid ${props => props.color};
	letter-spacing: 0px;
	margin-top: 1rem;
	top: 0;
	position: sticky;
	background: white;
	font: normal normal normal 14px/18px Ubuntu;
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		margin: 0.5rem 0;
	}

	.right {
		/* width: 1rem; */
	}

`

export const PartTitle = (props: { id?: string, onClick?: Function, style?: object, text: string, color: string, right?: JSX.Element | number }) => {
	return (
		<StyledPartTitle id={props?.id} onClick={props?.onClick} style={props?.style} color={props.color}>
			<p>{props.text}</p>
			<div className="right">
				{props.right}
			</div>
		</StyledPartTitle>
	)
}

export type RightPanelProps = {
	editorRef: RefObject<Editor> | null,
	isDemo?: boolean,
	frontRecos: IFrontRecommandations | null
};

export const StyledGettingStarted = styled.div`
	width: ${props => props.theme.ui.cards.width};
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 1rem;
	padding-left: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-top: 2rem;
	margin-left: auto;
	::-webkit-scrollbar{
		display: none;
	}

	.suggestionAuto {
		img {
			display: none;
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.option {
		box-shadow: 0px 0px 15px 0px #27376221;
		border-radius: 3px;
		padding: 0.5rem 1rem;
		margin-top: 1rem;

		p {
			&.actionTitle,
			&.title {
				font-family: 'Ubuntu';
				font-style: normal;
				/* font-weight: 500; */
				font-size: 14px;
				line-height: 18px;
				/* color: ${props => props.theme.color.primary}; */
			}
		}
	}


`


export const StyledRightPanel = styled.div<RightPanelProps>`
	position: relative;
	min-height: 100%;
	z-index: 3;
	display: flex;
	margin-left: auto;
	width: 0;
	max-width: 0;
	transform: translateX(100%);
	flex: 0;
	transition: 0.4s;

	#hideAssistant {
		${props => props.isDemo == true && `display: none;`}
		margin-left: -1rem;
		transition: 0.4s;
		justify-content: space-between !important;
		right: 0;
		/* right: calc(12rem + 300px + 2rem); */
	}
	
	&.active {
		width: 100%;
		max-width: 100%;
		transform: translateX(0);
		#hideAssistant {
			margin-left: 1rem;
			box-sizing: border-box;
			right: ${props => props.theme.ui.rightPanel.width};
		}
	}
`

export const RightColumnWrapper = styled.div`
	::-webkit-scrollbar {
		display: none;
	}
	display: flex;
	flex-flow: column;
	overflow: auto;
	height: 100%;
	width: ${props => props.theme.ui.rightPanel.width};
	background: white;
	box-shadow: 0px 0px 15px #27376217;
	border-left: 1px solid #CACFDB;

	.needMoreCharacters {
		margin: 1rem;
		font-size: 14px;
		font-weight: 500;
		color: #677688;
	}

	#score {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		.text {
			gap: 0.4rem;
			display: flex;
			flex-flow: column;
		}

		button.reload {
			width: 100%;
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: flex-end;
			cursor: pointer;
			margin-top: auto;
			border: 0;
			padding: 0;
			background: none;
			width: auto;
			flex: 0;
			img {
				width: 2rem;
			}
		}
	}
`

export const StyledScore = styled.div`
	position: relative;
	margin: 1rem;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
	gap: 0.4rem;

	#clariscore {
		margin-right: auto;
	}

	/* &.disabled {
		filter: grayscale(1);
		opacity: 0.6;		
	} */

	.title {
		font: normal normal normal 17px/23px Ubuntu;
		letter-spacing: -0.34px;
		color: #4F5456;
		margin: 0;
		margin-top: 0.8rem;
	}
	
	.score {
		font: normal normal 500 25px/23px Ubuntu;
		letter-spacing: -0.5px;
		color: ${props => props.color};
		margin: 0;
	}


`


export const StyledStepper = styled.div`
	display: flex;
	flex-flow: column;
	margin: 1rem;
	align-items: center;
	gap: 1rem;
	position: relative;
	padding-top: 1rem;

	.noOutline {
		outline: 1px solid #CACFDB;
		&:focus {
			outline: 1px solid #CACFDB;
		}
	}

	.grouppedCategory {
		display: flex;
		width: 100%;
		flex-flow: column;
		align-items: center;
		gap: 1rem;
		position: relative;
		padding: 0 2px;
		margin-top: -1rem;
		left: 0.5rem;
		transition: 0.4s;
		width: calc(100% - 1rem);
		overflow: hidden;
		height: 0;
		opacity: 0;
		&.active {
			transition: height 0.4s;
			opacity: 1;
			margin-top: 0;
			overflow: visible;
			height: calc(4rem * 3);
		}
	}

	:before {
		content: '';
		position: absolute;
		height: calc(100% - 1rem);
		width: 2px;
		background: #CACFDB;
	}

	&.export {
		margin: 0;
		:before {
			display: none;
		}
	}
`

export interface StepProps {
	categoryKey: string,
	active: boolean,
	processed: boolean,
	errorCount: number,
	isExportStep?: boolean,
}

export const StyledExportStep = styled(U31StyledStep)`
	position: relative;
	margin-top: 2rem;
	&:before {
		content: '';
		display: inline-block;
		height: 0;
		width: 0;
		border-right: 0.4em solid transparent;
		border-bottom: 0.5em solid #cacfdb;
		border-left: 0.4em solid transparent;
		position: absolute;
		top: -0.45rem;
		left: calc(50% - 0.4em);
		transform: rotate(180deg);
	}

`;


export const StyledRightColumnActions = styled.div`
	margin-top: auto;

	.action {
		display: flex;
		width: 100%;
		align-items: center;
		border: none;
		border-top: 1px solid #CACFDB;
		background: white;
		padding: 0.6rem 0.5rem;
		cursor: pointer;
		text-decoration: none;
		box-sizing: border-box;
		/* justify-content: space-between; */

		&.disabled {
			opacity: 0.6;
			pointer-events: none;
		}

		img {
			height: 1rem;
			width: 1rem;
			/* margin-left: 0.8rem; */
			margin-right: 0.8rem;
		}

		.name {
			text-align: left;
			&:hover {
				p.actionTitle {
					color: ${props => props.theme.color.primary} !important;
				}
			}
			p {
				font: normal normal 400 14px/22px Ubuntu;
				margin: 0;
				&.actionTitle {
					font-weight: 500;
					color: #3A3C40;
				}
				
				&.subTitle {
					font: normal normal normal 14px/22px Ubuntu;
					color: #515E6E;
				}
			}
		}
		
	}
`