import styled from "styled-components";
import { U31Button, U31Paragraph } from "@u31-dev/u31-ui";


export const StyledRightPanelQuotations = styled.div`
    ::-webkit-scrollbar{
        display: none;
    }
    width: ${props => props.theme.ui.cards.width};
    overflow: auto;
    height: calc(100%);
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 3rem;
    margin-left: auto;

    .solutions {
        display: flex;
        flex-flow: column;
        .solution {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 15px #27376221;
            border: 1px solid #2737622B;
            padding: 1rem;
            margin-top: 1rem;

            
            p.title {
                font: normal normal 500 13px/16px Ubuntu;
                color: ${props => props.theme.color.primary};
                margin: 0;
                margin-bottom: 2rem;
            }

            .select {
                padding-top: 0;
            }

            #smart_billing {
                margin-top: 2rem;
            }

            p.checked {
                display: flex;
                gap: 0.3rem;
                font: normal normal normal 12px/15px Ubuntu;
                color: #1B2026;

                b {
                    font-weight: 500;
                }

                &:before {
                    content: url('/images/rightPanel/cards/blue_check.svg');
                    height: 1rem;
                    width: 1rem;
                }
            }

            p.guaranteed_date {
                display: flex;
                gap: 0.3rem;
                margin-top: 2rem;
                margin-bottom: 0.2rem;
                font: normal normal normal 13px/18px Ubuntu;
                color: #515E6E;
                &:before {
                    content: url('/images/rightPanel/clock.svg');
                    height: 1rem;
                    width: 1rem;
                }                
            }

            p.price {
                margin: 0;
                font: normal normal 500 20px/17px Ubuntu;
                color: ${props => props.theme.color.primary};

                span.ttc {
                    font: normal normal 500 12px/17px Ubuntu;
                    color: #8A94AF;
                }
                span.credits {
                    font: normal normal normal 13px/16px Ubuntu;
                    color: #1B2026;
                }
            }

            p.availableCredits {
                margin: 0;
                font: normal normal normal 13px/22px Ubuntu;
                color: #515E6E;
                span.credits {
                    font-weight: 500;
                    color: ${props => props.theme.color.primary};                    
                }
            }


            &>button {
                width: 100%;
                margin: 0;
                padding: 0.8em 0em;
                margin-top: 2rem;
                border-radius: 3px;
            }

        }
    }

    .requestQuotation {
        .summary {
            background: #F8F8FB;
            padding: 1rem;
            margin-bottom: 2rem;

            .divider {
                width: 100%;
                border-bottom: 1px solid #AAB4CE;
            }

        }
        p {
            &.title {
                margin: 0;
                font: normal normal 500 14px/16px Ubuntu;
                color: ${props => props.theme.color.primary};
            }

            &.row {
                font: normal normal normal 13px/16px Ubuntu;
                color: #1B2026;
            }

            &.delivery {
                font: normal normal normal 13px/15px Ubuntu;
                color: #4F5456;
                b {
                    color: ${props => props.theme.color.primary};
                } 
            }

            &.ttc {
                margin: 0;
                font: normal normal normal 13px/36px Ubuntu;
                color: #6A788A;
            }

            &.price {
                margin: 0;
                font: normal normal 500 20px/17px Ubuntu;
                color: ${props => props.theme.color.primary};
            }
        }

        #cancel {
            display: flex;
            cursor: pointer;
            align-items: center;
            gap: 0.2rem;
            background: none;
            border: none;
            margin: 1rem 0;
            img {
                transform: rotate(180deg);
                width: 1rem;
            }
            p {
                margin: 0;
            }
        }

        form {
            display: flex;
            flex-flow: column;
            input {
                width: 100%;
                border: none;
                border-bottom: 1px solid #98A2B1;
                height: 1rem;
            }

            textarea {
                width: 100%;
                height: 4rem;
                border: none;
                border-bottom: 1px solid #98a2b1;
                resize: vertical;
                max-height: 13rem
            }

            label.input {
                margin: 0;
                margin-top: 0.5rem;
                font: normal normal normal 13px/20px Ubuntu;
                letter-spacing: 0.13px;
                color: #1B2026;
            }

            button {
                margin: 0;
                border-radius: 3px;
                margin-top: 2rem;
                padding: 0.8rem;
                width: 100%;
            }
        }
    }

    .quotationSent {
        text-align: center;
        margin-top: 2rem;

        p.title {
            font: normal normal 500 16px/23px Ubuntu;
            letter-spacing: -0.32px;
            color: ${props => props.theme.color.primary};
        }

        p.text {
            font: normal normal normal 14px/23px Ubuntu;
            margin-top: 1rem;
            letter-spacing: -0.28px;
            color: #4F5456;
        }

        p.blue {
            font: normal normal 500 14px/23px Ubuntu;
            margin-top: 2rem;
            letter-spacing: -0.28px;
            color: ${props => props.theme.color.primary};
        }

        button {
            width: 100%;
            margin: 0;
            margin-top: 2rem;
            padding: 0.5rem;
        }
    }
`


export const DatePriceOption = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    p {
        margin: 0 !important;
        font: normal normal 500 13px/18px Ubuntu;
        &.date {
            color: #1B2026;
        }
        &.price {
            color: ${props => props.theme.color.primary};
        }

    }
`