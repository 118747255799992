import { Mark } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Dispatch } from 'react';
import { analyze } from '../../../../../helpers';

export interface U31LiveProcessOptions {
  inactivityDelayBeforeProcess?: number,
  inactivityDelayBeforeProcessShort?: number
}

let needToProcessAfterTimeout: NodeJS.Timeout | null = null;
const triggerProcessTimeout = (callback: any, delay: number) => setTimeout(() => {
  if (callback) {
    callback();
  }
}, delay)


const isApple = () => {
  var expression = /(Mac|iPhone|iPod|iPad)/i;
  return expression.test(navigator.platform);
}

const isControl = (event: KeyboardEvent) => { // Returns true if Ctrl or cmd keys were pressed.
  if (isApple()) {
    return event.metaKey;
  }
  return event.ctrlKey; // Windows, Linux, UNIX
}

export const U31LiveProcess = (dispatch: Dispatch<any>, analyzeCallback: Function) => Mark.create<U31LiveProcessOptions>({
  name: 'u31LiveProcess',

  addOptions() {
    return {
      inactivityDelayBeforeProcess: 5_000,
      inactivityDelayBeforeProcessShort: 500,
    }
  },
  addProseMirrorPlugins() {
    const name = this.name;
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleKeyDown: (view, event) => {
            // if not alphanumeric char return;
            if (event.key.length != 1) return false;

            if (needToProcessAfterTimeout)
              clearTimeout(needToProcessAfterTimeout);

            if (isControl(event) && event.key == "v") {
              needToProcessAfterTimeout = triggerProcessTimeout(() => analyzeCallback(), 100);
              return false;
            }
            // analyze text on new paragraph or end of sentence
            else if (["Enter", "."].includes(event.key)) {
              needToProcessAfterTimeout = triggerProcessTimeout(() => analyzeCallback(), this.options.inactivityDelayBeforeProcessShort as number);
            }
            // analyze text after inactivity delay expires
            else {
              needToProcessAfterTimeout = triggerProcessTimeout(() => analyzeCallback(), this.options.inactivityDelayBeforeProcess as number);
            }


            return false;
          },
          // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
        },


      }),
    ]
  },
  addAttributes() {
    return {
      u31recommandations: {
        default: null,
      },
    }
  },
})
