import '../../../css/landing/common_landing.scopped.css';
import { jsPDF } from "jspdf";
import { FC, useEffect, useRef, useState } from "react";
import html2canvas from 'html2canvas';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ClariScore, getClariScoreInfo, U31Button, U31Heading, U31Paragraph, U31TextArea, U31TextInput } from "@u31-dev/u31-ui";
import { getClariScore } from './clarisSore';
import { formatDate, getDocument } from '../../../helpers';
import { generateHTML } from '@tiptap/react';

import { useDispatch, useSelector } from 'react-redux';
import { getExtensions } from '../../editor/editorConfig';
import { getCategories } from '../../../reducers/settings-reducer';
import { DEFAULT_DOCUMENT_TITLE } from '../../editor/U31Editor';
import { StyledScore } from '../../editor/rightPanel/shared.styled';
import './Ubuntu-normal'
import './Ubuntu-bold'

const translations = {
    grammar_general: "Grammaire générale",
    ortograph: "Ortographe",
    syntax: "Syntaxe",
    conjugation: "Conjugaison",
    typography: "Typographie",
    accord: "Accord",
    grammar: "Catégorie grammaticale",
    conjugaison: "Conjugaison",
    grammar_error: "Grammaire",
    pronunciation: "Prononciation",
    semantics: "Sémantique",
    grammar_registre: "Registre",
    specific_vocabulary: "Mots rares ou vocabulaire spécifique",
    difficult_to_read: "Mots difficiles à lire",
    difficult_to_understand: "Mots difficiles à comprendre",
    synonyms: "Synonymes",
    acronymes: "Acronymes",
    abreviations: "Abréviations",
    numerical: "Expressions numériques",
    complex_pronouns: "Pronoms complèxes",
    named_entities: "Entités nommées",
    connectives: "Mots de liaison",
    long_sentence: "Phrases longues",
    complex_sentence: "Phrases compliquées",
    negations: "Négations",
    complex_verbal_tense: "Temps verbal compliqué",
    passive_voice: "Voix passive",
    temporal_break: "Changement de temps des verbes",
    impersonal: "Tournure impersonnelle",
    conditional: "Conditionnel",
    bold: "Mot en gras",
    italic: "Mot en italique",
}

export const clariScoreText = {
    "A": "Bravo votre texte s’adresse au plus grand nombre!",
    "B": "Votre texte est plutôt clair !",
    "C": "Votre texte n’est pas toujours facile à lire et à comprendre, suivez les recommandations d’U31 pour gagner en clarté",
    "D": "Votre texte est difficile à lire et à comprendre, suivez les recommandations d’U31 pour gagner en clarté",
    "E": "Votre texte est très difficile à lire et à comprendre, suivez les recommandations d’U31 pour gagner en clarté",
}

const Params = styled.div`
    display: flex;
    gap: 1rem;
    height: 3.5rem;
    margin-bottom: 1rem;
    & > * { 
        height: 100%;
        margin: 0;
    }

    & input {
        height: 100%;
    }
`

const DiagnosticWrapper = styled.div<{ label: string }>`
    font-family: Ubuntu;

    /* border: 1px solid black; */
    padding: 1rem;
    width: 820px;
    outline: 1px solid black;
    display: flex;
    flex-flow: column;
    gap: 2rem;
    & .page {
        padding: 1rem;
    }

    #clariscore {

        .active.part {
            p {
                display: none;
            }
            &:before {
                content: "${props => props.label}";
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    #header {
        margin-bottom: 1rem;
        position: relative;
        flex-flow: row;
        display: flex;
        & #logo {
            position: absolute;
            height: 100%;
            aspect-ratio: 1;
            transform: scale(0.7);
        }

        h4 {
            margin-left: auto;
            margin-right: auto;
        }
    }

    & .topGray {
        border-top: 3px solid #f8f8fb;
    }

    & .bottomGray {
        border-bottom: 3px solid #f8f8fb;
    }

    & .grayBorder {
        padding: 1rem;
        border-radius: 10px;
        border: 3px solid #f8f8fb;
    }

    & #processedText {
        font-size: 14pt;
        font-family: Ubuntu;
        h1 {
            font-size: 30pt;
            font-family: Ubuntu;
        }
        h2 {
            font-size: 22pt;
            font-family: Ubuntu;
        }
        img {
            width: 50pt;
        }
        p {
            font-size: 14pt;
            font-family: Ubuntu;
            margin-top: 10pt;
            line-height: 1.5rem;
        }
    }

    & .grayBg {
        padding: 1rem;
        border-radius: 10px;
        background: #f8f8fb;
        display: flex;
        & .left {
            border-right: 2px solid #d5d5e6;
        }
        & .right {
            padding-left: 1rem;
            & .blue {
                color: #446ee0;
                font-weight: 500;
            }
        }
        & .left ,
        & .right {
            width: 50%;
        }

        & .textOrigin {
            text-decoration: underline;
            font-weight: 500;
            color: gray;
        }

        & .date {
            color: gray;
        }

    }

    & #row2 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
        margin-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        gap: 2rem;
        & > * {
            width: 50%;
        }
    }

    & .stats {
        display: flex;
        gap: 2rem;

        & .left, & .right {
            width: 50%;
            box-sizing: border-box;
            padding: 1rem;
        }
        & .right {
            & #errors {
                border: 2px solid #eaecf3;
                border-radius: 10px;
                & .error:first-child {
                    border-top: none;
                }
                & .error {
                    display: flex;
                    padding: 0 1rem;
                    justify-content: space-between;
                    align-items: center;
                    color: #555869;
                    font-weight: 500;
                    border-top: 2px solid #eaecf3;
                    & span {
                        height: 35px;
                        width: 35px;
                        border-radius: 20px;
                        background: #446ee0;
                        color: white;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

    }

`;

const VocabLevel = styled.div`
    font-weight: 500;
    margin-top: 0.5rem;
    width: fit-content;
    padding: 0.2rem 0.4rem;
    border-radius: 0.9rem;
    border: 2px solid white;
    color: ${props => props.color};
    ${props => props.active && `
        border: 2px solid ${props.color};
    `};
`

const MainError = styled.div`
    color: #2d2d2d;
    margin-top: 1rem;
    font-family: Ubuntu;
    & .percent {
        color: ${props => props.color};
        font-weight: 500;
    }
    & b {
        color: black;
    }
`;

const ProgressLinear = styled.div`
    width: 100%;
    height: 20px;
    margin-top: 70px;
    border: 2px solid black;
    border-radius: 10px;
    background: white;
    position: relative;
    padding: 2px;
    box-sizing: border-box;
    & .blue {
        position: absolute;
        /* width: 50%; */
        width: ${props => props.goalPercent}%;
        height: 12px;
        border-radius: 8px 0px 0px 8px;
        background: blue;
        & p {
            position: absolute;
            top: -30px;
            right: 0;
            transform: translateX(50%);
            margin: 0;
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            height: 40px;
            border-right: 2px solid blue;
            transform: translateY(-12.5px);
        }

    }

    & .orange {
        position: absolute;
        /* width: 70%; */
        width: ${props => props.userPercent}%;
        height: 12px;
        border-radius: 8px;
        background: orange;
        & p {
            position: absolute;
            top: -50px;
            right: 0;
            transform: translateX(50%);
            margin: 0;
        }
        &:after {
            content: '';
            position: absolute;
            right: -5px;
            height: 60px;
            border-right: 2px solid gray;
            transform: translateY(-24.5px);
        }
    }

`

const Progress = (props) => {
    const { goal, user, max } = props;
    const goalPercent = goal / max * 100;
    const userPercent = user / max * 100;
    return (
        <ProgressLinear goalPercent={goalPercent} userPercent={userPercent}>
            <div className="orange"><p>{parseInt(user)} mots</p></div>
            <div className="blue"><p>15</p></div>
        </ProgressLinear>
    )
}



const Diagnostic: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const categories = useSelector(getCategories);
    const id = searchParams.get("id") as string;
    const captureRef = useRef<HTMLDivElement>(null);
    const [textOrigin, setTextOrigin] = useState("définir l'origin du texte");
    // const [textDescriptif, setTextDescriptif] = useState("Remplir le descriptif");
    const [analyzeDate, setAnalyzeDate] = useState(`Analysé le ${formatDate(new Date())} par U31`);
    const [stats, setStats] = useState<any>(null);
    const [htmlDoc, setHtmlDoc] = useState("No text available")
    const [diagDocTitle, setDiagDocTitle] = useState(DEFAULT_DOCUMENT_TITLE)

    const loadDocument = async () => {
        const doc = await getDocument(id);

        if (doc?.editor_state) {

            const contentHtml = generateHTML(
                doc.editor_state[0],
                getExtensions({ isHeadLess: true })
            );
            setHtmlDoc(contentHtml)
            setDiagDocTitle(doc.title);
            console.log("METRICS SCORE", doc.recommandations.scores.lc)
            setStats({
                ...doc.recommandations.metrics,
                "score": doc.recommandations.scores.lc,
            })
        }
    }
    useEffect(() => {
        loadDocument()
    }, [])

    const exportPdf = async () => {
        if (!captureRef.current) return
        const doc = new jsPDF({
            // format: "a4",
            unit: "mm",
            floatPrecision: 1,
            format: [captureRef.current.clientWidth / 2 + 30, (captureRef.current.clientHeight / 2) * 1.2]
        });

        // Adding the fonts
        // doc.addFileToVFS('/Ubuntu-R.ttf', 'Ubuntu')
        // doc.addFont('/Ubuntu-R.ttf', 'Ubuntu', 'normal')
        console.log("doc.getFontList()", doc.getFontList())

        doc.html(captureRef.current, {
            async callback(doc) {
                // doc.save(`Rapport U31 - ${diagDocTitle}.pdf`);
                window.open(doc.output('bloburl'));
            },
            width: 800,
            windowWidth: window.innerWidth + 48,
        });
    }
    const properties = { header: 'Acme' }

    const errorColors = ["#FD5B61", "#FD905F", "#FAB430"]
    const clariScoreInfo = getClariScoreInfo(stats?.score || 0);
    let longParagraphsErrorColor = "green"
    if ([1, 2].includes(stats?.longParagraphsCount)) {
        longParagraphsErrorColor = "#FD905F"
    }
    if (stats?.longParagraphsCount > 2) {
        longParagraphsErrorColor = "#FD5B61"
    }
    return (
        <>
            <h1 className="title">Diagnostic</h1>
            <Params>
                <U31Button onClick={exportPdf} style={{ marginLeft: 0 }}>Exporter en PDF</U31Button>
                <U31TextInput placeholder="Origin du texte" value={textOrigin} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextOrigin(e.target.value)} />
                {/* <U31TextArea placeholder="Texte descriptif" value={textDescriptif} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextDescriptif(e.target.value)} /> */}
            </Params>


            {stats && <DiagnosticWrapper label={clariScoreInfo?.label || "-"} ref={captureRef} style={{ width: "800px" }} id="diagnostic">
                <div className="page">
                    <div id="header">
                        <img src="/images/logo.png" alt="" id="logo" />
                        <U31Heading level={4} color="#446ee0">Diagnostic de clarté</U31Heading>
                    </div>
                    <div className="grayBg">
                        <div className="left">
                            <p>Origine du texte analysé :</p>
                            <p className="textOrigin">{textOrigin}</p>
                            <p className="date">{analyzeDate}</p>
                        </div>
                        <div className="right">
                            <p className="metrics">Nombre de caractères : <span className="blue">{stats.charCount}</span></p>
                            <p className="metrics">Nombre de mots : <span className="blue">{stats.wordCount}</span></p>
                            <p className="metrics">Temps de lecture : <span className="blue">{stats.readingTime} min.</span></p>

                        </div>
                    </div>

                    <div id="row2" className="topGray bottomGray">
                        {/* <img src={getClariScore(stats.score)} /> */}
                        <StyledScore color={clariScoreInfo?.color}>
                            <div id="clariscore">

                                <div id="score">
                                    <div className="text">
                                        <p className="title">Score</p>
                                        <p className="score" style={{ fontWeight: "bold" }}>{stats.score} {stats.score && <span style={{
                                            color: '#4a81ff',
                                            fontSize: '70%'
                                        }}>/ 100</span>}</p>
                                    </div>
                                </div>
                                <p className="title">Grade</p>
                                <ClariScore isLoading={false} score={stats.score} />
                                <p>{clariScoreText[(clariScoreInfo?.label || "A") as keyof typeof clariScoreText]}</p>
                            </div>
                        </StyledScore>
                        <div>
                            <U31Heading level={5}>Erreurs les plus fréquentes</U31Heading>

                            {Object.keys(stats.subCategories).sort((cat1, cat2) => stats.subCategories[cat2] - stats.subCategories[cat1]).slice(0, 3).map((category, i) => {
                                return (
                                    <MainError color={errorColors[i]}><b>#{i + 1}</b> {translations[category] || category} : <span className="percent">{(stats.subCategories[category] / Object.keys(stats.subCategories).reduce((acc, curr) => stats.subCategories[curr] + acc, 0) * 100).toFixed(1)}%</span></MainError>
                                )
                            })}
                        </div>
                        {/* <U31Paragraph fontSize="18px" className="text">
                            {textDescriptif}
                        </U31Paragraph> */}
                    </div>

                    <div className="stats">
                        <div className="left">
                            <U31Heading level={5}>Longueur moyenne des phrases</U31Heading>
                            <Progress user={stats.avgSentSize} goal={15} max={40} />


                        </div>
                        <div className="right">
                            <U31Heading level={5}>Nombre de détections</U31Heading>
                            <div id="errors">
                                {
                                    Object.keys(stats.categories).sort((cat1, cat2) => stats.categories[cat2] - stats.categories[cat1]).slice(0, 3).map(category => {
                                        return <div className="error"><p>{categories[category]?.name}</p> <span>{stats.categories[category]}</span> </div>
                                    })
                                }
                            </div>



                        </div>
                    </div>
                    <div className="stats">
                        <div className="left">
                            <div id="vocab">
                                <U31Heading level={5}>Complexité du vocabulaire</U31Heading>
                                <VocabLevel className="level" active={stats.ratioComplexWords < 0.001} color="#169A7D" bgColor="#169A7D14">Facile</VocabLevel>
                                <VocabLevel className="level" active={stats.ratioComplexWords >= 0.001 && stats.ratioComplexWords < 0.025} color="#FAB430" bgColor="#FAB43014">Moyenne</VocabLevel>
                                <VocabLevel className="level" active={stats.ratioComplexWords >= 0.025 && stats.ratioComplexWords < 0.05} color="#FD905F" bgColor="#FD905F14">Complexe</VocabLevel>
                                <VocabLevel className="level" active={stats.ratioComplexWords >= 0.05} color="#FD5B61" bgColor="#FD5B6114">Très complexe</VocabLevel>
                            </div>

                        </div>
                        {(stats?.longParagraphsCount != undefined || stats?.complexVerbsRatio != undefined) ?
                            <div className="right">
                                <div id="longParagraph">
                                    <U31Heading level={5}>&nbsp;</U31Heading>
                                    <div id="errors">
                                        {stats?.longParagraphsCount && <div className="error"><p>Nombre de paragraphe longs</p> <span style={{ background: longParagraphsErrorColor }}>{stats?.longParagraphsCount}</span> </div>}
                                        {stats?.complexVerbsRatio && <div className="error"><p>Temps verbaux compliqués</p> <span style={{ fontSize: "0.8rem" }}>{Math.floor((stats?.complexVerbsRatio || 0) * 100)}%</span> </div>}
                                    </div>
                                </div>

                            </div>
                            : <></>
                        }
                    </div>
                </div >

                {/* <div className="page">
                    <U31Heading level={5}>Texte analysé</U31Heading>
                    <div className="grayBorder">
                        <div id="processedText" dangerouslySetInnerHTML={{ __html: htmlDoc.replaceAll('&nbsp;', ' ') }}></div>
                    </div>
                </div> */}
            </DiagnosticWrapper >
            }
        </>
    )
}

export default Diagnostic;

