import { U31Recommandation } from '@u31-dev/u31-ui/dist/types';
import { BackgroundColor } from '@styled-icons/foundation';
import { Mark, mergeAttributes } from '@tiptap/core'
import { getAttributes } from '@tiptap/react';
import Color from 'color';
import { Plugin, PluginKey } from 'prosemirror-state'
import { setHoveredCardsIds, setNlpLoading, setOpenCardId, setOpenCardIdForCategory } from '../../../../../actions/settings-actions';
import { setTextSelection } from 'prosemirror-utils';

export interface U31HighlightRecoOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    u31HighlightReco: {
      setU31ActiveCategories: (activeCategories: string[]) => ReturnType
      setU31HighlightReco: (attributes: { u31recommandations: [] }) => ReturnType
      setU31IgnoredCardIds: (attributes: { ignoredCardIds: [] }) => ReturnType
      toggleU31HighlightReco: () => ReturnType,
      unsetU31HighlightReco: () => ReturnType,
    }
  }
}

function getPos(view, e) {
  let clientY = 0;
  clientY = e.clientY;
  if (e.offsetY < 1) {
    clientY = clientY + Math.abs(e.offsetY);
  }

  const nodePos = view.posAtCoords({
    left: e.clientX,
    top: clientY
  });

  let pos = null;
  if (null !== nodePos) {
    pos = nodePos.pos;
  }
  return pos
}



export const U31HighlightReco = (dispatch) => Mark.create<U31HighlightRecoOptions>({
  name: 'u31HighlightReco',
  inclusive: false,
  // exclude self, so it can no more create nested children (<span recoid="abc"><span recoid="abc"><span recoid="abc"></span></span></span>)
  excludes: 'u31HighlightReco',
  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  addStorage() {
    return {
      activeCategories: [],
      ignoredCardIds: [],
    }
  },
  addProseMirrorPlugins() {
    const that = this;
    const name = this.name;
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleDOMEvents: {
            mouseover: (view, event) => {

              const pos = getPos(view, event)
              const resolvedPos = view.state.doc.resolve(pos);
              let marks = view.state.doc.resolve(pos).marks().filter(mark => mark.type.name == name);
              if (marks.length <= 0) {
                marks = view.state.doc.resolve(pos).marksAcross(resolvedPos);
              }
              if (!marks) return false;
              const recosAtPosition = [...new Set(marks.filter(mark => mark.type.name == "u31HighlightReco").map(mark => mark.attrs.u31recommandations).flat())]
              const hoveredCardsIds = recosAtPosition
                .filter(reco => that.storage.activeCategories.includes(reco.category))
                .filter(reco => !that.storage.ignoredCardIds.includes(reco.id))
                .map(reco => reco.id)
              dispatch(setHoveredCardsIds(hoveredCardsIds))

              // const hoveredCardsIds = JSON.parse(event?.target?.getAttribute("u31-ids"));
              // if (hoveredCardsIds)
              //   dispatch(setHoveredCardsIds([hoveredCardsIds[hoveredCardsIds.length - 1]]));
              // return true;
            },
            mouseleave: (view, event) => {
              dispatch(setHoveredCardsIds([]))
            },
          },
          handleClick(view, pos, event) {
            const resolvedPos = view.state.doc.resolve(pos);
            let marks = view.state.doc.resolve(pos).marks().filter(mark => mark.type.name == name);
            if (marks.length <= 0) {
              marks = view.state.doc.resolve(pos).marksAcross(resolvedPos);
            }
            if (!marks) return false;

            const recosAtPosition = [...new Set(marks.filter(mark => mark.type.name == "u31HighlightReco").map(mark => mark.attrs.u31recommandations).flat())]
            const recosOfRightCategory = recosAtPosition
              .filter(reco => that.storage.activeCategories.includes(reco.category))
              .filter(reco => !that.storage.ignoredCardIds.includes(reco.id))

            const recoToClick = recosOfRightCategory.pop() as U31Recommandation | undefined;
            if (recoToClick)
              return dispatch(setOpenCardIdForCategory({ category: recoToClick.category, id: recoToClick.id }));
            return true;
          },
          // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
        },

      }),
    ]
  },
  addAttributes() {
    return {
      u31recommandations: {
        default: null,
      },
      activeCategories: {
        default: []
      },
      ignoredCardIds: {
        default: []
      }
    }
  },

  renderHTML({ HTMLAttributes, mark }) {
    const { activeCategories, ignoredCardIds } = this.storage;
    const { u31recommandations } = mark?.attrs;
    // if (!u31recommandations) return ['text'];

    const visibleU31recommandations = u31recommandations
      .filter((reco: U31Recommandation) => activeCategories.includes(reco.category))
      .filter((reco: U31Recommandation) => !ignoredCardIds.includes(reco.id)) as U31Recommandation[]


    if (visibleU31recommandations.length <= 0) return ['span'];

    const recoToDisplay = visibleU31recommandations[visibleU31recommandations.length - 1];
    const u31color = recoToDisplay.color;
    const recommandationsIds = visibleU31recommandations.map((reco: U31Recommandation) => reco.id);
    const allRecommandationsIds = u31recommandations.map((reco: U31Recommandation) => reco.id);

    // const isSuperSpan = visibleU31recommandations.every(reco => reco?.data?.super_span);
    const isSuperSpan = visibleU31recommandations.every(r => r?.isSuperSpan)
    const isSuperSpanChild = !isSuperSpan && visibleU31recommandations.some(r => r?.isSuperSpan)
    console.log("visibleU31recommandations",)
    return ['span',
      // return ['span', mergeAttributes(
      // this.options.HTMLAttributes,
      // HTMLAttributes,
      {
        // ...(recommandationsIds.reduce((acc, curr) => ({ ...acc, [`u31reco-${curr}`]: "" }), {})),
        ...(allRecommandationsIds.reduce((acc, curr) => ({ ...acc, [`u31reco-${curr}`]: "" }), {})),
        "u31-ids": JSON.stringify(recommandationsIds),
        // "tabindex": 0,
        // "aria-hidden": "true",
        // style: `--data-color:${u31color}; --data-color-opacity:${Color(u31color).alpha(0.2).string()};`,
        class: `${isSuperSpan && "u31-underlined-super-span" || isSuperSpanChild && "u31-underlined-super-span-children" || "u31-underlined"}`
        // }), 0]
      }]
  },

  addCommands() {
    return {
      setU31HighlightReco: attributes => ({ commands }) => {
        commands.setMeta("addToHistory", false);
        return commands.setMark(this.name, attributes);
      },
      toggleU31HighlightReco: () => ({ commands }) => {
        commands.setMeta("addToHistory", false);
        return commands.toggleMark(this.name)
      },
      unsetU31HighlightReco: () => ({ commands }) => {
        commands.setMeta("addToHistory", false);
        return commands.unsetMark(this.name)
      },
      setU31ActiveCategories: (activeCategories) => ({ commands, state }) => {
        this.storage.activeCategories = activeCategories;
        const selection = state.selection || 0;
        commands.selectAll();
        commands.setMeta("addToHistory", false);
        commands.resetAttributes(this.name, ["activeCategories"])
        commands.updateAttributes(this.name, { activeCategories })
        return commands.setTextSelection(selection);
      },
      setU31IgnoredCardIds: (ignoredCardIds) => ({ commands, state }) => {
        this.storage.ignoredCardIds = ignoredCardIds;
        const selection = state.selection || 0;
        commands.selectAll();
        commands.setMeta("addToHistory", false);
        commands.resetAttributes(this.name, ["ignoredCardIds"])
        commands.updateAttributes(this.name, { ignoredCardIds })
        return commands.setTextSelection(selection);

      },
    }
  },

  addKeyboardShortcuts() {
    return {
      // 'Mod-u': () => this.editor.commands.toggleU31HighlightReco(),
    }
  },
})
