import { Editor } from "@tiptap/react";
import { BaseCard, CardContext, RevisionState, UserType } from "@u31-dev/u31-ui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenCardIdForCategory } from "../../actions/settings-actions";
import { getOpenCardIdForCategory, getRevisionState } from "../../reducers/settings-reducer";
import { getUser } from "../../reducers/user-reducer";
import { ActionIgnoreReco } from "../editor/rightPanel/recommandations/cards/CardActions";
import { scrollDocumentMarkToTop } from "../editor/rightPanel/recommandations/Recommandations";

const catName = 'accessibility'
export const showTableOrganisation = (editor: Editor) => {
    const htmlContent = editor.getHTML();
    const hasTable = /<table/.test(htmlContent);
    return hasTable
}
export const TableOrganisation = (props: { editor: Editor }) => {
    const id = "20723187-3484-54c9-b82e-10d2296150c3";
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const user = useSelector<UserType | null>(getUser);
    const dispatch = useDispatch();
    const openCardId = useSelector(getOpenCardIdForCategory(catName));
    const isOpen = id == openCardId;

    const onCardClicked = () => {
        scrollDocumentMarkToTop(id);
        dispatch(setOpenCardIdForCategory({ category: catName, id: isOpen ? null : id }))
    }

    const cardContext: CardContext = {
        enableGeneration: true, // NOTE: now always enable generation
        document_id: lastRevisionState.document_id,
        revision_id: lastRevisionState.document_backup_id,
        user: user,
    }



    const mainContent = <div>
        <p className="textRecommandation">Si vous utilisez un tableau, assurez-vous que les informations soient dans l’ordre, c’est-à-dire de gauche à droite, cellule par cellule.</p>
    </div>

    const reco = {
        id,
        name: catName,
        category: catName,
        subCategory: catName,
        subcategory_name: catName,
        context: '',
        languages: ['lc'],
        spans: [],
        type: 'simple'
    }
    return <BaseCard
        isOpen={isOpen}
        onClick={onCardClicked}
        reco={reco}
        title="Organisation tableau"
        mainContent={mainContent}
        setErrorSelection={() => { }}
        nextCardId={""}
        cardContext={cardContext}
        color="#F273B6"
        actions={<ActionIgnoreReco editor={props.editor} nextCardId={""} reco={reco} />}
    />
}

