import { Editor } from "@tiptap/react";
import { BaseCard, CardContext, RevisionState, UserType } from "@u31-dev/u31-ui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenCardIdForCategory } from "../../actions/settings-actions";
import { getOpenCardIdForCategory, getRevisionState } from "../../reducers/settings-reducer";
import { getUser } from "../../reducers/user-reducer";
import { ActionIgnoreReco } from "../editor/rightPanel/recommandations/cards/CardActions";
import { scrollDocumentMarkToTop } from "../editor/rightPanel/recommandations/Recommandations";


export const showInclusivePictures = (editor: Editor) => {
    const htmlContent = editor.getHTML();
    const hasImage = /<img[^>]+src="[^"]+"/.test(htmlContent);
    return hasImage
}
export const InclusivPictures = (props: { editor: Editor }) => {
    const id = "b41504d7-3232-5cee-9ddf-cc781af77cd6";
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const user = useSelector<UserType | null>(getUser);
    const dispatch = useDispatch();
    const openCardId = useSelector(getOpenCardIdForCategory("inclusion"));
    const isOpen = id == openCardId;

    const onCardClicked = () => {
        scrollDocumentMarkToTop(id);
        dispatch(setOpenCardIdForCategory({ category: "inclusion", id: isOpen ? null : id }))
    }

    const cardContext: CardContext = {
        enableGeneration: true, // NOTE: now always enable generation
        document_id: lastRevisionState.document_id,
        revision_id: lastRevisionState.document_backup_id,
        user: user,
    }


    const mainContent = <div>
        <p className="textRecommandation">Si vous utilisez des images, faites attention à ce qu’elles ne représentent pas des stéréotypes. Assurez-vous que les images reflètent une diversité de genres, d'ethnies, d'âges, de capacités, etc.</p>
    </div>
    const reco = {
        id,
        name: 'Inclusion',
        category: 'inclusion',
        subCategory: 'inclusion',
        subcategory_name: 'inclusion',
        context: '',
        languages: ['lc'],
        spans: [],
        type: 'simple'
    }
    return <BaseCard
        isOpen={isOpen}
        onClick={onCardClicked}
        reco={reco}
        title="Image inclusive"
        mainContent={mainContent}
        setErrorSelection={() => { }}
        nextCardId={""}
        cardContext={cardContext}
        color="#DAA520"
        actions={<ActionIgnoreReco editor={props.editor} nextCardId={""} reco={reco} />}
    />
}

