import styled from "styled-components";

export const StyledBgBlur = styled.div.attrs({ id: "bg_blur" })`
    background-image: url(/images/background_shadow.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    position: absolute;
    width: 100%;
    filter: blur(50px);
    margin-top: 50vh;
    right: 0;
    z-index: -1;
`

export const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    font-size: 1.05em;
`

const StyledBackHome = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    /* position: absolute; */

    &, &>span {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 1em;
        letter-spacing: 0.01125em;
        color: var(--blue);
    }

    img {
        width: 2em;
        height: 1em;
        margin-right: 0.5em;
    }

`

interface BackHomeProps {
    href?: string,
    text?: string,
}

export const StyledGoBack = (props: BackHomeProps) => {
    return (
        <StyledBackHome id="back-home" href={props?.href}>
            <img src="/images/icon/left-arrow.svg" alt="Flèche vers la gauche" />
            <span>{props?.text}</span>
        </StyledBackHome>
    )
}