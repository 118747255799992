import { useSelector } from "react-redux"
import { getAccessibilityMessage } from "../../reducers/settings-reducer"

const AccessibilityMessage = () => {
    const accessibilityMessage = useSelector(getAccessibilityMessage);
    return (
        <div
            className="sr-only"
            role="status"
            aria-live="polite"
            aria-atomic="true"
            >
            {accessibilityMessage ? <span>{accessibilityMessage}</span> : ''}
        </div>
    )
}

export default AccessibilityMessage;