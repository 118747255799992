import React, { forwardRef, useState, useImperativeHandle, useLayoutEffect, useRef, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { U31Heading, U31Paragraph, U31Popup, U31Counter, U31Button, U31TextInput, U31TextArea } from "@u31-dev/u31-ui"
import { askCredits, askLicence } from '../../helpers';
import { getMetrics } from '../../reducers/nlp-reducer';
import { getRevisionState } from '../../reducers/settings-reducer';
import { getUser } from '../../reducers/user-reducer';
import { RevisionState } from '../../types';
import { PopupRightPart } from '../shared/popup';

export interface GetLicencePopupProps {
    onClose?: Function,
    open: boolean,
}


export const Divider = styled.span`
display: block;
width: 100%;
height: 1px;
background: #324764;
margin-top: 2em;
margin-bottom: 2em;
`;


const Title = styled.h2`
font: normal normal 500 18px/16px Ubuntu;
letter-spacing: 0px;
color: #1B2026;
`;

const SubTitle = styled.h3`
font: normal normal 500 16px/16px Ubuntu;
margin-bottom: 2em;
letter-spacing: 0px;
color: #1B2026;
/* margin-bottom: 2em; */
`;


export const GetLicencePopupContext = React.createContext({});
export const useGetLicencePopup = () => useContext(GetLicencePopupContext);

export const GetLicencePopupProvider = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);

    const memoChild = useMemo(() => children, []);

    return (
        <GetLicencePopupContext.Provider value={{ setOpen }}>
            <GetLicencePopup open={open} />
            {memoChild}
        </GetLicencePopupContext.Provider>
    );
};



export const GetLicencePopup = (props: GetLicencePopupProps) => {

    const popup = useGetLicencePopup();

    const OrderCredits = () => {

        const [price, setPrice] = useState<number>(1000);

        return (
            <>
                <Title>Commandez la licence annuelle U31</Title>
                <div className="flex" style={{ gap: "4em", flex: 1 }}>
                    <div className="flex column" style={{ width: "55%" }}>
                        <SubTitle>Votre commande</SubTitle>
                        <div style={{ whiteSpace: 'nowrap', marginTop: "1em", display: 'flex', flex: 1, flexFlow: "column", alignItems: "flex-start" }}>
                            <U31Paragraph color="#000" fontSize="15px" fontWeight="500">Licence annuelle U31</U31Paragraph>
                            <U31Paragraph color="#8A94AF" fontSize="15px" fontWeight="500" >Valables sur un compte web</U31Paragraph>
                            <Divider style={{ marginBottom: "1em", marginTop: "auto" }} />
                            <div className="flex center-v space-between row" style={{ width: "100%", alignItems: "flex-start", marginTop: "1em" }}>
                                <U31Paragraph color="#8A94AF" fontSize="16px" fontWeight="500" >Total TTC</U31Paragraph>
                                <U31Paragraph><b style={{ color: "#3A3C40", fontSize: "25px", display: "block" }}>{price}€</b> </U31Paragraph>
                            </div>
                        </div>

                    </div>
                    <div className="flex column" style={{ width: "45%" }}>
                        <SubTitle>Vos informations</SubTitle>
                        <form
                            style={{ paddingTop: "1em", flex: 1, display: "flex", flexFlow: "column", justifyContent: "space-between" }}
                            onSubmit={async (event: React.SyntheticEvent<HTMLFormElement>) => {
                                event.preventDefault();
                                const form = event.currentTarget;
                                const formElements = form.elements;
                                const firstname = formElements[0].value;
                                const lastname = formElements[1].value;
                                const email = formElements[2].value;
                                const phone = formElements[3].value;
                                const comment = formElements[4].value;

                                await askLicence({
                                    firstname,
                                    lastname,
                                    email,
                                    phone,
                                    comment
                                });
                                setStep(1);
                                return false;
                            }}
                        >
                            <div>
                                <U31TextInput label="Nom" />
                                <U31TextInput label="Prénom" />
                                <U31TextInput label="Email" />
                                <U31TextInput label="Téléphone" />
                            </div>
                            <div>
                                <SubTitle style={{ marginTop: "3em" }}>Commentaire</SubTitle>
                                <U31TextArea rows={6} />
                            </div>
                            <U31Button
                                type="submit"
                                variant="normal"
                                style={{ alignSelf: "flex-end", fontSize: "1em", marginRight: 0, marginBottom: 0 }}>
                                Transférer la commande
                            </U31Button>
                        </form>
                    </div>
                </div>

            </>
        )
    }


    const Success = () => {
        return (
            <div className="flex center" style={{
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '100%',
                textAlign: 'center',
                padding: '10% 20%'
            }}>
                <img style={{ width: "6rem" }} src="/images/business/check.svg" />
                <U31Heading level={3} color={theme?.color?.primary}>Nous avons bien enregistré votre demande.</U31Heading>
                <U31Paragraph fontSize="18px">Notre équipe vous contactera prochainement afin de finaliser votre commande.</U31Paragraph>
                <U31Button variant="normal" onClick={() => setOpen(false)}>Terminer</U31Button>
            </div>
        )
    }

    const setOpen = (open: boolean) => { popup?.setOpen(open) }
    const open = props?.open || false;
    const theme = useTheme();
    const [step, setStep] = useState(0);
    const [options, setOptions] = useState({
        "service": { inCart: false, price: null, date: null },
        "use_editor": { inCart: false },
    });
    const lastRevisionState: RevisionState = useSelector(getRevisionState);

    // deal with popup size
    const [size, setSize] = useState({ width: undefined, height: undefined })
    const popupRef = useRef<HTMLDivElement>();
    useLayoutEffect(() => {
        if (popupRef.current && !size.width && !size.height) {
            popupRef.current.focus();
            setSize({
                width: `${popupRef.current.offsetWidth}px`,
                height: `${popupRef.current.offsetHeight}px`
            })
        }
    }, [popupRef.current])



    return (
        open ?
            <U31Popup
                ref={popupRef}
                width={size.width}
                height={size.height}
                onClose={() => { setOpen(false) }}
            >
                <PopupRightPart>
                    {step == 0 ? < OrderCredits /> : <Success />}
                </PopupRightPart>
            </U31Popup> : <></>

    )
};
