import { useEditor } from '@tiptap/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentTitle, setRecommandations } from '../../actions/nlp-actions';
import { setAccessibilityMessage, setActiveDocumentId, setDiscardedBestPracticesIds, setIgnoredCardIds, setOpenCardIdForCategory, setRevisionState, setRightPanelActivePage, setValidatedBestPracticesIds } from '../../actions/settings-actions';
import { analyze, createRangesForRecos, getDocument, getRemainingWordCount, setIgnoredCards, updateTitle } from '../../helpers';
import { getDocumentTitle, getLanguageRecommandationsAsArray, getMetrics } from '../../reducers/nlp-reducer';
import { getActiveDocumentId, getHiddenCardIds, getActiveCategories, getHoveredCardsIds, getIsImportExportPopupOpen, getLanguage, getCategories, getIgnoredCardIds, getRevisionState, getNlpLoading, getOpenCardIdForCategory } from '../../reducers/settings-reducer';
import RightPanel, { RightPanelPages } from './rightPanel/RightPanel';
import ImportExportPopup from './toolbar/ImportExportPopup';
import Toolbar from './toolbar/Toolbar';
import { RevisionState, U31Recommandation } from '@u31-dev/u31-ui/dist/types';
import { U31Button, U31Paragraph } from "@u31-dev/u31-ui";
import React from 'react';
import { NotEnoughCredits, Notifications } from '../notifications/Notifications';
import { EMPTY_EDITOR_CONTENT, getExtensions } from './editorConfig';
import { findChildren, findChildrenByAttr } from 'prosemirror-utils';
import { Editor } from '@tiptap/core';
import styled from 'styled-components';
import { StyledEditor, StyledEditorContent } from './editor.styled';
import Color from 'color';
import useDebounce from '../helpers/useDebounce';
import { useLocation } from 'react-router-dom';
import { SpinnerLoading } from '../WaitLoading';
import { Tooltip } from '@mui/material';
import { getUser } from '../../reducers/user-reducer';
import { UserType } from '../../types';
import LinkPreview from './plugins/tiptap/LinkPreview';

const StyledTopBar = styled.div`
    display: flex;
    align-items: center;
    /* height: 4rem; */
    /* max-height: 4rem; */
    margin-top: 1.2rem;
    /* flex-grow: 1; */
    padding-bottom: 1rem;
    position: sticky;
    top: 0;
    z-index: 1;
    margin-right: auto;
    width: 100%;
    gap: 1rem;

    img {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
    }

    #documentTitle::placeholder {
        color: #939CA8;
    }

    #documentTitle {
        width: 100%;
        border: none;
        /* color: #939CA8; */
        color: black;
        margin-right: auto;
        outline: 1px solid #747775;
        border-radius: 4px;
        padding: 5px;
        font-size: 18px;
        font-weight: 500;
        height: 20px;
        line-height: 22px;
    }

    #documentTitle>img {
        height: 0.8em;
        margin-left: 0.2em;
        cursor: pointer;
    }

    #documentTitle:focus {
    }

`

const StyledDemoTopBar = styled.div`
    width: 100%;
    display: flex;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: flex-end;
    position: sticky;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    top: 0;
    border: 2px solid #446ee0;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 1rem;
    margin-top: 1rem;

    & .logo {
        width: 50px;
    }
    & p {
        & b {
            font-weight: 500;
        }
        & .blue {
            color: #446EE0;
        }
    }
    & button {
        padding: 0.8rem 0.4rem;
        margin: 0;
    }
`
const DemoTopBar = React.memo(() => {
    return (
        <StyledDemoTopBar>
            <U31Paragraph color="#3A3C40"><b className="blue">Créez un compte pour accéder au diagnostic complet</b><br /> Nous vous offrons <b ><span className="blue">300</span> mots</b> pour découvrir U31</U31Paragraph>
            <U31Button variant="normal" onClick={() => window.open("/creer-un-compte", "_blank")} >Créer mon compte</U31Button>
        </StyledDemoTopBar >
    )
});


export const DEFAULT_DOCUMENT_TITLE = "Document sans titre";
const TopBar = React.memo((props: { editorRef: React.RefObject<Editor> }) => {
    const dispatch = useDispatch()
    const documentTitle = useSelector(getDocumentTitle)
    const titleRef = useRef<HTMLInputElement>(null);
    const documentId = useSelector(getActiveDocumentId);
    const user = useSelector(getUser);
    const [initialTitle, setInitialTitle] = useState<string | boolean>(false);
    const debouncedTitle = useDebounce(documentTitle, 1000)

    const selectEmptyDocumentTitle = () => {
        if (documentTitle != DEFAULT_DOCUMENT_TITLE) return;
        if (!titleRef?.current) return;
        titleRef.current.focus()
        titleRef.current.select()
    }


    useEffect(() => {
        if (!initialTitle && documentTitle != DEFAULT_DOCUMENT_TITLE) {
            setInitialTitle(documentTitle)
        }
    }, [documentTitle])

    useEffect(() => {
        if (debouncedTitle != DEFAULT_DOCUMENT_TITLE && initialTitle && initialTitle != debouncedTitle && documentId)
            updateTitle(documentId, documentTitle, dispatch)
    }, [debouncedTitle])

    return (
        <StyledTopBar onClick={selectEmptyDocumentTitle}>
            <input style={{ width: `min(100%, ${documentTitle.length}ch)` }} aria-label="Titre du document" id="documentTitle" type="text" maxLength={120} autoComplete="off" ref={titleRef} value={documentTitle} onChange={(e) => dispatch(setDocumentTitle(e.target.value))} />
        </StyledTopBar >
    )
});

const StyledBottomBar = styled.div`
    width: 100%;
    bottom: 0;
    padding-bottom: 1em;
    padding-top: 1em;
    color: #687C98;
    background: white;
    z-index: 1;
`


interface EditorHomeProps {
    isDemo?: Boolean
}

const U31Editor = (props: EditorHomeProps) => {
    const dispatch = useDispatch();
    const isDemo = props.isDemo || false;
    const [mounted, setMounted] = useState(false);
    const activeDocumentId = useSelector(getActiveDocumentId);
    const categories = useSelector(getCategories);
    const language = useSelector(getLanguage);
    const languageRecommandationList = useSelector(getLanguageRecommandationsAsArray(language)) as Array<U31Recommandation>;
    const activeCategories = useSelector(getActiveCategories);
    const popupOpen = useSelector(getIsImportExportPopupOpen)
    const hiddenCardIds = useSelector(getHiddenCardIds);
    const ignoredCardIds = useSelector(getIgnoredCardIds);
    const [isImported, setIsImported] = useState(false);
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const location = useLocation();
    const not_enough_credits = lastRevisionState?.not_enough_credits;

    const [showNotEnoughCreditsPopup, setShowNotEnoughCreditsPopup] = useState(false);

    useEffect(() => {
        if (lastRevisionState?.not_enough_credits) {
            setShowNotEnoughCreditsPopup(true);
        }
    }, [lastRevisionState])

    const BottomBar = React.memo(() => {
        const metrics = useSelector(getMetrics);
        const user: UserType | null = useSelector(getUser);
        const AnalyzeButton = () => {
            const isNlpLoading = useSelector(getNlpLoading);

            const Icon = () => isNlpLoading ? <SpinnerLoading size="1rem" color="#ffffff" /> : <img alt="" role="presentation" src="/images/refresh.svg" />

            return (
                <U31Button
                    onMouseDown={() => !isNlpLoading && analyze({
                        editor: editorRef.current,
                        documentId: activeDocumentId,
                        dispatch,
                        demo: true,
                        callback: () => {
                            dispatch(setRightPanelActivePage(RightPanelPages.RECOMMANDATIONS))
                        }
                    })}
                    style={{ marginLeft: 0 }}
                    variant="normal">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem'
                    }}>
                        <Icon /> {isNlpLoading ? "Analyse en cours..." : "Analyser mon texte"}
                    </div>
                </U31Button>
            )

        }

        return (
            <StyledBottomBar>
                <U31Paragraph color="#8A94AF" lineHeight="1.5rem" fontSize="1em" fontWeight="400" fontFamily="Ubuntu">
                    Nombre de mots : <span style={{ fontWeight: 500, color: "#515E6E" }}>{metrics?.wordCount || 0}</span><br />
                </U31Paragraph>
                {user?.offer_type?.key == "free_account" &&
                    <U31Paragraph color="#8A94AF" lineHeight="1.5rem" fontSize="1em" fontWeight="400" fontFamily="Ubuntu">
                        Mots restants avec l’essai gratuit: <span style={{ fontWeight: 500, color: "#515E6E" }}>{not_enough_credits ? <span style={{ color: "#d74f55" }}>0</span> : getRemainingWordCount(user)} / 300</span>
                    </U31Paragraph>
                }
                {props.isDemo && <AnalyzeButton />}
            </StyledBottomBar>
        )
    });

    useEffect(() => {
        if (!lastRevisionState?.document_backup_id) return;
        if (ignoredCardIds.length == 0) return;
        setIgnoredCards(lastRevisionState.document_backup_id, ignoredCardIds);
    }, [ignoredCardIds])


    const analyzeCallback = (document_id?: string) => {
        if (!editorRef?.current) return;
        analyze({
            editor: editorRef.current,
            documentId: document_id || activeDocumentId,
            dispatch,
            demo: isDemo,
            callback: () => {
                console.log("analyzeCallback 1")
                dispatch(setRightPanelActivePage(RightPanelPages.RECOMMANDATIONS))
            }
        });
    }

    const editorRef = useRef<Editor | null>(null);
    const extensions = useMemo(() => {
        // if (editorRef?.current) {
        //     editorRef.current.commands.setContent(EMPTY_EDITOR_CONTENT)
        // }
        return getExtensions({ isDemo: isDemo, dispatch, analyzeCallback });
    }, [editorRef.current])

    const editor = useEditor({
        extensions,
        content: EMPTY_EDITOR_CONTENT,
    }, [activeDocumentId])

    editorRef.current = editor;

    const ignoredCardIdsRef = useRef<Array<string>>([]);
    useEffect(() => {
        ignoredCardIdsRef.current = ignoredCardIds;
    }, [ignoredCardIds])

    const groupRecommandationsByBlock = (recos: Array<U31Recommandation>) => recos.reduce((acc: Object<any>, curr: U31Recommandation) => {
        if (!curr.block_id) {
            acc["origin"].push(curr)
            return acc;
        }
        if (!(curr.block_id in acc)) {
            acc[curr.block_id] = []
        }

        // remove span linked to specific block_id (other than reco's block_id)
        const spans = curr.spans.filter(span => {
            const spanBlockId = span[2];
            if (spanBlockId) {
                if (!(spanBlockId in acc)) {
                    acc[spanBlockId] = []
                }
                acc[spanBlockId].push({ ...curr, spans: [span] })
            }
            return !spanBlockId;
        })
        acc[curr.block_id].push({ ...curr, spans })
        return acc;
    }, { "origin": [] })

    const updateEditorRecommandations = (recommandationList: Array<U31Recommandation>) => {
        if (!editor || editor?.isDestroyed)
            return;
        // const selection = editor?.isFocused && editor?.state.selection || 0;
        const selection = editor?.state.selection || 0;
        // remove all recommandations
        // editor?.commands.focus();
        editor?.commands.selectAll();
        editor?.commands.unsetU31HighlightReco();
        editor?.commands.setMeta('addToHistory', false);

        const groupedRecommandations = groupRecommandationsByBlock(recommandationList);
        console.log("groupedrecommandationList", groupedRecommandations)
        console.log("recommandationList", recommandationList)
        for (const block_id in groupedRecommandations) {
            let offset = 0;
            const blockRecommandationsList = groupedRecommandations[block_id];
            const recoRanges = createRangesForRecos(blockRecommandationsList, categories)

            if (block_id != "origin") {
                offset = findChildrenByAttr(editor?.state.doc, attrs => attrs.block_id == block_id).pop()?.pos;
                if (offset == undefined) continue;
            }
            // // regularRecos
            editor?.commands.forEach(recoRanges, (range, { commands }) => {
                // prevent writing to history
                commands.setMeta('preventUpdate', true)
                commands.setMeta('addToHistory', false);
                commands.setTextSelection({
                    from: range.begin + offset + 1,
                    to: range.end + offset + 1
                })
                commands.setU31HighlightReco({
                    u31recommandations: range.recos
                })
                return true;
                // return commands.insertContent(item)
            })

            // console.log("superSpanRecos recoRangesSuperSpanRecos", recoRangesSuperSpanRecos);

            // // super spans
            // editor?.commands.forEach(recoRangesSuperSpanRecos, (range, { commands }) => {
            //     // prevent writing to history
            //     commands.setMeta('preventUpdate', true)
            //     commands.setMeta('addToHistory', false);
            //     commands.setTextSelection({
            //         from: range.begin + offset + 1,
            //         to: range.end + offset + 1
            //     })
            //     commands.setU31HighlightReco({
            //         u31recommandations: range.recos,
            //         isSuperSpan: true
            //     })
            //     return true;
            //     // return commands.insertContent(item)
            // })

        }

        editor?.commands.setU31ActiveCategories(activeCategories);
        editor?.commands.setMeta('addToHistory', false);
        editor?.commands.setTextSelection(selection);
        // editor?.commands.focus();


    }

    // highlight marks whenever recommandations changes or activeCategories changes 
    useEffect(() => {
        if (!editor || editor?.isDestroyed)
            return;


        // find updated position of ignored recommandations in potentially edited doc
        const u31HighlightRecoNodes = findChildren(editor.state.doc, child => child.marks.map(mark => mark.type.name).includes("u31HighlightReco"), true)
        const ignored_recos_with_updated_position: Array<any> = []
        u31HighlightRecoNodes.forEach((nodeWithPos) => {
            nodeWithPos.node.marks.forEach(mark => {
                if (mark.type.name == "u31HighlightReco") {
                    mark.attrs.u31recommandations.forEach((reco) => {
                        if (ignoredCardIdsRef.current.includes(reco.id) || reco.ignored == true) {
                            // if (reco.ignored == true) {
                            ignored_recos_with_updated_position.push({ start: nodeWithPos.pos, end: nodeWithPos.pos + nodeWithPos.node.nodeSize, context: reco.context, reco, category: reco.category })
                        }
                    });
                }
            });
        })

        const new_ignored_cards_ids: Array<string> = []
        let recommandationList = languageRecommandationList;
        const recoRanges = createRangesForRecos(recommandationList, categories)
        recoRanges.forEach(recoRange => {
            // const is_reco_ignored = ignored_recos_with_updated_position.map(ignored_reco => reco.spans.find(span => span[0] + 1 == ignored_reco.start && span[1] + 1 == ignored_reco.end)).filter(span => span != undefined).length > 0
            const ignoredRecosRanges = ignored_recos_with_updated_position.filter(ignored_reco => recoRange.begin == ignored_reco.start - 1 && recoRange.end == ignored_reco.end - 1);
            if (ignoredRecosRanges) {

                ignoredRecosRanges.forEach(ignoredRecoRanges => {
                    recoRange.recos.forEach((reco: any) => {
                        if (reco.category == ignoredRecoRanges.category) {
                            new_ignored_cards_ids.push(reco.id)
                        }
                    })
                })

            }

        })


        recommandationList = recommandationList.map(reco => {
            const spans = reco.spans.map(span => {
                // span[0] += 1
                // span[1] += 1
                return span;
            })
            return {
                ...reco,
                ignored: new_ignored_cards_ids.includes(reco.id),
                hidden: !activeCategories.includes(reco.category) || hiddenCardIds.includes(reco.id),
                spans
            }
        })
        dispatch(setIgnoredCardIds([...new Set([...ignoredCardIdsRef.current, ...new_ignored_cards_ids])]))
        updateEditorRecommandations(recommandationList)
        // }, [languageRecommandationList, activeCategories])
    }, [languageRecommandationList])

    useEffect(() => {
        const selection = editor?.state.selection || 0;
        editor?.commands.setMeta('addToHistory', false);
        editor?.commands.selectAll();
        editor?.commands.setU31ActiveCategories(activeCategories);
        editor?.commands.setTextSelection(selection);
    }, [activeCategories])

    useEffect(() => {
        const selection = editor?.state.selection || 0;
        editor?.commands.setMeta('addToHistory', false);
        editor?.commands.selectAll();
        editor?.commands.setU31IgnoredCardIds(ignoredCardIds);
        editor?.commands.setTextSelection(selection);
    }, [ignoredCardIds])


    const loadDocumentFromId = async (documentId: string, callback?: Function) => {
        if (editor && editorRef.current && documentId) {
            dispatch(setActiveDocumentId(documentId));
            const document = await getDocument(documentId);
            const { editor_state, recommandations, title, last_revision, is_imported, ignored_cards_ids, validated_best_practices, discarded_best_practices }: any = document;
            if (editor_state) {
                editorRef.current.chain()
                    .setContent(editor_state)
                    .setMeta('addToHistory', false)
                    .run();
                dispatch(setRightPanelActivePage(RightPanelPages.RECOMMANDATIONS))
            }
            // if storage sends empty editor_state (Ie: doc has never been saved, or is totally new), we fill the editor with 
            // EMPTY_EDITOR_CONTENT, which includes `importDocument` extension, so the user has a button to import a new document.
            else {
                editorRef.current.commands.setContent(EMPTY_EDITOR_CONTENT)
                dispatch(setRightPanelActivePage(RightPanelPages.GETTING_STARTED))
            }
            if (last_revision) {
                dispatch(setRevisionState(last_revision))

            }

            dispatch(setRecommandations(recommandations));
            dispatch(setIgnoredCardIds(ignored_cards_ids || []))
            dispatch(setValidatedBestPracticesIds(validated_best_practices || []))
            dispatch(setDiscardedBestPracticesIds(discarded_best_practices || []))

            console.log("recommandations", recommandations);

            if (recommandations?.recommandations && recommandations.recommandations.length > 0) {
                dispatch(setOpenCardIdForCategory({ category: recommandations.recommandations[0].category, id: recommandations.recommandations[0]._id }))
            }
            dispatch(setDocumentTitle(title))
            setIsImported(is_imported);

            if (location.hash.includes("imported")) {
                analyzeCallback(documentId);
                try {
                    //try to remove the hash
                    window.location.hash = ""
                    history.pushState("", window.document.title, window.location.pathname + window.location.search)
                } catch (error) {
                }
            }
            callback && callback(!last_revision?.not_enough_credits)
        } else {
            console.log("U31 Error", "loadDocumentFromId")
        }
    }

    const didMount = async () => {
        if (!editor || mounted) return;
        setMounted(true);


        const urlDocId = window.location.pathname.split('/document/')[1] || "empty";
        if (urlDocId) {
            loadDocumentFromId(urlDocId, (focus) => {
                setTimeout(() => {
                    if (focus) {
                        const editorDom = document.querySelector("#u31editor > div");
                        console.log("FOCUS", editorDom);
                        if (editorDom) {
                            (editorDom as HTMLDivElement).focus();
                        }
                    }

                }, 1000)
            });
        }

    }



    useEffect(() => {
        didMount();
    }, [editor])


    useEffect(() => {
        dispatch(setAccessibilityMessage("page éditeur U31"))
        setTimeout(() => setAccessibilityMessage(""), 500);
        document.title = "Editeur u31"
        fixTippySelectionJump()
    }, [])

    return (
        <>
            <StyledEditor>
                <LinkPreview editor={editor} />
                <HoveredCardsStyle key="cards_style" />
                {popupOpen ? <ImportExportPopup editor={editor} /> : null}
                <div id="editorToolbarWrapper">
                    <Toolbar isDemo={isDemo} editor={editor} />
                    <div id="editorWrapper">
                        {showNotEnoughCreditsPopup == true && <NotEnoughCredits onClose={() => setShowNotEnoughCreditsPopup(false)} />}
                        <div id="editorContainer" >
                            <Notifications />
                            {props.isDemo ? <DemoTopBar /> : <TopBar editorRef={editorRef} />}
                            <StyledEditorContent autoFocus id="u31editor" spellCheck={false} editor={editor} />
                            <BottomBar />

                        </div>
                    </div>
                </div>

                {useMemo(() => <RightPanel editorRef={editorRef} isDemo={isDemo} />, [editorRef])}
            </StyledEditor >

        </>
    )
}

const HoveredCardsStyle = () => {
    const activeCategories = useSelector(getActiveCategories);
    const openCardId = useSelector(getOpenCardIdForCategory(activeCategories[0]));
    const hoveredCardIds = useSelector(getHoveredCardsIds);
    const ignoredCardIds = useSelector(getIgnoredCardIds);
    const hiddenCardIds = useSelector(getHiddenCardIds);

    const language = useSelector(getLanguage);
    const languageRecommandationList = useSelector(getLanguageRecommandationsAsArray(language)) as Array<U31Recommandation>;

    const recosToHide = languageRecommandationList
        .filter(reco => !activeCategories.includes(reco.category))
        .filter(reco => ignoredCardIds.includes(reco.id))
        .filter(reco => hiddenCardIds.includes(reco.id))
    const recosToShow = languageRecommandationList
        .filter(reco => activeCategories.includes(reco.category))
        .filter(reco => !ignoredCardIds.includes(reco.id))
        .filter(reco => !hiddenCardIds.includes(reco.id))

    const categories = useSelector(getCategories);
    const activeColor = activeCategories.length ? categories[activeCategories[0]]?.color : "inherit";
    let opacityColor = "inherit";
    const alpha = 0.2;



    if (activeColor != "inherit") {
        opacityColor = Color(activeColor).alpha(0.2).string();
    }

    // only efficient way (I found) with prosemirror to add dynamic style to custom marks:
    // build a regular css code for highlighting the right cards in the editor
    const buildStyleForHoveredCards = () => {
        let style = '';
        const cardsToHighlight = [...hoveredCardIds, ...([openCardId] || [])];
        if (cardsToHighlight.length <= 0) return "";
        style += cardsToHighlight.map(id => `span[u31reco-${id}]`).join(',')
        style += "{background: var(--data-color-opacity) !important;}"

        style += recosToHide.map(reco => `span[u31reco-${reco.id}]`).join(',')
        style += "{--data-color: inherit !important; --data-color-opacity: inherit !important;}"

        style += recosToShow.map(reco => `span[u31reco-${reco.id}]`).join(',')
        // style += `{--data-color:${activeColor} !important; --data-color-opacity:${Color(activeColor).alpha(0.2).string()} !important;}`
        style += `{--data-color:${activeColor} !important; --data-color-opacity:${opacityColor} !important;}`

        return style;
    }
    return <style>{buildStyleForHoveredCards()}</style>

}

function fixTippySelectionJump() {

    document.addEventListener('mousedown', function (evt) {
        setTimeout(() => {
            const tippy = document.querySelector("#tippy-1") as HTMLDivElement;
            if (!tippy) return;
            tippy.style.pointerEvents = "none";
        }, 150)
    })

    document.addEventListener('mouseup', function (evt) {
        setTimeout(() => {
            const tippy = document.querySelector("#tippy-1") as HTMLDivElement;
            if (!tippy) return;
            tippy.style.pointerEvents = "all";
        }, 150)
    })
}


export default U31Editor;
