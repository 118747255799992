import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setImportExportPopupOpen, setRightPanelActivePage } from "../../../actions/settings-actions";
import useInput from "../../helpers/useInput";
import { getActiveDocumentId, getImportExportPopupType } from "../../../reducers/settings-reducer";
import { useDropzone } from 'react-dropzone';
import mammoth from "mammoth";
import { Editor, generateHTML, generateJSON, JSONContent } from "@tiptap/react";
import {normalizeHtml} from "../../helpers/normalizeHtml";
import { analyze, createDocument, exportDocument, fetchMyDocuments, importDocument, readFileInputEventAsArrayBuffer } from "../../../helpers";
import { U31Button, U31Heading, U31Paragraph, U31LinearProgress } from "@u31-dev/u31-ui";
import styled, { useTheme } from "styled-components";
import { getDocumentTitle } from "../../../reducers/nlp-reducer";
import { getExtensions } from "../editorConfig";
import { RightPanelPages } from "../rightPanel/RightPanel";

export const mammothOptions = {
    styleMap: [
        "p[style-name='center'] => p:fresh > center",
        "p[style-name='right'] => p:fresh > right",
        "p[style-name='left'] => p:fresh > left",
        "p[style-name='justify'] => p:fresh > justify",

        "p[style-name='Heading 1'] => h1.test2:fresh",
        "p[style-name='Heading 2'] => h2:fresh",
        "p[style-name='Heading 3'] => h3:fresh",
        "p[style-name='Heading 4'] => h4:fresh",
        "p[style-name='Heading 5'] => h5:fresh",
        "p[style-name='Heading 6'] => h6:fresh",

        // CUSTOM ERWAN GOOGLE DOCS
        "p[style-name='Title'] => h1.title:fresh",
        "p[style-name='Subtitle'] => h2.subtitle:fresh",
    ],
    // transformDocument: transformElement,
    // transformDocument: mammoth.transforms.paragraph(transformParagraph),
    ignoreEmptyParagraphs: true,
    includeEmbeddedStyleMap: true,
    includeDefaultStyleMap: true,
};


const StyledImportExportContainer = styled.div`
    position: fixed;
    background: #1b202673;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;



    #importExport {
        position: relative;
        width: 35em;
        min-height: 75vh;
        background: #FFFFFF;
        box-shadow: 5px 5px 12px #5A73BA2B;
        border: 1px solid #7186A362;
        border-radius: 6px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        padding: 3% 5%;
        box-sizing: border-box;

        .dropzone {
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            width: 100%;
            background-color: #FAFAFF;
        }

        .dropzone>div {
            display: flex;
            flex-flow: column;
            width: 100%;
            box-sizing: border-box;
        }

        .dropzone button {
            border-width: 1.5px;
        }

        .close {
            position: absolute;
            width: 1em;
            height: 1em;
            top: 1em;
            right: 1em;
            cursor: poUbuntu;
        }

        #fileName {
            display: flex;
            height: 2em;
            width: 100%;
            align-items: center;
            justify-content: center;
            justify-content: space-between;
        }

        #fileName #wordIcon {
            height: 2em;
            margin-right: 1em;
        }

        #fileName #closeIcon {
            height: 1em;
        }

        #fileName p {
            margin-right: auto;
        }

        h2 {
            font: normal normal 600 1.3em/1em Ubuntu;
            color: #1B2026;
        }

        .documentName {
            width: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            gap: 1rem;
        }

        .documentName label {
            font: normal normal 500 14px/22px Ubuntu;
            letter-spacing: 0px;
            color: #515E6E;
        }

        .documentName input {
            width: 75%;
            text-align: center;
            border: 1px solid #8A94AF;
            border-radius: 3px;
            font: normal normal normal 14px/22px Ubuntu;
            letter-spacing: 0px;
            color: #515E6E;
            padding: 0.3rem 1rem;
        }


        .info {
            font: normal normal 500 14px/15px Ubuntu;
            letter-spacing: 0px;
            color: #515E6E;
        }

        .options .option.selected {
            outline: 2px solid #446EE0;
        }

        .options {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 2rem;
        }

        .options .option {
            width: 50%;
            height: 12em;
            cursor: pointer;
            background: #FAFAFF 0% 0% no-repeat padding-box;
            border: 0.5px solid #DCDFE6;
            border-radius: 7px;
            display: flex;
            flex-flow: column;
            justify-content: space-evenly;
            align-items: center;
            position: relative;
        }

        .options .option input[type="radio"] {
            position: absolute;
            top: 1rem;
            left: 1rem;
        }

        .options .option .type {
            display: flex;
            align-items: center;
            flex-flow: column;
        }

        .options .option p {
            margin: 0;
            font: normal normal 400 0.9em/1em Ubuntu;
            color: #293B50;
        }

        .options .option p.extension {
            font: normal normal 300 0.9em/1em Ubuntu;
            letter-spacing: 0px;
            color: #6D7E91;
        }
    }
`



type ImportExportPopupProps = {
    editor: Editor | null
};

const ImportExportPopup: FC<ImportExportPopupProps> = ({ editor }: ImportExportPopupProps) => {

    const action = useSelector(getImportExportPopupType);
    const documentId = useSelector(getActiveDocumentId);

    const dispatch = useDispatch()
    const theme = useTheme();
    const close = () => dispatch(setImportExportPopupOpen(false))


    const [type, setType] = useState("docx");
    const Export = () => {

        const documentTitle = useSelector(getDocumentTitle);
        const fileName = useInput(documentTitle);


        return (
            <StyledImportExportContainer id="importExportContainer">
                <div id="importExport">
                    <div className="close" onClick={close} ><img src="/images/business/close.svg" alt="" /></div>
                    <U31Heading level={2}> Exporter un document</U31Heading>
                    <div className="documentName">
                        <label htmlFor="name">Nom du document : </label>
                        <input {...fileName} type="text" name="name" placeholder={documentTitle} />
                    </div>
                    <div className="info">Choisissez un format de document</div>
                    <div className="options">
                        <div
                            onClick={() => { setType("docx") }}
                            className={`option ${type == "docx" ? "selected" : ""}`}>
                            <input type="radio" checked={type == "docx"} />
                            <img src="/images/word.svg" alt="" />
                            <div className="type">
                                <p>Document Word</p>
                                <p className="extension">.docx</p>
                            </div>
                        </div>
                        <div
                            onClick={() => { setType("pdf") }}
                            className={`option ${type == "pdf" ? "selected" : ""}`}>
                            <input type="radio" checked={type == "pdf"} />
                            <img src="/images/pdf-file.svg" alt="" />
                            <div className="type">
                                <p>Document PDF</p>
                                <p className="extension">.pdf</p>
                            </div>
                        </div>
                    </div>
                    <U31Button style={{ width: "100%" }} variant="normal" onClick={() => { exportDocument({ editor, fileName: fileName.value, type }) }}>Exporter</U31Button>
                </div>
            </StyledImportExportContainer>
        );
    }


    const Import = () => {
        const baseStyle = {
            flex: 1,
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 1,
            borderRadius: 8,
            borderColor: '#bdbdbd',
            borderStyle: 'dashed',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        };

        const activeStyle = {
            borderColor: '#4070f4'
        };

        const acceptStyle = {
            borderColor: '#4070f4'
        };

        const rejectStyle = {
            borderColor: '#ff1744'
        };




        const importDocx = async () => {
            if (loading) {
                console.warn("already processing document")
                return;
            }
            setLoading(true);
            if (file) {
                // const documentId = await createDocument();

                const arrayBuffer = await readFileInputEventAsArrayBuffer(file)
                console.log("ARRAY BUFFER", arrayBuffer)
                mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, mammothOptions)
                    .then(async (result: { value: any; }) => {
                        const { value } = result;
                        const html = normalizeHtml(value);
                        const editor_state = generateJSON(html, getExtensions({ isHeadLess: true })) as JSONContent
                        if (editor) {
                            editor.commands.setContent(editor_state);
                            analyze({
                                editor,
                                documentId,
                                dispatch,
                                callback: (success, statusCode) => {
                                    console.log("CALLBACK--", success, statusCode)
                                    dispatch(setRightPanelActivePage(RightPanelPages.QUOTATION))
                                }
                            });
                        }
                        else {

                            const importedDoc = await importDocument(file?.name, editor_state);
                            fetchMyDocuments(dispatch);
                            console.log("IMPORTED", importedDoc);

                            setLoading(false);
                            // add #process to tell the new window to 
                            window?.open(`/document/${importedDoc?._id}#imported`, '_blank')?.focus();
                        }

                        // console.log("IMPORTED DOC", impo)
                        // editor.commands.setContent(editor_state);
                        // console.log("FILE EDITOR VALUE", editor_state)
                        // setTimeout(() => {
                        //     analyze(editor, documentId, dispatch);
                        // })

                        close();

                    })
                    .done();

            }

        }

        const [myFiles, setMyFiles] = useState([])

        const onDrop = useCallback(acceptedFiles => {
            setMyFiles([...myFiles, ...acceptedFiles])
        }, [myFiles])

        const removeAll = () => {
            setMyFiles([])
        }

        const {
            acceptedFiles,
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
            inputRef
        } = useDropzone({
            onDrop,
            accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });

        const style = useMemo(() => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(acceptedFiles.length > 0 ? activeStyle : {}),
            ...(acceptedFiles.length > 0 ? acceptStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }), [
            isDragActive,
            isDragReject,
            isDragAccept,
            acceptedFiles
        ]);




        const file = myFiles.length > 0 ? myFiles[0] : null;
        const fileName = file?.name;
        const [loading, setLoading] = useState(false);

        return (
            <StyledImportExportContainer id="importExportContainer">
                <div id="importExport">
                    <div className="close" onClick={close} ><img src="/images/business/close.svg" alt="" /></div>
                    <U31Heading level={2}> Importer un document</U31Heading>
                    <div id="fileName">
                        {fileName && <img id="wordIcon" src="/images/word.svg" alt="" />}
                        {fileName && <U31Paragraph>{fileName}</U31Paragraph>}
                        {fileName && <U31Button style={{ border: "none", padding: 0 }} onClick={() => { removeAll() }}><img id="closeIcon" src="/images/business/close.svg" alt="" /></U31Button>}
                    </div>
                    {loading && <U31LinearProgress variant="undetermined" backgroundColor={"#D5E0FF"} style={{ width: 'calc(100% - 4em)', marginLeft: 'auto', marginTop: '-1em', paddingRight: '1em' }} isLabelVisible={false} />}

                    <section className="dropzone">
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <U31Paragraph style={{ textAlign: "center" }} fontSize="15px" fontWeight="300" color="#515E6E">Déposez votre document <b>.docx</b> ici <br /> ou</U31Paragraph>
                            <U31Button variant="light">
                                Sélectionnez un document sur l’ordinateur
                            </U31Button>
                        </div>
                    </section>
                    <U31Paragraph style={{ textAlign: "center" }}>L’éditeur affichera uniquement le texte du document importé. <br /> <b style={{ fontWeight: "500" }}>La mise en page d’origine ne sera pas conservée.</b></U31Paragraph>
                    <U31Button variant="normal" style={{ width: "100%" }} onClick={importDocx}>Importer</U31Button>
                </div>
            </StyledImportExportContainer>
        )
    }

    if (action == "import")
        return <Import />
    else if (action == "export")
        return <Export />
    return null;
}


export default ImportExportPopup;