import { U31Button } from "@u31-dev/u31-ui";
import styled from "styled-components";

export const StyledRecommandations = styled.div`
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    height: 100%;
    overflow-y: auto;
    background: #FFFFFF;
    transition: 0.4s;
    display: block;
    overflow-y: auto;
    flex-flow: column;
    align-items: flex-start;
    position: relative;
    overflow: visible;
    display: flex;

    .scrollableY::-webkit-scrollbar {
        display: none;
    }

    .scrollableY {
        overflow-y: auto;
        scrollbar-width: none;
        width: ${props => props.theme.ui.cards.width};
        /* height: calc(100% - 8rem); */
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 3rem;
        padding-bottom: 1rem;
        margin-left: auto;        

        height: 100%;
        display: flex;
        flex-flow: column;

        /* @media screen and (max-width: 1280px) {
        } */
        .card {
            overflow: visible !important;
            &.best_practice {
                .headerArrow {
                    display: none;
                }
            }
        }

    }

`

export const IgnoredCardsWrapper = styled.div<{ active: boolean, count: number }>`
    position: relative;
    margin-top: 4rem;
    width: ${props => props.theme.ui.cards.width};
    margin-left: auto;

    &:before {
        content: "";
        border-top: 1px solid #C6CBD6;
        width: 100%;
        position: absolute;
        top: -1rem;
    }

    &>.header {
        border: none;
        height: 3rem;
        background-color: #738090;
        width: 100%;
        display: flex;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font: normal normal 500 13px/19px Ubuntu;
        border-radius: 4px;
        color: white;
        cursor: pointer;

        &:before {
            content: "5";
            ${props => props.count && `content: "${props.count}";`}
            height: 1.5rem;
            width: 1.5rem;
            background: white;
            color: #738090;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            font-weight: bold;
            border-radius: 50%;
        }

        &:after {
            content: "";
            height: 1rem;
            width: 1rem;
            ${props => props.active && 'transform: rotate(90deg);'}
            background-image: url('/images/right_arrow_white.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .ignoredCardsList {
        display: none;
    }
    .ignoredCardsList.visible {
        display: block;
    }

`


export const StyledGoToNextCategory = styled.div`
    margin-top: 2rem;
    width: ${props => props.theme.ui.cards.width};
    margin-left: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;

    .congrats {
        font: normal normal 500 16px/30px Ubuntu;
        color: ${props => props.theme.color.primary};
    }

    .message {
        font: normal normal normal 14px/22px Ubuntu;
        letter-spacing: 0px;
        color: #4F5456;
    }

    .divider {
        width: 100%;
        margin: 1rem 0;
        border-bottom: 1px solid #D0D0E3;
    }

    `
export const StyledNextStep = styled.button<{ color: string }>`
    font: normal normal 500 14px/22px Ubuntu;
    letter-spacing: 0px;
    color: white;
    width: 40%;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    padding-top: 1rem;
    border-radius: 3px;
    overflow: hidden;

    &:focus-visible {
        outline: 2px solid ${props => props.color};
    }

    .name {   
        background: ${props => props.color};
        width: 100%;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-top: 0.5rem;
    }

    svg {
        height: 3rem;
    }
`;
