import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Calendar from 'react-calendar';
import { useSelector } from 'react-redux';
import { getCalendarPrices, getMetrics } from '../../../reducers/nlp-reducer';
import { U31Paragraph } from "@u31-dev/u31-ui";
import './Calendar.css';

const datesToAddContentTo = [new Date()];
const now = new Date();
const shortDays = ["L", "M", "M", "J", "V", "S", "D"]
const days = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."]
const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]


export const PriceCalendar = forwardRef((props, ref) => {
    const { disabled, date, updateDateAndPrice, calendarPrices } = props;
    const getLabelForDate = (date) => disabled == true ? "——" : date ? `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getUTCFullYear()}` : "Choisissez une date";
    const onChange = (date, event) => {
        const button = event.target.nodeName == "BUTTON" ? event.target : event.target.parentNode;
        if (button.querySelector(".price")) {
            const price = parseFloat(button.querySelector(".price").getAttribute("data-price"))
            setOpen(false);
            updateDateAndPrice(date, price);
        }
    }

    const _setOpen = (_open) => {
        setOpen(_open);
    }

    useImperativeHandle(
        ref,
        () => ({
            setOpen: _setOpen,
        })
    )

    const tileContent = ({ date, view }) => {
        // Add class to tiles in month view only
        if (view === 'month') {
            // if (datesToAddContentTo.find(dDate => dDate == date)) {

            const difference_in_time = date.getTime() - now.getTime();
            const difference_in_days = Math.floor(difference_in_time / (1000 * 3600 * 24));
            const key = Object.keys(calendarPrices).map(diff => parseInt(diff)).filter(diff => diff <= difference_in_days).slice(-1).pop()
            if (key && calendarPrices) {
                const price = Math.round(calendarPrices[key] * 100) / 100;
                return <div data-price={price} className="price">{price}€</div>;
            }
            // return <div data-price={-1} className="price">X€</div>;
            // }
        }
    }


    // const [date, setDate] = useState(null);
    const [open, setOpen] = useState(false);

    return (
        <div className={`calendarContainer ${disabled == true ? 'disabled' : ''}`}>
            <div className="dateView" onMouseUp={() => { console.log("ONCLICKKKK"); disabled == true ? null : setOpen(!open) }}>
                <U31Paragraph style={{ margin: 0, padding: "0.5em 1em" }}>{getLabelForDate(date)}</U31Paragraph>
                <img style={{ marginRight: "0.5em" }} src="/images/FLECHE.svg" alt="" />
            </div>
            {open ? <Calendar
                formatShortWeekday={(locale, date) => { return shortDays[(date.getDay() + 6) % shortDays.length] }}
                tileDisabled={({ activeStartDate, date, view }) => { return [0, 6].includes(date.getDay()) }} // disable weekends
                minDate={new Date(now.getTime() + 1000 * 60 * 60 * 24 * 5)}
                maxDate={new Date(now.getTime() + 1000 * 60 * 60 * 24 * 60)} // disable after 60 days
                onChange={onChange}
                value={date}
                tileContent={tileContent}
            /> : null}
        </div>
    );
})