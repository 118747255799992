
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { HashLink } from 'react-router-hash-link';
import styled from "styled-components";
import { setAccessibilityMessage } from "../../actions/settings-actions";
import { IS_CNAV } from "../../config";
import { useWindowSize } from "../../helpers";
import { getIsUserLoggedIn } from "../../reducers/user-reducer";

interface HeaderProps {
    hideEditorButton?: boolean,
    showOnlyIcon?: boolean,
}

const StyledHeader = styled.header`
    display: flex;
    position: sticky;
    z-index: 99;
    top: 0;
    left: 0;
    height: 110px;
    width: 100vw;
    justify-content: space-between;
    font-family: 'Ubuntu';
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0.1) 100%);

    a {
        text-decoration: none;
    }

    #menu {
        width: 100%;
        height: 100%;
        display: flex;
    }

    ul {
        display: flex;
        flex-flow: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
        padding-left: 0 !important;
        
        li {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            a {
                color: #4D5167;
                font: normal normal normal 15px/22px Ubuntu;
                letter-spacing: 0px;
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                :after {
                    content: '';
                    transition: 0.2s;
                    width: 0em;
                    border-bottom: 2px solid var(--blue);
                    position: absolute;
                    left: calc(50%);
                    margin-top: 2em;
                    box-sizing: border-box;
                }
            }

            &.active a:after,
            a:focus-visible:after, 
            &:hover a:after {
                width: 4em;
                left: calc(50% - 4em/2);
            }

            &.active a,
            & a:focus-visible, header &:hover a {
                color: var(--blue);
            }
        }
    }

    @media screen and (max-width:900px) {
        ul {
            display: none;
        }
        #menu {
            ul {
                padding-top: 15vh;
                width: 60vw;
                right: 0;
                position: absolute;
                height: 100vh;
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
                background: white;
                padding-left: 5vw !important;
                padding-right: 5vw !important;
                margin-top: 0;
                transition: 0.4s;
                transform: translateX(100%);
                display: flex;
                li {
                    height: 5em;
                    width: 100%;
                    justify-content: flex-start;
                    &:not(:last-child) {
                        border-bottom: 1px solid #CFD8E3;
                    }
                    a {
                        font: normal normal 500 1em/1em Ubuntu;
                        color: black;
                    }
                }
            }
            #cover {
                position: fixed;
                transition: 0.4s;
                opacity: 0;
                left: 0;
                top: 0;
                width: 100vw;
                height: 100vh;
                opacity: 0;
                background: #272e38;
                pointer-events: none;
            }
        }
        #menu.open {
            ul {
                transform: translateX(0vw);
            }
            #cover {
                opacity: 0.52;
                pointer-events: all;
            }
        }
        #toggleMenu {
            display: inherit;
            height: 35%;
            align-self: center;
            margin-right: 2em;
            z-index: 1;
        }

    }
`

const StyledHeaderLogo = styled.div`
    
    padding-left: 1.5vw;
    width: 100px;

    &>a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &>a>img.logo {
        height: 75%;
    }

    &>a>h2 {
        font-size: 1.125em;
        letter-spacing: 0.01125em;
        margin-left: 1em;
    }

    /* mobile */
    @media screen and (max-width:900px) {
        width: 30%;
        padding-left: 5vw;

        &>a {
            margin: 0;
        }

        &>a>img.logo {
            height: 50%;
        }

    }
`

const StyledAccessEditor = styled.div`
    justify-content: flex-end;
    padding-right: 2.6vw;
    font-size: 1em;
    white-space: nowrap;

    &>.u31-btn {
        font-family: 'Ubuntu', sans-serif;
    }

    &>a.sign-in {
        color: var(--blue);
        margin-right: 1.5625em;
    }

    /* mobile */
    @media screen and (max-width:900px) {
        width: 70%;
        padding-right: 5vw;
        display: none !important;
        &>.u31-btn {
            font-size: 0.875em;
        }
    }

`

const Header = (props: HeaderProps) => {
    const { hideEditorButton, showOnlyIcon } = props;
    const isLoggedIn = useSelector(getIsUserLoggedIn);
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);

    const setMessage = (message: string) => {
        dispatch(setAccessibilityMessage(message));
    }
    // const { width, height } = useWindowSize()

    // if (isLoggedIn == true ) {
    //     history.push('/account')
    // }

    const isAnnexe = window.location.pathname == "/pourquoi-adapter-vos-contenus"
    const isOurTool = window.location.pathname == "/notre-technologie"
    const isAccessibilis = window.location.pathname == "/accessibilis"
    const [isOffers, setIsOffers] = useState(false);
    useEffect(() => {
        if (window.location.pathname == "/") {
            window.onscroll = (e) => {
                const offers = document.querySelector("#vous-voulez-adapter-vos-contenus")
                if (!offers) return;
                const offersTop = offers.getBoundingClientRect().top;

                if (offersTop < 110 && offersTop > -offers.clientHeight) {
                    setIsOffers(true)
                } else {
                    setIsOffers(false)
                }


            }
        }
    })

    return (
        <StyledHeader>
            <a className="sr-only" href="#main">Accéder au contenu</a>
            <a className="sr-only" href="#footer-content">Accéder au pied de page</a>
            <StyledHeaderLogo className="flex align-items-center">
                <HashLink to="/#" className="flex">
                    <img className="logo" src="/images/logo.svg" alt="Logo U31, accueil" />
                </HashLink>
            </StyledHeaderLogo>

            <nav id="menu" className={menuOpen ? 'open' : ''}>
                <div id="cover" onClick={() => setMenuOpen(false)}></div>
                <ul role="menu">
                    <li role="menuitem" id="header_go_home"><a href="/#">Accueil</a></li>
                    {!IS_CNAV && <li className={isAnnexe ? "active" : ""} role="menuitem"><HashLink to="/pourquoi-adapter-vos-contenus#">Pourquoi adapter vos contenus ?</HashLink></li>}
                    {!IS_CNAV && <li className={isOurTool ? "active" : ""} role="menuitem"><HashLink onClick={() => setMessage("Défilement vers la section Notre solution")} to="/notre-technologie#">Notre technologie</HashLink></li>}
                    {!IS_CNAV && <li className={isOffers ? "active" : ""} role="menuitem"><HashLink onClick={() => setMessage("Défilement vers la section Nos offres et services")} to="/#vous-voulez-adapter-vos-contenus">Nos offres et services</HashLink></li>}
                    {!IS_CNAV && <li role="menuitem"><a target="_blank" href="https://blog.u31.io">Blog</a></li>}
                    {/* <li className={isAccessibilis ? "active" : ""} role="menuitem"><a href="/accessibilis">Notre extension Accessibilis</a></li> */}
                </ul>
            </nav>
            <img id="toggleMenu" onClick={() => setMenuOpen(!menuOpen)} src={`/images/landing/${menuOpen ? 'close' : 'open'}_menu.svg`} alt="" />

            {hideEditorButton == undefined && <StyledAccessEditor id="header-access-editor" className="flex align-items-center">
                {/* <a href="/" className="flex sign-in"> Connexion </a> */}
                {/* <a href="/connexion">Home</a> */}

                <a href={isLoggedIn ? "/mon-compte" : "/connexion"} style={{ font: "normal normal 500 16px/20px Ubuntu" }} className="flex u31-btn light btn-hover-shadow sign-up"> Accédez à l'outil U31 </a>
            </StyledAccessEditor>}
        </StyledHeader>

    )
}

export default Header;