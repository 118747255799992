import { U31Recommandation } from '@u31-dev/u31-ui/dist/types';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state'
import { Dispatch } from 'react';
import { setAccessibilityMessage, setOpenCardId, setOpenCardIdForCategory, setRightPanelActivePage } from '../../../../../actions/settings-actions';
import { EditorView } from 'prosemirror-view';
import { RightPanelPages } from '../../../rightPanel/RightPanel';
import { srSpeak } from '../../../../../helpers';
import { u31store } from '../../../../..';
import { getActiveCategories, getIgnoredCardIds } from '../../../../../reducers/settings-reducer';
import { getScores } from '../../../../../reducers/nlp-reducer';


const getRecoAtPos = (view: EditorView, name: string): U31Recommandation | false => {
    const resolvedPos = view.state.selection.$from;
    const activeCategories = getActiveCategories(u31store.getState())
    const ignoredCardIds = getIgnoredCardIds(u31store.getState());
    if (resolvedPos) {

        // const resolvedPos = view.state.selection.$anchor;
        let marks = resolvedPos.marks().filter(mark => mark.type.name == name);
        if (marks.length <= 0) {
            marks = resolvedPos.marksAcross(resolvedPos);
        }
        if (!marks) return false;

        const recosAtPosition = [...new Set(marks.filter(mark => mark.type.name == "u31HighlightReco").map(mark => mark.attrs.u31recommandations).flat())]
        const recosOfRightCategory = recosAtPosition
            .filter(reco => activeCategories.includes(reco.category))
            .filter(reco => !ignoredCardIds.includes(reco.id))

        const recoToClick = recosOfRightCategory.pop();
        return recoToClick as U31Recommandation;
    }
}
const U31TiptapAccessibility = (dispatch: Dispatch<any>) => Extension.create({
    name: "u31TiptapAccessibility",
    addProseMirrorPlugins() {
        const name = this.name;
        return [
            new Plugin({
                key: new PluginKey('eventHandler'),
                props: {
                    handleKeyDown: (view, event) => {
                        console.log("KEYDOWN", event.key);
                        if (event.key == "F7") {
                            console.log("OPEN PANE");
                            dispatch(setAccessibilityMessage("Ouverture du panneau de recommandations"))
                            // dispatch(setOpenCardId(null));
                            dispatch(setRightPanelActivePage(RightPanelPages.RECOMMANDATIONS));
                            setTimeout(() => {
                                document.querySelectorAll("#recommandations .card button")[0].focus()
                            }, 1000)
                            return true;
                        }
                        else if (event.altKey && event.key == "Enter") {
                            const recoToClick = getRecoAtPos(view, this.name);

                            console.log("recoToClick", recoToClick)
                            if (recoToClick) {
                                dispatch(setOpenCardIdForCategory({ category: recoToClick.category, id: recoToClick.id }));
                                setTimeout(() => {
                                    const recoInRibbon = document.querySelector(`[data-id="${recoToClick.id}"] > button`)
                                    console.log("recoInRibbon", recoInRibbon)
                                    if (recoInRibbon) {
                                        recoInRibbon.focus();
                                    }
                                }, 500)
                            }
                            return true;
                        }
                        else if (event.key.includes("Arrow")) {
                            const recoAtPos = getRecoAtPos(view, this.name);
                            if (recoAtPos) {
                                console.log("recoAtPos", recoAtPos)
                                dispatch(setAccessibilityMessage(`${recoAtPos.context} : Suggestion ${recoAtPos.name}, Alt Entrer pour ouvrir.`));
                            }
                        }
                        else if (event.altKey && event.key == "s") {
                            const score = getScores(u31store.getState()).lc;
                            dispatch(setAccessibilityMessage(`Score de clarté : ${score} pour cent.`))
                        }
                        return false;
                    }
                    // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
                },

            }),
        ]
    },
})

export default U31TiptapAccessibility;