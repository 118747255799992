import { DocumentPreview, UserType } from '@u31-dev/u31-ui';
import {
  SET_DOCUMENTS,
  SET_USER,
  SET_USER_LOGGED_IN,
} from '../actions/user-actions';

export default function userReducer(state: any = '', data: any) {
  const { type, payload } = data;
  switch (type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: payload
      }
    case SET_USER:
      return {
        ...state,
        user: payload
      }

    case SET_USER_LOGGED_IN:
      return {
        ...state,
        user: {
          ...state.user,
          loggedIn: payload
        }
      }

    default:
      return state;
  }
}


// selectors
export function getMyDocuments(state: any) {
  return state.userReducer.documents as DocumentPreview[];
}

export function getUser(state: any) {
  return state.userReducer.user as UserType | null;
}

export function getIsUserLoggedIn(state: any) {
  return state.userReducer.user.loggedIn as boolean;
}