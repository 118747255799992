import React, { forwardRef, useState, useImperativeHandle, useLayoutEffect, useRef, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { U31Heading, U31Paragraph, U31Popup, U31Counter, U31Button, U31TextInput, U31TextArea } from "@u31-dev/u31-ui"
import { askCredits } from '../../helpers';
import { getMetrics } from '../../reducers/nlp-reducer';
import { getRevisionState } from '../../reducers/settings-reducer';
import { getUser } from '../../reducers/user-reducer';
import { RevisionState } from '../../types';
import { PopupRightPart } from '../shared/popup';
import { getIsImportExportPopupOpen } from '../../reducers/settings-reducer';
import { setImportExportPopupOpen, setImportExportPopupType } from '../../actions/settings-actions';

export interface DemoDocChoicePopupProps {
    onClose?: Function,
    open?: boolean
}


export const Divider = styled.span`
display: block;
width: 100%;
height: 1px;
background: #324764;
margin-top: 2em;
margin-bottom: 2em;
`;


const Title = styled.h2`
font: normal normal 500 18px/16px Ubuntu;
letter-spacing: 0px;
color: #1B2026;
`;

const SubTitle = styled.h3`
font: normal normal 500 16px/16px Ubuntu;
margin-bottom: 2em;
letter-spacing: 0px;
color: #1B2026;
/* margin-bottom: 2em; */
`;


export const DemoDocChoicePopupContext = React.createContext();
export const useDemoDocChoicePopup = () => useContext(DemoDocChoicePopupContext);

export const DemoDocChoicePopupProvider = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);

    const memoChild = useMemo(() => children, [])
    return (
        <DemoDocChoicePopupContext.Provider value={{ setOpen }}>
            <DemoDocChoicePopup open={open}/>
            {memoChild}
        </DemoDocChoicePopupContext.Provider>
    );
};

const WriteNewDocument = styled(U31Button).attrs({ variant: 'normal' })`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    align-items: center;
    text-decoration: none;
    padding: 0 0.8em;
    &:before {
        content: "";
        height: 2.5em;
        width: 2.5em;
        margin: 0.5em;
        display: flex;
        flex: 1;
        background: url("/images/landing/CREER DOC.svg");
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 1em;
    }

`
const ImportDocumentButton = styled(U31Button).attrs({})`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    align-items: center;
    text-decoration: none;
    padding: 0 0.8em;
    &:before {
        content: "";
        height: 2.5em;
        width: 2.5em;
        margin: 0.5em;
        display: flex;
        flex: 1;
        background: url("/images/landing/upload_document.svg");
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 1em;
    }

`

const PopupContent = styled(PopupRightPart)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`;

export const DemoDocChoicePopup = (props: DemoDocChoicePopupProps) => {

    const popup = useDemoDocChoicePopup();


    const setOpen = (open: boolean) => { popup?.setOpen(open) }
    const open = props?.open || false;
    const theme = useTheme();
    const dispatch = useDispatch();

    const openImportPopup = () => {
        dispatch(setImportExportPopupOpen(true));
        dispatch(setImportExportPopupType("import"));
    }



    return (
        open ?
            <U31Popup
                onClose={() => { setOpen(false) }}
            >
                <PopupContent>
                    <U31Heading level={5} style={{fontWeight: 500}}>Nouveau document</U31Heading>
                    <WriteNewDocument onClick={() => setOpen(false)} >Rédiger un nouveau document</WriteNewDocument>
                    <U31Paragraph fontSize="16px" fontWeight="500" fontFamily="Ubuntu" color={theme?.color?.primary}>ou</U31Paragraph>
                    <ImportDocumentButton onClick={() => { setOpen(false); openImportPopup() }}>Importer un document Word</ImportDocumentButton>

                </PopupContent>
            </U31Popup> : <></>

    )
};
