import { Revision } from '@u31-dev/u31-ui';
import axios from 'axios';
import { useDispatch } from 'react-redux';
export const SET_RECOMMANDATIONS = 'nlp:SET_RECOMMANDATIONS';
export const SET_DOCUMENT_TITLE = 'nlp:SET_DOCUMENT_TITLE';
export const SET_CHAR_COUNT = 'nlp:SET_CHAR_COUNT';
export const SET_SCORES = 'nlp:SET_SCORES';

export function setRecommandations(data: Revision) {
  return {
    type: SET_RECOMMANDATIONS,
    payload: data
  };
}

export function setDocumentTitle(data: string) {
  // console.log("SET DOC TITLE", data)
  return {
    type: SET_DOCUMENT_TITLE,
    payload: data
  };
}

export function setCharCount(data: number) {
  // console.log("SET WORD COUNT", data)
  return {
    type: SET_CHAR_COUNT,
    payload: data
  };
}

export function setScores(data: { falc?: number, lc: number }) {
  // console.log("SET WORD COUNT", data)
  return {
    type: SET_SCORES,
    payload: data
  };
}

// export function fetchRecommandations(slateDocument) {
//   const dispatch = useDispatch();
//   console.log("FETCH RECOMMANDATIONS 1")


// }
