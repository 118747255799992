import styled from "styled-components"

export const PopupLeftPart = styled.div`
display: flex;
flex-flow: column;
background: #F7F8FE;
min-width: 20%;
padding: 2.5em 2em 2em 2em;
box-sizing: border-box;
align-items: center;
display: flex;
flex-flow: column;
`
export const PopupRightPart = styled.div`
display: flex;
flex-flow: column;
background: #FFF;
flex: 1;
padding: 2.5em 3em 3em 3em;
box-sizing: border-box;
overflow: auto;
`
