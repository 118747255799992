import { Extension } from '@tiptap/core'
import '@tiptap/extension-text-style'
import { Plugin, PluginKey } from 'prosemirror-state'
import {normalizeHtml, normalizeSlack} from '../../../../helpers/normalizeHtml'


type NormalizeTextOnPastOptions = {
  types: string[],
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    normalizeText: {
    }
  }
}

export const NormalizeTextOnPast = Extension.create<NormalizeTextOnPastOptions>({
  name: 'normalizeTextOnPast',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('transformPastedHTML'),
        props: {

          transformPastedHTML: (html) => {
            console.log("HTML", html);
            
            html = normalizeSlack(html);
            html = normalizeHtml(html)
            return html;
          }

        },

      })
    ]

  },

})