import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { U31Button, U31Paragraph } from "@u31-dev/u31-ui";
import { formatDate, payRecommendations } from "../../helpers";
import { getRevisionState } from "../../reducers/settings-reducer";
import { getUser } from "../../reducers/user-reducer";
import { RevisionState } from "../../types";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddCreditPopup } from "../modals/AddCreditPopup";
import { LoginGoogle } from "../account/LoginGoogle";

const StyledToastContainer = styled(ToastContainer) <{ fixed: boolean }>`
    width: auto !important;
    z-index: 9998 !important;
    left: 50%;
    position: ${props => props?.fixed == true ? "fixed" : "absolute"} !important;
    transform: translateX(-50%) !important;

    .Toastify__toast-container {width: fit-content; padding: 0; left: 0 !important; }
    .Toastify__toast {width: auto; padding: 0;}
    .Toastify__toast-body { padding: 0;}
    .Toastify__progress-bar {}
  `;



const NotificationWrapper = styled.div`
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 5px;
display: flex;
flex-flow: row;
align-items: center;
width: fit-content;
transition: 0.4s;
width: 100%;
padding: 0;
padding-left: 1rem;
box-sizing: border-box;
justify-content: space-between;
white-space: nowrap;
& .actions {
    margin-left: 1em;
    display: flex;
    white-space: nowrap;
}
& .actions button {
    margin: 0;
    margin-left: 0.5em;
}
`

const NotEnoughCreditsWrapper = styled.div`
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
    position: absolute;
    background: #ffffff9c;
    display: flex;
    z-index: 12;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    gap: 2rem;

    .close {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 2rem;
        right: 0rem;
        cursor: pointer;
    }
`
// export const NewRecommandations = (props) => {
//     console.log("PROPS", props)
//     const { closeToast } = props;
//     const theme = useTheme();
//     const dispatch = useDispatch();
//     const user = useSelector(getUser);
//     const lastRevisionState: RevisionState = useSelector(getRevisionState);

//     return (
//         <NotificationWrapper >
//             <U31Paragraph >
//                 <b style={{ color: theme?.color?.primary }}>Nouvelles recommandations U31</b> <br />
//                 Utilisez <b style={{ color: theme?.color?.primary }}>{Math.max(0, lastRevisionState?.prices?.recommendations_price)}</b> <b style={{ fontWeight: 500 }}>crédits</b> pour afficher
//             </U31Paragraph>

//             <div className="actions">
//                 <U31Button
//                     onClick={async () => {
//                         // notifications?.current.setOpen("new_reco", false);
//                         await payRecommendations(lastRevisionState, dispatch);
//                         closeToast();

//                     }}
//                 >Confirmer</U31Button>
//             </div>
//         </NotificationWrapper >
//     )
// }

export const NotEnoughCredits = (props: { onClose: Function }) => {
    const navigate = useNavigate();
    return (
        <NotEnoughCreditsWrapper >
            <div className="close" onClick={props.onClose} ><img src="/images/business/close.svg" alt="" /></div>
            <U31Paragraph fontFamily="Ubuntu" style={{ width: "75%",lineHeight: '1.3rem', fontSize: '1.3rem' }}>
                <b style={{ color: "#D74F55" }}>Vous avez dépassé le nombre de mots pour l’essai gratuit d’U31, obtenez la licence U31</b>
            </U31Paragraph>
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', fontSize: '1.1rem', lineHeight: '1.3rem' }}>
                Passez à la version premium pour utiliser U31 sans limite.
            </U31Paragraph>
            <U31Button
                as="a"
                href="/mon-compte/offres-premium"
                target="_blank"
                style={{ padding: "0.5rem", textDecoration: "none", margin: 0 }}
            >Passer à la Licence U31</U31Button>
        </NotEnoughCreditsWrapper>
    )
}

export const CreateAccount = () => {
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const addCreditPopup = useAddCreditPopup();
    const theme = useTheme();

    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} >
                <b style={{ color: theme?.color?.primary }}>Créez un compte pour accéder au diagnostic complet</b> <br />
                Nous vous offrons <b style={{ color: theme?.color?.primary }}>300</b> mots pour découvrir U31
            </U31Paragraph>
            <div className="actions">
                <U31Button
                    as="a"
                    href="/creer-un-compte"
                    style={{ textDecoration: "none" }}
                >Créer mon compte</U31Button>
            </div>
        </NotificationWrapper>
    )
}

export const LicenseExpiresSoon = () => {
    const user = useSelector(getUser);

    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#E2762D" }}>Votre licence expire bientôt…</span> <br />Renouvelez avant le <span style={{ fontWeight: 500, color: "#E2762D" }}>{formatDate(new Date(user.license_expiration_date))}</span>.
            </U31Paragraph>
            <div className="actions">
                <U31Button>Renouveler</U31Button>
                {/* <U31Button textColor="#677688" variant="text">Plus tard</U31Button> */}
            </div>
        </NotificationWrapper>
    )
}

export const LicenseExpired = () => {
    const user = useSelector(getUser);

    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#D74F55" }}>Votre licence a expirée...</span>
            </U31Paragraph>
            <div className="actions">
                <U31Button >Renouveler</U31Button>
                {/* <U31Button textColor="#677688" variant="text">Plus tard</U31Button> */}
            </div>
        </NotificationWrapper>
    )
}

export const LoginFailed = (props) => {
    const { closeToast, callback } = props;
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#D74F55" }}>Email ou mot de passe invalide.</span>
            </U31Paragraph>
            <div className="actions">
                <U31Button onClick={() => { callback(); closeToast() }}>Mot de passe oublié</U31Button>
            </div>

        </NotificationWrapper>
    )
}

export const AccountNotValidated = (props) => {
    const { closeToast, callback } = props;
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#446EE0" }}>Vérifiez vos mail pour activer votre compte.</span>
            </U31Paragraph>
            {/* <div className="actions">
                <U31Button onClick={() => { callback(); closeToast() }}>Mot de passe oublié</U31Button>
            </div> */}

        </NotificationWrapper>
    )
}

export const WrongAccount = (props) => {
    const { closeToast, callback, origin } = props;
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#446EE0" }}>Cet email est associé à un compte {origin}</span>
            </U31Paragraph>
            <div className="actions">
                {origin == "google" && <LoginGoogle />}
                {origin == "microsoft" && <LoginGoogle />}
            </div>

        </NotificationWrapper>
    )
}

export const AccountExists = (props) => {
    const { closeToast, callback, message } = props;
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#D74F55" }}>{message}</span>
            </U31Paragraph>
            <div className="actions">
                <U31Button onClick={() => { callback(); closeToast() }}>Me connecter</U31Button>
            </div>

        </NotificationWrapper>
    )
}

export const ResetPasswordLinkNotValid = () => {
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#D74F55" }}>Le lien de réinitialisation du mot de passe n'est pas valide</span>
            </U31Paragraph>

        </NotificationWrapper>
    )
}

export const ResetPasswordSent = () => {
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#446EE0" }}>Un mail vient de vous être envoyé</span> <br />Cliquez sur le lien reçu par mail pour choisir <br />un nouveau mot de passe
            </U31Paragraph>
            <div className="actions">
                <U31Button onClick={() => window.location.reload()}>Connexion</U31Button>
            </div>

        </NotificationWrapper>
    )
}

export const UserNotFoundEmail = () => {
    const navigate = useNavigate();
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#446EE0" }}>Aucun compte n'est lié à cet email</span>
            </U31Paragraph>
            <div className="actions">
                <U31Button onClick={() => navigate('/')}>Accueil</U31Button>
            </div>

        </NotificationWrapper>
    )
}

export const ResetPasswordSuccess = () => {
    const navigate = useNavigate();
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#446EE0" }}>Votre mot de passe a été mis à jour</span> <br />
            </U31Paragraph>
            <div className="actions">
                <U31Button onClick={() => navigate('/connexion')}>Me connecter</U31Button>
            </div>

        </NotificationWrapper>
    )
}

export const ResetPasswordPasswordDontMatch = () => {
    return (
        <NotificationWrapper >
            <U31Paragraph fontFamily="Ubuntu" style={{ whiteSpace: 'nowrap', lineHeight: '1.4rem' }} color="#3A3C40">
                <span style={{ fontWeight: 500, color: "#446EE0" }}>Le mot de passe de confirmation et le mot de passe sont différents.</span> <br />
            </U31Paragraph>
        </NotificationWrapper>
    )
}

export const Notifications = React.memo((props: { fixed?: boolean }) => {
    const user = useSelector(getUser);
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const isDemo = location.pathname == "/demo"

    // useEffect(() => {
    //     if (isDemo) return;
    //     if (lastRevisionState?.not_enough_credits == true) {
    //         toast(<NotEnoughCredits />, { toastId: "not_enough_credits" });
    //     }
    //     // if (lastRevisionState && !lastRevisionState?.payed && lastRevisionState?.prices?.recommendations_price > 0) {
    //     //     toast(<NewRecommandations />, { toastId: "new_recommandations" });
    //     // }

    // }, [user, lastRevisionState])


    const [licenseExpiredShown, setLicenseExpiredShown] = useState(false);
    useEffect(() => {
        if (isDemo) return;
        // if licence expires in less than 14 days
        if (user?.offer_type?.key == "annual_license") {
            const daysBeforeExpiration = (new Date(user.license_expiration_date).getTime() - new Date().getTime()) / 86400000;
            if (daysBeforeExpiration < 0) {
                setLicenseExpiredShown(true);
                if (!licenseExpiredShown)
                    toast(<LicenseExpired />, { toastId: "license_expires_soon" });
            }
            else if (daysBeforeExpiration < 14) {
                setLicenseExpiredShown(true);
                if (!licenseExpiredShown)
                    toast(<LicenseExpiresSoon />, { toastId: "license_expires_soon" });
            }
        }

    }, [user])

    return (
        <StyledToastContainer
            fixed={props?.fixed != undefined || false}
            position="top-center"
            closeOnClick={false}
            draggable={false}

            // newestOnTop={true}
            autoClose={false}
            hideProgressBar={true}
        />
    )

})
