import axios from 'axios';
import { useDispatch } from 'react-redux';
export const SET_DOCUMENTS = 'nlp:SET_DOCUMENTS';
export const SET_USER = 'nlp:SET_USER';
export const SET_USER_LOGGED_IN = 'nlp:SET_USER_LOGGED_IN';

export function setDocuments(data) {
  return {
    type: SET_DOCUMENTS,
    payload: data
  };
}

export function setUser(data) {
  return {
    type: SET_USER,
    payload: data
  };
}

export function setUserLoggedIn(data) {
  return {
    type: SET_USER_LOGGED_IN,
    payload: data
  };
}

