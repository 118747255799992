import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setaddRecommandationState } from "../../../../../actions/settings-actions";
import { sendUserSuggestion } from "../../../../../helpers";
// import { StyledCard } from "./cards.styled";
import styled from "styled-components";
import { U31Button } from "@u31-dev/u31-ui";
import { getActiveCategories, getCategories } from '../../../../../reducers/settings-reducer';
import { Autocomplete, Chip, TextField, TextareaAutosize } from '@mui/material';

export const AddRecommandationSelect = ({ onChange }) => {


    const categories = useSelector(getCategories);
    const activeCategories = useSelector(getActiveCategories);
    const options = Object.keys(categories).map(key => ({ value: key, name: categories[key].name }))

    const [selected, setSelected] = useState("");

    useEffect(() => {
        console.log("ACTIVE CATEGORIES", activeCategories);
        setSelected(activeCategories[0])
        onChange(activeCategories[0])
    }, [activeCategories])


    const handleChange = (event: SelectChangeEvent) => {
        console.log("CATEGORIESSS handleChange", event.target);
        setSelected(event.target.value as string);
        onChange(event.target.value)
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>

            <Select
                labelId="demo-simple-select-label"
                id="select-category"
                label="Catégorie"
                value={selected}
                onChange={handleChange}
                style={{ width: "100%" }}
            >
                {
                    options.map(cat =>
                        <MenuItem value={cat.value}>{cat.name}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

// const StyledAddRecommandationCard = styled(StyledCard)`
const StyledAddRecommandationCard = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 1rem;
    position: relative;
    box-shadow: 0px 0px 15px #27376217;
    padding: 1rem;

    position: sticky;
    top: 0;
    z-index: 9;
    background: white;
    border-radius: 5px;

    .cancel {
        height: 1.7em;
        position: absolute;
        object-fit: contain;
    }

    &.active {
        height: fit-content;
    }


    .header {
        margin-top: 3rem;

    }

    .content {
        flex: 1;
        gap: 1rem;
        display: flex;
        flex-flow: column;
        .message {
            border-left: 2px solid ${props => props.theme.color.primary};
            margin: 0;
            margin-top: 2rem;
            padding-left: 1rem;
            position: relative;
            line-height: 1.5rem;
        }
    }
    
    .actionButton {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.4rem 1rem;
        margin: 0;
        margin-top: 1rem;
        p {
            margin: 0;
        }
    }
`

const StyledAutoComplete = styled(Autocomplete)`
.MuiInputBase-root {
    background: white;
}

.MuiChip-root {
    background: white;
}
`
const useChips = (props: { label: string, placeholder: string }) => {

    const [chips, setChips] = useState<string[]>([])
    const component = <StyledAutoComplete
        multiple
        id="propositions"
        options={[]}
        freeSolo
        renderTags={(value, getTagProps) => {
            setChips(value as string[]);

            return value.map((option, index) => (
                <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                />
            ))
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="filled"
                label={props.label}
                placeholder={props.placeholder}
            />
        )}
    />

    return [component, chips]

}

const useText = (props: { label: string, placeholder: string, multiline: boolean }) => {
    const [text, setText] = useState("");

    const component = <TextField
        style={{ width: "100%" }}
        variant="filled"
        label={props.label}
        onChange={(e) => setText(e.target.value)}
        value={text}
        multiline={props.multiline}
        placeholder={props.placeholder}
    />
    return [component, text]
}


const useSelection = (element: RefObject<HTMLParagraphElement>) => {
    const [selection, setSelection] = useState<null | { start: number, end: number, text: string }>(null);
    useEffect(() => {
        const targetDiv = element.current;
        if (!targetDiv) return;
        function updateSelection() {
            console.log("secondSelection document.getSelection()", document.getSelection());
            const selection = document.getSelection();
            if (selection) {
                setSelection({ text: selection.toString(), start: selection.anchorOffset, end: selection.focusOffset });
                selection.empty()
            }
        }
        if (!targetDiv) return;
        targetDiv.addEventListener("mouseup", updateSelection)

        return () => {
            targetDiv.removeEventListener("mouseup", updateSelection)
        }
    }, [element.current])

    return selection
}
export const AddRecommandationCard = ({ selection, setErrorSelection, saveCallback }) => {
    const categories = useSelector(getCategories);
    const [open, setOpen] = useState(true);
    const [step, setStep] = useState(1);
    const contentRef = useRef(null);
    const cardRef = useRef(null);
    const dispatch = useDispatch();
    const [category, setCategory] = useState("");

    const [synonymesComponent, synonymes] = useChips({ label: "Synonymes", placeholder: "ajouter un synonyme" });
    const secondSelectionRef = useRef<HTMLParagraphElement>(null);
    const secondSelection = useSelection(secondSelectionRef)
    const [definitionComponent, definition] = useText({ label: "Définition", placeholder: "Entrez la définition", multiline: true });
    const [simplerSentenceComponent, simplerSentence] = useText({ label: "Phrase simple", placeholder: "Entrez phrase simplifiée", multiline: true });

    useEffect(() => {
        console.log("secondSelection", secondSelection);
    }, [secondSelection])

    useEffect(() => {
        startSelection();
    }, [])

    const startSelection = () => {
        setStep(1);
        const targetDiv = document.getElementById("u31editor");
        function logSelection() {
            setErrorSelection(document.getSelection().toString());
            if (document.querySelector("#addRecommandationCard .message")) {
                document.querySelector("#addRecommandationCard .message").scrollTop = 10000
            }
        }
        if (!targetDiv) return;
        targetDiv.addEventListener("selectstart", () => {
            document.addEventListener("selectionchange", logSelection);
        });
        targetDiv.addEventListener("mouseup", () => {
            document.removeEventListener("selectionchange", logSelection);
        })
    }

    const validateSelection = async () => {
        try {
            if (!selection) return alert("Selection vide")
            if (!secondSelection) return alert("Selection du contexte vide")
            if (!category) return alert("Categorie vide")

            const success = await sendUserSuggestion({
                category,
                sentence: selection,
                context: [secondSelection.start, secondSelection.end],
                // @ts-ignore
                synonymes: synonymes || undefined,
                // @ts-ignore
                definition: definition || undefined,
                // @ts-ignore
                simpleSentence: simplerSentence || undefined,
            });
            setStep(2);
            setTimeout(closeAddRecommandation, 1500)
            saveCallback();
        } catch (error) {
            console.log("error sending user suggestion")
        }
    }

    const closeAddRecommandation = () => {
        setOpen(false);
        setTimeout(() => { dispatch(setaddRecommandationState(0)) }, 500);
    }


    const onCategoryChange = (newCategory) => {
        console.log("onCategoryChange", categories[newCategory]);

        setCategory(newCategory);
    }

    return (
        <StyledAddRecommandationCard
            ref={cardRef}
            className={["card", open ? "active" : ""].join(' ')}
        >
            <img src="/images/cancel.svg" className="cancel" alt="" onClick={closeAddRecommandation} />

            <div className="header">
                <AddRecommandationSelect onChange={onCategoryChange} />
            </div>
            {category && <div className="content" ref={contentRef}>
                <div className="message" >{
                    !selection ? <p>Surlignez la phrase dans laquelle se trouve l’erreur dans l'éditeur. <br /></p>
                        : <>
                            <p ref={secondSelectionRef}>{selection}</p>
                            <p style={{
                                pointerEvents: "none",
                                position: "absolute",
                                top: "0",
                            }}>{secondSelection?.text ? <><span style={{ visibility: "hidden" }}>{selection.slice(0, secondSelection.start)}</span><span style={{ color: "transparent", borderBottom: `3px solid ${categories[category]?.color || "black"}` }}>{selection.slice(secondSelection.start, secondSelection.end)}</span><span style={{ visibility: "hidden" }}>{selection.slice(secondSelection.end, selection.length)}</span></> : <></>}</p>
                        </>
                }</div>
                {!secondSelection?.text && <div><p>Séléctionnez la zone problématique dans la phrase ci-dessus.</p></div>}
                {category == "word_complexity" && synonymesComponent}
                {category == "word_complexity" && definitionComponent}
                {category == "sent_complexity" && simplerSentenceComponent}
                <div className="actions">
                    {step == 0 ? <U31Button className="actionButton" variant="normal" onClick={startSelection}><p>Débuter la séléction</p> <img src="/images/right-arrow.svg" alt="" /> </U31Button> : null}
                    {step == 1 ? <U31Button className="actionButton" variant="normal" onClick={validateSelection} ><p>Enregistrer</p> <img src="/images/checked.svg" alt="" /> </U31Button> : null}
                    {step == 2 ? <U31Button className="actionButton" variant="normal"><p>Enregistré</p> <img src="/images/like.svg" alt="" /> </U31Button> : null}
                </div>
            </div>}
        </StyledAddRecommandationCard>


    )
}
