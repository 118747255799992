import { Editor } from "@tiptap/react";
import { BaseCard, CardContext, RevisionState, UserType } from "@u31-dev/u31-ui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenCardIdForCategory } from "../../actions/settings-actions";
import { getOpenCardIdForCategory, getRevisionState } from "../../reducers/settings-reducer";
import { getUser } from "../../reducers/user-reducer";
import { ActionIgnoreReco } from "../editor/rightPanel/recommandations/cards/CardActions";
import { scrollDocumentMarkToTop } from "../editor/rightPanel/recommandations/Recommandations";

const catName = 'eco'

export const showUsefulPictures = (editor: Editor) => {
    const htmlContent = editor.getHTML();
    const hasImage = /<img[^>]+src="[^"]+"/.test(htmlContent);
    return hasImage;
}

export const UsefulPictures = (props: { editor: Editor }) => {
    const id = "7f686ae6-1872-5c82-a306-a7745153bb8d";
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const user = useSelector<UserType | null>(getUser);
    const dispatch = useDispatch();
    const openCardId = useSelector(getOpenCardIdForCategory(catName));
    const isOpen = id == openCardId;

    const onCardClicked = () => {
        scrollDocumentMarkToTop(id);
        dispatch(setOpenCardIdForCategory({ category: catName, id: isOpen ? null : id }))
    }

    const cardContext: CardContext = {
        enableGeneration: true, // NOTE: now always enable generation
        document_id: lastRevisionState.document_id,
        revision_id: lastRevisionState.document_backup_id,
        user: user,
    }


    const mainContent = <div>
        <p className="textRecommandation">Si vous utilisez des images, vérifiez qu’elles sont essentielles à votre message. Si c’est le cas, compressez-les ou utilisez des sites de dépôts temporaires ou des dossiers partagés et partagez seulement le lien.</p>
    </div>

    const reco = {
        id,
        name: catName,
        category: catName,
        subCategory: catName,
        subcategory_name: catName,
        context: '',
        languages: ['lc'],
        spans: [],
        type: 'simple'
    }
    return <BaseCard
        isOpen={isOpen}
        onClick={onCardClicked}
        reco={reco}
        title="Utilité du média"
        mainContent={mainContent}
        setErrorSelection={() => { }}
        nextCardId={""}
        cardContext={cardContext}
        color="#6EB47F"
        actions={<ActionIgnoreReco editor={props.editor} nextCardId={""} reco={reco} />}
    />
}

