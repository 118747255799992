import { RevisionState } from '@u31-dev/u31-ui';
import {
  SET_LANGUAGE,
  SET_OPEN_CARD_ID,
  SET_OPEN_CARD_ID_FOR_CATEGORY,
  SET_HOVERED_CARDS_IDS,
  SET_ADD_RECOMMANDATION_OPEN,
  SET_ACTIVE_DOCUMENT_ID,
  SET_IMPORT_EXPORT_POPUP_OPEN,
  SET_IMPORT_EXPORT_POPUP_TYPE,
  SET_ACCESSIBILITY_MESSAGE,
  SET_NLP_LOADING,
  SET_REVISION_STATE,
  SET_CATEGORIES,
  SET_ACTIVE_CATEGORIES,
  SET_HIDDEN_CARD_IDS,
  SET_IGNORED_CARD_IDS,
  SET_RIGHT_PANEL_ACTIVE_PAGE,
  HAS_DOC_CHANGED_AFTER_PROCESS,
  SET_DISCARDED_BEST_PRACTICES_IDS,
  SET_VALIDATED_BEST_PRACTICES_IDS,
} from '../actions/settings-actions';
import { RightPanelPages } from '../components/editor/rightPanel/RightPanel';

export default function settingsReducer(state: any = '', data: any) {
  const { type, payload } = data;
  switch (type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload
      }

    case SET_CATEGORIES:
      return {
        ...state,
        categories: payload
      }

    case SET_OPEN_CARD_ID:
      return {
        ...state,
        openCardId: payload
      }
    case SET_OPEN_CARD_ID_FOR_CATEGORY:
      return {
        ...state,
        openCardId: { ...state.openCardId, [payload.category]: payload.id }
      }

    case SET_HOVERED_CARDS_IDS:
      return {
        ...state,
        hoveredCardsIds: payload
      }

    case SET_ADD_RECOMMANDATION_OPEN:
      return {
        ...state,
        addRecommandationState: payload
      }

    case SET_ACTIVE_DOCUMENT_ID:
      return {
        ...state,
        activeDocumentId: payload
      }
    case SET_IMPORT_EXPORT_POPUP_OPEN:
      return {
        ...state,
        importExportPopup: { ...state.importExportPopup, open: payload }
      }
    case SET_IMPORT_EXPORT_POPUP_TYPE:
      return {
        ...state,
        importExportPopup: { ...state.importExportPopup, type: payload }
      }
    case SET_ACCESSIBILITY_MESSAGE:
      return {
        ...state,
        accessibilityMessage: payload
      }
    case SET_NLP_LOADING:
      return {
        ...state,
        nlpLoading: payload
      }
    case SET_HIDDEN_CARD_IDS:
      return {
        ...state,
        hiddenCardIds: payload
      }
    case SET_IGNORED_CARD_IDS:
      return {
        ...state,
        ignoredCardIds: payload
      }
    case SET_REVISION_STATE:
      return {
        ...state,
        revisionState: payload
      }
    case SET_ACTIVE_CATEGORIES:
      return {
        ...state,
        activeCategories: payload
      }
    case SET_RIGHT_PANEL_ACTIVE_PAGE:
      return {
        ...state,
        rightPanelActivePage: payload
      }
    case HAS_DOC_CHANGED_AFTER_PROCESS:
      return {
        ...state,
        hasDocChangedAfterProcess: payload
      }
    case SET_DISCARDED_BEST_PRACTICES_IDS:
      return {
        ...state,
        discardedBestPracticesIds: payload
      }
    case SET_VALIDATED_BEST_PRACTICES_IDS:
      return {
        ...state,
        validatedBestPracticesIds: payload
      }
    default:
      return state;
  }
}


// selectors
export function getLanguage(state: any) {
  return state.settingsReducer.language as string;
}

export function getActiveCategories(state: any) {
  return state.settingsReducer.activeCategories as string[];
}

export function getCategories(state: any) {
  return state.settingsReducer.categories;
}

export function getOpenCardId(state: any) {
  return state.settingsReducer.openCardId as {
    grammar: string | null,
    word_complexity: string | null,
    sent_complexity: string | null,
    structure: string | null,
  };
}

export const getOpenCardIdForCategory = (category: string) => (state: any) => {
  let openCardId = null;
  try {
    openCardId = state.settingsReducer.openCardId[category] as string | null
  } catch (error) {
  }
  return openCardId;
}

export function getHoveredCardsIds(state: any) {
  return state.settingsReducer.hoveredCardsIds as string[];
}

export function getaddRecommandationState(state: any) {
  return state.settingsReducer.addRecommandationState as 0 | 1 | 2;
}

export function getActiveDocumentId(state: any) {
  return state.settingsReducer.activeDocumentId as string | null;
}

export function getIsImportExportPopupOpen(state: any) {
  return state.settingsReducer.importExportPopup.open as boolean;
}

export function getImportExportPopupType(state: any) {
  return state.settingsReducer.importExportPopup.type as string;
}

export function getAccessibilityMessage(state: any) {
  return state.settingsReducer.accessibilityMessage as string;
}

export function getNlpLoading(state: any) {
  return state.settingsReducer.nlpLoading as boolean;
}

export function getHiddenCardIds(state: any) {
  return state.settingsReducer.hiddenCardIds as string[];
}

export function getIgnoredCardIds(state: any) {
  return state.settingsReducer.ignoredCardIds as string[];
}

export function getRevisionState(state: any) {
  return state.settingsReducer.revisionState as RevisionState;
}

export function getRightPanelActivePage(state: any) {
  return state.settingsReducer.rightPanelActivePage as RightPanelPages;
}

export function getHasDocChangedAfterProcess(state: any) {
  return state.settingsReducer.hasDocChangedAfterProcess as boolean;
}

export function getDiscardedBestPracticesIds(state: any) {
  return state.settingsReducer.discardedBestPracticesIds as string[];
}

export function getValidatedBestPracticesIds(state: any) {
  return state.settingsReducer.validatedBestPracticesIds as string[];
}