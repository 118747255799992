import { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MIN_CHAR_COUNT } from '../../config';
import { getMetrics } from '../../reducers/nlp-reducer';
import { getRevisionState } from '../../reducers/settings-reducer';
import { RevisionState } from '../../types';


const useIsMinimumCharacterCountReached = (initValue = '') => {
    const metrics = useSelector(getMetrics);
    const charCount = metrics.charCount;

    const [isMinimumCharacterCountReached, setIsMinimumCharacterCountReached] = useState(charCount > MIN_CHAR_COUNT);
    
    useEffect(() => {
        setIsMinimumCharacterCountReached(charCount > MIN_CHAR_COUNT)
    }, [metrics])


    return [isMinimumCharacterCountReached, setIsMinimumCharacterCountReached];
};

export default useIsMinimumCharacterCountReached;