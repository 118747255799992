import styled from "styled-components"
import Header from "../shared/Header"
import '../../css/landing/common_landing.scopped.css';
// import '../../css/landing/accessibility.scopped.css';
import Footer from "../shared/Footer";
import { StyledGoBack } from "./shared.styled";

const CGUWrapper = styled.div`
    font-family: "Ubuntu";
    font: normal normal normal 16px/30px Ubuntu;
    letter-spacing: 0px;
    color: black;

    display: flex;
    flex-flow: column;
    padding: 0px 8.51562vw;
    width: 82.9688vw;    
    padding-top: 0;
    padding-bottom: 10rem;

    & #back-home {
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 1.125em;
        letter-spacing: 0.01125em;
        color: var(--blue);


        &:hover {
            & >span {
                text-decoration: underline;
            }
        }

        & >span {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 1.125em;
            letter-spacing: 0.01125em;
            color: var(--blue);
        }

        & >img {
            width: 2em;
            height: 1em;
            margin-right: 0.5em;
        }
    }



    & h1 {
        font: normal normal 500 26px/35px Ubuntu;
        color: black;
    }

    & h2 {
        font: normal normal 500 22px/35px Ubuntu;
        color: #446EE0;
    }
    & h3 {
        font: normal normal 500 18px/35px Ubuntu;
        color: #446EE0;
    }

    & a {
        color: #446EE0;
    }

    p {
        margin: 0.6rem 0;
    }
`
const CGU = () => {
    return (
        <>
            <Header />

            <CGUWrapper>
                <StyledGoBack text="Accueil" href="/" />

                <h1>Conditions générales d'utilisation et de services</h1>
                <h2>1. Désignation du prestataire</h2>
                <p><b>Le présent site est édité par :</b></p>
                <p>Le site <a
                    href="https://u31.io">u31.io</a> est édité par la société U31, société par actions simplifiée au capital de 90 000,00 Euros. L’adresse du siège
                    social est : 4 Chemin du Chatelet 42400 Saint-Chamond, France. Elle est immatriculée au registre du commerce et des
                    sociétés sous le numéro d’identification unique 903 834 190 R.C.S. de Saint-Etienne.</p>
                <p><b>Le présent site est hébergé par :</b></p>
                <p>La société Microsoft. L’adresse du siège social est : 39 quai du Président Roosevelt, 92130, à Issy-les-Moulineaux,
                    France.</p>
                <h2>2. Dispositions générales relatives aux présentes conditions générales d'utilisation et de services (ci-après les
                    CGUS)</h2>
                <h3>2.1 Objet des CGUS</h3>
                <p>Ces Conditions Générales d’Utilisation des Services (CGUS) définissent les responsabilités de chaque partie pour les
                    services proposés. Elles aident à comprendre les droits et les obligations de chacun. Cela permet une utilisation en
                    toute connaissance de cause des services proposés.</p>
                <h3>2.2 Domaine d'application des CGUS</h3>
                <p>Ces CGUS définissent les règles d'utilisation :</p>
                <ul>
                    <li>du site web <a
                        href="https://u31.io">u31.io</a>                     </li>
                    <li>et de tous ses sous-domaines (payants ou gratuits).</li>
                </ul>
                <p>Le site et les sous-domaines sont publiés par la société U31.</p>
                <p>Des contrats d'abonnement peuvent être réalisés sur le site internet <a
                    href="https://u31.io/">u31.io</a>                     entre la société U31 (ci-après le prestataire) et vous (clients).</p>
                <p>Ces CGUS s’appliquent aussi à ces contrats d’abonnement.</p>
                <p>Ces CGUS sont le seul document contractuel valables pour les clients/utilisateurs/acheteurs. Tout autre document :
                </p>
                <ul>
                    <li>prospectus,</li>
                    <li>catalogue</li>
                    <li>ou photo du produit</li>
                </ul>
                <p>n'ont qu'une valeur indicative. </p>
                <p>Les CGUS vous sont applicables si vous habitez en France, y compris :</p>
                <ul>
                    <li>en Corse et sur les îles continentales ;</li>
                    <li>dans les départements d’Outre-Mer (Mayotte incluse) et les collectivités d’Outre-Mer à statut particulier ;</li>
                    <li>ou si vous habitez à l'étranger.</li>
                </ul>
                <p>Les CGUS et tous les contrats sont rédigés en français.</p>
                <h3>2.3 Disponibilité et opposabilité des CGUS</h3>
                <p>Vous pouvez consulter les CGUS sur le site Internet de U31. Nous pouvons aussi vous les envoyer par mail ou par
                    courrier. </p>
                <p>Si vous acceptez les CGUS, elles vous sont opposables. </p>
                <p>En créant un compte sur <a
                    href="https://u31.io/">u31.io</a> ,
                    en achetant un abonnement, en validant une commande, en souscrivant à une offre par un autre moyen, vous acceptez
                    les CGUS à partir du jour de la création du compte, de l’achat de l’abonnement, de la validation de la commande, de
                    la souscription à une offre par un autre moyen. </p>
                <p>La conservation et la reproduction des CGUS sont assurées par la société U31 conformément à l'article 1127-2 du Code
                    civil (ancien C. civ., art. 1369-4).</p>
                <h3>2.4 Modification des CGUS</h3>
                <p>Nous pouvons modifier nos CGUS à tout moment.</p>
                <p>En cas de modification des CGUS, les CGUS applicables sont celles en vigueur à la date de la commande. Nous pouvons
                    vous envoyer une copie de ces CGUS si vous faites la demande.</p>
                <h3>2.5 Clauses des CGUS</h3>
                <p>Si une clause contractuelle est nulle, cela n'entraîne pas la nullité de toutes les CGUS.</p>
                <p>Si nous ne respectons pas temporairement ou définitivement une clause des CGUS, cela ne signifie pas que nous
                    abandonnons les autres clauses. Ces dernières restent en vigueur et continuent à produire leurs effets.</p>
                <h3>2.6 Définitions</h3>
                <p>Les termes du contrat sont définis dans cet article :</p>
                <p><b>Acheteur(s) / Client(s)</b> : désigne les personnes physiques ou morales - particuliers ou professionnels - qui utilisent
                    nos services payants sur le site internet <a
                        href="https://u31.io/">u31.io</a>                     ;</p>
                <p><b>Utilisateurs / Vous</b> : désigne les Utilisateurs des services quelle que soit la version utilisée, gratuite ou payante
                    ;</p>
                <p><b>Nous</b> : désigne U31 ; </p>
                <p><b>Compte</b> : désigne un compte valide qui donne accès aux services de la société U31 sur le site internet <a
                    href="https://u31.io/">u31.io</a> ,
                    ainsi que sur les sous-domaines accessibles depuis ce même site ; </p>
                <p><b>Contenu</b> : désigne le contenu et les services auxquels les Utilisateurs ont accès via le site internet <a
                    href="https://u31.io/">u31.io</a> ,
                    ainsi que sur les sous-domaines accessibles depuis ce même site ;</p>
                <p><b>Donnée à Caractère Personnel</b> : désigne toute information qui permet de reconnaître une personne ; </p>
                <p><b>Parties</b> : désignation de U31 et l'Utilisateur/Vous : </p>
                <p><b>Prix</b> : les tarifs de U31 qui vous sont applicables ; </p>
                <p><b>Site</b> : le site Internet U31 et les sous-domaines accessibles à l’adresse <a
                    href="https://u31.io/">u31.io</a>                     ; </p>
                <p><b>Souscription</b> : l’engagement contractuel payant qui lie les Acheteurs/Clients à U31 ; </p>
                <p><b>Acheteurs-consommateurs</b> : les Acheteurs/Clients reconnus consommateurs au sens des dispositions du Code de la
                    consommation ; </p>
                <p><b>Textes</b>: désigne vos contenus textuels saisis ou importés que vous allez adapter dans l’outil U31.</p>
                <h2>3. Services fournis</h2>
                <h3>3.1 Caractéristiques</h3>
                <p>Notre service fournit un accès à un système pour adapter le texte en toute simplicité et sécurité.</p>
                <p>Le système d’adaptation est accessible sur le site <a
                    href="https://u31.io/">u31.io</a> .
                </p>
                <p>Le fonctionnement de U31 rend nécessaire l’enregistrement de la frappe de l’utilisateur (du texte que vous entrez
                    dans l’outil U31).</p>
                <p>Les conditions d'utilisation des services sont différentes selon la version que vous utilisez. La version que vous
                    utilisez est soit payante, soit gratuite.</p>
                <p>Vous pouvez consulter les conditions d'utilisation des services en ligne. Pour cela, vous pouvez cliquer sur le lien
                    suivant : <a
                        href="https://u31.io/jm">u31.io</a>                 </p>
                <p>Nous pouvons modifier les conditions d'utilisation de la version gratuite. Nous pouvons aussi les supprimer. Nous
                    pouvons le faire à tout moment.</p>
                <p>Par exemple, nous pouvons supprimer sans avertissement tout accès gratuit aux services (suppression de toutes les
                    conditions d'utilisation).</p>
                <p>Si vous utilisez la version gratuite, vous ne pourrez jamais :</p>
                <ul>
                    <li>revendiquer l'existence d'un droit obtenu à utiliser gratuitement les services proposés.</li>
                    <li>invoquer un préjudice de la modification ou de la suppression des conditions d'utilisation même si cette
                        modification ou suppression entraîne une perte d'informations.</li>
                </ul>
                <p>Si vous utilisez la version gratuite, vous êtes informé que nous pouvons supprimer vos contenus. Nous pouvons le
                    faire à tout moment.</p>
                <p>Nous ne sommes pas responsables de l'intégrité de vos contenus. Nous ne sommes pas responsables de possibles
                    corruptions de données rendant votre document impossible à utiliser.</p>
                <p>Nous mettrons en œuvre tous les efforts raisonnables pour maintenir le service en accord avec les conditions que nous
                    présentons ici.</p>
                <p>Nous vous accordons un droit non-exclusif d'accès et d'utilisation de la technologie que nous fournissons. Nous nous
                    engageons à faire preuve de prudence et de vitesse et sérieux pour fournir un service de qualité, en accord avec les
                    usages en la matière.</p>
                <p>Nous nous efforcerons de fournir un accès permanent à notre plateforme, 24 heures sur 24 et chaque jour sauf en cas
                    de force majeure. Cela est défini dans la section appelée « Force majeure » plus bas.</p>
                <h3>3.2 Conditions d’accès aux services</h3>
                <p>Pour accéder et utiliser nos services payants ou gratuits, vous devez être légalement capables de contracter à toute
                    loi applicable (française et/ou étrangère). Vous devez aussi être capables de vous soumettre à toute loi applicable
                    (française et/ou étrangère).</p>
                <p>Vous pouvez créer un compte au nom d'un organisme, telle qu'une entreprise ou votre employeur. Dans ce cas, vous
                    déclarez avoir l’autorité légale nécessaire pour créer un tel compte. Vous déclarez aussi avoir l'autorité légale
                    pour conclure si le cas se présente un contrat pour le compte dudit organisme. Vous n'avez pas le droit de
                    transférer votre nom d’utilisateur et votre mot de passe de compte à un autre utilisateur ou à un autre organisme.
                    Vous devez veiller à ne pas communiquer les informations de votre compte et votre mot de passe pour protéger votre
                    compte.</p>
                <p>Vous devez disposer d'un accès à internet. Vous devez aussi disposer d'un outil informatique adapté.</p>
                <p>L'accès aux services est impossible sans connexion internet.</p>
                <p>Vous êtes seuls responsables de toutes les connexions, forfaits et/ou équipements nécessaires pour utiliser les
                    Services. Vous êtes aussi seuls responsables du paiement des frais facturés par le ou les fournisseurs de vos
                    connexions, forfaits et équipements. Ces frais s’ajoutent à ceux que vous nous payez dans le cadre de l’utilisation
                    des Services. Ces frais ne vous seront pas remboursés. </p>
                <p>Vous devez créer un Compte à partir de <a
                    href="https://u31.io/">u31.io</a> .
                </p>
                <p>Vous devez remplir les informations suivantes :</p>
                <p><b>Mentions obligatoires :‍</b></p>
                <ul>
                    <li>Nom,</li>
                    <li>Prénom,</li>
                    <li>Adresse e-mail.</li>
                </ul>
                <p>L’adresse e-mail doit rester valide tant que le Compte reste actif. Vous êtes responsable de la mise à jour de votre
                    adresse e-mail si nécessaire.</p>
                <p>Vous devez utiliser les services rendus conformément aux CGUS.</p>
                <p>Vous devez avoir un accès à un terminal permettant d’accéder à internet et à un navigateur récent qui accepte les
                    cookies.</p>
                <p>Tous les coûts nécessaires pour votre équipement sont de votre seule responsabilité. C'est aussi le cas pour votre
                    connexion à Internet.</p>
                <p>Vous utilisez sous votre seule responsabilité vos terminaux et navigateurs.</p>
                <p>Pour utiliser et accéder aux services, vous acceptez de :</p>
                <ul>
                    <li>toujours fournir des informations sincères et véridiques et à les mettre à jour si nécessaire afin qu’elles
                        restent complètes et exactes ;</li>
                    <li>utiliser le service conformément à sa destination et selon les termes des CGUS ;</li>
                    <li>respecter les droits de tiers et, plus généralement, toutes les lois et règlements en vigueur ;</li>
                    <li>ne pas nuire à la réputation de U31 ou diffamer U31 ;</li>
                    <li>ne pas s’engager dans toute conduite qui pourrait interrompre, détruire, limiter ou plus généralement nuire à
                        U31 ou permettre à d'autres utilisateurs d'accéder et d’utiliser « U31 » sans autorisation, y compris en
                        utilisant des virus, codes malveillants, des programmes ou des fichiers ;</li>
                    <li>ne pas copier ou vendre tout ou partie des services auxquels vous avez accès ;</li>
                    <li>ne faire aucune utilisation commerciale directe ou indirecte des services fournis.</li>
                </ul>
                <p>En cas de violation de ce qui précède, nous pouvons annuler et/ou bloquer votre compte sans préavis, ni indemnité.
                </p>
                <p>Vous reconnaissez avoir vérifié l'adéquation des services fournis avec vos besoins et avoir reçu toutes les
                    informations et conseils nécessaires pour conclure les Cgus. Vous reconnaissez avoir été pleinement informés par
                    nous, de l'étendue de leurs obligations contractuelles aux termes des CGUS.</p>
                <p>Vous êtes entièrement et seuls responsables de l’utilisation faite des services.</p>
                <p>Dans l’usage que vous faites du Service, vous vous engagez à respecter les droits de tiers, y compris les droits de
                    la personnalité, les droits de propriété intellectuelle ou industrielle tels que les droits d'auteur, droits des
                    brevets, des dessins et marques.</p>
                <h3>3.3 Conformité</h3>
                <p>Les services sont conformes aux prescriptions en vigueur relatives à la sécurité et à la santé des personnes, à la
                    loyauté des transactions commerciales et à la protection des consommateurs au moment de leur mise sur le marché
                    (texte reproduisant C. consom., art. L. 411-1, ancien C. consom., art. L. 212-1).</p>
                <p>Les produits sont conformes aux prescriptions du droit français en vigueur au moment de leur mise sur le marché.</p>
                <h2>4. Prix - Offres Premium</h2>
                <p>Vous pouvez souscrire une licence annuelle renouvelable (ci-après désigné “Premium Annuel”) ou acheter une devise
                    virtuelle (ci-après désignée “Crédits Premium”) à échanger contre des services proposés sur U31 (cf. 4.2 “Devise
                    Virtuelle”).</p>
                <h3>4.1 Domaine</h3>
                <p>Les offres payantes (accès aux services payants) sont présentées sur le Site aux Utilisateurs et Acheteurs et
                    consultables en cliquant sur le lien suivant : <a
                        href="https://u31.io/">u31.io</a> .
                </p>
                <h3>4.2 Devise Virtuelle</h3>
                <p>Dans l’outil U31, vous pouvez acheter des services à l'aide d'une devise virtuelle : les Crédits Premium. Vous pouvez
                    acheter des Crédits Premium avec de la monnaie réelle.</p>
                <p>Vous ne pouvez en aucun cas échanger des Crédits Premium contre de la monnaie réelle ou tout autre objet ayant une
                    valeur monétaire.</p>
                <p>Vous acceptez par le présent Accord que les Crédits Premium sont sous licence, et ne vous sont pas vendus. Nous nous
                    réservons tout droit, titre et intérêt légal vis-à-vis des Crédits Premium.</p>
                <p>Vous ne pouvez échanger ou transférer des Crédits Premium que dans le respect des dispositions légales ayant cours
                    dans votre pays ou région.</p>
                <p>Concernant les Crédits Premium, vous n'êtes pas autorisé à :</p>
                <ul>
                    <li>Les transférer ;</li>
                    <li>Les vendre ;</li>
                    <li>En faire don ;</li>
                    <li>Les échanger ;</li>
                    <li>Les troquer ;</li>
                    <li>Les louer ;</li>
                    <li>Les sous-licencier ;</li>
                    <li>Les sous-louer ;</li>
                    <li>Tout autre usage des Crédits Premium en dehors de U31.</li>
                </ul>
                <p>Toute transgression est considérée comme infraction au présent Accord et entraîne la fermeture automatique du Compte
                    ; U31 se réserve de plus le droit d'exiger une compensation.</p>
                <p>U31 se réserve à sa seule discrétion le droit de modifier, gérer, contrôler ou supprimer les Crédits Premium.</p>
                <p>Vous convenez que nous sommes libres à tout moment d'influer sur la valeur perçue ou le prix d'achat des Crédits
                    Premium, sauf si la loi en dispose autrement.</p>
                <p>En transmettant vos données de paiement à U31 ou tout autre agent agréé par U31, vous reconnaissez être un
                    utilisateur agréé de toute méthode de paiement que vous aurez choisie et vous autorisez U31 à facturer à cette
                    méthode de paiement le montant total de la transaction.</p>
                <p>Vous convenez qu'en raison de la nature des services, aucune transaction de biens physiques n'a lieu, et que
                    l’expérience d'achat découle des services en ligne proposés par la société U31.</p>
                <p>Vous ne pourrez pas être remboursé, sauf en cas d'arrêt de nos services.</p>
                <h3>4.3 Prix de vente</h3>
                <p>Les prix de vente sont indiqués en euros TVA comprise (TTC).</p>
                <p>Le montant total dû par les Acheteurs et Utilisateurs est indiqué sur la page de commande des produits. Le montant de
                    la commande peut aussi être indiqué par un autre moyen. </p>
                <p>Le prix de vente est celui en vigueur le jour de la commande.</p>
                <p>De même, le montant de la TVA est calculé au jour de la commande.</p>
                <p>En cas de promotion par les prix, nous nous engageons à appliquer le prix promotionnel à toute commande passée durant
                    la période de la publicité faite pour la promotion.</p>
                <p>Selon votre localisation, certaines transactions peuvent nécessiter une conversion de devises ou être traitées dans
                    un autre pays. Votre banque peut vous facturer des frais supplémentaires pour ces services. Ces frais restent à
                    votre charge. Rapprochez-vous de votre établissement bancaire pour toute question.</p>
                <p>Sauf mention contraire (notamment les conditions d'utilisation gratuite), les frais d’accès sont payables comptant.
                    Ces frais sont basés sur l’accès aux services et produits et non sur l’usage de celui-ci. Le paiement est non
                    annulable et non remboursable.</p>
                <p>En fonction de votre localisation, les Prix applicables pour les Services peuvent varier et peuvent être affichés
                    dans des devises différentes excluant les taxes étrangères si applicables.</p>
                <h3>4.4 Modification du prix</h3>
                <p>Nous nous réservons le droit de modifier nos prix à tout moment, tout en garantissant aux Acheteurs l'application du
                    prix qui vous est indiqué le jour de la commande.</p>
                <p>Nous nous réservons le droit de mettre à jour la politique de Prix et de créer de nouvelles charges à tout moment, à
                    condition de respecter un préavis d’un mois. Nous nous informerons de tout changement par courriel.</p>
                <p>La nouvelle politique des Prix est immédiatement applicable :</p>
                <ul>
                    <li>Pour les nouveaux Acheteurs et Utilisateurs ;</li>
                    <li>Pour les nouvelles fonctions, que les Acheteurs et Utilisateurs soient nouveaux ou existants.</li>
                </ul>
                <p>Pour les Utilisateurs existants, la nouvelle politique des Prix pour les Services existants sera applicable après
                    renouvellement de l'abonnement.</p>
                <p>Les Prix applicables aux Utilisateurs lors de la création de leur Compte sont garantis jusqu’au renouvellement de
                    l'abonnement.</p>
                <h2>5. Commande - offres Premium</h2>
                <h3>5.1 Étapes détaillées de conclusion du contrat de prestation de service</h3>
                <p>Pour commander :</p>
                <ul>
                    <li>Cliquez sur le bouton permettant de choisir une Offre Premium ; </li>
                    <li>Indiquez vos coordonnées (Nom, Prénom, e-mail) ; </li>
                    <li>Consultez le montant qui vous sera débité ; </li>
                    <li>Vous confirmez le paiement de la commande ce qui entraîne l'acceptation des CGU et forme le contrat.</li>
                </ul>
                <p>Vous pouvez aussi souscrire à une Offre Premium par un contrat de Prestation de services communiqué par un autre
                    moyen (par mail principalement). </p>
                <h3>5.2 Modification de commande</h3>
                <p>Pour modifier votre commande après l’étape de confirmation, nous devons d’abord accepter cette modification. </p>
                <p>S'agissant du contrat conclu avec un Acheteur-consommateur, nous nous réservons le droit d'apporter au service
                    commandé les modifications qui sont liées à l'évolution technique dans les conditions prévues à l'article R. 212-4
                    du code de la consommation (ancien C. consom., art. R. 212-4).</p>
                <h3>5.3 Validation de la commande</h3>
                <p>Nous pouvons refuser toute commande pour des motifs légitimes et plus particulièrement si les quantités de produits
                    commandés sont anormalement élevées.</p>
                <h3>5.4 Droit de rétractation</h3>
                <p>En accord avec l'article L221-18 du Code de la consommation, vous êtes considérés comme "consommateur" dès l'achat de
                    notre service. En tant que consommateur, vous disposez d'un <b>droit de rétractation</b>, c'est-à-dire que vous pouvez
                    mettre fin au contrat sans avoir à justifier de motifs.</p>
                <p>Ce délai de rétractation est de quatorze (14 jours) à compter de la date de souscription de votre contrat.</p>
                <p>Ce droit de rétractation vous permet d'obtenir le remboursement des Services concernés.</p>
                <p>Si vous voulez utiliser ce droit de rétractation, vous pouvez adresser un courrier postal à U31, à cette adresse : 4
                    chemin du Châtelet, 42400, Saint-Chamond. </p>
                <p>Si vous utilisez correctement votre droit de rétractation, alors les Services vous seront remboursés. </p>
                <p>En accord avec l'alinéa 13 de l'article L221-28 du Code de la Consommation, vous renoncez à votre droit de
                    rétractation à partir du moment où vous acceptez la mise en place de nos services et que vous utilisez nos services.
                    Dans ce cas, vous ne pourrez donc pas faire valoir votre droit de rétractation. </p>
                <h3>5.5 Archivage et preuve</h3>
                <p>L'archivage (le fait de stocker les données) des bons de commandes et factures est effectué sur un support sécurisé.
                    Nous gardons une copie des communications, bons de commandes et factures. Si vous avez besoin d'une preuve de
                    contrat, nous pourrons vous les envoyer sur demande.</p>
                <h2>6. Paiement</h2>
                <h3>6.1 Exigibilité</h3>
                <p>Le paiement du service vous est demandé dès la confirmation de votre commande. Le paiement s'effectue immédiatement
                    en carte bancaire. Nous pouvons accepter d'autres moyens de paiement, en nous mettant d'accord ensemble à l'avance.
                </p>
                <p>Si vous désirez souscrire à un abonnement payant, vous devrez nous transmettre les informations suivantes :</p>
                <ul>
                    <li>Le numéro de carte bancaire,</li>
                    <li>La date d’expiration de carte bancaire,</li>
                    <li>Votre CVC, le code de sécurité à 3 chiffres présent au dos de votre carte bancaire </li>
                    <li>Votre Adresse de facturation,</li>
                    <li>Votre numéro de TVA intracommunautaire, si vous êtes un professionnel soumis à la TVA.</li>
                </ul>
                <p>La société U31 fait confiance à la société STRIPE.inc pour s'occuper du paiement en ligne. Toutes les informations
                    bancaires que nous recevons sont transmises directement à la société STRIPE.inc et ne sont pas conservées par U31.
                </p>
                <p>Une fois que vous avez souscrit à un abonnement, c'est à vous de vous assurer que votre moyen de paiement reste
                    valable pendant toute la durée du contrat.</p>
                <h3>6.2 Retard de paiement ou défaut de paiement</h3>
                <p>En cas de retard ou de non-paiement, votre compte sera suspendu. Il vous sera demandé une pénalité (une sanction
                    financière) de cent (100) euros par jour de retard. Vous devrez également régler tous les frais associés au
                    remboursement des paiements manqués, y compris ceux des honoraires raisonnables d'avocat.</p>
                <p>En cas de non-paiement ou de violation des mentions présentes dans ces CGU, nous nous réservons le droit de suspendre
                    votre accès au site. en attendant de recevoir le paiement. En cas de défaut de paiement, nous gardons également la
                    possibilité de mettre fin à votre contrat.</p>
                <p>Nous ne pourrons pas être tenus responsables d'un défaut de paiement de votre part, ni de la résiliation qu'elle
                    entraîne, pour quelque motif que ce soit.</p>
                <p>Tout désaccord avec une facture doit être exprimé dans une lettre ou courriel avec accusé de réception dans les
                    quinze (15) jours qui suivent la date de la facture.</p>
                <p>Si vous ne nous contactez pas durant ce délai, alors vous serez considéré comme avoir accepté la facture.</p>
                <h2>7. Durée, modification et résiliation des CGUS</h2>
                <p>Les CGU sont valides durant toute la durée de votre accès au Service. Cette durée prend en compte les périodes
                    d'essai. Les CGU sont renouvelées automatiquement.</p>
                <p>Si vous choisissez de mettre fin à votre abonnement payant, alors votre accès sera bloqué à la fin de la période
                    d'abonnement. La fin de votre période d'abonnement sera tout de même facturée, malgré la résiliation.</p>
                <p>Vous pouvez choisir de mettre fin au renouvellement du Contrat en cours jusqu'à la veille du renouvellement
                    automatique (24h avant). Pour cela, vous pouvez envoyer une lettre recommandée avec accusé de réception à U31, ou
                    vous pouvez envoyer un mail à l'adresse <a target="_blank" href="mailto:contact@u31.io">contact@u31.io</a></p>
                <p>Si les CGU ne sont pas respectées, alors nous nous réservons le droit de les résilier à tout moment.</p>
                <p>En cas de retard de paiement, nous considérerons que les CGU ont été résiliées à la date à laquelle le paiement vous
                    a été demandé.</p>
                <p>Nous nous réservons le droit de résilier unilatéralement (sans accord de votre part) les CGU: </p>
                <ul>
                    <li>Dans le cas où le contenu envoyé sur le site est contraire au Droit Français</li>
                    <li>Dans le cas où votre utilisation créerait des problèmes pour le service et les serveurs informatiques de U31.</li>
                </ul>
                <p>Si la situation venait à se produire, vous serez informés directement et aurez un délai de quinze (15) jours pour
                    retirer le contenu.</p>
                <h2>8. Responsabilités et garanties de U31</h2>
                <p>Dans les limites prévues par la loi actuelle, U31 vous fournit un accès "tel quel" au service. </p>
                <p>C'est-à-dire que nous n'offrons aucune garantie, explicite ou implicite sur la qualité du service fourni. De plus,
                    nous ne pouvons pas garantir que l'utilisation à distance du service soit sans interruption, ou sans erreurs.</p>
                <p>U31 ne pourra pas être tenu pour responsable des erreurs commises par le système d'adaptation. Par exemple dans le
                    cas où des erreurs sont signalées comme exactes, ou à l'inverse, quand des éléments exacts sont signalés comme des
                    erreurs.</p>
                <p>L'assistance que vous offre U31 est donnée seulement pour utiliser son service. Elle ne concerne pas l'organisation
                    ou la direction des activités qui suivront votre utilisation de nos services.</p>
                <p>Les informations que nous vous fournissons ne constituent pas un conseil, qu'il soit commercial, financier,
                    juridique, ou d'autre nature.</p>
                <ul>
                    <li>Nous ne pouvons pas garantir que votre accès au service ne comporte ni anomalies ni erreurs. </li>
                    <li>Nous ne pouvons pas garantir que le service fonctionnera sans interruption ou dysfonctionnement.</li>
                </ul>
                <p>Dans tous les cas, notre responsabilité est strictement limitée au montant de la cotisation d'un abonnement mensuel
                    ou annuel. Cette clause est essentielle pour U31 et fait partie de l'accord des Parties.</p>
                <p>Dans tous les cas, U31 ne sera pas (directement ou indirectement) responsable de tout dommage causé en raison de la
                    faute des Utilisateurs. Nous nous réservons le droit de mettre fin à votre accès au service sans avertissement ni
                    indemnité.</p>
                <p>Enfin, nous ne pouvons pas garantir que l'utilisation du service sera compatible avec du matériel ou une
                    configuration autre que celle recommandée par U31.</p>
                <p>Nous pouvons mettre à jour le Service à n’importe quel moment de la journée. Donc le Service peut être arrêté pour
                    une courte période. En cas d’absolue nécessité, nous pouvons arrêter (définitivement / provisoirement) le Service et
                    son accès pour effectuer une intervention technique de maintenance ou d'amélioration pour assurer le bon
                    fonctionnement du Service, indépendamment de l'heure et la durée de l'intervention. Vous ne pouvez pas avoir
                    d'indemnités en cas de panne. Nous pouvons modifier le service pour faire des améliorations ou des mises à jour :
                </p>
                <ul>
                    <li>sans vous informer ; </li>
                    <li>à tout moment ; </li>
                    <li>à notre seule discrétion.</li>
                </ul>
                <p>Aucune partie ne sera responsable pour tout défaut ou retard dans l'exécution causée par des raisons indépendantes de
                    sa volonté, telles que :</p>
                <ul>
                    <li>des incendies,</li>
                    <li>inondations,</li>
                    <li>épidémies,</li>
                    <li>pandémie,</li>
                    <li>famines,</li>
                    <li>tremblements de terre,</li>
                    <li>ouragans</li>
                    <li>et autres catastrophes naturelles</li>
                </ul>
                <p>ou encore</p>
                <ul>
                    <li>de la réglementation ou des actes de toute autorité,</li>
                    <li>d'un acte civil ou militaire de toute autorité,</li>
                    <li>d'autoréglementation,</li>
                    <li>de guerres,</li>
                    <li>de terrorisme,</li>
                    <li>d’émeutes,</li>
                    <li>de troubles civils,</li>
                    <li>de sabotage,</li>
                    <li>de vol</li>
                    <li>ou d'autres actes criminels de tiers.</li>
                </ul>
                <h2>9. Information de l'acheteur-consommateur</h2>
                <p>Indépendamment de la garantie commerciale, U31 reste tenu de la garantie légale de conformité mentionnée :</p>
                <ul>
                    <li>dans les articles L. 217-4 et suivants du présent code (ancien Code de la consommation., articles L. 211-4 à L.
                        211-13)</li>
                    <li>la garantie relative aux défauts de la chose vendue, dans les conditions prévues aux articles 1641 à 1648 et
                        2232 du Code civil.</li>
                </ul>
                <h2>10. Réclamations</h2>
                <p>Pour faire des réclamations dans le cadre des garanties, vous devez envoyer un courriel à <a target="_blank" href="mailto:contact@u31.io">contact@u31.io</a>.</p>
                <h2>11. Propriété intellectuelle</h2>
                <p>Le site internet <a
                    href="https://u31.io">u31.io</a> est édité par la société U31.</p>
                <p>Nous sommes les seuls propriétaires de tous les éléments qui se trouvent :</p>
                <ul>
                    <li>sur le site internet <a
                        href="https://u31.io">u31.io</a>
                    </li>
                    <li>sur tous les sous-domaines accessibles depuis <a
                        href="https://u31.io">u31.io</a> .
                    </li>
                </ul>
                <p>Ces éléments sont protégés par :</p>
                <ul>
                    <li>le droit d'auteur,</li>
                    <li>le droit des marques,</li>
                    <li>et le droit des brevets.</li>
                </ul>
                <p>Vous n'avez pas le droit de reproduire ou diffuser ces éléments sans notre autorisation écrite. Si vous reproduisez
                    ou diffusez ces éléments sans notre autorisation écrite, vous risquez des poursuites judiciaires.</p>
                <p>Si vous acceptez ces CGUS, nous pouvons vous mentionner comme référence et utiliser votre logo sur tout document
                    (électronique ou autre). Nous pouvons aussi mentionner de manière anonyme la nature des prestations que nous avons
                    faites pour vous et les résultats obtenus, sauf si vous demandez par écrite ne pas le faire.</p>
                <p>Mais en aucun cas nous ne pouvons mentionner des informations confidentielles.</p>
                <h2>12. Confidentialité</h2>
                <p>Nous nous engageons à ce que notre personnel et nos éventuels sous-traitants respectent les obligations décrites dans
                    les CGUS. Les CGUS restent valables pendant dix (10) ans après la résiliation ou l’expiration du Contrat, quelle que
                    soit la raison de la résiliation ou de l'expiration.</p>
                <h3>12. 1. Informations couvertes par le secret professionnel</h3>
                <p>Cependant, pour les informations couvertes par le secret professionnel, les Parties doivent respecter l'obligation de
                    confidentialité tant que ces informations ne sont pas tombées dans le domaine public.</p>
                <p>Chaque Partie reconnaît par avance que : </p>
                <p>Si elle diffusait des informations couvertes par le secret professionnel concernant l'autre Partie, elle léserait
                    gravement les intérêts de l'autre Partie. Donc, la Partie victime pourra :</p>
                <ul>
                    <li>engage une action en justice contre l’autre Partie, ses coauteurs ou complices</li>
                    <li>réclamer des dommages et intérêts si l'autre Partie n'a pas respecté ses engagements, quelle qu'en soit la
                        raison.</li>
                </ul>
                <p>Vous restez propriétaire et responsable de :</p>
                <ul>
                    <li>toutes les données et informations que vous fournissez</li>
                    <li>tous les documents (quelle que soit leur nature) dont il est propriétaire et qui sont traités, enrichis et
                        améliorés par U31.</li>
                </ul>
                <p><u>Accès à vos Textes</u></p>
                <p>Nous garantissons un accès limité aux Textes. L'accès aux Textes est possible pour :</p>
                <ul>
                    <li>l'exécution du service</li>
                    <li>le débugage du service</li>
                    <li>l'amélioration technique du service.</li>
                </ul>
                <p>Seules les équipes infrastructures pourront y accéder.</p>
                <p><u>Obligations des Parties</u></p>
                <p>Chacune des Parties a des obligations en ce qui concerne :</p>
                <ul>
                    <li>la teneur des dispositions du présent Contrat</li>
                    <li>les informations de l’autre Partie.</li>
                </ul>
                <p>Ces obligations sont de :</p>
                <ul>
                    <li>les garder strictement confidentielles</li>
                    <li>ne pas les communiquer à qui que ce soit</li>
                    <li>ne pas les exploiter (directement ou indirectement)</li>
                    <li>ne pas permettre leur exploitation par des tiers sous leur contrôle.</li>
                </ul>
                <p>Sauf si cela est strictement nécessaire pour la bonne exécution du Contrat.</p>
                <p>Cela concerne les informations communiquées :</p>
                <ul>
                    <li>par écrit,</li>
                    <li>oralement</li>
                    <li>par tout autre moyen.</li>
                </ul>
                <p>Les informations qui entrent dans ces obligations sont :</p>
                <ul>
                    <li>les informations diffusées dans le cadre de la négociation et de l’exécution du Contrat,</li>
                    <li>les informations ont un caractère sensible sur un plan</li>
                </ul>
                <ul>
                    <li>financier, déontologique, économique, technique, commercial.</li>
                </ul>
                <ul>
                    <li>les informations sur les techniques, moyens et procédés utilisés par les Parties</li>
                    <li>les informations déclarées comme confidentielles par l’autre Partie (voir la définition des « Informations
                        Confidentielles » ci-dessous).</li>
                </ul>
                <p><u>Quelles sont les « Informations Confidentielles » ?</u></p>
                <p>Les « Informations Confidentielles » peut concerner des documents de différentes natures :</p>
                <ul>
                    <li>information,</li>
                    <li>donnée,</li>
                    <li>dessin,</li>
                    <li>concept,</li>
                    <li>secret de fabrication</li>
                    <li>et savoir-faire.</li>
                </ul>
                <p>Autre que ceux pour lesquels l’une des Parties pourrait apporter la preuve qu’ils :</p>
                <ul>
                    <li>Sont déjà connus des Parties lors de leur divulgation, sans obligation de confidentialité ; </li>
                    <li>Étaient, au moment de leur transmission, dans le domaine public ou qui sont tombés dans le domaine public par la
                        suite sans que cela soit le fait de l’une des Parties ;</li>
                    <li>Sont légitimement obtenus d’un tiers, sans restriction ni violation de la présente obligation de confidentialité
                        ;</li>
                    <li>Sont développés de façon indépendante par l’une des Parties ;</li>
                    <li>Sont divulgués en vertu d’une disposition législative ou réglementaire ;</li>
                    <li>Sont communiqués par l’une des Parties à des tiers sans obligation de confidentialité.</li>
                </ul>
                <h3>12.2. Données à caractère personnel contenues dans les Textes</h3>
                <p>Dans le cours de l’exécution du Service, nous pouvons être amenés à recevoir une communication de Données à caractère
                    personnel figurant dans les Textes (ci-après les Données). Ces Données peuvent concerner l’Utilisateur ou toute
                    autre personne physique et être de toute nature.</p>
                <p>Vous n’êtes pas autorisé à faire figurer dans les Textes :</p>
                <ul>
                    <li>des Données à caractère personnel de santé, à savoir des données à caractère personnel relatives à la santé
                        physique ou mentale d'une personne physique, y compris la prestation de services de soins de santé, qui révèlent
                        des informations sur l'état de santé de cette personne ;</li>
                    <li>des Données à caractère personnel relatives à l’origine raciale ou ethnique, aux opinions politiques, aux
                        convictions religieuses ou philosophiques, à l’appartenance syndicale, aux données génétiques ou biométriques, à
                        la vie sexuelle ou à l’orientation sexuelle, à la santé, aux infractions ou aux condamnations de toute nature,
                        notamment pénale ;</li>
                    <li>des numéros INSEE.</li>
                </ul>
                <p>De manière générale et dans la mesure du possible, il nous vous recommandons de supprimer ou de ne pas inclure de
                    Données à caractère personnel dans vos Textes.</p>
                <p>L’Utilisateur et/ou l’Acheteur est le Responsable du traitement des Données au sens de la législation et garantit à
                    U31 que ce traitement est licite et conforme à la législation sur les Données à caractère personnel. U31 agit en
                    tant que Sous-traitant de l’Utilisateur ou de l’Acheteur au sens de la législation sur les Données à caractère
                    personnel.</p>
                <p>L’Utilisateur/l’Acheteur garantit U31 contre toute action ou réclamation qui pourrait être formée contre U31 par les
                    personnes concernées du fait d’un manquement de l’Utilisateur ou de l’Acheteur à ses obligations en la matière.</p>
                <p>L’Utilisateur/l’Acheteur autorise U31 à traiter les Données pour son compte dans la mesure nécessaire à la fourniture
                    du Service.</p>
                <p>Aux fins de la bonne exécution des Services, U31 est amené à traiter les Données, en particulier à recevoir,
                    consulter, modifier, copier, sauvegarder et/ou restaurer les Données.</p>
                <p>
                    <u>
                        Clause relative à l'utilisation de services d'intelligence artificielle et de traitement des données à l'étranger :
                    </u>
                </p>
                <p>Pour certaines suggestions automatiques, U31 utilise le service d'OpenAI, une entreprise américaine privée. OpenAI
                    est un service d'intelligence artificielle qui nous aide à améliorer votre expérience utilisateur.</p>
                <p>Ainsi, et uniquement, lorsque vous activez le mode “Suggestions automatiques” (bouton situé en bas à droite sur du
                    parcours de simplification sur l’éditeur U31), certaines des données de l’Utilisateur peuvent être traitées par
                    OpenAI. Les données ainsi traitées sont les Textes saisis par l’Utilisateur. Les serveurs d'OpenAI se situent aux
                    États-Unis, donc les Textes ou des parties des Textes de l’Utilisateur peuvent être transférées et traitées en
                    dehors de la France.</p>
                <p>Nous nous efforçons de protéger la confidentialité et la sécurité de vos données dans tous les aspects de nos
                    services. Nous avons pris des mesures pour nous assurer que le transfert de vos données respecte toutes les lois
                    applicables en matière de protection des données, et OpenAI est également tenu par contrat de protéger les données.
                </p>
                <p>En utilisant certaines fonctionnalités (activation du mode “Suggestions automatiques”), vous consentez au transfert
                    et au traitement de vos Textes par OpenAI aux États-Unis conformément à cette clause. Si vous n’êtes pas d'accord
                    avec ce transfert et ce traitement pour un ou plusieurs de vos Textes, vous ne devez pas activer le mode
                    “Suggestions automatiques”.</p>
                <p>Si vous n’activez pas le mode “Suggestions automatiques”, les Données resteront hébergées en France ou dans un pays
                    de l’Union Européenne. </p>
                <p>Si vous avez des questions sur cette clause, n’hésitez pas à nous contacter à <a target="_blank" href="mailto:contact@u31.io">contact@u31.io</a>. </p>
                <p>Par ailleurs, veuillez noter que : </p>
                <p>Nous pouvons traiter vos Données pour que les Services fonctionnent bien. En particulier nous pouvons :</p>
                <ul>
                    <li>recevoir,</li>
                    <li>consulter,</li>
                    <li>modifier,</li>
                    <li>copier,</li>
                    <li>sauvegarder</li>
                    <li>ou restaurer vos Données.</li>
                </ul>
                <p>Vous nous autorisez à traiter les Données de votre compte si c'est nécessaire au bon fonctionnement du Service.</p>
                <p>Par ailleurs, nous pouvons sous-traiter la totalité ou une partie du traitement des Données nécessaires à la
                    fourniture du Service, notamment pour l’hébergement. Nous pouvons choisir le sous-traitant de notre choix. Les
                    Données sont hébergées en France ou dans un pays de l’Union Européenne.</p>
                <p>De plus, pour résoudre des bugs uniquement :</p>
                <ul>
                    <li>Vos Textes sont conservés sur les serveurs Azure de U31. Ces serveurs sont dans l’Union Européenne. Chez nous,
                        seule l’équipe infrastructure peut y accéder.</li>
                    <li>Les textes que vous conservez en tant que “Documents” dans votre compte U31 sont stockés sur les serveurs Azure
                        de U31 jusqu’à ce que vous les supprimiez.</li>
                </ul>
                <p>Pendant la durée des Services, et tant que nous sommes en possession des Données, nous nous engageons à :</p>
                <ol start="1">
                    <li>Mettre en place toutes les mesures techniques et d'organisation pour garantir un niveau de sécurité adapté à la
                        sensibilité des Données ;</li>
                    <li>Traiter les Données seulement pour l’exécution des Services ;</li>
                    <li>Vous fournir de la documentation (comme les présentes Conditions Générales d’Utilisations) ;</li>
                    <li>Vous informer immédiatement si une ou plusieurs de vos instructions va contre les dispositions du Règlement ou
                        du droit de l’Union Européenne pour les données à caractère personnel  ;</li>
                    <li>Vous assister, dans la mesure du possible, pour permettre aux personnes concernées par les Données d’exercer
                        leurs droits selon la loi sur les Données à caractère personnel ;</li>
                    <li>Vous informer de toute atteinte aux Données rapidement après en avoir pris connaissance ;</li>
                    <li>Vous assister dans vos obligations de notification à l’autorité de contrôle ou aux personnes concernées (vous
                        êtes seul responsable de cette notification) ;</li>
                    <li>Vous assister, dans la mesure du possible, pendant la mise en œuvre d’analyses d’impact, et pendant la
                        consultation avec l’autorité de contrôle. L'objectif est de déterminer les risques que peuvent provoquer un
                        traitement particulier des données sur les droits et libertés des personnes concernées ;</li>
                    <li>Si vous faites la demande, à supprimer les Textes contenant des Données à caractère personnel de tous les
                        supports. Nous n'en conserverons pas de copie, n'en feront pas d'usage après et nous justifierons par écrit de
                        leur destruction ;</li>
                    <li>À l’expiration des Services, supprimer définitivement les Textes contenant les Données de tous les supports où
                        ils se trouvent et n’en conserver aucune copie ;</li>
                    <li>Vous donner toutes les informations nécessaires pour démontrer le respect de nos obligations et permettre si
                        nécessaire la réalisation d’un audit (par vos soins ou par tout tiers que vous mandatez) ;.</li>
                    <li>Faire respecter par nos sous-traitants les mêmes obligations en matière de protection des Données que celles
                        présentées dans cet article.</li>
                </ol>
                <h3>12.3. Données à caractère personnel des Utilisateurs</h3>
                <p>U31 agit en qualité de responsable du traitement s’agissant des Données à caractère personnel des Utilisateurs
                    qu’elle collecte et traite. L’interlocuteur chez U31 est Mr. Paul Poupet ( <a
                        href="mailto:paul.poupet@u31.io">paul.poupet@u31.io</a> ).</p>
                <p><u>Pourquoi traitons-nous des Données à caractère personnel ?</u></p>
                <p>Elles nous permettent de gérer les abonnements, le fichier clients/utilisateurs et ainsi de permettre l’exécution des
                    Services.</p>
                <p>Selon la loi Informatique et Libertés, vous avez un droit d'accès, de rectification et, si les dispositions légales
                    le permettent, de suppression des données qui vous concernent.</p>
                <p>Nous nous engageons à mettre en œuvre les mesures techniques et organisationnelles appropriées pour garantir un
                    niveau de sécurité adapté aux risques présentés par le traitement :</p>
                <ul>
                    <li>Données hébergées dans l’Union Européenne par un prestataire,</li>
                    <li>Utilisation du protocole SSL (Https) de bout en bout).</li>
                </ul>
                <p>Nous conservons les données à caractère personnel seulement pour la durée nécessaire aux objectifs pour lesquelles
                    elles ont été collectées.</p>
                <p>Les durées varient selon l'objectif de leur collecte, à savoir :</p>
                <ol start="1">
                    <li>Les données traitées pour la gestion et le suivi de la relation contractuelle :</li>
                </ol>
                <ul>
                    <li>Nom, prénom, adresse de courriel et numéro de téléphone.</li>
                    <li>Durée : Toute la durée de la relation du contrat est augmentée de la durée des prescriptions légales. Le délai
                        de prescription de droit commun en matière civile et commerciale est de 5 ans à compter de la fin du contrat.
                    </li>
                </ul>
                <ol start="2">
                    <li>Envoi de lettre d’information à nos acheteurs et Utilisateurs :</li>
                </ol>
                <ul>
                    <li>Possibilité de recueillir leur avis sous forme de questions ou de libre rédaction afin qu’ils puissent exprimer
                        leurs besoins, leurs souhaits, leurs suggestions d’améliorations ou toute autre information susceptible
                        d’améliorer leur expérience vis-à-vis du produit. </li>
                    <li>Durée : 13 mois à compter de leur collecte</li>
                </ul>
                <ol start="3">
                    <li>Les données traitées pour l’amélioration de l’expérience utilisateur sur notre site et pour bénéficier des
                        fonctionnalités et services du site</li>
                </ol>
                <ul>
                    <li>Cookies</li>
                    <li>Durée : 13 mois à partir de la collecte</li>
                </ul>
                <ol start="4">
                    <li>Les données traitées pour l’élaboration de statistiques commerciales.</li>
                </ol>
                <ul>
                    <li>Durée : 13 mois après la fin de la relation contractuelle</li>
                </ul>
                <ol start="5">
                    <li>Les données traitées pour l’analyse et l’établissement de statistiques relatives à la consultation et à
                        l’utilisation du site, des services et des publicités affichées sur le site.</li>
                </ol>
                <ul>
                    <li>Durée : 13 mois à partir de la collecte</li>
                </ul>
                <ol start="6">
                    <li>Les données traitées pour la gestion des impayés.</li>
                </ol>
                <ul>
                    <li>Durée : 5 ans à compter de la fin de la procédure de recouvrement des impayés</li>
                </ul>
                <ol start="7">
                    <li>Les données traitées pour la gestion du contentieux.</li>
                </ol>
                <ul>
                    <li>Durée : de la procédure jusqu’à ce que soit prescrite l’action en exécution d’une décision de justice passée en
                        force de chose jugée.</li>
                </ul>
                <ol start="8">
                    <li>Les données traitées pour la caractérisation de profils clients et l’analyse des préférences en matière d’achat.
                    </li>
                </ol>
                <ul>
                    <li>Durée : 5 ans après la fin de la relation contractuelle</li>
                </ul>
                <ol start="9">
                    <li>Les données traitées pour l’analyse marketing .</li>
                </ol>
                <ul>
                    <li>Durée : 13 mois après la fin de la relation contractuelle</li>
                </ul>
                <ol start="10">
                    <li>Les données traitées pour la gestion de la comptabilité.</li>
                </ol>
                <ul>
                    <li>Durée : L’exercice en cours, augmenté de 10 ans à compter de la clôture.</li>
                </ul>
                <ol start="11">
                    <li>Les données traitées pour la gestion du droit d’opposition.</li>
                </ol>
                <ul>
                    <li>Durée : les informations permettant de prendre en compte son droit d'opposition sont conservées trois ans à
                        compter de l'exercice du droit d'opposition.</li>
                </ul>
                <ol start="12">
                    <li>Les données traitées pour l’amélioration des performances du service.</li>
                </ol>
                <ul>
                    <li>Durée : 36 mois après la fin de la relation contractuelle.</li>
                </ul>
                <p>Nous nous engageons à ne pas conserver, héberger ou archiver, temporairement ou définitivement, directement ou
                    indirectement, les données transmises par les acheteurs et utilisateurs à l’occasion de l'utilisation du Site et de
                    l'exécution des Services, sur le territoire d’un pays hors Union Européenne ou Espace Économique Européen.</p>
                <p>Nous nous engageons - personnel de U31 et prestataires éventuels - à respecter de façon absolue les obligations
                    suivantes :</p>
                <ul>
                    <li>Ne pas utiliser les documents et informations traités autrement que pour les raisons présentées dans ces CGUS;
                    </li>
                    <li>Ne pas divulguer ces documents ou informations à d’autres personnes sauf à celles qui participent à l’exécution
                        du Service ;</li>
                </ul>
                <p>Toutes les personnes autorisées à traiter les données pour le compte des acheteurs et utilisateurs ont une stricte
                    obligation de confidentialité.</p>
                <p>En cas de fin de Contrat, peu importe la raison, vous pouvez demander par écrit à ce que vos Données à caractère
                    personnel soient supprimées. Nous nous engageons à le faire dans les trente (30) jours après la réception de cette
                    demande écrite.</p>
                <h2>14. Litige</h2>
                <h3>14.1 Réclamation préalable</h3>
                <p>En cas de litige, vous devez vous adresser en priorité au service clientèle de l'entreprise par courrier électronique
                    à l’adresse <a target="_blank" href="mailto:contact@u31.io">contact@u31.io</a>.</p>
                <h2>15. Droit applicable</h2>
                <p>Le présent contrat et les CGUS le régissant sont soumis à la loi française.</p>
                <p>Si une version traduite du Contrat est contradictoire avec la version française, c'est la version française qui est
                    valable. Dans les limites prévues par la loi en vigueur, tout litige est soumis à la compétence exclusive des
                    Tribunaux de Paris :</p>
                <ul>
                    <li>pour sa validité, son interprétation ou son exécution</li>
                    <li>y compris en cas d’appel en garantie ou de pluralité des défenseurs.</li>
                </ul>
                <h2>16. Sous-traitance</h2>
                <p>U31 peut sous-traiter tout ou partie de ses droits et obligations au titre du Contrat, à tous les tiers de son choix
                    :</p>
                <ul>
                    <li>établis au sein de l'Union Européenne</li>
                    <li>en dehors de l'Union Européenne s'ils sont établis dans un pays adéquat ou qui présente des garanties
                        appropriées au sens du RGPD, et dans le strict respect des dispositions de la loi n° 75-1334 du 31 décembre
                        1975.</li>
                </ul>
                <p>Le cas échéant, U31 demeurera l’interlocuteur unique des acheteurs et utilisateurs.</p>
                <h2>17. Contact</h2>
                <p>Pour toute demande, vous pouvez :</p>
                <ul>
                    <li>écrire par courrier à U31 SAS – 4 Chemin du Châtelet 42400 Saint-Chamond, France</li>
                    <li>écrire au service client par e-mail à <a target="_blank" href="mailto:contact@u31.io">contact@u31.io</a>.</li>
                </ul>

            </CGUWrapper>
            <Footer />
        </>
    )
}

export default CGU