// @ts-nocheck
import { parse } from 'node-html-parser';

const stylesToKeep = ["color", "background-color", "font-weight", "font-style", "text-decoration"]
interface IDictionary<TValue> {
    [id: string]: TValue;
}

const _normalizeHtml = (el: HTMLElement) => {
    if (!el || !el.removeAttribute) return;
    const styleStr = (el.getAttribute("style") || "").replaceAll("; ", ";");
    el.removeAttribute("style")

    let newStyle = "";

    const originalStyle = Array.from(styleStr.matchAll(/([\w-]+)\s*:\s*([^;]+)\s*;?/g))
        .reduce((acc, curr) => ({ ...acc, [curr[1]]: curr[2] }), {}) as IDictionary<string>;

    // console.log("NormalizeHtml originalStyle", el.innerHTML, originalStyle)

    stylesToKeep.forEach(styleName => {
        if (originalStyle[styleName])
            newStyle += ` ${styleName}: ${originalStyle[styleName]};`
    })
    // console.log("NormalizeHtml newStyle", el.innerHTML, newStyle)

    el.setAttribute("style", newStyle)

    const ariaHiddenElems = el.querySelectorAll("[aria-hidden=true]")
    ariaHiddenElems.forEach(elem => el.removeChild(elem));

    if (el.childNodes)
        el.childNodes.forEach(child => _normalizeHtml(child))
}

export const normalizeHtml = (htmlString: string) => {
    const root = parse(htmlString);
    _normalizeHtml(root);
    var html = root.toString()
    return html;
}


export const normalizeSlack = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = doc.body.childNodes;

    let transformedHTML = '';

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        // Check if it's an empty span element representing a line break
        if (element.nodeName === 'SPAN' && element.classList.contains('c-mrkdwn__br')) {
            transformedHTML += '<br>';
        } else {
            const tempElement = document.createElement('div');
            tempElement.appendChild(element.cloneNode(true));
            transformedHTML += tempElement.innerHTML;
        }
    }
    return transformedHTML;

}
