import {
    Node,
    nodeInputRule,
    mergeAttributes,
  } from '@tiptap/core'
  import { ReactNodeViewRenderer } from '@tiptap/react'
import ImportDocExtView from './ImportDocExtView'
  
  export interface ImportDocumentOptions { }
  
  export const ImportDocument = Node.create<ImportDocumentOptions>({
    name: 'importDocument',
    group: 'block',
    atom: true,
    priority: 0,
    selectable: false,
    content: 'inline*',
    topNode: true,
    
  
  
    addAttributes() {
      return {
      }
    },
  
    parseHTML() {
      return [
        {
          tag: 'react-component',
        },
      ]
    },
  
    renderHTML({ node, HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes)]
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(ImportDocExtView)
    },
  })