import { Editor } from '@tiptap/react';
import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import { useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { U31Button, U31Link, U31LoadingPunchlines, U31Paragraph, U31Recommandation, U31StyledStep, U31Switch } from "@u31-dev/u31-ui";
import { setAccessibilityMessage, setActiveCategories, setCategories, setHoveredCardsIds, setImportExportPopupOpen, setOpenCardId, setOpenCardIdForCategory, setRightPanelActivePage } from '../../../actions/settings-actions';
import { IS_CNAV, MIN_CHAR_COUNT } from '../../../config';
import { analyze } from '../../../helpers';
import { getCategoriesErrorCount, getLanguageRecommandationsAsArray, getMetrics, getScores } from '../../../reducers/nlp-reducer';
import { getActiveCategories, getActiveDocumentId, getCategories, getHasDocChangedAfterProcess, getHiddenCardIds, getIgnoredCardIds, getLanguage, getNlpLoading, getOpenCardId, getOpenCardIdForCategory, getRevisionState, getRightPanelActivePage } from '../../../reducers/settings-reducer';
import { RevisionState, U31Metrics } from '../../../types';
import useIsDemo from '../../helpers/useIsDemo';
import useIsMinimumCharacterCountReached from '../../helpers/useIsMinimumCharacterCountReached';
import { ClariScore, getClariScoreInfo } from '../../shared/ClariScore';
import Export from './export';
import RightPanelQuotations from './quotations';
import Recommandations from './recommandations/Recommandations';
import { PartTitle, RightColumnWrapper, RightPanelProps, StepProps, StyledExportStep, StyledGettingStarted, StyledHideAssistant, StyledRightColumnActions, StyledRightPanel, StyledScore, StyledStepper } from './shared.styled';
import SwitchAutomaticSuggestions from './SwitchAutomaticSuggestions';
import { collectFrontRecommandations, IFrontRecommandations } from '../../frontRecommendations';

const badgeSize = 15;
// amount of time to show the badge
const badgeTime = 2000;

interface IBadge {
  color: string,
  opacity: number,
  variant: "contour" | "fill"
}

export const Badge = styled.div<IBadge>`
  position: absolute;
  opacity: ${props => props.opacity};
  transform: scale(${props => props.opacity});
  background: ${props => props.variant == "fill" ? props.color : "#fff"};
  width: ${badgeSize}px;
  height: ${badgeSize}px;
  top: -${badgeSize / 2}px;
  left: -${badgeSize / 2}px;
  border-radius: ${badgeSize}px;
  outline: ${props => props.variant == "fill" ? "none" : `2px solid #fff`};
  border: ${props => props.variant == "fill" ? "none" : `2px solid ${props.color}`};
  transition: 0.2s;
`


export const Step = React.memo((props: StepProps) => {
  const categories = useSelector(getCategories);
  const category = categories[props.categoryKey];
  const dispatch = useDispatch();
  const activePage = useSelector(getRightPanelActivePage);
  const activeCategories = useSelector(getActiveCategories);
  const openCardIdAll = useSelector(getOpenCardId);
  const openCardId = useSelector(getOpenCardIdForCategory(props.categoryKey));
  const languageRecommandationList = useSelector(getLanguageRecommandationsAsArray("lc")) as Array<U31Recommandation>;
  const activeRecommandation = languageRecommandationList.find(r => r.id == openCardId);

  const prevErrorCountRef = useRef<number>(props.errorCount);
  const isFirstTime = useRef<boolean>(true);
  const [showBadge, setShowBadge] = useState(false);

  console.log("errorCounterrorCount", props.categoryKey, props.errorCount)

  useEffect(() => {
    const errorCount = props.processed == true ? 0 : props.errorCount;
    if (errorCount > prevErrorCountRef.current && !isFirstTime.current) {
      setShowBadge(true);
      setTimeout(() => {
        setShowBadge(false);
      }, badgeTime)
    }
    if (errorCount != prevErrorCountRef.current) {
      isFirstTime.current = false;
    }
    prevErrorCountRef.current = props?.processed ? 0 : errorCount;
  }, [props.errorCount, props.processed]); //run this code when the value of count changes

  const onStepClicked = (add: boolean) => {
    if (activePage != RightPanelPages.RECOMMANDATIONS) {
      dispatch(setRightPanelActivePage(RightPanelPages.RECOMMANDATIONS))
      dispatch(setAccessibilityMessage(`Catégorie ${category.name}.`))
    }

    dispatch(setHoveredCardsIds([]))

    if (add && activeCategories.includes(props.categoryKey)) {
      return dispatch(setActiveCategories(activeCategories.filter(activeCategory => activeCategory != props.categoryKey)))
    }
    dispatch(setActiveCategories([props.categoryKey, ...(add ? activeCategories : [])]))

    console.log(`DEBUG ERWAN openCardIdAll : \nactiveRecommandation=${activeRecommandation}\nopenCardIdAll[category as keyof typeof openCardIdAll]=${openCardIdAll[category as keyof typeof openCardIdAll]}\ncategory=${category}\n`);
    if (!activeRecommandation || (props.categoryKey != activeRecommandation.category) && openCardIdAll[category as keyof typeof openCardIdAll] == null) {
      setTimeout(() => {
        const firstReco: U31Recommandation | undefined = languageRecommandationList.find(r => r.category == props.categoryKey);
        if (firstReco) {
          dispatch(setOpenCardIdForCategory({ category: firstReco.category, id: firstReco.id }))
        }
      }, 400)
    }
    // 
  }

  return (
    <>

      <U31StyledStep
        style={{ position: "relative" }}
        processed={props.processed}
        active={props.active}
        color={props.processed ? "#446EE0" : category.color}
        className={props.isExportStep ? "export" : ""}
        onKeyDownCapture={(e) => {
          if (e.key == "Enter") {

            onStepClicked(e.ctrlKey)
          }
        }}
        onMouseUp={(e) => onStepClicked(e.ctrlKey)}
      >
        <p className="category">
          {category.name}
        </p>
        {
          props.processed ?
            <img alt={`Aucune erreur`} className="checked" src={`/images/rightPanel/check_${props.active ? 'white' : 'blue'}.svg`} />
            :
            <p className="errorCount">
              {props.errorCount || 0}
              <span className="sr-only"> erreurs.</span>
            </p>
        }
        <div className="verified">
          <p>Vérifier</p>
          <img src="/images/grey_arrow.svg" alt="" />
        </div>
        <Badge variant={props.active ? "contour" : "fill"} opacity={showBadge ? 1 : 0} color={category.color} />
      </U31StyledStep>
      {props.active && !props.processed && <button className="sr-only" onClick={() => {
        const firstCard = document.querySelector("#recommandations .card button");
        console.log("FOCUSS firstCard", firstCard)
        if (firstCard) {
          firstCard.focus();
        } else {

        }
      }}>Aller aux recommandations.</button>}
    </>
  )
})

const ExportStep = (props: StepProps) => {
  const dispatch = useDispatch();

  return (
    <StyledExportStep
      processed={false}
      active={props.active}
      color="#446EE0"
      onClick={() => {
        dispatch(setRightPanelActivePage(RightPanelPages.EXPORT))
        dispatch(setActiveCategories([]))
      }}
    >
      <p className="category">
        Exporter
      </p>
      <img alt="" role="presentation" className="checked" src={`/images/rightPanel/export${props.active ? '_white' : ''}.svg`} />
    </StyledExportStep>
  )
}


const Actions = () => {
  const [isMinimumCharacterCountReached] = useIsMinimumCharacterCountReached();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lastRevisionState: RevisionState = useSelector(getRevisionState);
  const basePrice = lastRevisionState?.prices?.service_prices?.base;
  const activePage = useSelector(getRightPanelActivePage)
  return (
    <StyledRightColumnActions>
      {/* {
        !IS_CNAV && basePrice && isMinimumCharacterCountReached == true && <button
          className={`action`}
          onClick={() => {
            dispatch(setRightPanelActivePage(RightPanelPages.QUOTATION_WITHOUT_RECO))
          }}
        >
          <img src="/images/rightPanel/actions/icone_service.svg" alt="" />
          <div className="name">
            <p className="actionTitle">Service d’adaptation</p>
          </div>
        </button>
      } */}
      {/* <div
        className="action"
      >
        <SwitchAutomaticSuggestions />
      </div> */}
      <a
        className="action"
        target="_blank"
        href="/guide"
      >
        <img src="/images/rightPanel/actions/icone_guide.svg" alt="" />
        <div className="name">
          <p className="actionTitle">Guide & Méthode</p>
        </div>
      </a>
      {IS_CNAV && <a
        className="action"
        target="_blank"
        href="/contact"
      >
        <img src="/images/rightPanel/actions/icone_assistance.svg" alt="" />
        <div className="name">
          <p className="actionTitle">Assistance</p>
        </div>
      </a>}
      {IS_CNAV && <a
        className="action"
        target="_blank"
        href="https://cnavts.sharepoint.com/:f:/r/teams/U31158/Documents%20partages/General?csf=1&web=1&e=3MeJ3x"
      >
        <img src="/images/rightPanel/actions/icone_ressources.svg" alt="" />
        <div className="name">
          <p className="actionTitle">Ressources</p>
        </div>
      </a>}
      {/* {!IS_CNAV && <button
        className="action"
        onClick={() => {
          const newWin = window.open(`/mon-compte/personnalisation`, "_blank");
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            window.location = `/mon-compte/personnalisation`;
          }
        }}
      >
        <img src="/images/rightPanel/actions/icone_personnalisation.svg" alt="" />
        <div className="name">
          <p className="actionTitle">Personnalisation</p>
        </div>
      </button>} */}
      {!IS_CNAV && <button
        className="action"
        onClick={() => {
          const newWin = window.open(`/contact`, "_blank");
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            window.location.pathname = `/contact`;
          }
        }}
      >
        <img src="https://www.svgrepo.com/show/317909/contact-mail.svg" alt="" />
        <div className="name">
          <p className="actionTitle">Aide</p>
        </div>
      </button>}
    </StyledRightColumnActions>
  )
}

const ReAnalyze = styled(U31Button)`
  margin: 0;
  width: 100%;
  display: flex;
  padding: 0.4rem;
  justify-content: space-around;
  align-items: center;

  img {
    height: 1.5rem;
  }
`

const punchlines = [
  "U31 a trop de succès, patientez",
  "Vous êtes dans la queue, ça va arriver",
  "C’est exceptionnel, d’habitude c’est rapide",
]


const RightColumn = (props: RightPanelProps) => {
  const isDemo = props.isDemo;
  const language = useSelector(getLanguage);
  const scores = useSelector(getScores);
  const score = scores[language as keyof typeof scores];
  const [isMinimumCharacterCountReached] = useIsMinimumCharacterCountReached();
  const isNlpLoading = useSelector(getNlpLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("frontRecos UPDATE", props.frontRecos)
  }, [props.frontRecos])


  const clariScoreInfo = getClariScoreInfo(score);
  const activeCategories = useSelector(getActiveCategories);
  const categories = useSelector(getCategories);

  const recommandationList = useSelector(getLanguageRecommandationsAsArray(language)) as Array<U31Recommandation>;
  const hiddenCardsIds = useSelector(getHiddenCardIds);
  const ignoredCardsIds = useSelector(getIgnoredCardIds);

  const activePage = useSelector(getRightPanelActivePage);
  const categoriesErrorCountFromNlp = useSelector(getCategoriesErrorCount);
  const activeDocumentId = useSelector(getActiveDocumentId);

  const hasDocChangedAfterProcess = useSelector(getHasDocChangedAfterProcess);

  const [lcOpen, setLcOpen] = useState(false);

  const getFrontErrorCount = (categoryKey: string) => {
    if (props.frontRecos)
      if (categoryKey in props.frontRecos)
        return props.frontRecos[categoryKey].filter(c => c.props.children == null).length;
    return 0;
  }

  const getErrorCount = (categoryKey: string) => {
    return recommandationList
      .filter(reco => reco.category == categoryKey)
      .filter(reco => !ignoredCardsIds.includes(reco.id) && !hiddenCardsIds.includes(reco.id))
      .length + getFrontErrorCount(categoryKey);
  }

  const metrics: U31Metrics = useSelector(getMetrics);

  const LCErrorCountRatio = ['sent_complexity', 'word_complexity', 'structure'].reduce((prev, curr) => prev + getErrorCount(curr), 0) / (metrics.wordCount || 1)
  let LCBadgeColor = "transparent";
  if (LCErrorCountRatio == 0)
    LCBadgeColor = "transparent";
  else if (LCErrorCountRatio < 0.1)
    LCBadgeColor = "green";
  else if (LCErrorCountRatio < 0.2)
    LCBadgeColor = "orange";
  else if (LCErrorCountRatio >= 0.2)
    LCBadgeColor = "red";

  return (
    <RightColumnWrapper>
      <StyledScore color={clariScoreInfo?.color}>
        {!isNlpLoading && hasDocChangedAfterProcess &&
          <>
            <U31Paragraph style={{ lineHeight: "1.2rem", color: "#4F5456", textAlign: "center", marginBottom: '0.4rem' }}>Le score n'est peut-être plus à jour.</U31Paragraph>
            <ReAnalyze onClick={() => {
              if (props.editorRef && activeDocumentId)
                analyze({
                  editor: props.editorRef.current,
                  documentId: activeDocumentId,
                  dispatch,
                  demo: isDemo,
                });

            }}>
              réanalyser
              <img src="/images/rightPanel/reaload.svg" alt="" />
            </ReAnalyze>
          </>
        }
        <div id="score">
          <div className="text">
            <p className="title">Score</p>
            <p className="score">{score} {score && <span style={{
              color: '#4a81ff',
              fontSize: '70%'
            }}>/ 100</span>}</p>
          </div>
        </div>
        <p className="title">Grade</p>
        <ClariScore score={score} />

        {isNlpLoading && <U31LoadingPunchlines punchlines={punchlines} startAfter={4000} updateInterval={4000} />}
        <U31Link className="title" href={`/mon-compte/diagnostic?id=${activeDocumentId}`} target="_blank" style={{ color: "#677688" }}>Afficher le diagnostic</U31Link>
      </StyledScore>

      <StyledStepper id="categoriesStepper">

        <Step
          categoryKey={'grammar'}
          active={activeCategories.includes('grammar')}
          errorCount={isDemo ? (categoriesErrorCountFromNlp['grammar'] || 0) : getErrorCount('grammar')}
          processed={isDemo ? false : getErrorCount('grammar') == 0}
        />
        <U31StyledStep
          className='noOutline'
          onClick={() => { setLcOpen(!lcOpen) }}
          color={"#FD5B61"}
          active={false}
          processed={false}
          style={{ position: "relative" }}
        >
          {!lcOpen && <Badge variant={"fill"} opacity={1} color={LCBadgeColor} />}
          <p className="category">La clarté du langage
          </p>
          <p className="category"><img style={{ transition: "0.4s", transform: `rotate(${lcOpen ? -180 : 0}deg)` }} src="/images/icon/down_arrow.svg" /></p>
        </U31StyledStep>
        <div className={`grouppedCategory ${lcOpen ? "active" : ""}`}>
          {
            ['sent_complexity', 'word_complexity', 'structure']
              .map((categoryKey, i) => {

                const errorCount = getErrorCount(categoryKey)

                const processed = errorCount == 0;

                return <Step
                  key={i}
                  categoryKey={categoryKey}
                  active={activeCategories.includes(categoryKey)}
                  errorCount={isDemo ? (categoriesErrorCountFromNlp[categoryKey] || 0) : errorCount}
                  processed={isDemo ? false : processed}
                />
              })
          }
        </div>
        <Step
          categoryKey={'inclusion'}
          active={activeCategories.includes('inclusion')}
          errorCount={isDemo ? (categoriesErrorCountFromNlp['inclusion'] || 0) : getErrorCount('inclusion')}
          processed={false}
        />
        <Step
          categoryKey={'accessibility'}
          active={activeCategories.includes('accessibility')}
          errorCount={isDemo ? (categoriesErrorCountFromNlp['accessibility'] || 0) : getErrorCount('accessibility')}
          processed={false}
        />
        <Step
          key={getErrorCount("eco")}
          categoryKey={'eco'}
          active={activeCategories.includes('eco')}
          errorCount={isDemo ? (categoriesErrorCountFromNlp['eco'] || 0) : getErrorCount('eco')}
          processed={false}
        />

        {!isDemo &&
          <>
            <ExportStep
              errorCount={0}
              processed={true}
              categoryKey="export"
              active={activePage == RightPanelPages.EXPORT}
            />

            <button className="sr-only" onClick={(e) => { document.querySelectorAll("#recommandations .card button")[0].focus() }}>Aller aux recommandations</button>
            <button className="sr-only" onClick={(e) => { document.querySelector("#u31editor > .ProseMirror").focus() }}>Aller à l'éditeur</button>
          </>
        }
      </StyledStepper>

      {!isDemo && <Actions />}

    </RightColumnWrapper>
  )
}

const HideAssistant = (props: { setRecommandationPanelOpen: Function, recommandationPanelOpen: boolean }) => {
  return (
    <StyledHideAssistant tabIndex={-1} id="hideAssistant">
      <U31Button
        tabIndex={-1}
        variant="text"
        onClick={() => { props.setRecommandationPanelOpen(!props.recommandationPanelOpen) }}
      >
        {props.recommandationPanelOpen ? "Afficher les recommandations" : "Afficher les recommandations"}
        <img alt="" role="presentation" src="/images/hide_reco.svg" style={{ transform: props.recommandationPanelOpen ? "rotate(0)" : "rotate(180deg)" }} />
      </U31Button>
    </StyledHideAssistant>
  );
}

const GettingStarted = () => {
  const theme = useTheme();
  const color = theme?.color?.primary;
  return (
    <StyledGettingStarted>
      <PartTitle text="📝 Rédigez ou importez un texte" color={color} />
      <div className="option">
        <p className="title">Commencez à écrire ou importez un texte. <br /> L'analyse se lancera automatiquement.</p>
      </div>
      <PartTitle text="💯 Obtenez un score et des recommandations" color={color} />
      <div className="option">
        <p className="title">
          Pour obtenir un score et des recommandations fiables,
          il faut au moins 400 caractères.
        </p>
        <p className="title">
          Il faut parfois attendre quelques secondes pour que le score s'affiche.
        </p>
      </div>
      {/* <PartTitle text="✨ Activez les suggestions automatiques" color={color} />
      <div className="option">
        <div className="suggestionAuto">
          <SwitchAutomaticSuggestions text={<><p>Cliquez sur le bouton suivant pour autoriser l’utilisation de services tiers pour améliorer l’efficacité d’U31. </p><p> Attention, vos données seront traitées sur des serveurs privés hors de l’Union Européenne</p></>} />
        </div>
      </div> */}
    </StyledGettingStarted>
  )
}

export enum RightPanelPages {
  EMPTY,
  GETTING_STARTED,
  RECOMMANDATIONS,
  EXPORT,
  QUOTATION,
  QUOTATION_WITHOUT_RECO
}


const RightPanel: FC<RightPanelProps> = (props: RightPanelProps) => {
  const { editorRef, isDemo } = props;
  const [recommandationPanelOpen, setRecommandationPanelOpen] = useState(true);
  const activePage = useSelector(getRightPanelActivePage);
  const dispatch = useDispatch();

  const activeCategories = useSelector(getActiveCategories);
  const [savedActiveCategories, setSavedActiveCategories] = useState(activeCategories);


  useEffect(() => {
    if (recommandationPanelOpen) {
      setSavedActiveCategories(activeCategories);
    }
  }, [activeCategories])

  useEffect(() => {
    if (!recommandationPanelOpen) {
      dispatch(setActiveCategories([]))
    } else {
      dispatch(setActiveCategories(savedActiveCategories))
    }
  }, [recommandationPanelOpen])

  const [frontRecos, setFrontRecos] = useState<IFrontRecommandations | null>(collectFrontRecommandations(activeCategories, editorRef?.current));
  useEffect(() => {
    if (editorRef?.current) {
      editorRef.current.on("update", () => {
        if (editorRef.current) {
          const newFrontRecos = collectFrontRecommandations(activeCategories, editorRef.current)
          setFrontRecos(newFrontRecos);
        }
      })
    }
  }, [editorRef?.current])

  return (
    <StyledRightPanel editorRef={editorRef} id="rightPanel" isDemo={isDemo} className={`${recommandationPanelOpen ? "active" : ""}`}>
      <button className="sr-only" onClick={() => {
        const categoriesStepper = document.querySelector("#categoriesStepper button");
        if (!categoriesStepper) return;
        (categoriesStepper as HTMLButtonElement).focus()
      }}>Aller aux catégories</button>
      {!isDemo && <HideAssistant recommandationPanelOpen={recommandationPanelOpen} setRecommandationPanelOpen={setRecommandationPanelOpen} />}

      {activePage == RightPanelPages.RECOMMANDATIONS && <Recommandations frontRecos={frontRecos} isDemo={isDemo} editorRef={editorRef} />}
      {!isDemo && activePage == RightPanelPages.EXPORT && <Export editorRef={editorRef} />}
      {!isDemo && activePage == RightPanelPages.QUOTATION && <RightPanelQuotations without_reco={false} />}
      {!isDemo && activePage == RightPanelPages.QUOTATION_WITHOUT_RECO && <RightPanelQuotations without_reco={true} />}
      {activePage == RightPanelPages.GETTING_STARTED && <GettingStarted />}

      <RightColumn {...props} frontRecos={frontRecos} />
    </StyledRightPanel>
  );
}

export default RightPanel;