import { Edit, Search } from '@mui/icons-material'
import { Button, ButtonGroup, Fab, Icon, TextField, Typography } from '@mui/material'
import { CSSProperties, Suspense, useRef, useState } from 'react'
import 'react-spring-bottom-sheet/dist/style.css'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CheckIcon from '@mui/icons-material/Check';
import { Editor } from '@tiptap/react'
import useFetch from '../../helpers/useFetch'


const IconButton = styled.button<{selected: boolean}>`
    cursor: pointer;
    min-height: 5.5rem;
    max-height: 5.5rem;
    min-width: 5.5rem;
    max-width: 5.5rem;
    background: white;
    border-radius: 4px;
    border: none;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    overflow: hidden; /* Hide any overflow content */
    outline: ${props => props.selected ? "3px solid green" : "1px solid #4a81ff"};
    padding: 5%;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`

const StyledSheet = styled.div`
    margin: 5vw;
    display: flex;
    flex-flow: column;
    gap: 2rem;
    padding-bottom: 4rem;
`

const StyledIcons = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
    grid-gap: 20px;
    margin: 20px 0;
    row-gap: 1.5em;
    column-gap: 1.5em;
`

interface IIcon {
    path: string,
    size: "small" | "medium" | "big"
}

const sizes = {
    "small": "40px",
    "medium": "75px",
    "big": "150px",
}

const IconPicker = (props: { editor: Editor }) => {
    const { editor } = props;

    const [open, setOpen] = useState(false)
    const sheetRef = useRef<BottomSheetRef>(null)

    const [selectedIcon, setSelectedIcon] = useState<IIcon>({
        size: "medium",
        path: ''
    });
    const [iconSize, setIconSize] = useState<"small" | "medium" | "big">("small");


    // const [icons, setIcons] = useState([
    //     "/pictos/15-1-egalite-Hommes.png",
    //     "/pictos/15-1-egalite-homme-femme.png",
    //     "/pictos/18-10-homme-femme.png",
    //     "/pictos/18-8-homme-symbole.png",
    //     "/pictos/18-9-homme.png"
    // ])

    const [search, setSearch] = useState("");

    const { data: icons, error } = useFetch(`${process.env.REACT_APP_STORAGE_URL}/pictograms/${search || "png"}`, {
        headers: [
            ['Authorization', localStorage.getItem('u31token') || '']
        ]
    })



    return (
        <>
            <BottomSheet
                onDismiss={() => { setOpen(false) }}
                defaultSnap={({ maxHeight }) => maxHeight * 0.8}
                snapPoints={({ maxHeight }) => [
                    maxHeight - maxHeight / 10,
                    maxHeight / 4,
                    maxHeight * 0.6,
                ]}
                open={open}
                ref={sheetRef}
            >

                <StyledSheet>

                    <Typography className="title" variant="h6" component="h2">
                        Rechercher un pictogramme par nom
                    </Typography>

                    <TextField value={search} onChange={e => setSearch(e.target.value)} id="outlined-basic" label="Nom de l'icône" variant="outlined" />


                    <Typography className="title" variant="h6" component="h2">
                        Choisissez une taille pour votre pictogramme
                    </Typography>

                    <ButtonGroup size="large" aria-label="large button group">
                        <Button key="small"
                            onClick={() => { setIconSize("small"); setSelectedIcon({ ...selectedIcon, size: "small" }) }}
                            style={{ background: iconSize == "small" ? "#f3f3f3" : "inherit" }}
                        >
                            Petit
                        </Button>
                        <Button key="medium"
                            onClick={() => { setIconSize("medium"); setSelectedIcon({ ...selectedIcon, size: "medium"}) }}
                            style={{ background: iconSize == "medium" ? "#f3f3f3" : "inherit" }}
                        >
                            Moyen
                        </Button>
                        <Button key="big"
                            onClick={() => { setIconSize("big"); setSelectedIcon({ ...selectedIcon, size: "big" }) }}
                            style={{ background: iconSize == "big" ? "#f3f3f3" : "inherit" }}
                        >
                            Grand
                        </Button>
                    </ButtonGroup>


                    <Typography className="title" variant="h6" component="h2">
                        Cliquer sur un pictogramme pour le selectionner
                    </Typography>
                    <StyledIcons>
                        {
                            icons && (icons as string[]).map(i => {
                                const selected = selectedIcon.path.includes(i) 
                                return <IconButton selected={selected} onClick={() => setSelectedIcon({...selectedIcon, path: `${process.env.REACT_APP_STORAGE_URL}${i}`})}><img src={`${process.env.REACT_APP_STORAGE_URL}${i}`} /></IconButton>
                            })
                        }
                    </StyledIcons>
                    {/* <button onClick={() => sheetRef.current && sheetRef.current.snapTo(({ maxHeight }) => maxHeight)}>test</button> */}
                </StyledSheet>
                <Fab
                    variant="extended"
                    color="primary"
                    style={{ position: "absolute", bottom: "2rem", right: "2rem" }}
                    onClick={() => {
                        // @ts-ignore
                        editor && editor.chain().focus().setImage({ src: selectedIcon.path, width: sizes[selectedIcon.size], inline: true }).run();
                        setOpen(false);
                    }}
                >
                    <CheckIcon sx={{ mr: 1 }} />
                    Valider
                </Fab>
            </BottomSheet>
            <Button data-tooltip-place="right" data-tooltip-id="tbtt" data-tooltip-content="Pictogrammes" aria-label="Image"
                onClick={() => setOpen(!open)}
                // onClick={() => editor && editor.chain().focus().setImage({ src: window.prompt("URL de l'image") }).run()}
                // className={editor && editor.isActive('link') ? 'is-active' : ''}
            >
                <Icon fontSize="medium">
                    <img className="icon" style={{width: "2.2rem", height: "2.2rem"}} role="presentation" src="/images/toolbar/pictos.svg" />
                </Icon>
            </Button>

        </>
    )
}
export default IconPicker
