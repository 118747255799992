import { Extension } from '@tiptap/core'
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state'
import { setCharCount } from '../../../../../actions/nlp-actions';
import { u31store } from '../../../../..';

export const pluginKey = new PluginKey('limitCharCount')

export interface LimitCharCountOptions {
    maxChar?: number,
}

export const LimitCharCount = (dispatch) => Extension.create<LimitCharCountOptions>({
    name: 'limitChar',

    addOptions() {
        return {
            maxChar: 5,
        }
    },

    addProseMirrorPlugins() {
        const { options } = this;

        return [
            new Plugin({
                key: new PluginKey('eventHandler'),
                props: {
                    handleKeyDown: (view, event) => {
                        const isLimited = u31store.getState().settingsReducer?.revisionState?.not_enough_credits;
                        if (isLimited && !["Delete","Backspace"].includes(event.key)) {
                            return true;
                        }    
                    }
                }
            }),
            new Plugin({

                key: pluginKey,
                appendTransaction: (transactions, oldState, newState) => {
                    let max: number = options.maxChar || Infinity;
                    const getText = (state) => state?.doc?.textContent || "";
                    const getCharCount = (state) => (state?.doc?.content?.content?.length || 0) + getText(state).length;

                    const hasTextChanged = getText(newState) != getText(oldState)
                    const textIsBeingRemoved = getText(newState).length > getText(oldState).length
                    const charCount = getCharCount(newState);
                    if (!hasTextChanged) return;
                    // update charCount with current word count;
                    if (getText(newState) == "") {
                        dispatch(setCharCount(0));
                    } else {
                        dispatch(setCharCount(Math.min(max, charCount - 1)));
                    }

                    if (textIsBeingRemoved && hasTextChanged && charCount > max) {
                        const newTextSelection = TextSelection.create(
                            newState.doc,
                            getText(newState).slice(0, max).length + 1,
                            newState.doc.content.size
                        );
                        let newTr = newState.tr;

                        newTr.setSelection(newTextSelection);
                        newTr.deleteSelection();
                        // newTr.insertText(" ")
                        return newTr;
                    }

                },
            }),
        ]
    },
})
