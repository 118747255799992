import { Button, Icon, Menu, MenuItem, styled } from "@mui/material";
import { Editor } from "@tiptap/react";

import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useRef } from "react";


const StyledMenu = styled(Menu)`
    .addIcon:before {
        content: "+";
        margin-right: 0.5rem;
        font-weight: 600;
    }
`;
const TableButton = (props: { editor: Editor }) => {
    const { editor } = props;

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Button
                ref={buttonRef}
                data-tooltip-place="right"
                data-tooltip-id="tbtt"
                data-tooltip-content="Tableau"
                aria-label="Tableau"
                // onMouseEnter={() => setAnchorEl(buttonRef.current)}
                onClick={(e) => {
                    handleClick(e);
                }}
                className={editor && editor.isActive('table') ? 'is-active' : ''}
            // endIcon={<KeyboardArrowDownIcon />}
            >
                <Icon fontSize="medium">
                    <img className="icon" role="presentation" src="/images/TABLE.svg" />
                </Icon>
            </Button >
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                anchorPosition={{
                    top: 0,
                    left: 400,
                }}
                style={{ width: "30rem" }}

                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={(e) => { handleClose(); editor && editor.chain().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(); }} disableRipple>
                    Insérer un tableau
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                    className="addIcon"
                    onClick={() => editor.chain().focus().addColumnBefore().run()}
                    disabled={!editor.can().addColumnBefore()}
                    disableRipple>
                    Insérer une colonne à gauche
                </MenuItem>
                <MenuItem
                    className="addIcon"
                    onClick={() => editor.chain().focus().addColumnAfter().run()}
                    disabled={!editor.can().addColumnAfter()}
                    disableRipple>
                    Insérer une colonne à droite
                </MenuItem>
                <MenuItem
                    className="addIcon"
                    onClick={() => editor.chain().focus().addRowBefore().run()}
                    disabled={!editor.can().addRowBefore()}
                    disableRipple>
                    Insérer une ligne au dessus
                </MenuItem>
                <MenuItem
                    className="addIcon"
                    onClick={() => editor.chain().focus().addRowAfter().run()}
                    disabled={!editor.can().addRowAfter()}
                    disableRipple>
                    Insérer une ligne en dessous
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
            </StyledMenu>
        </>
    )
}

export default TableButton;