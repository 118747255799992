import styled from "styled-components"
import Header from "../shared/Header"
import '../../css/landing/common_landing.scopped.css';
// import '../../css/landing/accessibility.scopped.css';
import Footer from "../shared/Footer";
import { StyledGoBack } from "./shared.styled";

const PrivacyPolicyWrapper = styled.div`
    font-family: "Ubuntu";
    font: normal normal normal 16px/30px Ubuntu;
    letter-spacing: 0px;
    color: black;

    display: flex;
    flex-flow: column;
    padding: 0px 8.51562vw;
    width: 82.9688vw;    
    padding-top: 0;
    padding-bottom: 4rem;

    & #back-home {
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 1.125em;
        letter-spacing: 0.01125em;
        color: var(--blue);

        &:hover {
            & >span {
                text-decoration: underline;
            }
        }


        & >span {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 1.125em;
            letter-spacing: 0.01125em;
            color: var(--blue);
        }

        & >img {
            width: 2em;
            height: 1em;
            margin-right: 0.5em;
        }
    }



    & h1 {
        font: normal normal 500 26px/35px Ubuntu;
        color: black;
    }

    & h2 {
        font: normal normal 500 22px/35px Ubuntu;
        color: #446EE0;
    }
    & h3 {
        font: normal normal 500 18px/35px Ubuntu;
        color: #446EE0;
    }

    & a {
        color: #446EE0;
    }
`
const PrivacyPolicy = () => {
    return (
        <>
            <Header />

            <PrivacyPolicyWrapper>
                <StyledGoBack text="Accueil" href="/" />

                <h1>Politique de confidentialité</h1>
                <p>Les informations de contact sont disponibles dans les <a
                    href="https://u31.io/cgus">conditions
                    générales d’utilisation</a> de la plate-forme.</p>
                <p>U31 est un assistant de rédaction, visant à aider dans l'adaptation des textes.</p>
                <p>Les textes que vous voulez adapter ou rédiger sur l’éditeur sont envoyés sur nos serveurs au travers d'une connexion
                    sécurisée.</p>
                <p>Dans ce document, vous trouverez la liste des données collectées.</p>
                <p>En cas de besoin, vous pouvez nous contacter à tout moment depuis <a
                    href="https://u31.io/contact">cette
                    page</a>.</p>
                <h2>Collecte et traitement des données personnelles</h2>
                <p>Vous pouvez demander à tout moment la suppression des données citées ci-dessous. Pour cela, contactez nous
                    directement depuis l’adresse mail de contact.</p>
                <p>Lorsque vous soumettez ou rédigez un texte, le texte est envoyé via une connexion chiffrée sécurisée. Il est ensuite
                    stocké, dans le but de réaliser des statistiques, et d'améliorer notre produit.</p>
                <p>Une fois les données collectées, nous anonymisons tout ce qui peut être confidentiel :</p>
                <ul>
                    <li>Nom</li>
                    <li>Prénom</li>
                    <li>Adresse e-mail</li>
                    <li>Identifiants de connexion </li>
                    <li>Etc. </li>
                </ul>
                <p>Seules quelques personnes de l’équipe sont autorisées à consulter ces textes. Cette consultation se déroule toujours
                    dans un cadre formellement établi à l’avance. Dans le cadre où nous travaillons sur un bug ou une demande spécifique
                    d'un utilisateur par exemple.</p>
                <p>• Le texte que vous importez ou rédigez sur notre site est stocké jusqu'à la suppression du document. Lorsque vous
                    supprimez un document, toutes les données qu'il contient sont supprimées immédiatement.</p>
                <p>• Nous recueillons des informations sur la nature des alertes détectées. Nous conservons le type d'alerte détecté, sa
                    catégorie et sa nature. Cette collecte permet de penser aux améliorations possibles du produit.</p>
                <p>• Tous nos serveurs informatiques sont hébergés dans l’Union Européenne, par l'entreprise Microsoft.</p>
                <p>• Vous pouvez nous contacter avec l’adresse mail de contact ou donner votre avis suite à des sollicitations sur l'un
                    de nos produits. Ces données sont échangées au travers d'une connexion sécurisée et seront stockées sur nos
                    serveurs.</p>
                <p>• Vos identifiants de connexion sont stockés dans notre base de données chiffrée. Si vous désirez demander leur
                    suppression, vous pouvez nous contacter à tout moment.</p>
                <p>• Lors de votre inscription sur la plateforme, les données sont stockées aussi longtemps que votre compte existe. Ces
                    données sont conservées à des fins de qualification, c'est-à-dire de personnalisation de l'expérience.</p>
                <p>Vous pouvez à tout moment demander leur suppression. Vous pouvez également supprimer votre compte directement depuis
                    les paramètres.</p>
                <p>• Votre adresse IP peut être collectée pendant quelques jours au maximum. Cette information n'est pas stockée
                    définitivement au sein de notre base de données, elle sera supprimée automatiquement. Cette mesure existe pour
                    empêcher toute utilisation abusive de la plate-forme.</p>
                <p>• Enfin, n'hésitez pas à nous contacter à tout moment pour demander la suppression de ces données.</p>
            </PrivacyPolicyWrapper >
            <Footer />
        </>
    )
}

export default PrivacyPolicy