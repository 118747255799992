import { Editor, getAttributes, getNodeAttributes, getText } from "@tiptap/react";
import { findChildrenByAttr, findChildrenByMark } from "prosemirror-utils";
import React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { U31Button } from "@u31-dev/u31-ui";
import { setaddRecommandationState, setDiscardedBestPracticesIds, setHasDocChangedAfterProcess, setHiddenCardIds, setHoveredCardsIds, setIgnoredCardIds, setOpenCardId, setOpenCardIdForCategory, setValidatedBestPracticesIds } from "../../../../../actions/settings-actions";
import { getActiveDocumentId, getCategories, getDiscardedBestPracticesIds, getHiddenCardIds, getIgnoredCardIds, getOpenCardId, getRevisionState, getValidatedBestPracticesIds } from "../../../../../reducers/settings-reducer";
import { RevisionState, U31Recommandation } from '@u31-dev/u31-ui/dist/types';
// import { StyledCard, StyledNextCardButton } from "./cards.styled";
import { getOffset } from "../Recommandations";
import { analyze, discardBestPractices, setIgnoredCards, validateBestPractices } from "../../../../../helpers";

const ActionButton = styled(U31Button)`
    margin: 0;
    padding: 0.2rem 0.7rem;
`

const ActionValidateBestPracticeButton = styled(ActionButton)`
    border-color: ${props => props.color};
    background: ${props => props.color};
    color: white;
`

const GrayActionButton = styled(ActionButton)`
    border-color: #8A94AF;
    color: #8A94AF;
`

export const ActionReport = (props: { reco: U31Recommandation, setErrorSelection: Function }) => {
    const dispatch = useDispatch();

    return (
        <GrayActionButton onClick={() => {
            dispatch(setaddRecommandationState(1));
            dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
            document.querySelector("#recommandations .scrollableY")?.scrollTo({ top: 0, behavior: "smooth" })
            props.setErrorSelection(props.reco.context);
        }}>
            Signaler
        </GrayActionButton>
    )
};

// compensate for "original" insert_range:
// if we have written some text before the insert_range, the original insert_range (relative to the begining of the text) is not relevent anymore.
// here we compute the new insert_text based on the new highlightreco position.
const getInsertRange = (editor: Editor, reco: U31Recommandation) => {
    const u31HighlightRecoNodes = findChildrenByMark(editor.state.doc, editor.schema.marks.u31HighlightReco)

    if (!reco.insert_range) return false;
    for (let i = 0; i < u31HighlightRecoNodes.length; i++) {
        const nodeWithPos = u31HighlightRecoNodes[i]
        const { node, pos } = nodeWithPos;

        for (let j = 0; j < node.marks.length; j++) {
            const mark = node.marks[j];
            if (mark.type.name == "u31HighlightReco") {
                for (let k = 0; k < mark.attrs.u31recommandations.length; k++) {
                    const markReco = mark.attrs.u31recommandations[k]
                    if (markReco.id == reco.id) {
                        return {
                            from: pos - reco.spans[0][0] + reco.insert_range[0] + 1,
                            to: pos - reco.spans[0][0] + reco.insert_range[1] + 1
                        }
                    }
                }
            }
        }

    }
    return false;
};


export const getUpdatedRange = (editor: Editor, range: [number, number], reco: U31Recommandation) => {
    const u31HighlightRecoNodes = findChildrenByMark(editor.state.doc, editor.schema.marks.u31HighlightReco)

    if (!range) return false;
    for (let i = 0; i < u31HighlightRecoNodes.length; i++) {
        const nodeWithPos = u31HighlightRecoNodes[i]
        const { node, pos } = nodeWithPos;

        for (let j = 0; j < node.marks.length; j++) {
            const mark = node.marks[j];
            if (mark.type.name == "u31HighlightReco") {
                for (let k = 0; k < mark.attrs.u31recommandations.length; k++) {
                    const markReco = mark.attrs.u31recommandations[k]
                    if (markReco.id == reco.id) {
                        const res = reco?.data?.super_span ? {
                            from: pos - reco?.data?.super_span[0] + range[0],
                            to: pos - reco?.data?.super_span[0] + range[1]
                        } : {
                            from: pos - reco.spans[0][0] + range[0],
                            to: pos - reco.spans[0][0] + range[1]
                        }
                        console.log("INSERTTEXT DEBUG found reco : ", [res.from, res.to])
                        return res;
                    }
                }
            }
        }

    }
    return false;
};

export const insertText = (editor: Editor, reco: U31Recommandation, range: [number, number], text: string, callback?: Function) => {

    const _range = getUpdatedRange(editor, range, reco);
    console.log("INSERTTEXT DEBUG reco", reco)
    console.log("INSERTTEXT DEBUG range", range)
    console.log("INSERTTEXT DEBUG _range", _range)
    const selectWithoutSaving = (selection: any) => {
        editor?.chain()
            // .setMeta('preventUpdate', true)
            .setMeta('addToHistory', false)
            .setTextSelection(selection)
            .run()
    }

    // const tr = editor?.state.tr;
    // const dispatch = editor?.view.dispatch;

    // dispatch(
    //     tr
    //         // .setMeta('addToHistory', false)
    //         .insertText(
    //             text,
    //             _range?.from,
    //             _range?.to,
    //         ),
    // );
    // return;
    if (!_range) return;


    editor?.commands.focus();

    // if only insert
    if (_range.from == _range.to) {
        selectWithoutSaving(_range);
        editor?.commands.insertContent(text);
    } else {
        // need to save and reapply previous text attribute to new text, since when replacing content, we lost everything
        // because the node (u31highlightreco) is not inclusive
        editor?.chain()
            .setTextSelection(_range)
            .run()

        const attributes = getAttributes(editor?.state, editor?.schema.marks.u31HighlightReco);

        editor?.chain()
            .insertContent(text)
            .setMeta('addToHistory', true)
            .run();

        editor?.chain()
            .setMeta('addToHistory', false)
            .setTextSelection({
                from: _range.from + text.length,
                to: _range.from + text.length
            })
            .setU31HighlightReco(attributes)
            .run();

    }

    if (callback) {
        callback();
    }
}


// export const ActionReplace = (props: { editor: Editor, reco: U31Recommandation, text: string, nextCardId: string }) => {
//     const dispatch = useDispatch();
//     const hiddenCardIds = useSelector(getHiddenCardIds);
//     const documentId = useSelector(getActiveDocumentId);

//     return (
//         <ActionButton onClick={() => {

//             const range = getInsertRange(props.editor, props.reco);
//             const text = props.text;
//             if (!range) return;
//             console.log("PROSEMIRROR", range);

//             props.editor?.commands.focus();
//             props.editor?.commands.setTextSelection(range);
//             // need to save and reapply previous text attribute to new text, since when replacing content, we lost everything
//             // because the node (u31highlightreco) is not inclusive
//             const attributes = getAttributes(props.editor?.state, props.editor?.schema.marks.u31HighlightReco);
//             props.editor?.commands.insertContent(text);
//             props.editor?.commands.setTextSelection({
//                 from: range.from,
//                 to: range.from + text.length
//             })
//             // re apply previous range attributes (u31recommandations)
//             props.editor?.commands.setU31HighlightReco(attributes);
//             props.editor?.commands.setTextSelection({
//                 from: range.from + text.length,
//                 to: range.from + text.length
//             })


//             console.log("ATTRIBUTES", attributes);
//             // remove card
//             dispatch(setOpenCardId(null))
//             setTimeout(() => {
//                 dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
//                 dispatch(setOpenCardId(props.nextCardId))
//                 analyze({
//                     editor: props.editor,
//                     documentId: documentId,
//                     dispatch,
//                     demo: false,
//                 });

//                 // dispatch(setHasDocChangedAfterProcess(true));
//             }, 400)

//         }}>
//             Remplacer
//         </ActionButton>
//     )
// }

export const ActionInsertEnum = (props: { editor: Editor, reco: U31Recommandation, html: string, nextCardId: string }) => {
    const dispatch = useDispatch();
    const hiddenCardIds = useSelector(getHiddenCardIds);
    const documentId = useSelector(getActiveDocumentId);

    return (
        <ActionButton onClick={() => {


            const block_pos = getOffset(props.editor, props.reco)
            // const block_pos = findChildrenByAttr(props.editor.state.doc, attrs => attrs.block_id == props.reco.block_id).pop()?.pos;
            console.log("insertEnum", block_pos, props.reco.block_id, block_pos);
            if (block_pos == undefined) return;
            const enumRange = {
                from: props.reco.insert_range[0] + block_pos,
                to: props.reco.insert_range[1] + block_pos,
            }
            if (!props.html) return;
            const html = ` : ${props.html}`;
            console.log("PROSEMIRROR", enumRange, html);
            props.editor?.commands.focus()
            props.editor?.commands.setMeta('addToHistory', false);
            props.editor?.commands.setTextSelection(enumRange);
            props.editor?.commands.insertContent(html);
            props.editor?.commands.setTextSelection({
                from: enumRange.from,
                to: enumRange.from
            })

            // // remove card
            dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
            setTimeout(() => {
                dispatch(setHiddenCardIds([...hiddenCardIds, props.reco.id]))
                dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: props.nextCardId }))
                // dispatch(setHasDocChangedAfterProcess(true));
                analyze({
                    editor: props.editor,
                    documentId: documentId,
                    dispatch,
                    demo: false,
                });

            }, 400)

        }}>
            Insérer
        </ActionButton>
    )
}

const getSelectedText = (editor: Editor) => {
    const { from, to, empty } = editor.state.selection
    if (empty) {
        return "";
    }
    return editor.state.doc.textBetween(from, to, '')
}

export const ActionIgnoreReco = (props: { editor: Editor, reco: U31Recommandation, nextCardId: string }) => {
    const dispatch = useDispatch();
    const ignoredCardIds = useSelector(getIgnoredCardIds);

    return (
        <GrayActionButton onClick={() => {
            if (!props?.editor) return;
            dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
            // const u31HighlightRecoNodes = findChildrenByMark(props.editor.state.doc, props.editor.schema.marks.u31HighlightReco)

            // // // find updated position of ignored recommandations in potentially edited doc
            // // const u31HighlightRecoNodes = findChildren(editor.state.doc, child => child.marks.map(mark => mark.type.name).includes("u31HighlightReco"), true)
            // const current_reco_ranges: Array<any> = []
            // u31HighlightRecoNodes.forEach((nodeWithPos) => {
            //     nodeWithPos.node.marks.forEach(mark => {
            //         if (mark.type.name == "u31HighlightReco") {
            //             mark.attrs.u31recommandations.forEach((reco) => {
            //                 if (props.reco.id == reco.id) {
            //                     current_reco_ranges.push({ from: nodeWithPos.pos, to: nodeWithPos.pos + nodeWithPos.node.nodeSize })
            //                 }
            //             });
            //         }
            //     });
            // })

            // const editor = props?.editor;
            // if (!editor) return;

            // editor.commands.focus();
            // for (let i = 0; i < current_reco_ranges.length; i++) {
            //     const range = current_reco_ranges[i];
            //     // current_reco_ranges.forEach(range => {
            //     editor.commands.setTextSelection(range);
            //     const attributes = getAttributes(editor.state, editor.schema.marks.u31HighlightReco);
            //     const text = getSelectedText(props.editor);
            //     console.log("setIgnoredCardIds attributes", props.reco.id, attributes, text);
            //     editor.commands.insertContent(text);
            //     editor.commands.setU31HighlightReco({
            //         u31recommandations: attributes.u31recommandations?.filter(reco => reco.id != props.reco.id) || []
            //     })
            //     // editor.commands.setBold();
            //     editor.commands.setU31ActiveCategories([]);
            //     editor.commands.setTextSelection({
            //         from: range.to,
            //         to: range.to
            //     })

            //     // })
            // }


            setTimeout(() => {
                dispatch(setIgnoredCardIds([...ignoredCardIds, props.reco.id]))
                dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: props.nextCardId }))
            }, 400)
        }}>
            Ignorer
        </GrayActionButton >
    )
}

export const ActionValidateBestPractice = (props: { reco: U31Recommandation, documentId: string, nextCardId: string, color: string }) => {
    const dispatch = useDispatch();
    const ignoredCardIds = useSelector(getIgnoredCardIds);
    const validatedBestPracticesIds = useSelector(getValidatedBestPracticesIds);
    const discardedBestPracticesIds = useSelector(getDiscardedBestPracticesIds);
    const hiddenCardsIds = useSelector(getHiddenCardIds);

    const validated = validatedBestPracticesIds.includes(props.reco.id);

    return validated ?
        <ActionValidateBestPracticeButton
            color={"green"}
        >
            Oui
        </ActionValidateBestPracticeButton> :

        <ActionButton
            // variant="normal"
            color={"green"}
            onClick={() => {
                console.log("ActionValidateBestPractice", props.reco)
                validateBestPractices(props.documentId, props.reco.id)
                dispatch(setValidatedBestPracticesIds([...validatedBestPracticesIds, props.reco.id]))
                dispatch(setDiscardedBestPracticesIds([...discardedBestPracticesIds.filter((bpId: string) => bpId != props.reco.id)]));
                // dispatch(setOpenCardId(null))
                // setTimeout(() => {
                //     dispatch(setIgnoredCardIds([...ignoredCardIds, props.reco.id]))
                //     dispatch(setOpenCardId(props.nextCardId))
                // }, 400)
            }}>
            Oui
        </ActionButton>

}

export const ActionDiscardBestPractice = (props: { reco: U31Recommandation, documentId: string, nextCardId: string, color: string }) => {
    const dispatch = useDispatch();
    const ignoredCardIds = useSelector(getIgnoredCardIds);
    const discardedBestPracticesIds = useSelector(getDiscardedBestPracticesIds);
    const validatedBestPracticesIds = useSelector(getValidatedBestPracticesIds);
    const hiddenCardsIds = useSelector(getHiddenCardIds);

    const discarded = discardedBestPracticesIds.includes(props.reco.id);
    return discarded ?
        <ActionValidateBestPracticeButton
            color={"red"}
        >
            Non
        </ActionValidateBestPracticeButton> :
        <GrayActionButton
            onClick={() => {
                console.log("ActionDiscardBestPractice", props.reco)
                discardBestPractices(props.documentId, props.reco.id)
                dispatch(setDiscardedBestPracticesIds([...discardedBestPracticesIds, props.reco.id]));
                dispatch(setValidatedBestPracticesIds([...validatedBestPracticesIds.filter((bpId: string) => bpId != props.reco.id)]));
                setTimeout(() => {
                    dispatch(setHiddenCardIds([...hiddenCardsIds, props.reco.id]))
                }, 500)
                // setTimeout(() => {
                //     dispatch(setIgnoredCardIds([...ignoredCardIds, props.reco.id]))
                //     dispatch(setOpenCardId(props.nextCardId))
                // }, 400)
            }}>
            Non
        </GrayActionButton>
}

export const ActionUndoIgnoreReco = (props: { reco: U31Recommandation }) => {
    const dispatch = useDispatch();
    const ignoredCardIds = useSelector(getIgnoredCardIds);
    const lastRevisionState: RevisionState = useSelector(getRevisionState);

    return (
        <GrayActionButton onClick={() => {
            const newIgnoredCards = ignoredCardIds.filter(recoId => recoId != props.reco.id);
            dispatch(setIgnoredCardIds(newIgnoredCards));
            setIgnoredCards(lastRevisionState.document_backup_id, newIgnoredCards);

            dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
            setTimeout(() => {
                dispatch(setOpenCardIdForCategory({ category: props.reco.category, id: null }))
            }, 400)
        }}>rétablir</GrayActionButton>
    )

}


// const NextStep = (props: { cardId: string, nextCardId: string }) => {
//     const openCardId = useSelector(getOpenCardId);
//     const dispatch = useDispatch();
//     return (

//         // <StyledNextCardButton style={{ display: isOpen ? "flex" : "none" }} key={`next-${props.nextCardId}`}>
//         <StyledNextCardButton className={`nextCardButton ${openCardId == props.cardId ? "active" : ""}`} key={`next-${props.nextCardId}`}>
//             <button
//                 onClick={() => {
//                     scrollDocumentMarkToTop(props.nextCardId);
//                     dispatch(setOpenCardId(props.nextCardId));
//                     const nextCard = document.querySelector(`[data-id='${props.nextCardId}'] button.header`);
//                     if (!nextCard) return;
//                     nextCard.focus();
//                 }}
//             ><img src="/images/right_arrow.svg" /></button>
//         </StyledNextCardButton>

//     )
// }

const scrollDocumentMarkToTop = (cardId: string) => {
    const scrollableY = document.querySelector(".scrollableY")
    const editor = document.querySelector("#editorWrapper")
    if (!scrollableY || !editor) return;
    const target = Array.from(document.querySelectorAll(".u31-underlined")).find(elem => JSON.parse(elem.getAttribute('u31-ids')).includes(cardId))
    if (!target) return;
    editor.scrollBy({ top: target.getBoundingClientRect().y - editor.getBoundingClientRect().y - scrollableY.getBoundingClientRect().y - 150, behavior: "smooth" })
}


