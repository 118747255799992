
import { NodeViewWrapper } from '@tiptap/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ImageSizeExtensionOptions } from './ImageSizeExtension';
import { IconMove } from './IconMove';
import { IconResize } from './IconResize';
import { Loader } from './Loader';
import styled from 'styled-components';

const covertPxToNum = (value: string | number) => {
    if (typeof value === 'string') {
        return Number(value.split('px')[0])
    }
    return value
}

const iconSizes = [16, 18, 20]

const StyledImageOptions = styled.div`
    display: flex;
    flex-flow: column;
    position: absolute;
    bottom: 0;
    right: 2px;
    opacity: 0.6;
    background-color: #fff;
    .sizes {
        display: flex;
        .image-resize-plugin-icon {
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            cursor: pointer;
            width: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }
    .alt {

    }

`;
const StyledAltText = styled.div`
&.image {
  width: fit-content;
  position: relative;
}

  bottom: 0;
  left: 0;
  max-width: calc(100% - 20px);
  padding: 0 0.5em;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  gap: 0.25em;

.symbol {
  flex: 0 0 auto;
  font-size: 1.4em;
  font-weight: bold;
}

.symbol-positive {
  color: #19961b;
}

.symbol-negative {
  color: #961f1f;
}

.text {
  flex: 1 1 auto;
  /* overflow: hidden; */
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.edit {
  flex: 0 0 auto;

  border: 0;
  padding: 0;
  background: transparent;
  appearance: none;

  text-decoration: underline;
}
`;

export const AutoSizeImage = (props: any) => {
    const { updateAttributes } = props

    const imageProps = props.node.attrs;
    const options = props.extension.options as ImageSizeExtensionOptions;


    const ref = useRef<HTMLDivElement>(null);

    const [isLoad, setIsLoad] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [imageWidth, setImageWidth] = useState(imageProps.width)
    let className = 'image'
    if (props.selected) { className += ' ProseMirror-selectednode' }

    const getValidWidth = useCallback(
        (_width: string | number) => {
            const width = covertPxToNum(_width);
            if (width < covertPxToNum(options.minWidth)) return covertPxToNum(options.minWidth);
            if (width > covertPxToNum(options.maxWidth)) return covertPxToNum(options.maxWidth);
            return covertPxToNum(_width);
        },
        [options.maxWidth, options.minWidth]
    );

    useEffect(() => {
        let observer = new ResizeObserver(function (mutations) {
            const { contentRect } = mutations[0]
            const { width, height } = contentRect
            try {
                props.updateAttributes({ width: `${width}px`, height: `${height}px` });
            } catch (error) {
                console.warn("u31, error while upadating picture attribute", error)
            }
        });
        isLoad && observer.observe(ref.current!);
    }, [isLoad])

    useEffect(() => {
        const onMouseMove = (e: any) => {
            if (isActive && props.selected) {
                const width = getValidWidth(e.pageX - (ref?.current?.getBoundingClientRect().left || 0));
                setImageWidth(width);
            }
        }
        const onMouseUp = () => {
            setIsActive(false)
        }
        document.addEventListener('mouseup', onMouseUp)
        document.addEventListener('mousemove', onMouseMove)
        return () => {
            document.removeEventListener('mouseup', onMouseUp)
            document.removeEventListener('mousemove', onMouseMove)
        }
    }, [isActive, props.selected])

    useEffect(() => {
        const preloadImage = (src: string) => {
            let img = new Image();
            img.src = src;
            img.onload = () => {
                setIsLoad(true)
            }
        }
        preloadImage(imageProps.src)
    }, [])


    const onEditAlt = () => {
        const newAlt = prompt(`Description de l'image:`, imageProps.alt || '')
        updateAttributes({ alt: newAlt })
    }

    return (
        <NodeViewWrapper className={className} style={{ maxWidth: "100%", display: options.inline ? 'inline-block' : 'block', width: 'min-content', position: 'relative', zIndex: 10 }}>
            {!isLoad && <Loader width={options.maxWidth} />}
            {isLoad && <div
                ref={ref}
                style={{
                    display: "flex",
                    boxSizing: "border-box",
                    maxWidth: "100%",
                    margin: "2px",
                    outline: props.selected ? `2px solid ${options.activeBorderColor}` : 'none',
                    width: 'max-content'
                }}>
                <img style={{
                    maxWidth: options.maxWidth,
                    minWidth: options.minWidth,
                }} width={imageWidth} src={imageProps.src} />
                {props.selected &&
                    <StyledImageOptions>
                        <div className="sizes">
                            {options.levels.map((level, index) =>
                                <div key={level} title={`${level} px`} className='image-resize-plugin-icon' onClick={() => setImageWidth(level)}><IconResize size={iconSizes[index]} /></div>
                            )}
                            <div title='move' className='image-resize-plugin-icon' onMouseDown={() => setIsActive(true)} style={{ cursor: 'move' }} >
                                <IconMove />
                            </div>
                            {/* <div title='max' className='image-resize-plugin-icon' onClick={() => setImageWidth(options.maxWidth)}><IconResize size={24} /></div> */}
                        </div>
                        <StyledAltText className='alt'>
                            {!imageProps.alt &&
                                <span className="symbol symbol-negative">!</span>
                                // <span className="symbol symbol-positive">✔</span> //:
                            }
                            {imageProps.alt ?
                                <span className="text">{imageProps.alt}</span> :
                                <button className="edit" type="button" onClick={onEditAlt}>
                                    Ajouter une description
                                </button>
                            }
                            {imageProps.alt && <button className="edit" type="button" onClick={onEditAlt}>
                                Modifier
                            </button>}

                        </StyledAltText>

                    </StyledImageOptions>}

                {props.selected && <img data-drag-handle style={{
                    position: "absolute",
                    top: "0.5rem",
                    left: "0.5rem",
                    padding: "0.2rem",
                    width: "20px",
                    cursor: "pointer",
                    background: "rgba(255,255,255,0.6)",
                }} src="/images/icon/drag_handle.svg" />}

            </div>}
        </NodeViewWrapper>
    );
};