import styled from "styled-components"
import Header from "../shared/Header"
import '../../css/landing/common_landing.scopped.css';
// import '../../css/landing/accessibility.scopped.css';
import Footer from "../shared/Footer";
import { StyledGoBack } from "./shared.styled";

const CookiesWrapper = styled.div`
    font-family: "Ubuntu";
    font: normal normal normal 16px/30px Ubuntu;
    letter-spacing: 0px;
    color: black;

    display: flex;
    flex-flow: column;
    padding: 0px 8.51562vw;
    width: 82.9688vw;    
    padding-top: 0;
    padding-bottom: 10rem;

    & #back-home {
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 1.125em;
        letter-spacing: 0.01125em;
        color: var(--blue);

        &:hover {
            & >span {
                text-decoration: underline;
            }
        }

        & >span {
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 1.125em;
            letter-spacing: 0.01125em;
            color: var(--blue);
        }

        & >img {
            width: 2em;
            height: 1em;
            margin-right: 0.5em;
        }
    }



    & h1 {
        font: normal normal 500 26px/35px Ubuntu;
        color: black;
    }

    & h2 {
        font: normal normal 500 22px/35px Ubuntu;
        color: #446EE0;
    }
    & h3 {
        font: normal normal 500 18px/35px Ubuntu;
        color: #446EE0;
    }

    & a {
        color: #446EE0;
    }
`
const Cookies = () => {
    return (
        <>
            <Header />

            <CookiesWrapper>
                <StyledGoBack text="Accueil" href="/" />

                <h1>
                    Politique de cookies
                </h1>
                <p>U31 utilise des cookies dans le but de faciliter votre navigation et de mieux comprendre l’expérience des Clients sur le site web <a target="#" rel="noopener noreferrer nofollow" href="http://u31.io">http://u31.io</a> (Ci-après : le site Internet) et sur la plateforme de gestion de la correction dénommée U31 (Ci-après : la plateforme U31). Les cookies nous permettent de personnaliser le contenu et les annonces, d'offrir des fonctionnalités relatives aux médias sociaux et d'analyser notre trafic. L’objectif est d’améliorer l’utilisation, les prestations et la convivialité du site internet et de la plateforme U31. Nous partageons également des informations sur l'utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse, qui peuvent combiner celles-ci avec d'autres informations que vous leur avez fournies ou qu'ils ont collectées lors de votre utilisation de leurs services. </p>
                <p>Un cookie est un petit fichier texte envoyé par le site Internet que vous consultez dans votre navigateur Internet ou sur l’appareil que vous utilisez. Le cookie a pour fonction de faciliter votre navigation sur le site que vous visitez (mémoriser vos choix techniques, votre activité passée) et à vous proposer des offres et services pertinents. Leur durée de vie maximale est de 13 mois.</p>
                <p>Conformément à la loi, nous ne pouvons stocker des cookies sur votre appareil que s’ils sont strictement nécessaires au fonctionnement de ce site. Pour tous les autres types de cookies, nous avons besoin de votre permission.</p>
                <p>À tout moment, vous pouvez modifier ou retirer votre consentement dès la Déclaration relative aux cookies sur notre site Web.</p>
                <p>Votre consentement s'applique aux domaines suivants :&nbsp; <a target="#" rel="noopener noreferrer nofollow" href="https://u31.io/">
                    https://u31.io/
                </a>
                </p>
                <p>Pour vérifier les mises à jour de cette Politique, nous vous invitons à consulter régulièrement cette page.</p>
                <h2>
                    1.&nbsp;Les cookies utilisés par u31
                </h2>
                <p>Le site internet et la plateforme U31 utilisent différents types de cookies :</p>
                <ul>
                    <li>
                        <p>Les cookies strictement nécessaires à la navigation sur le site Internet ou à l’utilisation de la plateforme U31. Sans ces cookies, vous ne pourrez pas accéder à l’ensemble des fonctionnalités que vous demandez (par exemple : une page sécurisée).</p>
                    </li>
                    {/* <li>
                        <p>Les cookies fonctionnels : ils enregistrent vos préférences et améliorent donc la fonctionnalité du site Internet et de la plateforme U31 (par exemple, configurer votre profil sur la page d’accueil).</p>
                    </li> */}
                    <li>
                        <p>Les cookies analytiques : ces cookies sont utilisés pour recueillir des informations sur votre manière d’utiliser le site internet et/ou la plateforme U31 (par exemple : les pages les plus visitées, la durée des visites) et permettent donc une meilleure expérience d’utilisation. U31 utilise Google Analytics, un service d’analyse web proposé par Google Inc. Ce service utilise des cookies pour analyser comment les utilisateurs utilisent le site internet et la plateforme U31.</p>
                    </li>
                    {/* <li>
                        <p>Les cookies publicitaires et de ciblage permettent de proposer des annonces adaptées à partir des recherches des internautes.</p>
                    </li> */}
                </ul>
                <h2>
                    2.&nbsp;Liste des cookies utilisés
                </h2>
                <h3>
                    2.1&nbsp;Cookies utilisés sur le site Internet
                </h3>
                <p>
                    <b>Cookies analytiques</b>
                </p>
                <p>Nous utilisons le cookie pour connaître les différentes pages consultées sur notre site web. Lors de la soumission du formulaire de demande de démonstration ou de création de compte d’essai par un utilisateur, cet historique est importé sur la nouvelle fiche prospect.</p>
                <p>Nous utilisons des cookies Google Analytics afin de collecter des données concernant vos sessions sur notre site web. Pour plus d’informations concernant le fonctionnement de ces cookies, reportez-vous à la Politique relative à la confidentialité et aux cookies figurant sur le site de Google <a target="#" rel="noopener noreferrer nofollow" href="https://policies.google.com/privacy?hl=fr">https://policies.google.com/privacy?hl=fr</a>. </p>
                {/* <p>
                    <b>Cookies tiers</b>
                </p>
                <p>Les cookies Linkedin, Twitter, Facebook et Google IDE sont utilisés pour cibler les visiteurs de notre site internet à travers ces plateformes.</p>
                <p>
                    <b>Cookies publicitaires et de ciblage</b>
                </p>
                <p>Nous utilisons le cookie Google Dynamic Remarketing pour vous proposer des messages adaptés à vos centres d’intérêt.</p>
                <p>
                    <b>Cookies statistiques</b>
                </p>
                <p>Nous utilisons ces cookies statistiques dans le but d'améliorer notre site en fonction de l’utilisation des visiteurs. Pour cela, vos données peuvent être transmises aux logiciels Convertflow et Google Analytics.</p> */}
                <h3>
                    2.2&nbsp;Cookies utilisés sur la plateforme U31
                </h3>
                <p>
                    <b>Cookies analytiques</b>
                </p>
                <p>Nous utilisons Google Analytics pour analyser votre comportement de navigation dans la plateforme U31.</p>
                {/* <p>
                    <b>Cookies des modules U31</b>
                </p>
                <p>Les cookies de Stripe sont nécessaires pour le fonctionnement du module Stripe, qui est la solution de paiement utilisée par la plateforme U31.</p> */}
            </CookiesWrapper>
            <Footer />
        </>
    )
}

export default Cookies;