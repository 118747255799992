import axios from "axios";
import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { setUser, setUserLoggedIn } from "../../actions/user-actions";
// import { getCookie } from "../../helpers";

const FetchUser = () => {
    const dispatch = useDispatch();
    useEffect(async () => {
        try {
            const auth = (await axios.get(`${process.env.REACT_APP_ACCOUNT_API_URL}/users/me`, { headers: { Authorization: localStorage.getItem("u31token") } }));
            const user = auth.data;
            dispatch(setUser(user));
            dispatch(setUserLoggedIn(true));
        } catch (e) {
            dispatch(setUserLoggedIn(false));
            return;
        }
    }, [])


    return null;
}
export default FetchUser;