import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { U31Button } from "@u31-dev/u31-ui";
import { setActiveCategories } from "../../../../actions/settings-actions";
import { getRemainingWordCount, payRecommendations } from "../../../../helpers";
import { getCategories, getRevisionState } from "../../../../reducers/settings-reducer";
import { getUser } from "../../../../reducers/user-reducer";
import { RevisionState, UserType } from "../../../../types";

const StyledNewRecommandations = styled.div`
    background: white;
    z-index: 3;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-left: auto;
    box-sizing: border-box;
    width: 100%;
    margin-top: auto;

    .divider {
        width: 100%;
        border-bottom: 1px solid #CACFDB;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    p.title {
        font: normal normal normal 14px/18px Ubuntu;
        color: ${props => props.theme.color.primary};
        text-align: center;
    }

    p.availableCredits {
        text-align: center;
        font: normal normal normal 14px/22px Ubuntu;
        color: #738090;

        b {
            color: ${props => props.theme.color.primary};
        }
    }

    button {
        margin: 0;
        padding: 0.8rem;
        width: 100%;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        display: flex;
    }

    .credits {
        background: ${props => props.theme.color.primary};
        color: white;
        font: normal normal bold 14px/18px Ubuntu;
        border-radius: 3px;
        padding: 0.2rem;
        margin: 0;
    }
`

const NewRecommandations = () => {
    const user = useSelector(getUser) as UserType;
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const not_enough_credits = lastRevisionState?.not_enough_credits;
    const categories = useSelector(getCategories);
    const dispatch = useDispatch();


    return (
        <StyledNewRecommandations>
            {lastRevisionState && !lastRevisionState?.payed && lastRevisionState?.prices?.recommendations_price > 0 && !lastRevisionState?.not_enough_credits && user.smart_billing == 0 &&
                <>
                    <div className="divider"></div>
                    <p className="title">Nouvelles détections disponibles</p>
                    <U31Button
                        variant="normal"
                        onClick={async () => {
                            // notifications?.current.setOpen("new_reco", false);
                            await payRecommendations(lastRevisionState, dispatch);
                            // open first category
                            dispatch(setActiveCategories([Object.keys(categories)[0]]))

                        }}
                    >Utiliser <span className="credits">{lastRevisionState?.prices?.recommendations_price}</span> crédits pour afficher</U31Button>
                </>
            }
        </StyledNewRecommandations>
    )
}

export default NewRecommandations;