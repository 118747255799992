import { U31Recommandation } from '@u31-dev/u31-ui';
import {
  SET_RECOMMANDATIONS,
  SET_DOCUMENT_TITLE,
  SET_CHAR_COUNT,
  SET_SCORES,
} from '../actions/nlp-actions';

import { categoryMap } from '../index'


export default function nlpReducer(state: any = '', data: any) {
  const { type, payload } = data;
  switch (type) {
    case SET_DOCUMENT_TITLE:
      return {
        ...state,
        title: payload
      }
    case SET_CHAR_COUNT:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          charCount: payload
        }
      }
    case SET_SCORES:
      return {
        ...state,
        scores: payload
      }
    case SET_RECOMMANDATIONS:
      return {
        ...state,
        ...payload,
        categories_error_count: payload.categories_error_count || {},
        recommandations: payload.recommandations
          .filter((reco: U31Recommandation) => reco.hidden != true)
          .reduce((structuredRecos: any, reco: U31Recommandation) => {
            reco.languages.forEach(lang => {
              if (!structuredRecos[lang])
                structuredRecos[lang] = { "categories": [] }

              reco.category = categoryMap[reco.category];

              const category = reco.category
              const subcategory = reco.subCategory

              if (!Object.keys(structuredRecos[lang]["categories"]).includes(category)) {

                structuredRecos[lang]["categories"][category] = {
                  "key": category,
                  "name": reco.name,
                  "subCategories": {},
                }
              }

              if (!Object.keys(structuredRecos[lang]["categories"][category]["subCategories"]).includes(subcategory))
                structuredRecos[lang]["categories"][category]["subCategories"][subcategory] = {
                  "key": subcategory,
                  "name": reco["subcategory_name"],
                  "errors": []
                }
              structuredRecos[lang]["categories"][category]["subCategories"][subcategory]["errors"].push(reco)
            })
            return {
              ...structuredRecos
            }
          },
            {
              falc: {
                categories: {
                },
                name: "falc",
                score: -1
              },
              lc: {
                categories: {},
                name: "lc",
                "score": -1
              }
            }
          ),
        asArray: payload.recommandations
          .filter((reco: U31Recommandation) => reco.hidden != true)
          .filter((reco: U31Recommandation) => reco?.spans && reco?.spans?.length > 0)
          .sort((recoA: U31Recommandation, recoB: U31Recommandation) => {
            const recoAoffset = recoA?.block_offset || 0;
            const recoBoffset = recoB?.block_offset || 0;
            return (recoA.spans[0][0] + recoAoffset) - (recoB.spans[0][0] + recoBoffset)
          })
          .reduce((acc: any, reco: U31Recommandation) => {
            reco.languages.forEach(lang => {
              if (!acc[lang])
                acc[lang] = []
              acc[lang].push(reco)
            })
            return {
              ...acc
            }
          }, {})
      }

    default:
      return state;
  }
}


// selectors
export function getRecommandations(state: any) {
  return state.nlpReducer.recommandations;
}

export function getCategoriesErrorCount(state: any) {
  return state.nlpReducer.categories_error_count;
}

// instanciate array bcs useSelector compare address of value ([] !== [])
const emptyArray: any[] = [];
// selectors
export const getLanguageRecommandationsAsArray = (language: string) => (state: any) => {
  if (state.nlpReducer && state.nlpReducer.asArray && state.nlpReducer.asArray[language])
    return state.nlpReducer.asArray[language];
  else return emptyArray;
}

export function getScores(state: any) {
  return {
    falc: state.nlpReducer.scores?.falc,
    lc: state.nlpReducer.scores?.lc,
  }
}

export function getMetrics(state: any) {
  return state.nlpReducer.metrics;
}

export function getCalendarPrices(state: any) {
  return state.nlpReducer.prices.serice_prices;
}

export function getDocumentTitle(state: any) {
  return state.nlpReducer.title;
}