import React, { forwardRef, useState, useImperativeHandle, useEffect, useLayoutEffect, useRef, useContext, useMemo } from 'react';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { U31CircularProgress, U31Heading, U31Paragraph, U31Popup, U31Switch, U31Button, U31TextInput, U31TextArea } from "@u31-dev/u31-ui"
import { formatDate, getRemainingWordCount, payRecommendations, sendQuotation, setSmartBilling } from '../../helpers';
import { getMetrics } from '../../reducers/nlp-reducer';
import { getRevisionState } from '../../reducers/settings-reducer';
import { getUser } from '../../reducers/user-reducer';
import { RevisionState } from '../../types';
import { PopupLeftPart, PopupRightPart } from '../shared/popup';
import { PriceCalendar } from '../UI/calendar/Calendar';



const Divider = styled.span`
display: block;
width: 100%;
height: 1px;
background: #324764;
margin-top: 2em;
margin-bottom: 2em;
`;

const Counter = styled(U31Paragraph).attrs(({ theme }) => ({ color: theme.color.primary, fontWeight: 500, fontSize: "15px" }))`
background: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 4px;
width: 100%;
padding: 0.2em;
&>span {
    margin-left: 0.2rem;
}
`;

const Title = styled.h2`
font: normal normal 500 18px/16px Ubuntu;
letter-spacing: 0px;
color: #1B2026;
`;

const SubTitle = styled.h3`
font: normal normal 500 16px/16px Ubuntu;
letter-spacing: 0px;
color: #1B2026;
/* margin-bottom: 2em; */
`;


const Info = styled(U31Paragraph).attrs(() => ({ fontFamily: "Ubuntu", fontSize: "15px", fontWeight: 500 }))`
margin-bottom: 1em;
`;

const BlueBoxContainer = styled.div`
display: flex;
min-width: 35rem;
max-width: 35rem;
justify-content: space-between;
width: 100%;
gap: 2rem;
flex: 1;
height: fit-content;
margin-top: 2rem;
`;

const BlueBox = styled.div`
display: flex;
flex-flow: column;
width: 50%;
flex: 1;
border: 1px solid #446EE0;
border-radius: 7px;
padding: 2rem;
`;

export const BlueBoxTitle = styled.p`
font: normal normal 500 16px/16px Ubuntu;
letter-spacing: 0px;
color: #446EE0;
margin-bottom: 2em;
`;

export const Check = styled(U31Paragraph)`
display: block;
line-height: 1.5rem;
&:before {
    content: url('/images/landing/check.svg');
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-right: 0.5em;
    font-size: 1rem;
    transform: translateY(0.2em);
}`;

const GrayCheck = styled(Check)`
color: #5F6E81;
& b {
    color: #1B2026
}
`

const PriceContainer = styled.div`
    margin-top: auto;
    padding-top: 1em;
`;

const ActionButton = styled(U31Button)`
align-self: center;
margin-top: 2em;
width: 100%;
margin-bottom: 0;
`;


export const GrayBox = styled.div`
background: #F8F8FB;
padding: 1em 2em;
display: flex;
align-items: center;
border-radius: 5px;
`;


export interface PaymentPopupProps {
    onClose?: Function,
    open: boolean,
    options: any,
    setOptions: Function,
    step: any,
    setStep: Function,
}

export const PaymentPopupContext = React.createContext<PaymentPopupProps>({});
export const usePaymentPopup = () => useContext(PaymentPopupContext);

export const PaymentPopupProvider = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [step, setStep] = useState(0);
    const [options, setOptions] = useState({
        "service": { inCart: false, price: null, date: null, visible: true },
        "use_editor": { inCart: false, visible: true, },
    });

    const memoChild = useMemo(() => children, [])

    return (
        <PaymentPopupContext.Provider value={{ open, setOpen, setStep, setOptions, options }}>
            <PaymentPopup open={open} options={options} setOptions={setOptions} step={step} setStep={setStep} />
            {memoChild}
        </PaymentPopupContext.Provider>
    );
};


export const PaymentPopup = (props: PaymentPopupProps) => {
    const popup = usePaymentPopup();
    const open = popup?.open || false;
    
    
    const { options, setOptions, step, setStep } = props;
    const setOpen = (open: boolean) => { setStep(0); popup?.setOpen(open) }

    const LeftPart = () => {
        return (
            <PopupLeftPart>
                <Title>Votre document</Title>
                <Info style={{ marginTop: "2em" }} >Nombre de caractères</Info>
                <Counter>{metrics.charCount}</Counter>

                <Divider />

                <Info>Score de clarté</Info>
                <U31CircularProgress style={{ width: "50%" }} strokeWidth={6} trailColor={"#ffffff"} color={theme?.color?.primary} progress={lastRevisionState?.scores?.lc} />

                <Divider />

                <Info>Solde actuel</Info>
                <Counter>{user?.credits} <span style={{ color: "#000" }}> crédits</span></Counter>

            </PopupLeftPart>
        );
    }

    const RightPart = () => {
        switch (step) {
            case 0:
                return <ChooseOffer />
            case 1:
                return <TransferOrder />
            case 2:
                return <Success />
            default:
                return ""
        }
    }

    const Success = () => {
        return (
            <div className="flex center" style={{
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '100%',
                textAlign: 'center',
                padding: '15% 20%'
            }}>
                <img style={{ width: "6rem" }} src="/images/business/check.svg" />
                <U31Heading level={3} color={theme?.color?.primary}>Nous avons bien enregistré votre devis.</U31Heading>
                <U31Paragraph fontSize="18px">Notre équipe vous contactera prochainement afin de finaliser votre commande.</U31Paragraph>
                <U31Button variant="normal" onClick={() => {setOpen(false)}}>Terminer</U31Button>
            </div>
        )
    }

    const TransferOrder = () => {
        if (!options?.service?.date) {
            setStep(0);
            return "";
        }

        useLayoutEffect(() => {
            document.querySelector(`#firstname`).value = user.firstname
            document.querySelector(`#lastname`).value = user.lastname
            document.querySelector(`#email`).value = user.email
        }, [user])

        return (
            <>
                <Title>Transférez-nous votre devis</Title>
                <div className="flex" style={{ gap: "2em", flex: 1 }}>
                    <div className="flex column" style={{ width: "55%" }}>
                        <SubTitle>Votre commande</SubTitle>
                        <GrayBox style={{ marginTop: "1em", flex: 1, flexFlow: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                            <ul style={{ paddingLeft: "1em" }}>
                                <li>
                                    <U31Paragraph fontWeight="600" style={{ marginBottom: "1em" }}>Document en Langage Clair</U31Paragraph>
                                    <GrayCheck>Adaptation par un <b>professionnel</b></GrayCheck>
                                    <GrayCheck><b>Aucune action</b> requise</GrayCheck>
                                    <GrayCheck>Livrable <b> prêt à l’emploi</b></GrayCheck>
                                    <U31Paragraph style={{ marginTop: "2em" }}>Livraison garantie le : <b style={{ color: theme?.color?.primary }}>{formatDate(options?.service?.date)}</b></U31Paragraph>
                                </li>

                            </ul>
                            <div className="flex center-v space-between column" style={{ width: "100%", alignItems: "flex-start", marginTop: "1em" }}>
                                <Divider />
                                <U31Paragraph color="#8A94AF" fontSize="16px" fontWeight="500" >Total TTC<b style={{ color: "#3A3C40", fontSize: "25px", display: "block" }}>{options?.service?.price}€</b> </U31Paragraph>
                            </div>
                        </GrayBox>

                    </div>
                    <div className="flex column" style={{ width: "45%" }}>
                        <SubTitle>Vos informations</SubTitle>
                        <form
                            style={{ paddingTop: "1em", flex: 1, display: "flex", flexFlow: "column", justifyContent: "space-between" }}
                            onSubmit={async (event: React.SyntheticEvent<HTMLFormElement>) => {
                                event.preventDefault();
                                const form = event.currentTarget;
                                const formElements = form.elements;
                                const firstname = formElements[0].value;
                                const lastname = formElements[1].value;
                                const email = formElements[2].value;
                                const phone = formElements[3].value;
                                const comment = formElements[4].value;

                                await sendQuotation({
                                    firstname,
                                    lastname,
                                    email,
                                    phone,
                                    comment,
                                    date: options.service.date,
                                    document_id: lastRevisionState.document_id,
                                    version_id: lastRevisionState.document_backup_id,
                                });
                                setStep(2);
                                return false;
                            }}
                        >
                            <div>
                                <U31TextInput id="lastname" label="Nom" />
                                <U31TextInput id="firstname" label="Prénom" />
                                <U31TextInput id="email" label="Email" />
                                <U31TextInput label="Téléphone" />
                            </div>
                            <div>
                                <SubTitle style={{ marginTop: "3em" }}>Commentaire pour le transcripteur</SubTitle>
                                <U31TextArea rows={6} />
                            </div>
                            <U31Button
                                type="submit"
                                variant="normal"
                                style={{ alignSelf: "flex-end", fontSize: "1.2em", marginBottom: 0, marginTop: "1em" }}>
                                Transférer
                            </U31Button>
                        </form>
                    </div>
                </div>

            </>
        )
    }
    const ChooseOffer = () => {
        const calendarRef = useRef(null);

        return (
            <>
                <Title>Nos solutions sur mesure</Title>
                <BlueBoxContainer>
                    {options["use_editor"].visible &&
                        <BlueBox>
                            <BlueBoxTitle>J’utilise l’outil U31.</BlueBoxTitle>
                            <Check><b>Recommandations</b> de U31</Check>
                            <Check>Intégralité des <b>fonctionnalités</b></Check>
                            <Check><b>Guide complet</b> du Langage Clair</Check>
                            <Divider />
                            {/* <div className="flex center-v">
                                <label htmlFor="smart_billing" style={{ fontSize: "14px", fontFamily: "Ubuntu", color: "#515E6E", whiteSpace: "nowrap", fontWeight: 500, marginRight: "1em" }}>Facturation intelligente </label>
                                <U31Switch checked={user?.smart_billing || false} onChange={(checked: boolean) => { setSmartBilling(checked, dispatch) }} />
                            </div> */}
                            <U31Paragraph fontFamily="Ubuntu" color="#515E6E">Mots restants : <b style={{ color: theme?.color?.primary }}>{getRemainingWordCount(user)}</b></U31Paragraph>

                            <PriceContainer style={{ marginTop: "1em" }}>
                                <U31Paragraph fontSize="16px" fontWeight="500" ><b style={{ color: theme?.color?.primary, fontSize: "25px" }}>{lastRevisionState?.prices?.recommendations_price}</b> crédits</U31Paragraph>
                            </PriceContainer>

                            <ActionButton
                                variant={options["use_editor"].inCart ? "normal" : "light"}
                                onMouseDown={() => {
                                    setOptions({
                                        ...options,
                                        use_editor: {
                                            ...options.use_editor,
                                            inCart: true
                                        },
                                        service: {
                                            ...options.service,
                                            inCart: false
                                        }
                                    });
                                }}
                            >Sélectionner cette option</ActionButton>
                        </BlueBox>
                    }

                    {options["service"].visible &&
                        <BlueBox>
                            <BlueBoxTitle>Je fais adapter mon document.</BlueBoxTitle>
                            <Check>Adaptation par un <b>professionnel</b></Check>
                            <Check><b>Aucune action</b> requise</Check>
                            <Check>Livrable <b> prêt à l’emploi</b></Check>
                            <Divider />

                            <div className="flex center-v" style={{ marginBottom: "1em" }}>
                                <img src="/images/business/wall-clock.svg" style={{ marginRight: "0.5em" }} />
                                <p style={{ margin: 0, color: "#6D7488" }}>Date de livraison garantie :</p>
                            </div>

                            <PriceCalendar ref={calendarRef} calendarPrices={calendarPrices} date={options["service"].date} updateDateAndPrice={(date, price) => {
                                let _options = { ...options }
                                // toggle
                                _options["use_editor"] = { inCart: false, price: null, date: null },

                                    _options["service"].price = price;
                                _options["service"].date = date;
                                setOptions(_options)
                            }} />

                            <PriceContainer >
                                {options["service"].price && <U31Paragraph color="#8A94AF" fontSize="16px" fontWeight="500" ><b style={{ color: "#3A3C40", fontSize: "25px" }}>{options["service"].price}€</b> TTC</U31Paragraph>}
                            </PriceContainer>

                            <ActionButton
                                variant={options["service"].inCart ? "normal" : "light"}
                                onMouseDown={() => {
                                    if (options?.service?.date) {

                                        setOptions({
                                            ...options,
                                            use_editor: {
                                                ...options.use_editor,
                                                inCart: false
                                            },
                                            service: {
                                                ...options.service,
                                                inCart: true
                                            }
                                        })
                                    }

                                }}
                            >Sélectionner cette option</ActionButton>

                        </BlueBox>
                    }
                </BlueBoxContainer>
                <Divider />
                <div className="flex space-between">
                    <div>
                        <U31Paragraph fontSize="15px" color="#5F6E81">Montant pour ce document</U31Paragraph>
                        <U31Paragraph fontWeight="600" fontSize="26px" style={{ height: "1em" }}>
                            {options?.use_editor?.inCart && `${lastRevisionState?.prices?.recommendations_price} crédits`}
                            {options?.service?.inCart && `${options?.service?.price} €`}
                        </U31Paragraph>
                    </div>
                    <U31Button
                        style={{ margin: 0, fontSize: '1.1rem' }} variant="normal"
                        onMouseDown={() => {
                            const choice = Object.keys(options).find(key => options[key].inCart == true);
                            if (choice == "service") {
                                setStep(1);
                            }
                            else if (choice == "use_editor") {
                                // close popup
                                setOpen(false);
                                payRecommendations(lastRevisionState, dispatch);
                            }
                        }}
                    >Confirmer mon choix</U31Button>
                </div>

            </>
        );
    }

    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const lastRevisionState: RevisionState = useSelector(getRevisionState);
    const metrics = useSelector(getMetrics);
    const calendarPrices = lastRevisionState?.prices?.service_prices;




    // deal with popup size
    const [size, setSize] = useState({ width: undefined, height: undefined })
    const popupRef = useRef<HTMLDivElement>();
    useEffect(() => {
        if (popupRef.current && !size.width && !size.height) {
            popupRef.current.focus();
            setSize({
                width: `${popupRef.current.offsetWidth + 100}px`,
                height: `${popupRef.current.offsetHeight}px`
            })
        }
    }, [popupRef.current])



    return (
        open ?
            <U31Popup
                ref={popupRef}
                width={size.width}
                height={size.height}
                onClose={() => { setOpen(false) }}
            >
                <>
                    {step != 2 && <LeftPart />}
                    <PopupRightPart>
                        < RightPart />
                    </PopupRightPart>
                </>
            </U31Popup> : ""

    )
};
