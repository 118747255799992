import FileHandler from '@tiptap-pro/extension-file-handler'
import { Editor } from '@tiptap/core';
import { analyze } from '../../../../../helpers';


interface IUploadResponse {
    url: string
}
const uploadImage = async (blob: Blob) => {
    const data = new FormData();
    if (blob)
        data.append('picture', blob, 'image.jpeg');

    const result = await (await fetch(`${process.env.REACT_APP_STORAGE_URL}/upload/picture`, {
        method: "POST",
        body: data,
    })).json() as IUploadResponse;
    console.log("UPLOADED ", result)
    return result.url;
}

const handleFile = async (editor: Editor, files: File[]) => {
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileReader = new FileReader()
        const uploadedUrl = await uploadImage(file);
        editor && editor.chain().focus().setImage({ src: uploadedUrl }).run()
    }

    const documentId = window.location.pathname.split('/document/')[1];
    analyze({
        // @ts-ignore
        editor,
        documentId: documentId,
        dispatch: () => {},
        demo: false,
        callback: () => {
        }
      });}
const fileHandler = FileHandler.configure({
    allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
    onDrop: async (editor, files, pos) => handleFile(editor, files),
    onPaste: (editor, files, htmlContent) => handleFile(editor, files),
});

export default fileHandler;