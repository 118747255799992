import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components"
import { getNlpLoading } from "../../reducers/settings-reducer";


interface CSProps {

}
const StyledClariScore = styled.div<CSProps>`
    width: max-content;
    width: max-content;
    display: flex;
    border: 1px solid #CACFDB;
    width: max-content;
    border-radius: 25px;
    gap: 0.4rem;
    align-self: center;

    --animation-speed      : 4s;
    --color-first          : #FD5B61;
    --color-second         : #FD905F;
    --color-third          : #FAB430;
    --color-forth          : #169A7D;
    --block-width          : calc(12rem - 2rem);
    --block-height         : 2.2rem;
    --border-width         : 0.5rem;
    --border-radius-outer  :     25px;
    --border-radius-inner  : calc(var(--border-radius-outer) / 2);
    --font-plain           : 'IBM Plex Sans', sans-serif;
    --font-special         : 'Fredoka One', sans-serif;

    box-sizing: border-box;
    line-height: 1.4;

    &.loading {
        .rainbow {
            display: block !important;
        }
        .c-subscribe-box {
            overflow: hidden;
        }
        .part {
            &:before {
                /* display: none; */
            }
        }
    }

    .rainbow {
        display: none;
        width: var(--block-width); 
        height: var(--block-width);
        margin-top: -55px;
        animation: o-rotate-360 linear var(--animation-speed) infinite;

        span {
            display: block;
            width: calc(var(--block-width) / 2); 
            height: calc(var(--block-width) / 2);
            position: relative;

            &:after {
                display: block;
                content: "";
                width: 100%; height: 100%;
                position: absolute;
                left: 100%;
            }

            &:first-child {
                background: var(--color-first);
                &:after { 
                    background: var(--color-second);
                }
            }

            &:last-child {
                background: var(--color-third);
                &:after { 
                    background: var(--color-forth)
                }
            }
        }
    }


    // Components
    .c-subscribe-box {
        width :  var(--block-width);
        height:  var(--block-height);
        overflow: visible;
        position: relative;
        box-shadow: 0 10px 40px -10px rgba(0,64,128,.2);
        border-radius: var(--border-radius-outer);
    }


.content {
    position: absolute;
    width: calc(var(--block-width) - var(--border-width));
    height: calc(var(--block-height) - var(--border-width));
    top: 0;
    border: var(--border-width) solid transparent;
    margin: calc(var(--border-width) / 2);
    box-sizing: border-box;
    background: white;
    border-radius: 25px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-around;
}

// Objects
@keyframes o-rotate-360 {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
}
`;

interface CSPartProps {
    active: boolean,
    color: string
}

const ClariScorePart = styled.div<CSPartProps>`
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.active ? props.color : "none"};
    font: ${props => props.active ? "normal normal 500 28px/35px Ubuntu" : "normal normal normal 21px/36px Ubuntu"};
    color: ${props => props.active ? "#fff" : "#8B9BAF"};

    p {
        z-index: 2;
    }

    &.active:before {
        content: "";
        width: calc(20% + 0.1rem);
        height: calc(var(--block-height) + 0.5rem);
        border-radius: 3px;
        background: ${props => props.color};
        position: absolute;
        z-index: 1;
    }

`

interface PartsInterface { [key: number]: { color: string, label: string }; }

const PARTS: PartsInterface = {
    20: { color: "#FD5B61", label: "E" },
    40: { color: "#FD905F", label: "D" },
    60: { color: "#FAB430", label: "C" },
    80: { color: "#83D8BE", label: "B" },
    100: { color: "#169A7D", label: "A" },
}

export const getClariScoreInfo = (score: number) => {
    const part = Object.keys(PARTS).find(part => parseInt(part) >= score);
    if (!part) return;
    return PARTS[parseInt(part)];
}

const num_parts = Object.keys(PARTS).length;


interface ClariScoreProps {
    score: number
}
export const ClariScore = (props: ClariScoreProps) => {

    const isNlpLoading = useSelector(getNlpLoading);

    return <StyledClariScore id="clariscore" className={isNlpLoading ? "loading" : ""}>

        <div className="c-subscribe-box">
            <div className="rainbow"><span></span><span></span></div>
            <div className="content">
                {
                    Object.keys(PARTS)
                        .reverse()
                        .map((_key, i) => {
                            const key = parseInt(_key);
                            const isPartActive = !isNlpLoading && Object.keys(PARTS).find(part => parseInt(part) >= props.score) == key.toString();
                            return <ClariScorePart
                                key={PARTS[key].label}
                                className={`${isPartActive ? "active" : ""} part`}
                                active={isPartActive }
                                color={PARTS[key].color}
                            >
                                <p>{PARTS[key].label}</p>
                            </ClariScorePart>
                        })
                }
            </div>
        </div>
    </StyledClariScore>

}