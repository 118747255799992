import React, { Suspense } from 'react'
import styled, { keyframes } from 'styled-components'

const dashAnimation = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`;

const rotateAnimation = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
    & .spinner {
        animation: ${rotateAnimation} 2s linear infinite;
        z-index: 2;
        width: ${props => props?.size || "100px"};
        height: ${props => props?.size || "100px"};

        & .path {
            stroke: ${props => props?.color || "#446EE0"};
            stroke-linecap: round;
            animation: ${dashAnimation} 1.5s ease-in-out infinite;
        }
    }
`

export const SpinnerLoading = (props) => <Spinner {...props}>
    <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2"></circle>
    </svg>
</Spinner>

export const FullPageLoader = () => <div style={{
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}}>
    <SpinnerLoading />
</div>

export default (props: any) => {
    return <Suspense fallback={<FullPageLoader />}>
        {props.children}
    </Suspense>

}