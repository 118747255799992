
import { NodeViewWrapper } from '@tiptap/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { U31Button } from "@u31-dev/u31-ui"
import { setImportExportPopupOpen, setImportExportPopupType } from '../../../../../actions/settings-actions'



const ImportButton = styled.button`
    background:#F8F8FB;
    border: none;
    font: normal normal 500 13px/20px Ubuntu;
    letter-spacing: 0px;
    color: #40505E;
    padding: 0.7rem 0.7rem;
    display: flex;
    justify-content: center;
    gap: 0.7rem;
    &:hover {
        cursor: pointer;
    }
`

const Or = styled.p`
    font: normal normal 500 14px/30px Ubuntu;
    letter-spacing: 0px;
    color: #6A788A;
`

export default (props: any) => {

    const dispatch = useDispatch();
    const openImportPopup = () => {
        dispatch(setImportExportPopupOpen(true));
        dispatch(setImportExportPopupType("import"));
    }

    return (
        <NodeViewWrapper contentEditable={false} id="importDocExt">
            <Or >OU</Or>
            <ImportButton onClick={openImportPopup}> <img src="/images/upload_gray.svg" alt="" /> Cliquez ici pour importer un fichier .docx</ImportButton>
        </NodeViewWrapper>

    )
}
